import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {DoiService, DoiObject, DoiSelectionCriteria, DoiObjectPanel} from '../../doi/DoiModule';
import {ObjectFile, KlaraObjectPanel, PropertyValue, UnitValueReader, Unit, PropertyValueList} from '../../klara/KlaraModule';
import {ProcessService} from '../model/ProcessService';
import {ProcessPart, ProcessObject, ProcessChild, ProcessActivity, ProcessActType, ProcessUnit } from '../model/ProcessObject';
import {UnitPart, UnitService} from '../../unit/UnitModule';

@Component({
	templateUrl: 'ProcessObjectGeneralPanel.html'
})
export class ProcessObjectGeneralPanel extends KlaraObjectPanel<ProcessObject>
{
	name = 'general';
	children: ProcessObject[];
	processActTypes: DoiObject[];
	units: Unit[];
	unitService: UnitService;
	processService: ProcessService;

	propertyValueList = new PropertyValueList(true);

	constructor(doi: DoiService, route: ActivatedRoute, processService: ProcessService, unitService: UnitService)
	{
		super(doi, route);
		this.unitService = unitService;
		this.processService = processService;
	}

	/**
	 * Return the icon class for the specified object.
	 */
	processIconClass(process: ProcessChild)
	{
		return this.iconClass(process.iconName);
	}

	/**
	 * Return a router link to the specified object
	 */
	routerLinkProcess(process : ProcessChild)
	{
		return [ '/process', process.processID, 'general' ];
	}

	routerLinkActType(actType : ProcessActType)
	{
		return [ '/processacttype', actType.processActTypeID ];
	}

	/**
	 * Download an object file.
	 */
	downloadObjectFile(objectFile: ObjectFile): void
	{
		this.processService.downloadObjectFile(objectFile);
	}

	/**
	 * Open the specified object
	 */
	openProcess(process : ProcessChild)
	{
		this.doi.router.navigate([ '/process', process.processID, { outlets: { tab: ['general'] } } ]);
	}

	/**
	 * 
	 */
	propertyValues() : PropertyValue[]
	{
		if (!this.object || !this.object.propertyValues)
			return PropertyValueList.empty;

		return this.propertyValueList.propertyValues(this.object.propertyValues, null, this.editing(), this.editingAsStructAdm());
	}

	/**
	 * Return the process text for the specified process.
	 */
	processText(process: ProcessObject)
	{
		return process && (process.descriptionShort || process.description);
	}

	readChildren(): Observable<ProcessObject[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Process_ProcessID', 'Process_ParentProcessID', 'Process_ProcessTypeID', 'Process_Code', 'Process_NameList', 'Process_DescriptionShort')
			.where('Process_ParentProcessID', '='+this.objectID)
			.orderBy('Process_Code', 'Process_ParentProcessID');

		return this.service().readObjectSelection(null, criteria);
	}

	readProcessActTypes(): Observable<DoiObject[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('ProcessActType_ProcessActTypeID', 'ProcessActType_ProcessID', 'ProcessActType_NameList', 'ProcessActType_Sequence')
			.where('ProcessActType_ProcessID', '='+this.objectID)
			.orderBy('ProcessActType_Sequence')
			.limit(this.readProcessActTypesLimit());

		return this.doi.brokerService('ProcessActType').readObjectSelection(null, criteria);
	}

	readProcessActTypesLimit(): number
	{
		return 11;
	}

	refreshView(): Observable<any>
	{
		return forkJoin(this.refreshViewObservables());
	}

	refreshViewObservables(): Observable<any>[]
	{
		let observables = new Array<Observable<any>>();

		observables.push(
			this.refreshObjectPart(ProcessPart.General),
			this.refreshObjectPart(ProcessPart.Description),
			this.refreshObjectPart(ProcessPart.PropertyValues)
		);

		observables.push(
			this.readChildren().pipe(tap((processes: ProcessObject[]) => {
				this.children = processes;
			})),
			this.readProcessActTypes().pipe(tap((processActTypes: DoiObject[]) => {this.processActTypes = processActTypes})),
			this.refreshObjectPart(ProcessPart.ObjectFiles),
		);

		return observables;
	}
}
// gX5SvmHcXpePl65Ou85kM04kZCY=
