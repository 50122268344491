import {Injectable} from '@angular/core';
import {HttpParams, HttpErrorResponse, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse} from '@angular/common/http';
import {EMPTY, Observer, Observable, Subscription, timer} from 'rxjs';
import {catchError, map, startWith, switchMap, tap} from "rxjs/operators";

import {DoiService} from '../../doi/DoiModule';
import {DoiIdentityProvider} from './DoiIdentityProvider';

/**
 * An authentication start response.
 */
class AuthenticationStart
{
	/**
	 * A device token, unique to the user, identifies the access granted to the user. Not sent to the IDP.
	 */
	deviceToken: string;

	/**
	 * A request token used during the authentication flow. Sent to the IDP.
	 */
	requestToken: string;

	/**
	 * A request secret used during the authentication flow. Not sent to the IDP.
	 */
	requestSecret: string;

	/**
	 * A random value to prevent replay.
	 */
	nonce: string;

	/**
	 * The IDP URI.
	 */
	uri: string;

	/**
	 * Optional comment, e g app@hostname.
	 */
	comment: string;

	/**
	 * What to do when authorization is completed.
	 */
	completion: string;
}

/**
 * A login access response.
 */
class LoginAccess
{
	/**
	 * The user name that the user entered or selected for authentication.
	 */
	userIdentityName: string;

	/**
	 * The user name actually used for authentication.
	 */
	userLoginName: string;

	/**
	 * The primary user name used by the application.
	 */
	userName: string;

	/**
	 * The password.
	 */
	password: string;

	/**
	 * An error message.
	 */
	errorMessage: string;
}

/**
 * A login service that uses an identity provider to supply credentials that are then passed to the auth service.
 */
@Injectable()
export class DoiLoginService
{
	/**
	 * The DOI service.
	 */
	doi: DoiService;

	private timerSubscription: Subscription;

	/**
	 * Construct a new login service.
	 */
	constructor(doi: DoiService)
	{
		this.doi = doi;
	}

	/**
	 * Fetch a login access with credentials if it has become available.
	 */
	fetchLoginAccess(requestSecret: string): Observable<LoginAccess>
	{
		let params = new HttpParams().set('requestSecret', requestSecret);

		return this.doi.http.get<LoginAccess>(this.doi.urlContext()+'/login/auth/fetchLoginAccess', { params: params });
	}

	/**
	 * Cancel any pending login timer.
	 */
	cancelTimer()
	{
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
			this.timerSubscription = null;
		}
	}

	finalCountdown: number;

	/**
	 * Wait for credentials to be available. Delegates to loginWithCredentials which sends null or an error message to the observer.
	 */
	loginWait(providerWindow: Window, requestSecret: string, expires: Date, observer: Observer<string>)
	{
		this.cancelTimer();

		this.timerSubscription = timer(1000).subscribe(tick =>
		{
			this.cancelTimer();

			this.fetchLoginAccess(requestSecret).subscribe((response: LoginAccess) => {
				if (response) {
					if (response.errorMessage)
						observer.next(response.errorMessage);
					else {
						this.loginWithCredentials(response.userLoginName, response.password, expires, observer);
					}
				} else {
					if (providerWindow == null || providerWindow.closed) {
						if (this.finalCountdown) {
							if (--this.finalCountdown <= 0) {
								observer.next('');
								return;
							}
						} else {
							this.finalCountdown = 2;
						}
					} else {
						this.finalCountdown = null;
					}
					this.loginWait(providerWindow, requestSecret, expires, observer);
				}
			})
		});
	}

	/**
	 * Log in with the specified credentials. Delegates to the auth service and sends null or an error message to the observer.
	 */
	loginWithCredentials(userName: string, password: string, expires: Date, observer: Observer<string>)
	{
		return this.doi.auth.login(userName, password, expires).subscribe(
			result => {
				observer.next(null);
			},
			(error:HttpErrorResponse) => {
				observer.next('Felaktigt användarnamn eller lösenord'); // TODO: i18n
			},
			() => {
				observer.next(null);
			}
		);
	}

	/**
	 * Log in using the specified provider. Starts the authentication process and opens a window showing the provider's login page, then
	 * invokes loginWait to wait for credentials to be available.
	 */
	loginWithProvider(provider: DoiIdentityProvider, expires: Date, promptLogin: boolean, comment: string, observer: Observer<any>)
	{
		let deviceToken = this.doi.storage.getContextItem('deviceToken', null);

		let params = new HttpParams()
			.set('provider', provider.providerID)
			.set('completion', 'close');

		if (comment)
			params = params.set('comment', comment);

		if (promptLogin)
			params = params.set('promptLogin', 'true');

		if (deviceToken)
			params = params.set('deviceToken', deviceToken);

		this.doi.http.get<AuthenticationStart>(this.doi.urlContext()+'/login/auth/authenticationStart', { params: params })
		.subscribe(
			(response: AuthenticationStart) => {
				if (response.deviceToken)
					this.doi.storage.setContextItem('deviceToken', response.deviceToken);
				let uri = response.uri;
				let requestSecret = response.requestSecret;
				let ww = window.document.body.clientWidth, wh = window.document.body.clientHeight;
				let wx = window.screenX+window.document.body.clientLeft, wy = window.screenY+window.document.body.clientTop;
				let dw = 500, dh = 600;
				let dx = (ww-dw)/2+wx, dy = (wh-dh)/2+wy;
				let providerWindow = window.open(uri, "idplogin", 'menubar=no,location=yes,resizable=yes,scrollbars=no,status=no,width='+dw+',height='+dh+',left='+dx+',top='+dy);
				this.loginWait(providerWindow, requestSecret, expires, observer);
			},
			(error) => {
				observer.error(error);
			}
		)
	}
}
