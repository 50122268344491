/**
 * Compare two values using natural ordering. Embedded numbers are sorted numerically.
 * Neither value may be null.
 */
export function naturalOrder(v1: string, v2: string, direction: number = 1): number
{
	let language = navigator.languages[0] || navigator.language;

	return v1.localeCompare(v2, language, {numeric: true, ignorePunctuation: true})*direction;
}

/**
 * Compare two values using the specified comparator. The comparator is only invoked if neither value is null. Null values are
 * considered higher than any other value.
 */
export function nullsLast<T>(a: T, b: T, comp: ((a: T, b: T) => number), direction: number = 1): number
{
	if (a == null)
		if (b == null)
			return 0;
		else
			return 1;
	else
		if (b == null)
			return -1;
		else
			return comp(a, b)*direction;
}

/**
 * Compare two objects by comparing the values of the specified attributes.
 */
export function compareObjects<T extends any>(o1: any, o2: any, direction: number = 1, ...attrs: string[]): number
{
	for (let attr of attrs) {
		let v1 = o1[attr];
		let v2 = o2[attr];
		let c = nullsLast(v1, v2, (v1, v2) => compareValues(v1, v2)*direction);
		if (c)
			return c;
	}
	return 0;
}

/**
 * Compare two values of the same type.
 */
export function compareValues(v1: any, v2: any): number
{
	if (typeof v1 == 'string' && typeof v2 == 'string')
		return naturalOrder(v1, v2);
	else if (typeof v1 == 'number' && typeof v2 == 'number')
		return v1 - v2;
	else if (typeof v1 == 'boolean' && typeof v2 == 'boolean')
		return (v1 ? 1 : 0) -  (v2 ? 1 : 0);
	else if (v1 instanceof Date && v2 instanceof Date)
		return v1.getTime() - v2.getTime();
	return 0;
}