import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {catchError, map, startWith, switchMap, tap} from "rxjs/operators";

import { DocStoreLink, KlaraBrokerService } from '../../klara/KlaraModule';
import { DoiService, DoiBrokerService } from '../../doi/DoiModule';
import { ArchiveService, ArchivePart, ArchiveObject } from '../../archive/ArchiveModule';
import { SeriesPart, SeriesObject } from './SeriesObject';
import { SeriesGroupObject, SeriesGroupPart, SeriesGroupService } from '../../seriesgroup/SeriesGroupModule';

/**
 * Manages Series objects.
 */
@Injectable()
export class SeriesService extends KlaraBrokerService<SeriesObject>
{
	/**
	 * Construct a new Series service.
	 */
	constructor(doi: DoiService, private archiveService: ArchiveService, private seriesGroupService: SeriesGroupService)
	{
		super(doi, 'Series')
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return SeriesObject.iconName;
	}

	/**
	 * Create a new Series object.
	 */
	newObject(objectID: number): SeriesObject
	{
		return new SeriesObject(objectID);
	}

	/**
	 * Create and return an observable that fetches the parent structure.
	 * This implementation returns an observable that fetches the SeriesGroup or Archive, connects it to the Series
	 * and then switches to an ArchiveService.parentObjectObservable.
	 */
	parentObjectObservable(series: SeriesObject): Observable<any>
	{
		if (series.seriesGroupID) {
			return this.seriesGroupService.readObjectPart(series.seriesGroupID, SeriesGroupPart.Label, series.seriesGroup).pipe(
				switchMap((seriesGroup: SeriesGroupObject) => {
					series.seriesGroup = seriesGroup;
					return this.seriesGroupService.parentObjectObservable(seriesGroup);
				})
			);
		} else {
			return this.archiveService.readObjectPart(series.archiveID, ArchivePart.Label, series.archive).pipe(
				switchMap((archive: ArchiveObject) => {
					series.archive = archive;
					return this.archiveService.parentObjectObservable(archive);
				})
			);
		}
	}

	/**
	 * Read an object part.
	 */
	readObjectPart(id: number, partName: string, object?: SeriesObject): Observable<SeriesObject>
	{
		let options = undefined;
		switch (partName) {
			case SeriesPart.Label:
				options = {
					params: new HttpParams().append('related', 'Archive').append('related', 'SeriesGroup')
				};
				break;
			case SeriesPart.General:
				options = {
					params: new HttpParams().append('related', 'Archive')
				};
				break;
			case SeriesPart.ProcessActTypes:
				options = {
					params: new HttpParams().append('related', 'Process')
				};
				break;
			case SeriesPart.Processes:
				options = {
					params: new HttpParams().append('related', 'ProcessStructure').append('related', 'ProcessType')
				};
				break;
		}

		return super.readObjectPart(id, partName, object, options);
	}

	/**
	 * Read search result entries.
	 */
	readSearchResultEntries(objectIDs?: number[]): Observable<SeriesObject[]>
	{
		let params = new HttpParams();

		params = params
			.append('col', 'Series_SeriesID')
			.append('col', 'Series_Signum')
			.append('col', 'Series_Title')
			.append('col', 'Series_Notes');

		for (let objectID of objectIDs) {
			params = params.append('id', objectID.toString());
		}

		return this.readObjectSelection(params, null);
	}
}

// Q0KJozt4tEstwnIsHtPJHZMWHyM=
