import {Injectable} from '@angular/core';

export enum DoiLogLevel
{
	ALL = 0,
	FINE = 500,
	CONFIG = 700,
	INFO = 800,
	WARNING = 900,
	SEVERE = 1000
}

@Injectable()
export class DoiLogService
{
	/**
	 * The log level. Initially INFO.
	 */
	private _level = DoiLogLevel.INFO;
	
	/**
	 * Log a level CONFIG message and zero or more objects.
	 */
	config(message: string, ...objects: any[]): void
	{
		this.log(DoiLogLevel.CONFIG, message, ...objects);
	}
	
	/**
	 * Log a level FINE message and zero or more objects.
	 */
	fine(message: string, ...objects: any[]): void
	{
		this.log(DoiLogLevel.FINE, message, ...objects);
	}
	
	/**
	 * Log a level INFO message and zero or more objects.
	 */
	info(message: string, ...objects: any[]): void
	{
		this.log(DoiLogLevel.INFO, message, ...objects);
	}
	
	/**
	 * Log a level WARNING message and zero or more objects.
	 */
	warning(message: string, ...objects: any[]): void
	{
		this.log(DoiLogLevel.WARNING, message, ...objects);
	}
	
	/**
	 * Log a level SEVERE message and zero or more objects.
	 */
	severe(message: string, ...objects: any[]): void
	{
		this.log(DoiLogLevel.SEVERE, message, ...objects);
	}
	
	/**
	 * The log level. Initially INFO.
	 */
	get level()
	{
		return this._level;
	}
	set level(level: DoiLogLevel)
	{
		this._level = level;
		this.log(level, 'Log level changed.');
	}
	
	/**
	 * Log a message and zero or more objects.
	 */
	log(level: DoiLogLevel, message: string, ...objects: any[]): void
	{
		if (!this.isLoggable(level))
			return;
			
		switch (level) {
			case DoiLogLevel.SEVERE :
				console.error(DoiLogLevel[level]+' - '+message);
				for (let object of objects) {
					console.error(object);
				}
				break;
			case DoiLogLevel.WARNING :
				console.warn(DoiLogLevel[level]+' - '+message);
				for (let object of objects) {
					console.warn(object);
				}
				break;
			default:
				console.log(DoiLogLevel[level]+' - '+message);
				for (let object of objects) {
					console.log(object);
				}
		}
	}
	
	/**
	 * Test if the specified level would be logged.
	 */
	isLoggable(level: DoiLogLevel): boolean
	{
		return level >= this.level;
	}
}
