/**
 * Information about the session.
 */
export class DoiAuthSession
{
	/**
	 * The unique user ID identifying a user meta data object in the backend application. May be null or undefined.
	 */
	readonly userid: number;

	/**
	 * The primary user name, usually identifying application specific meta data about the user, typically in a User entity.
	 * With classic password login, this is the same as the login name. With IDP login, it may be different.
	 */
	readonly userName: string;

	/**
	 * The identity name.
	 * With classic password login, this is the same as the user name. With IDP login, it may be different.
	 */
	readonly userIdentityName: string;

	/**
	 * The login name or principal, used to login.
	 * With classic password login, this is the same as the user name. With IDP login, it may be different.
	 */
	readonly userLoginName: string;

	/**
	 * The user's real name, or null if unknown.
	 */
	readonly name: string;

	/**
	 * Symbolic role names, or null if unknown.
	 */
	readonly roles: string[];

	/**
	 * A secret that can be used to retrieve credentials.
	 */
	readonly secret: string;

	/**
	 * Expiration date, or null.
	 */
	readonly expires: Date;

	/**
	 * Create a new session from an object, either restored from local storage or for cloning. Another object may also be passed to modify values.
	 * @param object The object to clone, or null.
	 */
	static create(object1: any, object2?: any): DoiAuthSession
	{
		return new DoiAuthSession(object1, object2);
	}

	/**
	 * Construct a new session from one or two objects.
	 */
	private constructor(object: any, objectPatch?: any)
	{
		if (object)
			Object.assign(this, object);
		if (objectPatch)
			Object.assign(this, objectPatch);

		//	Handle old versions and non IDP login.

		if (typeof this.userid == 'string')
			this.userid = parseInt(this.userid);

		if (!this.userName)
			this.userName = object.username;

		if (!this.userIdentityName)
			this.userIdentityName = this.userName;

		if (!this.userLoginName)
			this.userLoginName = this.userName;
	}

	/**
	 * Create a new session with the specified secret.
	 */
	withSecret(secret: string)
	{
		return new DoiAuthSession(this, { secret: secret} );
	}
}

