import {Observable, concat, EMPTY} from 'rxjs';
import {map} from "rxjs/operators";

import {DoiIconMapper, DoiObjectRef, DoiSelectionCriteria} from '../doi/DoiModule';
import {DoiNavNode, DoiNavNodeChildSupplier} from '../doi-navigator/DoiNavigatorModule';

import {SeriesService, SeriesObject} from '../series/SeriesModule';
import {SeriesGroupService, SeriesGroupObject} from '../seriesgroup/SeriesGroupModule';

/**
 * A navigator node child supplier for a series group node.
 */
export class SeriesGroupNodeChildSupplier extends DoiNavNodeChildSupplier
{
	constructor(
		private seriesGroupService: SeriesGroupService,
		private seriesService: SeriesService,
	)
	{
		super();
	}

	readChildren(node: DoiNavNode): Observable<DoiNavNode[]>
	{
		var criteria: DoiSelectionCriteria;

		criteria = new DoiSelectionCriteria()
			.select('Series_SeriesID', 'Series_Signum', 'Series_Title')
			.where('Series_SeriesGroupID', '='+node.pathElement.objectID)
			.orderBy('Series_Signum', 'Series_Title', 'Series_SeriesID');

		let seriesObservable = this.seriesService.readObjectSelection(null, criteria).pipe(
			map((serieses: SeriesObject[]) =>
			{
				let nodes = new Array<DoiNavNode>();
				for (let series of serieses) {
					let objectRef = DoiObjectRef.forObject('Series', series.objectID);
					nodes.push(
						new DoiNavNode(series.objectTitle(), '/series/'+series.objectID)
							.setObjectRef(objectRef)
							.setIconName(series.iconName)
							.setIconMapper(node.getIconMapper())
					);
				}
				return nodes;
			})
		);

		return seriesObservable;
	}
}
