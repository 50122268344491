import {DoiObject, DoiObjectRef, DoiObjectPermission} from '../../doi/DoiModule';

import {ProcessObject} from '../../process/ProcessModule';
import {PropertyValue, ObjectFile, Unit} from '../../klara/KlaraModule';


/**
 *	Object part names for the ProcessActType entity object.
 */
export const ProcessActTypePart =
{
	Label : 'Label',
	General : 'General',
	Description : 'Description',
	PropertyValues: 'PropertyValues',
	Units: 'Units',
	UnitsNav: 'UnitsNav',
	Series: 'Series',
	AltNames : 'ProcActTypeAltNames',
	ObjectFiles : "ObjectFiles",
}

/**
 * Enumeration ConfidentialTimeUnit.
 */
export enum ConfidentialTimeUnit
{
	Years,
	Months,
	Days,
}

/**
 * Enumeration se.knowit.klara.process.model.CullPeriodTimeUnit.
 */
export enum CullPeriodTimeUnit
{
	Years,
	Months,
	Days,
}

/**
 * Enumeration se.knowit.klara.app.model.PublishLevel.
 */
export enum PublishLevel
{
}

export class ProcessActTypeUnitObject
{
	static readonly iconName = 'archive';

	unitID: number;
	unitTypeName: string;
	code: string;
	title: string;
	date: string;
	navText: string;
	location: string;
	archiveOrigID: number;
	archiveOrigName: string;
	description: string;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): ProcessActTypeUnitObject
	{
		this.unitID = data.ProcActTypeUnit_UnitID;
		this.unitTypeName = data.UnitType_Name;
		this.code = data.Unit_Code;
		this.title = data.Unit_Title;
		this.date = data.Unit_Date;
		this.navText = data.Unit_NavText;
		this.location = data.Unit_Location;
		this.archiveOrigID = data.Unit_ArchiveOrigID;
		this.archiveOrigName = data.ArchiveOrig_Name;

		return this;
	}

	toUnit(): Unit
	{
		let unit = new Unit();
		unit.unitID = this.unitID;
		unit.unitTypeName = this.unitTypeName;
		unit.code = this.code;
		unit.title = this.title;
		unit.date = this.date;
		unit.navText = this.navText;

		return unit;
	}
}

export class ProcessActTypeSeriesObject
{
	static readonly iconName = 'inventory';

	seriesID: number;
	signum: string;
	title: string;
	date: string;
	location: string;
	archiveID: number;
	archiveName: string;
	archiveOrigID: number;
	archiveOrigName: string;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): ProcessActTypeSeriesObject
	{
		this.seriesID = data.Series_SeriesID;
		this.signum = data.Series_Signum;
		this.title = data.Series_Title;
		this.date = data.Series_Date;
		this.location = data.Series_Location;
		this.archiveID = data.Series_ArchiveID;
		this.archiveName = data.Archive_Name;

		return this;
	}
}

/**
 *	The ProcessActType entity object.
 */
export class ProcessActTypeObject extends DoiObject
{
	static readonly iconName = 'file-alt';

	/**
	 *	Attribute values.
	 */
	name: string;
	processID: number;
	confidential: boolean;
	confidentialText: string;
	confidentialAmount: number;
	confidentialTimeUnit: ConfidentialTimeUnit;
	confidentialTimeUnitLabel: string;
	cullPeriodAmount: number;
	cullPeriodTimeUnit: CullPeriodTimeUnit;
	description: string;
	descriptionShort: string;
	nameML: string;
	nameShort: string;
	objectUUID: any;
	publish: PublishLevel;
	sequence: number;
	validPeriod: string;
	altNameList: string;
	copy: boolean;
	nameList: string;
	procStructID: number;
	processCode: string;
	processName: string;
	processNameShort: string;
	processNameList: string;
	actKindNameList: string;
	primaryEntityID: number;
	modifiedWhen: Date;

	/**
	 *	Parent.
	 */
	process: ProcessObject;
	propertyValues: Array<PropertyValue>;
	units: Array<ProcessActTypeUnitObject>;
	series: Array<ProcessActTypeSeriesObject>;
	objectFiles: Array<ObjectFile>;

	/**
	 * Construct a new ProcessActType object.
	 */
	constructor(objectID: number)
	{
		super(objectID);
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return ProcessActTypeObject.iconName;
	}

	/**
	 * Return a text suitable for additional text in search results etc. Overridden to return the short or long description.
	 */
	objectText(): string
	{
		return this.descriptionShort || this.description;
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectTitle(): string
	{
		return this.nameShort || this.name;
	}

	/**
	 * The symbolic object type name, i e 'ProcessActType'.
	 */
	get objectType(): string
	{
		return 'ProcessActType';
	}

	/**
	 * Return an object reference path from the root, corresponding to the navigation structure, or null.
	 */
	objectRefPath(): DoiObjectRef[]
	{
		let path: DoiObjectRef[] = null;

		if (this.process) {
			path = this.process.objectRefPath();
			path.push(this.objectRef());
			return path;
		}

		path = new Array<DoiObjectRef>();

		path.push(this.objectRef());

		return path;
	}

	/**
	 * Populate an object from a data object.
	 */
	parseData(data: any, partName: string): ProcessActTypeObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, ProcessActTypePart.Label);
				this.parseData(data, ProcessActTypePart.Description);
				//	Additional search result entry information.
				if (!this.processNameList)
					this.processNameList = this.processNameShort || this.processName;
				if (data.ProcessActType_DescriptionShort)
					this.descriptionShort = data.ProcessActType_DescriptionShort;
				break;
			case ProcessActTypePart.Label:
				this.objectID = data.ProcessActType_ProcessActTypeID;
				this.name = data.ProcessActType_Name;
				this.nameShort = data.ProcessActType_NameShort;
				this.nameList = data.ProcessActType_NameList;
				this.processID = data.ProcessActType_ProcessID;
				this.procStructID = data.Process_ProcStructID;
				this.processCode = data.Process_Code;
				this.processName = data.Process_Name;
				this.processNameShort = data.Process_NameShort;
				this.processNameList = data.Process_NameList;
				this.actKindNameList = data.Process_NameListActKind;
				break;
			case ProcessActTypePart.General:
				this.parseData(data, ProcessActTypePart.Label);
				this.confidential = data.ProcessActType_Confidential;
				this.confidentialText = this.confidential ? "ja" : "nej";
				this.confidentialAmount = data.ProcessActType_ConfidentialAmount;
				if (data.ProcessActType_ConfidentialTimeUnit !== undefined) {
					this.confidentialTimeUnit = (<any>ConfidentialTimeUnit)[data.ProcessActType_ConfidentialTimeUnit ];
				}
				this.cullPeriodAmount = data.ProcessActType_CullPeriodAmount;
				if (data.ProcessActType_CullPeriodTimeUnit !== undefined) {
					this.cullPeriodTimeUnit = (<any>CullPeriodTimeUnit)[data.ProcessActType_CullPeriodTimeUnit];
				}
				this.nameML = data.ProcessActType_NameML;
				this.publish = data.ProcessActType_Publish;
				this.sequence = data.ProcessActType_Sequence;
				this.validPeriod = data.ProcessActType_ValidPeriod;
				this.copy = data.ProcessActType_Copy;
				this.nameList = data.ProcessActType_NameList;
				this.modifiedWhen = data.ProcessActType_ModifiedWhen;
				break;
			case ProcessActTypePart.Description:
				this.description = data.ProcessActType_Description;
				this.descriptionShort = data.ProcessActType_DescriptionShort;
				break;
			case ProcessActTypePart.AltNames:
				this.altNameList = data.ProcessActType_AltNameList;
				break;
			case ProcessActTypePart.PropertyValues:
				this.propertyValues = new Array<PropertyValue>();
				if (data.ProcessActType_PropertyValues) {
					for (let e of data.ProcessActType_PropertyValues) {
						this.propertyValues.push(new PropertyValue().parseData(e));
					}
				}
				break;
			case ProcessActTypePart.Units:
			case ProcessActTypePart.UnitsNav:
				this.units = new Array<ProcessActTypeUnitObject>();
				if (data.ProcessActType_Units) {
					for (let e of data.ProcessActType_Units) {
						let unit = new ProcessActTypeUnitObject();
						unit.parseData(e);
						this.units.push(unit);
					}
				}
				break;
			case ProcessActTypePart.Series:
				this.series = new Array<ProcessActTypeSeriesObject>();
				if (data.ProcessActType_Series) {
					for (let e of data.ProcessActType_Series) {
						let series = new ProcessActTypeSeriesObject();
						series.parseData(e);
						this.series.push(series);
					}
				}
				break;
			case ProcessActTypePart.ObjectFiles:
				this.objectFiles = new Array<ObjectFile>();
				if (data.ProcessActType_ObjectFiles) {
					for (let e of data.ProcessActType_ObjectFiles) {
						let objectFile = new ObjectFile();
						objectFile.parseData(e);
						this.objectFiles.push(objectFile);
					}
				}
				break;
		}
		return this;
	}

	/**
	 * Build a data object part for writing.
	 * Aktuell då uppdatering införs
	 */
	buildDataPart(partName: string): any
	{
		switch (partName) {
			case ProcessActTypePart.General:
				return {
					ProcessActType_ObjectDelete: this.objectDelete,
					ProcessActType_Name: this.name,
					ProcessActType_Confidential: this.confidential,
					ProcessActType_ConfidentialAmount: this.confidentialAmount,
					ProcessActType_ConfidentialTimeUnit: this.confidentialTimeUnit,
					ProcessActType_CullPeriodAmount: this.cullPeriodAmount,
					ProcessActType_CullPeriodTimeUnit: this.cullPeriodTimeUnit,
					ProcessActType_NameML: this.nameML,
					ProcessActType_NameShort: this.nameShort,
					ProcessActType_Publish: this.publish,
					ProcessActType_Sequence: this.sequence,
					ProcessActType_ValidPeriod: this.validPeriod,
					ProcessActType_AltNameList: this.altNameList,
					ProcessActType_Copy: this.copy,
					ProcessActType_NameList: this.nameList,				}
			case ProcessActTypePart.Description:
				return {
					ProcessActType_Description: this.description,
					ProcessActType_DescriptionShort: this.descriptionShort
				}
			case ProcessActTypePart.PropertyValues:
				if (this.propertyValues) {
					let propertyValues = new Array<any>();
					for (let propertyValue of this.propertyValues) {
						propertyValues.push(propertyValue.buildDataPart());
					}
					return {
						ProcessActType_PropertyValues: propertyValues
					}
				}
				break;
		}
	}

	/**
	 * Add permissions on a new object, based on the parent object.
	 */
	newPermissions()
	{
		if (this.process && this.process.permitWrite())
			this.objectPermissions |= DoiObjectPermission.create|DoiObjectPermission.write;
	}
}

// rrBT2iI88hJo9tIa935QFYFGFXY=
