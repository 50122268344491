import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';

import {SeriesPart, SeriesObject, SeriesVolume} from '../model/SeriesObject';


@Component({
	templateUrl: 'SeriesObjectVolumesPanel.html'
})
export class SeriesObjectVolumesPanel extends DoiObjectPanel<SeriesObject>
{
	name = 'volumes';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openVolume(volume : SeriesVolume)
	{
		this.appView.openObject('Volume', volume.volumeID);
	}


	routerLinkVolume(volume : SeriesVolume)
	{
		return ['/volume', volume.volumeID];
	}

	/**
	 * Return the icon class for the specified object.
	 */
	volumeIconClass()
	{
		return this.iconClass(SeriesVolume.iconName);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(SeriesPart.Volumes),
		);
	}
}

// rS9PI84cysHrMnRkC1Y62Cfx+ZY=
