import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';

/**
 * A principal service that is configured by the authorization service and processes requests for the HTTP interceptor.
 */
@Injectable()
export class DoiAuthPrincipalService
{
	/**
	 * Ignore authorization errors during login.
	 */
	ignoreAuthErrors: boolean;

	/**
	 * The target URL that triggered a login page redirection. Used to show a friendly authorization error in the login page.
	 */
	friendlyAuthErrorUrl: string;

	/**
	 * The login path.
	 */
	private loginPath: string[];

	/**
	 * The currently used authorization header.
	 */
	private authorizationHeader: string;

	/**
	 * Construct a new principal service interceptor.
	 */
	constructor(private router: Router)
	{
	}

	/**
	 * Invoked by the authorization service to configure this principal service.
	 */
	configure(loginPath: string[])
	{
		this.loginPath = loginPath;
	}

	/**
	 * Invoked by the authorization service to use the specified authorization header in subsequent requests.
	 */
	useAuthHeader(authorization: string)
	{
		this.authorizationHeader = authorization;
	}

	/**
	 * Invoked by the interceptor to add the authorization header if there is one available and the request doesn't already have one.
	 * Also clear the friendly authentication error URL.
	 */
	authorizeRequest<T>(request: HttpRequest<T>): HttpRequest<T>
	{
		this.friendlyAuthErrorUrl = null;

		if (!this.authorizationHeader)
			return request;
		if (request.headers.get('Authorization'))
			return request;

		return request.clone({setHeaders: {Authorization: this.authorizationHeader}});
	}

	/**
	 * Invoked by the HTTP interceptor if an error occurs. Navigates to the configured login page if an authentication error occurs,
	 * unless authentication errors are currently ignored.
	 */
	error(error: HttpErrorResponse, requestUrl: string)
	{
		if (error.status == 401 || error.status == 403) {
			if (!this.ignoreAuthErrors && this.loginPath) {
				this.friendlyAuthErrorUrl = requestUrl;
				this.router.navigate(this.loginPath);
			}
		}
	}
}


