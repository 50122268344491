import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, BehaviorSubject, EMPTY} from 'rxjs';
import {catchError, map, switchMap, tap, finalize} from "rxjs/operators";

import {DoiAlertService} from './DoiAlertService';
import {DoiAuthSession} from './DoiAuthSession';
import {DoiAuthPrincipalService} from './DoiAuthPrincipalService';
import {DoiLogService} from './DoiLogService';
import {DoiStorageService} from './DoiStorageService';

/**
 * An authorization service that handles login, password change, URL context, etc.
 */
@Injectable()
export class DoiAuthService
{
	/**
	 * The current session, or null.
	 */
	session: DoiAuthSession = null;

	private _urlContextPub: string;
	private _urlContextSec: string;
	private _loginPath: string[];

	private authenticatedSubject = new BehaviorSubject<boolean>(false);

	/**
	 * Construct a new authorization service.
	 */
	constructor(
		private principalService: DoiAuthPrincipalService,
		private log: DoiLogService,
		private alert: DoiAlertService,
		private storage: DoiStorageService,
		private http: HttpClient,
		private router: Router)
	{
	}

	/**
	 * Configure this authorization service.
	 */
	configure(urlContext: string, subContextPub: string, subContextSec: string, loginPath: string[])
	{
		this._urlContextPub = urlContext+subContextPub;
		this._urlContextSec = urlContext+subContextSec;
		this._loginPath = loginPath;

		this.principalService.configure(loginPath);
		this.principalService.useAuthHeader(this.authHeader(null, null));
	}

	/**
	 * Calculate an expiry date.
	 */
	expires(days?: number, baseDate?: Date): Date
	{
		if (days == undefined)
			days = 30;
		if (days <= 0)
			return null;
		if (!baseDate)
			baseDate = new Date();
		let expires = new Date(baseDate.getTime()+days*24*60*60*1000);
		return expires;
	}

	/**
	 * Return the target URL that triggered a login page redirection. Used to show a friendly authorization error in the login page.
	 */
	friendlyAuthErrorUrl(): string
	{
		return this.principalService.friendlyAuthErrorUrl;
	}

	/**
	 * Start re-authentication if the user has a saved secret for fetching login credentials. If not, navigate to the login page if the
	 * user was logged in when last leaving the application.
	 * @return True if reauthenticate was started.
	 */
	reauthenticate(): boolean
	{
		let authSession = this.savedSession();

		if (!authSession || !authSession.userLoginName || !authSession.secret) {
			if (this.loginPath() && this.storage.getContextItem('loggedIn') == 'true') {
				this.storage.removeContextItem('loggedIn');
				this.router.navigate(this.loginPath());
			}
			return false;
		}

		this.authBegin();
		let observable = this.http.post(this._urlContextPub + '/loginCredentials/loginCredentials.json', {username: authSession.userLoginName, secret: authSession.secret}).pipe(
			switchMap((response: any) => {
				let expires = authSession.expires;
				if (expires)
					expires = this.expires();
				return this.login(authSession.userLoginName, null, expires, response.authorization, authSession.secret).pipe(
					tap(response => {
						this.authComplete(null);
					}),
					catchError((error: HttpErrorResponse) => {
						this.authComplete(error);
						this.authenticatedSubject.next(false);
						return EMPTY;
					})
				)
			}),
			catchError((error: HttpErrorResponse) => {
				this.authComplete(error);
				this.authenticatedSubject.next(false);
				return EMPTY;
			})

		);

		observable.subscribe();

		return true;
	}

	/**
	 * Return the user session saved in either session or local storage.
	 */
	savedSession(): DoiAuthSession
	{
		let authSessionJSON = this.storage.getStorageContextItem(sessionStorage, 'user');
		if (!authSessionJSON)
			authSessionJSON = this.storage.getStorageContextItem(localStorage, 'user')
		if (authSessionJSON) {
			let authSession: DoiAuthSession = JSON.parse(authSessionJSON);
			if (authSession.expires && authSession.expires <= new Date())
				authSession = null;
			return authSession;
		} else {
			return null;
		}
	}

	/**
	 * Save the user session in session storage and optionally in the preferred storage.
	 */
	saveSession()
	{
		let json = JSON.stringify(this.session);
		this.storage.setStorageContextItem(sessionStorage, 'user', json);
		if (this.session.expires)
			this.storage.setContextItem('user', json);
	}

	/**
	 * Return an observable that emits the current authenticated state, true or false, when it changes.
	 */
	authenticatedState(): Observable<boolean>
	{
		return this.authenticatedSubject.asObservable();
	}

	/**
	 * Test if the user is authenticated.
	 */
	isAuthenticated(): boolean
	{
		return this.session != null;
	}

	/**
	 * Test if authentication is in progress. Used to ignore authorization errors for resources that are not yet available but soon will be.
	 */
	isAuthenticating(): boolean
	{
		return this.principalService.ignoreAuthErrors && !this.isAuthenticated();
	}

	/**
	 * Invoked when a sequence of authentication requests starts.
	 * Restores authentication errors reporting.
	 */
	authBegin()
	{
		this.log.fine('DoiAuthService.authBegin');
		this.alert.ignoreErrors = true;
		this.principalService.ignoreAuthErrors = true;
	}

	/**
	 * Invoked when a sequence of authentication requests is completed, either successfully or because.
	 * Restores authentication errors reporting.
	 */
	authComplete(error: any)
	{
		this.log.fine('DoiAuthService.authComplete '+(error ? 'with error: '+error : ' OK'));
		this.alert.ignoreErrors = false;
		this.principalService.ignoreAuthErrors = false;
	}

	/**
	 * Build an auth header.
	 */
	authHeader(username: string, password: string)
	{
		if (username && password)
			return 'Basic ' + btoa(username + ':' + password);
		else
			return null;
	}

	changepwd(oldpwd: string, newpwd: string): Observable<Object>
	{
		if (!this.isAuthenticated())
			throw new Error('Not authenticated');

		return this.http.post(this._urlContextSec + '/changepwd/changepwd.json',
			{oldpwd: oldpwd, newpwd: newpwd, secret: this.session.secret}).pipe(
			map((response: any) =>
			{
				this.session = this.session.withSecret(response.secret);
				this.principalService.useAuthHeader(this.authHeader(this.session.userLoginName, newpwd));
				this.saveSession();
				return response;
			}));
	}

	requestpwd(username: string): Observable<Object>
	{
		return this.http.post(this.urlContext() + '/requestpwd/requestpwd.json', {username: username});
	}

	choosepwd(username: string, seccode: string, password: string, expires?: Date): Observable<Object>
	{
		if (expires && expires < new Date())
			expires = null;

		return this.http.post(this.urlContext() + '/choosepwd/choosepwd.json',
			{username: username, seccode: seccode, password: password}).pipe(
			switchMap((response: any) =>
			{
				return this.login(username, password, expires);
			}));
	}

	login(userLoginName: string, password: string, expires?: Date, authorization?: string, secret?: string): Observable<any>
	{
		this.storage.removeStorageContextItem(sessionStorage, 'user');
		this.storage.removeStorageContextItem(localStorage, 'user');

		if (password != null)
			authorization = this.authHeader(userLoginName, password);
		else if (authorization == null)
			throw new Error('Neither password not authorization specified');

		if (expires && expires < new Date())
			expires = null;

		return this.http.post(this._urlContextSec + '/login/login.json',
			{username: userLoginName, secret: secret},
			{headers: new HttpHeaders().set('Authorization', authorization)}).pipe(
			map((response: any) =>
			{
				this.session = DoiAuthSession.create(response, { expires: expires });
				this.principalService.useAuthHeader(authorization);
				this.saveSession();
				this.storage.setContextItem('loggedIn', 'true');
				this.authenticatedSubject.next(true);
				return response;
			}));
	}

	/**
	 * Return the configured path to the login page.
	 */
	loginPath(): string[]
	{
		return this._loginPath;
	}

	/**
	 * Log out.
	 */
	logout(explicit: boolean = true)
	{
		if (explicit) {
			this.storage.removeContextItem('loggedIn');
			this.storage.removeStorageContextItem(localStorage, 'user');
		}

		this.storage.removeStorageContextItem(sessionStorage, 'user');

		if (this.isAuthenticated())
			this.http.post(this._urlContextSec + '/logout/logout.json', {username: this.session.userLoginName}).subscribe();

		this.session = null;
		this.principalService.useAuthHeader(this.authHeader(null, null))
		this.authenticatedSubject.next(false);
	}

	/**
	 * Test if the current user is authenticated and has the specified role.
	 */
	hasRole(role: string)
	{
		if (this.session == null || this.session.roles == null)
			return false;

		return this.session.roles.includes(role);
	}

	/**
	 * Test if the current user is authenticated and has any of the specified roles.
	 */
	hasAnyRole(...roles: string[])
	{
		if (this.session == null || this.session.roles == null)
			return false;

		for (let role of roles) {
			if (this.session.roles.includes(role))
				return true;
		}

		return false;
	}

	/**
	 * Return the public or secure URL context depending on if the user is authenticated or not.
	 */
	urlContext(): string
	{
		return this.isAuthenticated() ? this._urlContextSec : this._urlContextPub;
	}

	/**
	 * Return the public URL context.
	 */
	urlContextPub(): string
	{
		return this._urlContextPub;
	}
}
