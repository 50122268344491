import {DoiObject, DoiObjectRef, DoiObjectPermission} from '../../doi/DoiModule';

import {Feature} from '././Feature';

/**
 *	Application version and license information.
 */
export class AppInfo
{
	version: {
		implementation: string,
		specification: string
	}

	license: {
		licenseNo: number,
		userCount: number,
		featureBits: number
	}

	/**
	 * Construct a new SystemInfo object.
	 */
	constructor(response: AppInfo)
	{
		Object.assign(this, response);
	}

	/**
	 * Test if a feature is available.
	 */
	hasFeature(feature: Feature): boolean
	{
		if (!this.license)
			return false;

		let mask = 1 << feature.valueOf();
		return (this.license.featureBits & mask) != 0;
	}

	/**
	 * Test if the Klara Dialog feature (Dialog) is available.
	 */
	hasFeatureDialog(): boolean
	{
		return this.hasFeature(Feature.Dialog);
	}

	/**
	 * Test if the Klara Web feature (Web) is available.
	 */
	hasFeatureWeb(): boolean
	{
		return this.hasFeature(Feature.Web);
	}

	/**
	 * Test if the editing feature (WebFull) is available.
	 */
	hasFeatureEdit(): boolean
	{
		return this.hasFeature(Feature.WebFull);
	}

	/**
	 * Test if the feature Experimental is available.
	 */
	hasFeatureExperimental(): boolean
	{
		return this.hasFeature(Feature.Experimental);
	}

	/**
	 * Test if the feature Process is available.
	 */
	hasFeatureProcess(): boolean
	{
		return this.hasFeature(Feature.Process);
	}
}

// YnQn6+tLul1nvhMP2oiIUPxruMw=
