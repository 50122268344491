/**
 * A theme.
 */
export class DoiTheme
{
	/**
	 * The symbolic name.
	 */
	name: string;

	/**
	 * The display name.
	 */
	label: string;

	/**
	 * A description.
	 */
	description: string;

	/**
	 * Additional properties.
	 */
	properties: Map<string, string>;

	/**
	 * Construct a new theme.
	 */
	constructor(name: string, label: string, description: string, properties?: any)
	{
		this.name = name;
		this.label = label;
		this.description = description;
		this.properties = new Map<string, string>();
		if (properties) {
			for (let key in properties) {
				this.properties.set(key, properties[key]);
			}
		}
	}
}
