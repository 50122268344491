<div class="doi-subview doi-scroll">

	<form role="form">
		<div class="row" *ngIf="object.description">
			<div class="col-12 col-xl-11">
				<doi-textarea name = "Beskrivning" labelEdit="Beskrivning" size="60" [(ngModel)]="object.description" [edit]="editing()" [ngModelOptions]="{standalone: true}"></doi-textarea>
			</div>
		</div>
		<property-values [view]="this" [propertyValues]="object?.propertyValues"></property-values>
		<div *ngIf="!environment.dialog" class="row">
			<div class="col-12 col-md-6 col-xl-4">
				<doi-field type="date" name="TidFran" label="Tid från" size="60" [(ngModel)]="object.startDate"  [edit]="editing()"></doi-field>
			</div>
			<div class="col-12 col-md-6 col-xl-4">
				<doi-field type="date" name="TidTill" label="Tid till" size="60" [(ngModel)]="object.endDate" [edit]="editing()"></doi-field>
			</div>
			<div *ngIf="object?.versionNo" class="col-12 col-md-6 col-xl-4">
				<doi-field type="string" name="Version" label="Versionsnr" size="60" [(ngModel)]="object.versionNo" ></doi-field>
			</div>
		</div>
		<h3 *ngIf="object.objectFiles?.length">Referenser</h3>
		<div class="row">
			<div class="col-12"><object-files [objectFiles]="object.objectFiles" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files></div>
		</div>
		<h3 *ngIf="processes?.length">Ingående strukturenheter</h3>
		<div doi-object-grid [iconMapper]="this" [objects]="processes" moreView="processes"></div>
	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
