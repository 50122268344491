import {Component, Input, Output, ElementRef, EventEmitter, HostBinding, QueryList, ViewChild, ViewChildren} from '@angular/core';

import {DoiFocusFirst} from '../core/DoiFocusFirst';
import {DoiIconMapper} from '../core/DoiIconMapper';
import {DoiObject} from '../service/DoiObject';

/**
 * An object grid item component.
 */
@Component({
	selector: '[doi-object-grid-item]',
	host: { 'class' : 'doi-object-grid-item col-12 col-sm-12 col-md-12' },
	template: `
		<a #objectGridItemAnchor href="" [routerLink]="link" (click)="onClick($event)" role="button">
			<div class="doi-object-grid-item-header">
				<img *ngIf="icon" [src]="icon"/>
				<i *ngIf="iconClass" class="doi-object-grid-item-icon" [ngClass]="iconClass"></i>
				<span class="doi-object-grid-item-title">{{title}}</span>
				<span *ngIf="info" class="infotag doi-infotag">{{info}}</span>
				<i *ngIf="infoIconClass" class="infotag doi-infotag" [ngClass]="infoIconClass"></i>
			</div>
			<div *ngIf="contentSupplier" class="doi-object-grid-item-body" [innerHtml]="bodyContent()"></div>
			<div *ngIf="textSupplier" class="doi-object-grid-item-body">{{bodyText()}}</div>
		</a>
  `
})
export class DoiObjectGridItemComponent implements DoiFocusFirst
{
	/**
	 * The icon source.
	 */
	@Input() icon: any;

	/**
	 * The icon class.
	 */
	@Input() iconClass: string;

	/**
	 * The object title.
	 */
	@Input() title: string;

	/**
	 * The object content supplier.
	 */
	@Input() contentSupplier: () => string;

	/**
	 * The object text supplier.
	 */
	@Input() textSupplier: () => string;

	/**
	 * Short object information text, such as status or version.
	 */
	@Input() info: string;

	/**
	 * Object information icon, typically indicating some status.
	 */
	@Input() infoIconClass: string;

	/**
	 * The router link.
	 */
	@Input()
	link: any[] = null;

	/**
	 * Cells in LG mode.
	 */

	@Input() cellsLG: string = '6';

	/**
	 * Cells in XL mode.
	 */
	@Input() cellsXL: string = '4';

	/**
	 * Emits events when the object is clicked.
	 */
	@Output() open = new EventEmitter();

	/**
	 * The grid item anchor element.
	 */
	@ViewChild('objectGridItemAnchor')
	anchorRef: ElementRef<HTMLAnchorElement>;

	/**
	 * Accessor for dynamic cell classes on the host grid item element.
	 */
	@HostBinding('class')
	get colClasses(): string
	{
		return 'col-lg-'+this.cellsLG+' col-xl-'+this.cellsXL;
	}

	/**
	 * Construct a new object grid object component.
	 */
	constructor()
	{
	}

	/**
	 * Return the body HTML content.
	 */
	bodyContent(): string
	{
		if (this.contentSupplier)
			return this.contentSupplier();
	}

	/**
	 * Return the body content.
	 */
	bodyText(): string
	{
		if (this.textSupplier)
			return this.textSupplier();
	}

	/**
	 * Focus the first inner component.
	 */
	focusFirst(): boolean
	{
		if (this.anchorRef) {
			this.anchorRef.nativeElement.focus();
			return true;
		}

		return false;
	}

	/**
	 * Invoked when the link is clicked. Emits an event to "open".
	 */
	onClick(event: Event)
	{
		event.stopPropagation();

		this.open.emit(event);
	}
}
