<div class="doi-subview doi-scroll">

	<form role="form">

		<h3 *ngIf="series?.length">Serier</h3>
		<div doi-object-grid [iconMapper]="this" [objects]="series" moreView="series"></div>

	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
