import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {Observer, Subscriber, Subscription} from 'rxjs';

import {DoiService, DoiTopView} from '../doi/DoiModule';
import {DoiLoginService, DoiIdentityProvider, DoiLoginView} from '../doi-login/DoiLoginModule';

import {AppBaseView} from '../AppBaseView';

@Component({
	templateUrl: 'LoginView.html'
})

export class LoginView extends DoiLoginView
{
	constructor(doi: DoiService, doilogin: DoiLoginService, route: ActivatedRoute,	private router: Router)
	{
		super(doi, doilogin, route);
	}

	/**
	 * Test if the user has consented to store settings in local storage.
	 * Returns true or false if the user has made a choice, otherwise null.
	 */
	localStorageChoice(): boolean
	{
		let appView = this.appView as AppBaseView;
		return appView.localStorageChoice();
	}

}
