import {Pipe, PipeTransform} from '@angular/core';

/**
 * Converts a byte count to human readable text, e g "792 GB".
 */
@Pipe({name: 'bytes'})
export class DoiBytesPipe implements PipeTransform
{
	transform(bytes: number): string
	{
		if (isNaN(bytes) || !isFinite(bytes))
			return '-';
		let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'],
		number = Math.floor(Math.log(bytes) / Math.log(1024));
		return Math.ceil((bytes / Math.pow(1024, number))).toFixed(0) + ' ' + units[number];
	}
}
