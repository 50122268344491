<div class="doi-subview doi-scroll">
	<form #form="ngForm" role="form">

		<div class="row" *ngIf="editing()">
			<div class="col-12">
				<doi-field name="title" labelEdit="Benämning" maxLength="255" [(ngModel)]="object.title" [edit]="editing()"></doi-field>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12">
				<doi-textarea *ngIf="object.notes || editing()" name="notes" labelEdit="Beskrivning" size="60" [(ngModel)]="object.notes" [edit]="editing()"></doi-textarea>
			</div>
		</div>

		<property-values [view]="this" [propertyValues]="object?.propertyValues" [edit]="editing()"></property-values>

		<div class="row">
			<div class="col-12 col-xl-11" *ngIf="object?.archiveOrigName"><doi-label>Arkivbildare</doi-label></div>
			<div class="col-12 col-xl-11" *ngIf="object?.archiveOrigName"><a *ngIf="routerLinkArchiveOrigOrig(object)"[routerLink]="routerLinkArchiveOrigOrig(object)">{{object.archiveOrigName}}</a></div>
		</div>
		<div class="row">
			<div class="col-6 col-md-6 col-lg-4">
				<doi-field name="dateBegin" label="Tid från" size="20" [(ngModel)]="object.dateBegin" maxLength="10" [edit]="editing()"></doi-field>
			</div>
			<div class="col-6 col-md-6 col-lg-4">
				<doi-field name="dateEnd" label="Tid till" size="20" [(ngModel)]="object.dateEnd" maxLength="10" [edit]="editing()"></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="date" label="Tid" size="20" [(ngModel)]="object.date" maxLength="30" [edit]="editing()"></doi-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-xl-11" *ngIf="object?.location">
				<doi-field name="location" label="Plats" size="60" [(ngModel)]="object.location" [edit]="editing()"></doi-field>
			</div>
		</div>

		<h3 *ngIf="docStoreLinks?.length">Dokumentlager</h3>
		<docstore-links [docStoreLinks]="docStoreLinks"></docstore-links>

		<h3 *ngIf="object.objectFiles?.length">Referenser</h3>
		<object-files [objectFiles]="object.objectFiles" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files>

		<h3 *ngIf="object.relatedUnits?.length">Samband</h3>
		<div *ngIf="object.relatedUnits?.length">
			<table class="table table-striped table-hover">
				<thead>
					<tr class="doi-clickable">
						<th class="doi-grow-1 doi-wrap">Sambandstyp</th>
						<th class="doi-grow-1 doi-wrap  doi-hidden-xs-down">Typ</th>
						<th class="doi-grow-2 doi-wrap">Benämning</th>
						<th class="doi-grow-1 doi-wrap  doi-hidden-xs-down">Tid</th>
						<th class="doi-grow-5 doi-wrap  doi-hidden-xs-down">Arkivbildare</th>
						<th class="doi-grow-5 doi-wrap  doi-hidden-xs-down">Kommentar</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let relUnit of object.relatedUnits" (click)="openRelUnit(relUnit)" class="doi-clickable">
						<td class="doi-wrap"><a [routerLink]="routerLinkRelUnit(relUnit)">{{relUnit.relName}}</a></td>
						<td class="doi-wrap doi-hidden-xs-down"><a [routerLink]="routerLinkRelUnit(relUnit)">{{relUnit.unitTypeName}}</a></td>
						<td class="doi-wrap"><a [routerLink]="routerLinkRelUnit(relUnit)">{{relUnit.code}} - {{relUnit.title}}</a></td>
						<td class="doi-wrap doi-hidden-xs-down">{{relUnit.date}}</td>
						<td class="doi-wrap doi-hidden-xs-down"><a *ngIf="routerLinkArchiveOrig(relUnit)"[routerLink]="routerLinkArchiveOrig(relUnit)">{{relUnit.archiveOrigName}}</a></td>
						<td class="doi-wrap doi-hidden-xs-down">{{relUnit.comment}}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<h3 *ngIf="children?.length">Ingående förvaringsenheter</h3>
		<div doi-object-grid [iconMapper]="this" [objects]="children" moreView="children"></div>
	</form>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
