import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, of, forkJoin, EMPTY} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

import { DoiService, DoiObject, DoiObjectPanel, DoiSelectionCriteria } from '../../doi/DoiModule';
import { ObjectFile, DocStoreLink } from '../../klara/KlaraModule';
import { SeriesObject } from '../../series/SeriesModule';
import { SeriesGroupObject } from '../../seriesgroup/SeriesGroupModule';
import { ArchivePart, ArchiveObject } from '../model/ArchiveObject';
import { ArchiveService } from '../model/ArchiveService';

@Component({
	templateUrl: 'ArchiveObjectGeneralPanel.html'
})
export class ArchiveObjectGeneralPanel extends DoiObjectPanel<ArchiveObject>
{
	name = 'general';
	docStoreLinks: DocStoreLink[];
	series: SeriesObject[];
	seriesGroups: SeriesGroupObject[];

	constructor(doi: DoiService, route: ActivatedRoute, private archiveService: ArchiveService)
	{
		super(doi, route);
	}

	/**
	 * Download an object file.
	 */
	downloadObjectFile(objectFile: ObjectFile): void
	{
		this.archiveService.downloadObjectFile(objectFile);
	}

	openArchive(archiveID : number)
	{
		this.appView.openObject('Archive', archiveID);
	}

	readSeries(archiveID: number): Observable<SeriesObject[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Series_SeriesID', 'Series_Signum', 'Series_Title')
			.where('Series_ArchiveID', '='+archiveID)
			.orderBy('Series_Signum')
			.limit(10);

		return this.doi.brokerService('Series').readObjectSelection(null, criteria);
	}

	readSeriesGroups(archiveID: number): Observable<SeriesGroupObject[]>
	{
		let criteria = new DoiSelectionCriteria()
				.select('SeriesGroup_SeriesGroupID', 'SeriesGroup_Code', 'SeriesGroup_Title')
				.where('SeriesGroup_ArchiveID', '='+archiveID)
				.orderBy('SeriesGroup_Code');

		return this.doi.brokerService('SeriesGroup').readObjectSelection(null, criteria);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ArchivePart.General).pipe(
				switchMap((archive: ArchiveObject) => {
					if (archive.signumTreeStrict) {
						return this.readSeriesGroups(archive.objectID).pipe(tap((seriesGroups: SeriesGroupObject[]) => {
							this.series = null;
							this.seriesGroups = seriesGroups;
						}));
					} else {
						return this.readSeries(archive.objectID).pipe(
							tap((series: SeriesObject[]) => {
								this.series = series;
								this.seriesGroups = null;
						}));
					}
				})
			),
			this.refreshObjectPart(ArchivePart.Label),
			this.refreshObjectPart(ArchivePart.ObjectFiles),
			this.refreshObjectPart(ArchivePart.PropertyValues),
			this.refreshObjectPart(ArchivePart.Notes),
			this.archiveService.readDocumentStoreLinks(this.objectID).pipe(
				tap((links: DocStoreLink[]) => {
					this.docStoreLinks = links;
				}))
		);

	}
}

// FIekkHEDGNH9VTbO3U8zM7VmpcA=
