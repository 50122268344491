import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, Observable, forkJoin} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiObjectView, DoiService} from '../../doi/DoiModule';

import {VolumeService} from '../model/VolumeService';
import {VolumePart, VolumeObject} from '../model/VolumeObject';

/**
 *
 */
@Component({
	templateUrl: 'VolumeObjectView.html'
})
export class VolumeObjectView extends DoiObjectView<VolumeObject>
{
	name = 'VolumeObjectView';

	/**
	 * Template for a new object.
	 */
	objectTemplate: VolumeObject;

	/**
	 * Construct a new object view.
	 */
	constructor(doi: DoiService, private volumeService: VolumeService, route: ActivatedRoute)
	{
		super(doi, volumeService, route);
	}

	routerLinkSeries()
	{
		return this.object.seriesID ? ['/series', this.object.seriesID] : null;
	}

	routerLinkArchiveOrig()
	{
		return this.object.series && this.object.series.archive && this.object.series.archive.archiveOrig ?	['/archiveorig', this.object.series.archive.archiveOrigID] : null;
	}

	routerLinkArchive()
	{
		return this.object.series && this.object.series.archive ? ['/archive', this.object.series.archiveID] : null;
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * Overridden to refresh object parts presented by the object view itself.
	 */
	refreshView(): Observable<any>
	{
		if (this.doi.auth.isAuthenticating())
			return EMPTY;

		return forkJoin(
			this.refreshObjectPart(VolumePart.Label).pipe(
				switchMap((volume: VolumeObject) => {
					return this.volumeService.parentObjectObservable(volume);
				})
			),
			this.probeObjectPart(VolumePart.General),
			this.probeObjectPart(VolumePart.Notes),
			super.refreshView()
		);
	}

	/**
	 * Test if any of the object parts used by the specified object panel has been found to have backend values as a result of an object probe. If not
	 * known, undefined is returned.
	 * Overriddene to invoke objectPartHasValues with the part name or part names used by the panel.
	 */
	probedPanel(panelName: string): boolean
	{
		switch (panelName) {
			case 'general': return this.probedPart('General');
			case 'notes': return this.probedPart('Notes');
		}
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Volym'; // I18N
	}
}

// 2sxMxYWt1pfKelOZbvACPV6JOww=
