import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {environment} from '../../environments/environment';

import {DoiModule, DoiObjectEditGuard, DoiRedirectGuard} from '../doi/DoiModule';

import {KlaraModule} from '../klara/KlaraModule';
import {ArchiveOrigService} from './model/ArchiveOrigService';
import {ArchiveOrigObjectView} from './view/ArchiveOrigObjectView';
import {ArchiveOrigObjectGeneralPanel} from './view/ArchiveOrigObjectGeneralPanel';
import {ArchiveOrigObjectArchivesPanel} from './view/ArchiveOrigObjectArchivesPanel';
import {ArchiveOrigObjectDetailPanel} from './view/ArchiveOrigObjectDetailPanel';
import {ArchiveOrigObjectChildrenPanel} from './view/ArchiveOrigObjectChildrenPanel';
import {ArchiveOrigSelectionView} from './view/ArchiveOrigSelectionView';

export * from './model/ArchiveOrigObject';
export * from './model/ArchiveOrigService';

export const archiveOrigRoutes: Routes = environment.dialog ? [] : [
	{path: 'archiveorigs', component: ArchiveOrigSelectionView, data: {title: 'Arkivbildare'}},
	{path: 'archiveorig/:id', component: ArchiveOrigObjectView, canActivate: [DoiRedirectGuard], canDeactivate: [DoiObjectEditGuard], data: { defaultChild: 'general' }, children: [
	{path: 'general', component: ArchiveOrigObjectGeneralPanel, data: {title: 'Allmänt'}},
	{path: 'archives', component: ArchiveOrigObjectArchivesPanel, data: {title: 'Arkiv'}},
	{path: 'details', component: ArchiveOrigObjectDetailPanel, data: {title: 'Detaljer'}},
	{path: 'children', component: ArchiveOrigObjectChildrenPanel, data: {title: 'Ingående'}},
	]},
];

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(archiveOrigRoutes),
		DoiModule, KlaraModule,
	],
	declarations: [
		ArchiveOrigObjectView, ArchiveOrigObjectGeneralPanel, ArchiveOrigObjectArchivesPanel,
		ArchiveOrigObjectDetailPanel, ArchiveOrigSelectionView, ArchiveOrigObjectChildrenPanel,
	],
	exports: [
	],
	providers: [
		ArchiveOrigService,
	],

})
export class ArchiveOrigModule
{
}

// /au4cZflo5cj302pZV1qLVc8Iso=
