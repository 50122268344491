import {Observable} from 'rxjs';

import {DoiBrokerService, DoiObject} from '../../doi/DoiModule';

/**
 * Implemented by services that supports full text search.
 */
export interface DoiSearchBrokerService<T extends DoiObject> extends DoiBrokerService<T>
{
	/**
	 * Read search result entries.
	 */
	readSearchResultEntries(objectIDs?: number[]): Observable<T[]>;
}
