import {Component, Input} from '@angular/core';

import {DoiView} from '../view/DoiView';

/**
 * Toolbar tools for switching between view modes.
 */
@Component({
	selector: 'doi-viewmode-tools',
	template: `
		<li doi-tool [target]="view" action="ViewModeGrid" [compact]="true"></li>
		<li doi-tool [target]="view" action="ViewModeTable" [compact]="true"></li>
  `,
})
export class DoiViewModeToolsComponent
{
	/**
	 * The view.
	 */
	@Input() view: DoiView;
}
