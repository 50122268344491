import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

import {DoiSearchResult} from '../model/DoiSearchResult';

/**
 * A search result page header.
 */
@Component({
	selector: 'doi-search-result-page-header',
	host: { 'class' : 'doi-search-result-page-header' },
	template: `
		<h3>{{countText()}}</h3>
	` // I18N
//		<h3><span *ngIf="result.countIncluded < result.countTotal">{{result.countIncluded}} av </span><span *ngIf="result.countTotal">{{result.countTotal}} objekt</span></h3>
})
export class DoiSearchResultPageHeaderComponent
{
	/**
	 * The search result.
	 */
	@Input() result: DoiSearchResult;

	/**
	 * The function that returns the count text format.
	 */
	@Input()
	countTextFormatFunction = (total, included) => this.countTextFormat(total, included);

	/**
	 * Indicates if a search is in progress.
	 */
	@Input()
	@HostBinding('class.doi-searching')
	searching: boolean = false;

	/**
	 * Return the count text.
	 */
	countText()
	{
		if (this.searching)
			return 'Söker...';

		let format = this.countTextFormatFunction(this.result.countTotal, this.result.countIncluded);
		let args = [ this.result.countTotal, this.result.countIncluded ];
		return format.replace(/{(\d+)}/g, (match, n) => String(args[parseInt(n)]));
	}

	/**
	 * Return the format for the count text. Positional parameter 0 is the total count, and parameter 1 is the included count.
	 */
	countTextFormat(countTotal: number, countIncluded: number)
	{
		return countIncluded < countTotal ? '{1} av {0} objekt' : '{0} objekt';
	}
}
