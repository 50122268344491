import {DoiObject, DoiObjectRef, DoiObjectPermission} from '../../doi/DoiModule';

import {DocStoreLink, ObjectFile} from '../../klara/KlaraModule';
import {SeriesObject} from '../../series/SeriesModule';

/**
 *	Object part names for the Volume entity object.
 */
export const VolumePart =
{
	Label : 'Label',
	General : 'General',
	Notes : 'Notes',
	ObjectFiles : "ObjectFiles",
	MediaTypes: "MediaTypes",
	ObjectIntegrations: "ObjectIntegrations",
}

/**
 * Enumeration se.knowit.klara.app.model.PublishLevel.
 */
export enum PublishLevel
{
}

/**
 *	The Volume entity object.
 */
export class VolumeObject extends DoiObject
{
	static readonly iconName = 'box';

	/**
	 *	Attribute values.
	 */
	seriesID: number;
	volumeTypeID: number;
	cullMark: number;
	date: string;
	dateBegin: string;
	dateEnd: string;
	internalNotes: string;
	labelText: string;
	location: string;
	notes: string;
	publish: PublishLevel;
	sequence: number;
	title: string;
	volumeCode: string;
	hasActType: boolean;
	hasNotes: boolean;

	/**
	 *	Parent.
	 */
	series: SeriesObject;
	signum: String; // Delivered with search results.

	objectFiles: Array<ObjectFile>;
	mediaTypes: Array<MediaType>;
	docStoreLinks: Array<DocStoreLink>;

	/**
	 * Construct a new Volume object.
	 */
	constructor(objectID: number)
	{
		super(objectID);
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return VolumeObject.iconName;
	}

	/**
	 * Return a text suitable for additional text in search results etc. Overridden to return notes.
	 */
	objectText(): string
	{
		return this.notes;
	}

	/**
	 * The symbolic object type name, i e 'Volume'.
	 */
	get objectType(): string
	{
		return 'Volume';
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectTitle(): string
	{
		if (this.series && this.series.signum)
			return this.series.signum+':'+this.volumeCode;
		else if (this.signum)
			return this.signum+':'+this.volumeCode;
		return this.volumeCode;
	}

	/**
	 * Return an object reference path from the root, corresponding to the navigation structure, or null.
	 */
	objectRefPath(): DoiObjectRef[]
	{
		if (this.series) {
			let path = this.series.objectRefPath();
			if (path)
				path.push(this.objectRef());
			return path;
		}

		return null;
	}

	/**
	 * Populate an object from a data object.
	 */
	parseData(data: any, partName: string): VolumeObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, VolumePart.Label);
				//	Additional search result entry information.
				if (data.Volume_Title)
					this.notes = data.Volume_Title;
				break;
			case VolumePart.Label:
				this.objectID = data.Volume_VolumeID;
				this.seriesID = data.Volume_SeriesID;
				this.volumeCode = data.Volume_VolumeCode;
				if (data.Series_Signum)
					this.signum = data.Series_Signum;
				break;
			case VolumePart.General:
				this.parseData(data, VolumePart.Label);
				this.cullMark = data.Volume_CullMark;
				this.date = data.Volume_Date;
				this.dateBegin = data.Volume_DateBegin;
				this.dateEnd = data.Volume_DateEnd;
				this.internalNotes = data.Volume_InternalNotes;
				this.labelText = data.Volume_LabelText;
				this.location = data.Volume_Location;
				this.publish = data.Volume_Publish;
				this.sequence = data.Volume_Sequence;
				this.title = data.Volume_Title;
				this.volumeCode = data.Volume_VolumeCode;
				this.hasActType = data.Volume_HasActType;
				this.hasNotes = data.Volume_HasNotes;
				break;
			case VolumePart.Notes:
				this.notes = data.Volume_Notes;
				break;
			case VolumePart.ObjectFiles:
				this.objectFiles = new Array<ObjectFile>();
				if (data.Volume_ObjectFiles) {
					for (let e of data.Volume_ObjectFiles) {
						let objectFile = new ObjectFile();
						objectFile.parseData(e);
						this.objectFiles.push(objectFile);
					}
				}
				break;
			case VolumePart.MediaTypes:
				this.mediaTypes = new Array<MediaType>();
				if (data.Volume_MediaTypes) {
					for (let e of data.Volume_MediaTypes) {
						let mediaType = new MediaType();
						mediaType.parseData(e);
						this.mediaTypes.push(mediaType);
					}
				}
				break;
		}
		return this;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(partName: string): any
	{
		switch (partName) {
			case VolumePart.General:
				return {
					Volume_ObjectDelete: this.objectDelete,
					Volume_CullMark: this.cullMark,
					Volume_Date: this.date,
					Volume_DateBegin: this.dateBegin,
					Volume_DateEnd: this.dateEnd,
					Volume_InternalNotes: this.internalNotes,
					Volume_LabelText: this.labelText,
					Volume_Location: this.location,
					Volume_Publish: this.publish,
					Volume_Sequence: this.sequence,
					Volume_Title: this.title,
					Volume_VolumeCode: this.volumeCode,
					Volume_HasActType: this.hasActType,
					Volume_HasNotes: this.hasNotes,
				}
		}
	}

	/**
	 * Add permissions on a new object, based on the parent object.
	 */
	newPermissions()
	{
		if (this.series && this.series.permitWrite())
			this.objectPermissions |= DoiObjectPermission.create|DoiObjectPermission.write;
	}
}

/**
 * A Mediatype.
 */
export class MediaType
{
	ID: number;
	typeID: number;
	name: string;
	extent: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): MediaType
	{

		this.ID = data.VolumeMediaType_VolumeMediaTypeID;
		this.typeID = data.VolumeMediaType_MediaTypeID;
		this.name = data.MediaType_Name;
		this.extent = data.VolumeMediaType_Extent;
		return this;
	}
}

// Fn7klSmXDHk+HL8B5+SAAyjbn6k=
