import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { DoiModule, DoiObjectEditGuard, DoiRedirectGuard } from '../doi/DoiModule';

import { KlaraModule } from '../klara/KlaraModule';
import { SeriesGroupService } from './model/SeriesGroupService';
import { SeriesGroupObjectView } from './view/SeriesGroupObjectView';
import { SeriesGroupObjectGeneralPanel } from './view/SeriesGroupObjectGeneralPanel';
import { SeriesGroupObjectSeriesPanel } from './view/SeriesGroupObjectSeriesPanel';

export * from './model/SeriesGroupObject';
export * from './model/SeriesGroupService';

export const seriesGroupRoutes: Routes = [
	{path: 'seriesgroup/:id', component: SeriesGroupObjectView, canActivate: [DoiRedirectGuard], canDeactivate: [DoiObjectEditGuard], data: { defaultChild: 'general' }, children: [
		{path: 'general', component: SeriesGroupObjectGeneralPanel, data: {title: 'Allmänt'}},
		{path: 'series', component: SeriesGroupObjectSeriesPanel, data: {title: 'Serier'}},
	]},
];

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(seriesGroupRoutes),
		DoiModule, KlaraModule,
	],
	declarations: [
		SeriesGroupObjectView, SeriesGroupObjectGeneralPanel, SeriesGroupObjectSeriesPanel,
	],
	exports: [
	],
	providers: [
		SeriesGroupService,
	],

})
export class SeriesGroupModule
{
}

// c0HJ2XSPJ1towrX7slIR633RkVw=
