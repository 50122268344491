export class DoiObjectCache<T>
{
	readonly name: string;
	private map: {[objectID: number]: T} = {};

	constructor(name: string)
	{
		this.name = name;
	}

	get(objectID: number): T
	{
		if (objectID)
			return this.map[objectID];
		else
			return null;
	}

	put(objectID: number, object: T)
	{
		if (objectID)
			this.map[objectID] = object;
	}
}

