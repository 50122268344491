import {FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors} from '@angular/forms';

/**
 * Validate that a text value is either empty or has a minimum length.
 */
export function minLengthOrEmptyValidator(length: number): ValidatorFn
{
	return (control: FormControl): ValidationErrors | null => {
		let text = control.value as string;
		if (!text || text.length >= length)
			return null;
		else
			return {minLengthOrEmpty: {value: text}};
	};
}
