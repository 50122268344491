<div class="doi-view doi-object-view processacttype-object-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start" [homeIcon]="false" [tailImplied]="false" #focusFirst>
			<li doi-breadcrumb *ngIf="routerLinkProcess()" [text]="object?.process?.parent?.objectName()" [link]="routerLinkProcess()"></li>
			<li doi-breadcrumb *ngIf="routerLinkProcess()" [text]="object?.process?.objectName()" [link]="routerLinkProcess()"></li>
			<li doi-breadcrumb [text]="object?.objectTitle()"></li>
		</ol>
		<ul doi-toolbar>
		</ul>
	</doi-view-title>

	<router-outlet (activate)="tabActivate($event)" (deactivate)="tabDeactivate($event)"></router-outlet>

</div>

<!-- 3zPW60A5uroUy+EN6l7G7UNNqbQ= -->
