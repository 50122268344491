import {DoiObject} from '../../doi/DoiModule';

/**
 * A search result entry, with object type, ID, weight, categories, etc.
 */
export class DoiSearchResultEntry
{
	/**
	 * The object type.
	 */
	objectType: string;
	
	/**
	 * The object ID.
	 */
	objectID: number;
	
	/**
	 * The weight value.
	 */
	weight: number;
	
	/**
	 * The category IDs.
	 */
	catIDs: number[];
	
	/**
	 * Application defined properties.
	 */
	props: any;
	
	/**
	 * Indicates if the entry is included in the current category selection.
	 */
	included: boolean = true;
	
	/**
	 * The model object that the entity refers to. Set when result entries are fetched.
	 */
	object: DoiObject;

	/**
	 * The object type text. Set when the entry text are built by the search page. 
	 */	
	typeText: string = '';

	/**
	 * The header or title text. Set when the entry text are built by the search page. 
	 */	
	headerText: string = '';

	/**
	 * The body text. Set when the entry text are built by the search page. 
	 */	
	bodyText: string = '';
	
	/**
	 * The icon name. Set when the entry text are built by the search page. 
	 */	
	iconName: string = null;
	
	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): DoiSearchResultEntry
	{
		if (!data.Cats)
			throw new Error("No Cats array in search result entry.");
			
		this.objectType = data.Type;
		this.objectID = data.ID;
		this.weight = data.Weight;
		this.catIDs = new Array<number>();
		for (let catID of data.Cats) {
			this.catIDs.push(catID);
		}
		
		this.props = data.Props || {};
		
		return this;
	}
}
