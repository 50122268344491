import {DoiIconMapper} from '../core/DoiIconMapper';
import {DoiTreeNodeChildSupplier} from './DoiTreeNodeChildSupplier';

/**
 * A navigators node.
 */
export abstract class DoiTreeNode<TN extends DoiTreeNode<TN, PET>, PET>
{
	/**
	 * The path element associated with the node.
	 */
	pathElement: PET;

	/**
	 * The element id of the component.
	 */
	id: string;

	/**
	 * The displayed label.
	 */
	label: string;

	/**
	 * The parent node.
	 */
	parent: TN;

	/**
	 * Child nodes.
	 */
	children: TN[];

	/**
	 * The child node supplier.
	 */
	childNodeSupplier: DoiTreeNodeChildSupplier<TN, PET>;

	/**
	 * Indicates if the node is expanded.
	 */
	private _expanded: boolean = false;

	/**
	 * Indicates if this node is always expanded.
	 */
	private _expandedAlways: boolean = false;

	/**
	 * Indicates if the node is selected. Can only be true for expanded nodes.
	 */
	private _selected: boolean = false;

	/**
	 * The icon name.
	 */
	private _iconName: string = 'folder';

	/**
	 * Translate an icon name to one or more class names separated by spaces.
	 */
	private _iconMapper: DoiIconMapper;

	/**
	 * A function that returns the icon name for a node.
	 */
	private _iconNameHandler: (node: TN) => string;

	/**
	 * The node class or classes.
	 */
	private _nodeClass: string;

	/**
	 * The pending expand path.
	 */
	private _expandPath: PET[];

	/**
	 * Indicates if this node is sticky. A sticky node is kept expanded even if another non-sticky node is expanded. This propery is propagated to children.
	 */
	private _sticky: boolean = false;

	/**
	 * The tooltip text.
	 */
	private _tooltip: string;

	/**
	 * Application defined values.
	 */
	private _values: Map<string, any>;

	/**
	 * Construct a new node.
	 */
	constructor(label?: string)
	{
		this.label = label;
	}

	/**
	 * Indicates if the node is expanded.
	 */
	get expanded(): boolean
	{
		return this._expanded;
	}

	set expanded(expanded: boolean)
	{
		this._expanded = expanded;
	}

	/**
	 * Indicates if the node is always expanded.
	 */
	get expandedAlways(): boolean
	{
		return this._expandedAlways;
	}

	set expandedAlways(expandedAlways: boolean)
	{
		this._expandedAlways = expandedAlways;
	}

	/**
	 * Indicates if the node is selected as well as expanded.
	 */
	get selected(): boolean
	{
		return this._selected;
	}
	set selected(selected: boolean)
	{
		this._selected = selected;
	}

	/**
	 * Set a child node supplier that is invoked when children needs to be populated.
	 */
	setChildNodeSupplier(supplier: DoiTreeNodeChildSupplier<TN, PET>): TN
	{
		this.childNodeSupplier = supplier;
		return this as any;
	}

	/**
	 * Return the icon class.
	 */
	getIconClass(): string
	{
		if (this._iconMapper)
			return this._iconMapper.iconClass(this.getIconName());
		else
			return 'far fa fa-'+this._iconName;
	}

	/**
	 * Return the icon name.
	 */
	getIconName(): string
	{
		if (this._iconNameHandler)
			return this._iconNameHandler(this as any);
		else
			return this._iconName;
	}

	/**
	 * Set the icon name.
	 */
	setIconName(iconName: string): TN
	{
		this._iconName = iconName;

		return this as any;
	}

	/**
	 * Return the icon mapper, or null.
	 */
	getIconMapper() : DoiIconMapper
	{
		return this._iconMapper;
	}

	/**
	 * Set the function that returns the icon name.
	 */
	setIconMapper(iconMapper: DoiIconMapper): TN
	{
		this._iconMapper = iconMapper;
		return this as any;
	}

	/**
	 * Return the function that returns the icon name, or null.
	 */
	getIconNameHandler() : (node: TN) => string
	{
		return this._iconNameHandler;
	}

	/**
	 * Set the function that returns the icon name.
	 */
	setIconNameHandler(iconNameHandler: (node: TN) => string): TN
	{
		this._iconNameHandler = iconNameHandler;
		return this as any;
	}

	/**
	 * Test if this node is sticky. A sticky node is kept expanded even if another non-sticky node is expanded. This propery is propagated to children.
	 */
	isSticky(): boolean
	{
		return this._sticky;
	}

	/**
	 * Return the path element associated with this node.
	 */
	getPathElement(): PET
	{
		return this.pathElement;
	}

	/**
	 * Associate this node with a path element.
	 */
	setPathElement(pathElement: PET): TN
	{
		this.pathElement = pathElement;
		return this as any;
	}

	/**
	 * Specify if this node is sticky. A sticky node is kept expanded even if another non-sticky node is expanded. This propery is propagated to children.
	 */
	setSticky(sticky: boolean): TN
	{
		this._sticky = sticky;

		if (this.children) {
			for (let child of this.children) {
				child.setSticky(sticky);
			}
		}

		return this as any;
	}

	/**
	 * Add a child node and return this node.
	 */
	addChild(childNode: TN): TN
	{
		this.checkCircular(childNode);

		if (!this.children)
			this.children = new Array<TN>();
		this.children.push(childNode);
		childNode.parent = this as any;

		if (!childNode.getIconMapper())
			childNode.setIconMapper(this.getIconMapper());

		return this as any;
	}

	/**
	 * Refresh this node by clearing the children.
	 * @param selected Indicates that the path from the root should be considered selected. Optional, defaults to true.
	 */
	refresh(selected: boolean = true): TN
	{
		this.children = null;

		if (this.expanded)
			this.expand(selected);

		return this as any;
	}

	/**
	 * Remove a child node and return this node.
	 */
	removeChild(childNode: TN): TN
	{
		if (!this.children)
			return this as any;

		let ix = this.children.indexOf(childNode);
		if (ix >= 0)
			this.children.splice(ix, 1);
	}

	/**
	 * Examine the parent hierarchy to ensure that a new child node isn't a parent.
	 */
	private checkCircular(newNode: TN)
	{
		if (!this.parent)
			return;

		if (this.parent == newNode)
			throw new Error('Circular tree');

		this.parent.checkCircular(newNode);
	}

	/**
	 * Test if this node has the same label and path element as the specified node.
	 */
	equals(node: TN): boolean
	{
		if (this.label != node.label)
			return false;
		if (!this.equalsPathElement(this.getPathElement(), node.getPathElement()))
			return false;
		return true;
	}

	/**
	 * Test if two path elements are equal.
	 */
	abstract equalsPathElement(element1: PET, element2: PET): boolean;

	/**
	 * Collapse this node and its children.
	 * @param selected Indicates that the node should be considered selected. Optional, defaults to false.
	 */
	collapse(selected: boolean = false)
	{
		this.selected = selected;

		if (!this.expanded || this.expandedAlways)
			return;

		this.expanded = false;

		if (this.children) {
			for (let child of this.children) {
				child.collapse();
			}
		}
	}

	/**
	 * Expand this node, collapse it's siblings and refresh the children.
	 * @param selected Indicates that the path from the root should be considered selected. Optional, defaults to true.
	 */
	expand(selected: boolean = true): TN
	{
		//	Collapse non-sticky siblings. Also collapse sticky siblings if this node is sticky.

		let _this: TN = this as any;
		if (this.parent && this.parent.children) {
			for (let sibling of this.parent.children) {
				if (sibling != _this) {
					if (!sibling.isSticky() || this.isSticky())
						sibling.collapse();
				}
			}
		}

		//	Ensure this and all parents are expanded. If this node is selected then all parents are.

		let n: TN = this as any;
		while (n) {
			n.expanded = true;
			if (selected)
				n.selected = selected;
			n = n.parent;
		}

		//	Check expansion path.

		let expandFirst: PET = null;
		let expandRest: PET[] = null;
		if (this._expandPath && this._expandPath.length > 0) {
			expandFirst = this._expandPath[0];
			expandRest = this._expandPath.slice(1);
		}
		this._expandPath = null;

		//	Collapse children and check expand path for child to expand.

		let expandChild: TN = null;
		if (this.children) {
			for (let child of this.children) {
				if (expandFirst && !expandChild) {
					if (this.equalsPathElement(expandFirst, child.getPathElement()))
						expandChild = child;
				}
				if (expandChild != child) {
					child.collapse(false);
				}
			}
		}

		if (expandChild && !this.childNodeSupplier) {
			expandChild._expandPath = expandRest;
			expandChild.expand(selected);
			return;
		}

		//	Fetch children.

		if (this.childNodeSupplier) {
			let children = new Array<TN>();
			let node: TN = this as any;
			this.childNodeSupplier.readChildren(node).subscribe(
				nodes => {
					children = children.concat(nodes);
				},
				error => {
					console.error('Navigator expand:');
					console.error(error);
				},
				() => {
					//	Check if the child list is changed.
					let equal = true;
					if (this.children && children.length == this.children.length) {
						for (let i in children) {
							if (!children[i].equals(this.children[i])) {
								equal = false;
								break;
							}
						}
					} else {
						equal = false;
					}
					//	Propagate parent and sticky.
					if (!equal) {
						for (let child of children) {
							child.parent = this as any;
							child.setSticky(this.isSticky());
						}
						this.children = children;
					}
					//	Expand current path.
					if (expandFirst) {
						for (let child of this.children) {
							if (this.equalsPathElement(expandFirst, child.getPathElement())) {
								expandChild = child;
								break;
							}
						}
						if (expandChild) {
							expandChild._expandPath = expandRest;
							expandChild.expand(selected);
						}
					}
				}
			);
		}

		return this as any;
	}

	/**
	 * Specify if this node is always expanded.
	 */
	setExpandedAlways(expandedAlways: boolean): TN
	{
		this.expandedAlways = true;

		this.expand();

		return this as any;
	}

	/**
	 * Expand the specified path.
	 */
	expandPath(path: PET[], selected?: boolean): void
	{
		if (!path || path.length == 0)
			return;
		let first = path[0];
		if (!first)
			return;

		this._expandPath = path;
		this.expand(selected);
	}

	/**
	 * Return the expanded path recursively.
	 */
	getExpandedPath(): TN[]
	{
		let path = new Array<TN>();

		this.buildExpandedPath(path);

		return path;
	}

	/**
	 * Build the expanded path recursively.
	 */
	buildExpandedPath(path: TN[])
	{
		if (!this.expanded)
			return;

		path.push(this as any);

		if (this.children) {
			for (let child of this.children) {
				child.buildExpandedPath(path);
			}
		}
	}

	/**
	 * Return a separated string with all labels from the outermost object node to this node.
	 * @param separator The separator.
	 */
	pathLabels(separator: string): string
	{
		if (!this.parent || !this.parent.label || !this.parent.getPathElement())
			return this.label;

		return this.parent.pathLabels(separator)+separator+this.label;
	}

	/**
	 * Test if this node is a leaf.
	 */
	isLeaf(): boolean
	{
		if (this.children && this.children.length)
			return false;

		return !this.childNodeSupplier;
	}

	/**
	 * Return the node class.
	 */
	getNodeClass(): string
	{
		return this._nodeClass;
	}

	/**
	 * Set the node class.
	 */
	setNodeClass(nodeClass: string): TN
	{
		this._nodeClass = nodeClass;

		return this as any;
	}

	/**
	 * Return the node class. If a class has been explicitly set, it is returned. Otherwise, a subclass may return
	 * a class derived from other properties.
	 */
	nodeClass(): string
	{
		return this._nodeClass;
	}

	/**
	 * Return the tooltip text.
	 */
	getTooltip()
	{
		return this._tooltip ? this._tooltip : '';
	}

	/**
	 * Set the tooltip text.
	 */
	setTooltip(tooltip: string): TN
	{
		this._tooltip = tooltip;

		return this as any;
	}

	/**
	 * Return an application defined value.
	 */
	getValue(key: string): any
	{
		return this._values ? this._values.get(key) : undefined;
	}

	/**
	 * Set an application defined value.
	 */
	setValue(key: string, value: any): TN
	{
		if (!this._values)
			this._values = new Map<string, any>();
		this._values.set(key, value);

		return this as any;
	}
}