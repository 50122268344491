import {NgModule} from '@angular/core';

import {DoiLoginService} from './model/DoiLoginService';

export * from './model/DoiIdentityProvider';
export * from './model/DoiLoginService';
export * from './view/DoiLoginView';

@NgModule({
	imports: [
	],
	declarations: [
	],
	exports: [
	],
	providers: [
		DoiLoginService
	],
})
export class DoiLoginModule
{
}
