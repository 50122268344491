import {DoiObject, DoiObjectRef, DoiObjectPermission} from '../../doi/DoiModule';

import {DocStoreLink, PropertyValue, ObjectFile} from '../../klara/KlaraModule';

/**
 *	Object part names for the Unit entity object.
 */
export const UnitPart =
{
	Label : 'Label',
	General : 'General',
	Confidential : 'Confidential',
	Notes: 'Notes',
	Texts: 'Texts',
	Children: 'Children',
	ProcActTypes: 'ProcActTypes',
	Processes: 'Processes',
	ObjectFiles : "ObjectFiles",
	PropertyValues : "PropertyValues",
	RelatedUnits: "RelatedUnits",

}

/**
 * Enumeration UnitCullMark.
 */
export enum UnitCullMark
{
	Yes,
	Partial,
	No,
}

/**
 * Enumeration PublishLevel.
 */
export enum PublishLevel
{
}

export class ProcessObject
{
	static readonly iconName = 'rectangle-landscape';

	processID: number;
	code: string;
	nameListActKind: string;
	nameList: string;
	sequence: number;
	processTypeName: string;
	processStructureName: string;
	procStructID: number;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): ProcessObject
	{
		this.processID = data.Process_ProcessID;
		this.code = data.Process_Code;
		this.nameListActKind = data.Process_NameListActKind != null ? data.Process_NameListActKind : data.Process_NameList;
		this.nameList = data.Process_NameList;
		this.processTypeName = data.ProcessType_Name;
		this.processStructureName = data.ProcessStructure_Name;
		this.procStructID = data.Process_ProcStructID;
		return this;
	}
}


export class ProcActTypeObject
{
	static readonly iconName = 'file-alt';

	processID: number;
	processActTypeID: number;
	processCode: string;
	nameListActKind: string;
	processNameList: string;
	nameList: string;
	sequence: number;
	processTypeName: string;
	processStructureName: string;
	procStructID: number;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): ProcActTypeObject
	{
		this.processID = data.ProcessActType_ProcessID;
		this.processActTypeID = data.ProcessActType_ProcessActTypeID;
		this.processCode = data.Process_Code;
		this.nameListActKind = data.Process_NameListActKind;
		this.nameList = data.ProcessActType_NameList;
		this.sequence = data.ProcessActType_Sequence;
		this.processTypeName = data.ProcessType_Name;
		this.processNameList = data.Process_NameList;
		this.processStructureName = data.ProcessStructure_Name;
		this.procStructID = data.Process_ProcStructID;

		return this;
	}
}


/**
 *	The Unit entity object.
 */
export class UnitObject extends DoiObject
{
	static readonly iconName = 'archive';

	/**
	 *	Attribute values.
	 */
	archiveOrigID: number;
	code: string;
	title: string;
	parentUnitID: number;
	unitTypeID: number;
	containsMaxCount: number;
	cullMark: UnitCullMark;
	date: string;
	dateBegin: string;
	dateEnd: string;
	internalNotes: string;
	labelText: string;
	location: string;
	nextSerialNo: number;
	notes: string;
	objectUUID: any;
	publish: PublishLevel;
	refNoSeqFrom: number;
	refNoSeqTo: number;
	availableCount: number;
	confidential: boolean;
	confidentialUntil: Date;
	hasNotes: boolean;
	level: number;
	navText: string;
	parentCode: string;
	publishImplied: PublishLevel;
	refNoSeq: string;
	unitTypeName: string;
	archiveOrigName: string;

	procActTypes: ProcActTypeObject[];
	processes: ProcessObject[];
	units: Child[];
	docStoreLinks: DocStoreLink[];
	objectFiles: ObjectFile[];
	propertyValues: PropertyValue[];
	relatedUnits: RelUnit[];

	/**
	 *	Parent.
	 */
	parent: UnitObject;

	/**
	 * Construct a new Unit object.
	 */
	constructor(objectID: number)
	{
		super(objectID);
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return UnitObject.iconName;
	}

	/**
	 * Return a text suitable for additional text in search results etc. Overridden to return notes.
	 */
	objectText(): string
	{
		return this.notes;
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectTitle(): string
	{
		return this.navText || this.title;
	}

	/**
	 * The symbolic object type name, i e 'Unit'.
	 */
	get objectType(): string
	{
		return 'Unit';
	}

	/**
	 * Return an object reference path from the root, corresponding to the navigation structure, or null.
	 */
	objectRefPath(): DoiObjectRef[]
	{
		let path: DoiObjectRef[] = null;

		if (this.parent) {
			path = this.parent.objectRefPath();
			path.push(this.objectRef());
			return path;
		}

		path = new Array<DoiObjectRef>();

		path.push(this.objectRef());

		return path;
	}

	/**
	 * Populate an object from a data object.
	 */
	parseData(data: any, partName: string): UnitObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, UnitPart.Label);
				this.date = data.Unit_Date;
				//	Additional search result entry information.
				if (data.Unit_Notes)
					this.notes = data.Unit_Notes;
				break;
			case UnitPart.Label:
				this.objectID = data.Unit_UnitID;
				this.archiveOrigID = data.Unit_ArchiveOrigID;
				this.code = data.Unit_Code;
				this.title = data.Unit_Title;
				this.parentUnitID = data.Unit_ParentUnitID;
				this.unitTypeID = data.Unit_UnitTypeID;
				this.objectUUID = data.Unit_ObjectUUID;
				this.navText = data.Unit_NavText;
				this.unitTypeName = data.UnitType_Name;
				this.archiveOrigName = data.ArchiveOrig_Name;
				if (data.Unit_Date)
					this.date = data.Unit_Date;
				break;
			case UnitPart.General:
				this.parseData(data, UnitPart.Label);
				this.containsMaxCount = data.Unit_ContainsMaxCount;
				this.cullMark = data.Unit_CullMark;
				this.date = data.Unit_Date;
				this.dateBegin = data.Unit_DateBegin;
				this.dateEnd = data.Unit_DateEnd;
				this.internalNotes = data.Unit_InternalNotes;
				this.labelText = data.Unit_LabelText;
				this.location = data.Unit_Location;
				this.nextSerialNo = data.Unit_NextSerialNo;
				this.publish = data.Unit_Publish;
				this.refNoSeqFrom = data.Unit_RefNoSeqFrom;
				this.refNoSeqTo = data.Unit_RefNoSeqTo;
				this.availableCount = data.Unit_AvailableCount;
				this.confidentialUntil = data.Unit_ConfidentialUntil ? new Date(data.Unit_ConfidentialUntil) : null;
				this.hasNotes = data.Unit_HasNotes;
				this.level = data.Unit_Level;
				this.navText = data.Unit_NavText;
				this.parentCode = data.Unit_ParentCode;
				this.publishImplied = data.Unit_PublishImplied;
				this.refNoSeq = data.Unit_RefNoSeq;
				break;
			case UnitPart.Confidential:
				this.confidential = data.Unit_Confidential;
				break;
			case UnitPart.Notes:
				this.notes = data.Unit_Notes;
				break;
			case UnitPart.PropertyValues:
				this.propertyValues = new Array<PropertyValue>();
				if (data.Unit_PropertyValues) {
					for (let e of data.Unit_PropertyValues) {
						this.propertyValues.push(new PropertyValue().parseData(e));
					}
					PropertyValue.sort(this.propertyValues);
				}
				break;
			case UnitPart.ProcActTypes:
				this.procActTypes = new Array<ProcActTypeObject>();
				for (let e of data.Unit_ProcActTypes) {
					let object = new ProcActTypeObject();
					object.parseData(e);
					this.procActTypes.push(object);
				}
				break;
			case UnitPart.Processes:
				this.processes = new Array<ProcessObject>();
				for (let e of data.Unit_Processes) {
					let object = new ProcessObject();
					object.parseData(e);
					this.processes.push(object);
				}
				break;
			case UnitPart.Children:
				this.units = new Array<Child>();
				if (data.Unit_Children) {
					for (let e of data.Unit_Children) {
						this.units.push(new Child().parseData(e));
					}
				}
				break;
			case UnitPart.RelatedUnits:
				this.relatedUnits = new Array<RelUnit>();
				if (data.Unit_RelatedUnits) {
					for (let e of data.Unit_RelatedUnits) {
						this.relatedUnits.push(new RelUnit().parseData(e));
					}
				}
				break;
			case UnitPart.ObjectFiles:
				this.objectFiles = new Array<ObjectFile>();
				if (data.Unit_ObjectFiles) {
					for (let e of data.Unit_ObjectFiles) {
						let objectFile = new ObjectFile();
						objectFile.parseData(e);
						this.objectFiles.push(objectFile);
					}
				}
				break;
		}
		return this;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(partName: string): any
	{
		switch (partName) {
			case UnitPart.General:
				return {
					Unit_ObjectDelete: this.objectDelete,
					Unit_ArchiveOrigID: this.archiveOrigID,
					Unit_Code: this.code,
					Unit_Title: this.title,
					Unit_ParentUnitID: this.parentUnitID,
					Unit_UnitTypeID: this.unitTypeID,
					Unit_ContainsMaxCount: this.containsMaxCount,
					Unit_CullMark: this.cullMark,
					Unit_Date: this.date,
					Unit_DateBegin: this.dateBegin,
					Unit_DateEnd: this.dateEnd,
					Unit_InternalNotes: this.internalNotes,
					Unit_LabelText: this.labelText,
					Unit_Location: this.location,
					Unit_NextSerialNo: this.nextSerialNo,
					Unit_Publish: this.publish,
					Unit_RefNoSeqFrom: this.refNoSeqFrom,
					Unit_RefNoSeqTo: this.refNoSeqTo,
				}
			case UnitPart.Notes:
				return {
					Unit_Notes: this.notes,
				}
			case UnitPart.PropertyValues:
				if (this.propertyValues) {
					let propertyValues = new Array<any>();
					for (let propertyValue of this.propertyValues) {
						propertyValues.push(propertyValue.buildDataPart());
					}
					return {
						Unit_PropertyValues: propertyValues
					}
				}
				break;
		}
	}

	/**
	 * Add permissions on a new object, based on the parent object.
	 */
	newPermissions()
	{
		if (this.parent && this.parent.permitWrite())
			this.objectPermissions |= DoiObjectPermission.create|DoiObjectPermission.write;
	}
}

/**
 * A Child, corresponding to a detail object from Project part children.
 */
export class Child
{
	static readonly iconName = 'archive';

	unitID: number;
	unitTypeName: string;
	code: string;
	title: string;
	date: string;
	navText: string;
	location: string;
	archiveOrigID: number;
	archiveOrigName: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): Child
	{
		this.unitID = data.Unit_UnitID;
		this.code = data.Unit_Code;
		this.title = data.Unit_Title;
		this.date = data.Unit_Date;
		this.navText = data.Unit_NavText;
		this.unitTypeName = data.UnitType_Name;
		this.archiveOrigID = data.Unit_ArchiveOrigID;
		this.archiveOrigName = data.ArchiveOrig_Name;
		return this;
	}
}

/**
 * A related unit, corresponding to a detail object from unit part related units.
 */
export class RelUnit
{
	static readonly iconName = 'archive';

	unitID: number;
	code: string;
	title: string;
	date: string;
	location: string;
	sequence: number;
	relName: string;
	comment: string;
	unitTypeName: string;
	archiveOrigID: number;
	archiveOrigName: string;


	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): RelUnit
	{
		this.unitID = data.UnitRel_UnitID;
		this.code = data.Unit_Code;
		this.title = data.Unit_Title;
		this.date = data.Unit_Date;
		this.unitTypeName = data.UnitType_Name;
		this.archiveOrigID = data.Unit_ArchiveOrigID;
		this.archiveOrigName = data.ArchiveOrig_Name;
		this.relName = data.UnitRelType_RelName;
		this.comment = data.UnitRel_Comment;
		return this;
	}
}


// bS6t0pC5lf1Qmf7TtEE+NECClF8=
