import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {ObjectFile, DocStoreLink} from '../../klara/KlaraModule';
import {SeriesPart, SeriesObject, } from '../model/SeriesObject';
import {SeriesService} from '../model/SeriesService';

@Component({
	templateUrl: 'SeriesObjectGeneralPanel.html'
})
export class SeriesObjectGeneralPanel extends DoiObjectPanel<SeriesObject>
{
	name = 'general';
	docStoreLinks: DocStoreLink[];

	constructor(doi: DoiService, route: ActivatedRoute, private seriesService: SeriesService)
	{
		super(doi, route);
	}

	openSeries(seriesID : number)
	{
		this.appView.openObject('Series', seriesID);
	}

	/**
	 * Download an object file.
	 */
	downloadObjectFile(objectFile: ObjectFile): void
	{
		this.seriesService.downloadObjectFile(objectFile);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(SeriesPart.General),
			this.refreshObjectPart(SeriesPart.Label),
			this.refreshObjectPart(SeriesPart.ObjectFiles),
			this.refreshObjectPart(SeriesPart.Notes),
			this.seriesService.readDocumentStoreLinks(this.objectID).pipe(tap((links: DocStoreLink[]) => {this.docStoreLinks = links}))
		);

	}
}

// MmdBQZcnOwOPzGJpwuwFNUe0Pbk=
