import {Component, Directive, Input, ViewChild, ElementRef, AfterViewInit, HostListener} from '@angular/core';

import {NgbModal, NgbActiveModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

export enum DoiModalType
{
	OK_CANCEL, YES_NO, YES_NO_CANCEL
}

export enum DoiModalResult
{
	CANCEL, OK, NO, YES
}

@Component({ // I18N
	selector: 'doi-modal-dialog-header',
	template: `
	<div class="modal-header">
		<h4 *ngIf="title" class="modal-title">{{title}}</h4>
		<button type="button" class="btn-close" tabindex="-1" aria-label="Close" (click)="dismiss()">
		</button>
	</div>
	`,
	host: { 'class' : 'doi-modal-dialog-header' }
})
export class DoiModalDialogHeader
{
	@Input() dialog: DoiModalDialog;
	@Input() title: string;
	@Input() subtitle: string;

	dismiss()
	{
		this.dialog.dismiss();
	}
}

@Component({ // I18N
	selector: 'doi-modal-dialog-footer',
	template: `
	<div *ngIf="type == 0" class="modal-footer">
		<button type="button" #buttonOK class="btn btn-primary" (click)="close(1)">OK</button>
		<button type="button" class="btn btn-light" (click)="dismiss()">Avbryt</button>
	</div>
	<div *ngIf="type == 1" class="modal-footer">
		<button type="button" #buttonYes1 class="btn btn-primary" (click)="close(3)">Ja</button>
		<button type="button" class="btn btn-secondary" (click)="close(2)">Nej</button>
	</div>
	<div *ngIf="type == 2" class="modal-footer">
		<button type="button" #buttonYes2 class="btn btn-primary" (click)="close(3)">Ja</button>
		<button type="button" class="btn btn-secondary" (click)="close(2)">Nej</button>
		<button type="button" class="btn btn-light" (click)="dismiss()">Avbryt</button>
	</div>
	`,
	host: { 'class' : 'doi-modal-dialog-footer' }
})
export class DoiModalDialogFooter implements AfterViewInit
{
	@Input() dialog: DoiModalDialog;
	@Input() type = DoiModalType.OK_CANCEL;

	@ViewChild('buttonOK') buttonOK: ElementRef;
	@ViewChild('buttonYes1') buttonYes1: ElementRef;
	@ViewChild('buttonYes2') buttonYes2: ElementRef;

	ngAfterViewInit()
	{
		setTimeout(() => {
			switch (this.type) {
				case DoiModalType.OK_CANCEL: this.buttonOK.nativeElement.focus(); break;
				case DoiModalType.YES_NO: this.buttonYes1.nativeElement.focus(); break;
				case DoiModalType.YES_NO_CANCEL: this.buttonYes2.nativeElement.focus(); break;
			}
		});
	}

	close(result: DoiModalResult)
	{
		this.dialog.close(result);
	}

	dismiss()
	{
		this.dialog.dismiss();
	}
}

@Component({ // I18N
	selector: 'doi-modal-dialog',
	template: `
		<doi-modal-dialog-header [dialog]="this" [title]="title"></doi-modal-dialog-header>
		<div *ngIf="body" class="modal-body">
			{{body}}
		</div>
		<doi-modal-dialog-footer [dialog]="this" [type]="type"></doi-modal-dialog-footer>
	`,
	host: { 'class' : 'doi-modal-dialog' }
})
export class DoiModalDialog
{
	activeModal: NgbActiveModal;

	@Input() type = DoiModalType.OK_CANCEL;
	@Input() title: string;
	@Input() body: string;

	constructor(activeModal: NgbActiveModal)
	{
		this.activeModal = activeModal;
	}

	close(result: DoiModalResult)
	{
		this.activeModal.close(result);
	}

	dismiss()
	{
		this.activeModal.close(DoiModalResult.CANCEL);
	}

	/**
	 * Handle key down events.
	 */
	@HostListener('document:keydown', ['$event'])
	handleKeydownEvent(event: KeyboardEvent)
	{
		switch (event.code) {
			case 'Escape':
				this.dismiss();
				break;
		}
	}
}