import {DoiSearchWordMode} from './DoiSearchWordMode';

/**
 * A search criteria.
 */
export class DoiSearchCriteria
{
	/**
	 * The search text.
	 */
	text: string = null;

	/**
	 * The client. Application defined syntax and semantics.
	 */
	client: string = "WebUI";

	/**
	 * The search scope. Application defined syntax and semantics.
	 */
	scope: string = null;

	/**
	 * Object types to search, or null. Application defined semantics.
	 */
	objectTypes: string[];

	/**
	 * The search word mode.
	 */
	wordMode = DoiSearchWordMode.All;

	/**
	 * Construct a new search criteria.
	 */
	constructor(text?: string, scope?: string, wordMode?: DoiSearchWordMode)
	{
		this.text = text ? text : null;
		this.scope = scope ? scope : null;

		if (wordMode)
			this.wordMode = wordMode;
	}

	/**
	 * Test if the specified search criteria has the same text, scope and object types.
	 */
	equals(that: DoiSearchCriteria)
	{
		if (!that || this.text != that.text || this.scope != that.scope || this.wordMode != that.wordMode)
			return false;

		if (this.objectTypes && that.objectTypes)
			return this.objectTypes.sort().toString() == that.objectTypes.sort().toString();

		if (this.objectTypes || that.objectTypes)
			return false;

		return true;
	}

	/**
	 * Create query parameters.
	 */
	queryParams(): Object
	{
		return this;
	}
}
