import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {ProcessPart, ProcessObject} from '../model/ProcessObject';
import {PropertyValue} from '../../klara/model/PropertyValue';
import {KlaraObjectPanel, PropertyValueList } from '../../klara/KlaraModule';

@Component({
	templateUrl: 'ProcessObjectActKindPanel.html'
})
export class ProcessObjectActKindPanel extends KlaraObjectPanel<ProcessObject>
{
	name = 'actkind';

	propertyValueList = new PropertyValueList(false);

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openProcess(processID : number)
	{
		this.appView.openObject('Process', processID);
	}

	/**
	* För beskrivningsält som inte är hanteringsanvisningar
	*/
	propertyValues() : PropertyValue[]
	{

		if (!this.object  || this.object.propertyValues == null)
			return PropertyValueList.empty;

		return this.propertyValueList.propertyValues(this.object.propertyValues, null, this.editing(), this.editingAsStructAdm());
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessPart.General),
			this.refreshObjectPart(ProcessPart.ActKindAltNames),
			this.refreshObjectPart(ProcessPart.ActKind),
			this.refreshObjectPart(ProcessPart.PropertyValues),
		);
	}
}

// rcT9DIxhYj6XC14VqboZUpIT5aA=
