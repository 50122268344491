import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';

import {ArchiveOrigPart, ArchiveOrigObject, Child} from '../model/ArchiveOrigObject';

@Component({
	templateUrl: 'ArchiveOrigObjectChildrenPanel.html'
})
export class ArchiveOrigObjectChildrenPanel extends DoiObjectPanel<ArchiveOrigObject>
{
	name = 'children';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

//	openArchiveOrig(archiveOrigID : number)
//	{
//		this.appView.openObject('ArchiveOrig', archiveOrigID);
//	}

	openArchiveOrig(archOrig : Child)
	{
		this.doi.router.navigate(this.routerLinkArchiveOrig(archOrig));
	}

	routerLinkArchiveOrig(archOrig : Child)
	{
		return ['/archiveorig', archOrig.archiveOrigID];
	}

	/**
	 * Return the icon class for the specified object.
	 */
	archiveOrigIconClass()
	{
		return this.iconClass(ArchiveOrigObject.iconName);
	}


	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ArchiveOrigPart.Children),
		);
	}
}

// rS9PI84cysHrMnRkC1Y62Cfx+ZY=
