<div class="doi-view doi-object-view volume-object-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start">
			<li doi-breadcrumb link="/archiveorigs" text="Struktur"></li>
			<li doi-breadcrumb *ngIf="routerLinkArchiveOrig()" [text]="object?.series?.archive?.archiveOrig?.objectTitle()" [link]="routerLinkArchiveOrig()"></li>
			<li doi-breadcrumb *ngIf="routerLinkArchive()" [text]="object?.series?.archive?.objectTitle()" [link]="routerLinkArchive()"></li>
			<li doi-breadcrumb *ngIf="routerLinkSeries()" [text]="object?.series?.objectTitle()" [link]="routerLinkSeries()"></li>
		</ol>
		<ul doi-toolbar>
			<doi-object-edit-tools [objectView]="this"></doi-object-edit-tools>
		</ul>
		<doi-title>Volym {{object.isNew() && !object.objectID ? '(ny)' : object.objectTitle()}}</doi-title> <!-- I18N -->
	</doi-view-title>

	<ul doi-view-tabs [iconMapper]="this">
		<li doi-view-tab name="general" [iconName]="probedIconName('general')" #focusFirst>Allmänt</li>
		<li doi-view-tab name="notes" [iconName]="probedIconName('notes')">Beskrivning</li>
	</ul>

	<router-outlet (activate)="tabActivate($event)" (deactivate)="tabDeactivate($event)"></router-outlet>

</div>

<!-- ybsB3i6rY7GNkyjWkagSA9hDvgs= -->
