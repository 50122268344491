import {Component, ViewChild, ElementRef} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {tap} from "rxjs/operators";

import {environment} from '../../environments/environment';

import {DoiBookmark, DoiService, DoiAction, DoiTopView} from '../doi/DoiModule';
import {DoiSearchToolComponent} from '../doi-search/DoiSearchModule';
import {AppInfo, KlaraTopView, ObjectFile, DocStoreLink} from '../klara/KlaraModule';
import {SystemInfoObject, SystemInfoService, SystemInfoPart} from '../systeminfo/SystemInfoModule';

import {AppBaseView} from '../AppBaseView';
import {KlaraDialogTopView} from '../klara/KlaraModule';

import {StartBaseView} from './StartBaseView';

@Component({
	templateUrl: 'StartView.html',
})
export class StartView extends StartBaseView implements KlaraDialogTopView
{

	constructor(doi: DoiService, route: ActivatedRoute, systemInfoService: SystemInfoService)
	{
		super(doi, route, systemInfoService);
	}
}
