import { ViewChild, Directive, HostListener } from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Subscription} from 'rxjs';
import {map, switchMap, tap} from "rxjs/operators";

import {DoiAction} from '../core/DoiAction';
import {DoiFocusFirst} from '../core/DoiFocusFirst';
import {DoiObject} from '../service/DoiObject';
import {DoiService} from '../service/DoiService';
import {DoiView} from './DoiView';
import {DoiSubView} from './DoiSubView';

/**
 * A top view.
 */
@Directive()
export class DoiTopView extends DoiView
{
	name = 'DoiTopView';

	route: ActivatedRoute;
	activeSubView: DoiSubView;

	@ViewChild('focusFirst')
	focusFirstComponent: any;

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi);

		this.route = route;

		let nav: any = window.navigator;

		this.actions.add(
			new DoiAction(this, 'FileShare', 'share-alt', 'Dela')
			.enabledHandler(() => nav && nav.share)
			.executeHandler(() => this.actionShare()));
		this.actions.add(
			new DoiAction(this, 'ViewRefresh', 'fas-sync-alt', 'Förnya')
			.workingHandler(() => this.refreshing)
			.executeHandler(() => this.refresh()));
	}

	/**
	 * Invoked when the view has been initialized. Subscribes to path and query parameters.
	 */
	ngOnInit()
	{
		super.ngOnInit();

		if (this.route) {
			this.route.paramMap.pipe(switchMap((pm: ParamMap) => [ pm ])).subscribe((pm) => this.paramsRecieved(pm));
			this.route.queryParamMap.pipe(switchMap((pm: ParamMap) => [ pm ])).subscribe((pm) => this.queryParamsRecieved(pm));
		}
	}

	/**
	 * Return the specified action. Delegates to the active view.
	 */
	action(actionName: string): DoiAction
	{
		if (this.activeSubView) {
			let action = this.activeSubView.action(actionName);
			if (action)
				return action;
		}

		return super.action(actionName);
	}

	actionShare()
	{
		let nav: any = window.navigator;
		if (nav && nav.share)
			nav.share({url: this.appView.lastUrl});
	}

	/**
	 * Test if editing the object is possible and allowed. The default implementation returns false.
	 */
	canEdit(): boolean
	{
		return false;
	}

	/**
	 * Test if this view recommends the user to be prompted if the window is about to be closed.
	 * The default implementation returns false.
	 */
	checkPromptClose(): boolean
	{
		return false;
	}

	/**
	 * Focus the first component.
	 */
	focusFirst(): boolean
	{
		if (!this.focusFirstComponent)
			return super.focusFirst();

		if (this.focusFirstComponent.focusFirst) {
			this.focusFirstComponent.focusFirst();
			return true;
		}

		if (this.focusFirstComponent.nativeElement) {
			this.focusFirstComponent.nativeElement.focus();
			return true;
		}

		return super.focusFirst();
	}

	/**
	 * Handle key down events. Delegates to DoiActionSet.executeMappedAction.
	 */
	@HostListener('document:keydown', ['$event'])
	handleKeydownEvent(event: KeyboardEvent)
	{
		this.actionSet().executeMappedAction(event);
	}

	/**
	 * Return the action names for the application menu. The default implementation delegates to the active subview.
	 * @param full Indicates if the full menu should be returned, visible on a reduced screen.
	 */
	menuAppActionNames(full: boolean): string[]
	{
		let actionNames = super.menuAppActionNames(full);

		if (this.activeSubView) {
			let subviewActionNames = this.activeSubView.menuAppActionNames(full);
			if (subviewActionNames.length) {
				if (actionNames.length)
					actionNames.push('-');
				actionNames.push.apply(actionNames, subviewActionNames);
			}
		}

		return actionNames;
	}

	/**
	 * Invoked when path parameters are received. Invokes processParams.
	 */
	paramsRecieved(pm: ParamMap): void
	{
		this.processParams(pm);
	}

	/**
	 * Invoked when query parameters are received. Invokes processParams.
	 */
	queryParamsRecieved(pm: ParamMap): void
	{
		this.processParams(pm);
	}

	/**
	 * Invoked when parameters are received. The default implementation checks the 'refresh' query parameter.
	 * If supplied, the parameter is removed and the view is refreshed.
	 */
	processParams(pm: ParamMap): void
	{
		if (pm.get('refresh')) {
			var snapshot = this.route.snapshot;
			const params = { ...snapshot.queryParams };
			delete params.refresh;
			this.doi.router.navigate([], { queryParams: params });
			this.refresh();
		}
	}

	/**
	 * Test if a search tools should be shown in the application toolbar. The default implementation returns true.
	 * Override to return false if the view has its own search field.
	 */
	searchToolVisible()
	{
		return true;
	}

	tabActivate(event: DoiSubView): void
	{
		this.activeSubView = event;
	}

	tabDeactivate(event: DoiSubView): void
	{
		this.activeSubView = null;
	}

	/**
	 * Invoked by an object view when it is visited or refreshed. Used to update last visited list and provide a value for a bookmark.
	 * Delegates to the application view.
	 * @param object The object.
	 * @param options Application defined options.
	 */
	visitingObject(object: DoiObject, options?: any)
	{
		if (this.appView)
			this.appView.visitingObject(object, options);
	}

	/**
	 * Invoked by a view when it is visited or refreshed. Used to update last visited list and provide a value for a bookmark.
	 * Delegates to the application view.
	 * @param path The navigation path.
	 * @param title The title to use for bookmarks.
	 * @param iconName Optional icon name.
	 * @param options Application defined options.
	 */
	visitingPath(path: string, title: string, iconName?: string, options?: any)
	{
		if (this.appView)
			this.appView.visitingPath(path, title, iconName, options);
	}
}
