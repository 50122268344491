import {Component} from '@angular/core';
import {Observable, forkJoin} from 'rxjs';

import {ActivatedRoute} from '@angular/router';
import {NgbModal, NgbActiveModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {DoiAction, DoiLabeledValue, DoiModalDialog, DoiModalType, DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {KlaraObjectPanel} from '../../klara/KlaraModule';

import {ProcessPart, ProcessObject, ProcessActType, ProcessActivity} from '../model/ProcessObject';

@Component({ // I18N
	template: `
		<doi-modal-dialog-header [dialog]="this" [title]="title"></doi-modal-dialog-header>
		<div class="modal-body">
			<div class="modal-body-text">
				<p>{{body}}</p>
			</div>
			<div class="modal-body-scroll doi-scroll">
				<div *ngFor="let entry of data">
					<doi-checkbox [(ngModel)]="entry.selected" [ngModelOptions]="{standalone: true}" [edit]="true">{{entry.actType.nameList}}</doi-checkbox>
				</div>
			</div>
		</div>
		<doi-modal-dialog-footer [dialog]="this" [type]="type"></doi-modal-dialog-footer>
	`,
	host: { 'class' : 'doi-modal-dialog' },
	styles: [ `
		.modal-body-scroll {
			height: auto;
			max-height: 10rem;
		}
	` ]
})
export class ProcessObjectActivityActTypesDialog extends DoiModalDialog
{
	data: { selected: boolean; actType: ProcessActType };

	constructor(activeModal: NgbActiveModal)
	{
		super(activeModal);
	}
}

@Component({
	templateUrl: 'ProcessObjectActivitiesPanel.html'
})
export class ProcessObjectActivitiesPanel extends KlaraObjectPanel<ProcessObject>
{
	name = 'activities';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);

		this.actions.add(
			new DoiAction(this, 'AddActivity', 'far-square-plus', 'Lägg till aktivitet')
			.enabledHandler(() => this.editingAsStructAdm())
			.executeHandler(() => this.addActivity())
		);
		this.actions.add(
			new DoiAction(this, 'EditActTypes', 'far-pen', 'Koppla handlingstyper')
			.enabledHandler(() => this.editingAsEditor())
			.executeHandler((arg) => this.editActTypes(arg as ProcessActivity))
		);
	}

	/**
	 * Editing as StructAdm currently not supported. Needs row move up/down.
	 */
	editingAsStructAdm()
	{
		return false;
	}

	/**
	 * Add a new activity row.
	 */
	addActivity()
	{
		let activity = new ProcessActivity();
		activity.sequence = this.object.activities.reduce((s, a) => Math.max(s, a.sequence), 0)+1;
		this.object.activities.push(activity);
	}

	/**
	 * Edit connected act types in a dialog.
	 */
	editActTypes(activity: ProcessActivity)
	{
		this.refreshObjectPart(ProcessPart.ActTypes).subscribe(
			(object) => {
				let selectedIDs = new Set<number>();
				if (activity.actTypes) {
					for (let activityActType of activity.actTypes) {
						selectedIDs.add(activityActType.processActTypeID);
					}
				}
				let entries = new Array<{ selected: boolean; actType: ProcessActType }>();
				for (let objectActType of object.actTypes) {
					entries.push({ selected: selectedIDs.has(objectActType.processActTypeID), actType: objectActType });
				}
				this.appView.modalDialog(DoiModalType.OK_CANCEL,
					 "Koppla handlingstyper", "Koppla handlingstyper till "+activity.name,
					 { size: 'lg', backdrop: 'static' },
					 ProcessObjectActivityActTypesDialog, entries).subscribe(
					(result) => {
						if (activity.actTypes)
							activity.actTypes.length = 0;
						else
							activity.actTypes = new Array<ProcessActType>();
						for (let entry of entries) {
							if (entry.selected)
								activity.actTypes.push(entry.actType);
						}
						this.formOnChange(true);
					}
				);
			}
		);
	}

	routerLinkActType(actType : ProcessActType)
	{
		return [ '/processacttype', actType.processActTypeID ];
	}

	routerLinkLinkedProcess(activity : ProcessActivity)
	{
		return [ '/process', activity.linkedProcessID ];
	}

	activityDeleteClass(activity: ProcessActivity)
	{
		if (activity.objectDelete)
			return 'fas fa-trash text-danger';
		else
			return 'far fa-trash';
	}

	activityIconClass(activity: ProcessActivity)
	{
		if (activity.objectDelete)
			return this.iconClass(ProcessActivity.iconName) + ' delete';
		else
			return this.iconClass(ProcessActivity.iconName);
	}

	/**
	 * Mark an object file for deletion, or clear the mark.
	 */
	deleteActivity(activity: ProcessActivity): void
	{
		if (activity) {
			activity.objectDelete = !activity.objectDelete;
			this.formOnChange(true);
		}
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessPart.Activities));
	}
}
