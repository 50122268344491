import {HttpParams, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map, startWith, switchMap, tap} from "rxjs/operators";

export const DoiObjectPermission =
{
	read: 1,
	write: 2,
	create: 4,
	delete: 8
}

/**
 * An object reference with a type and ID. Can optionally have a subview name to specify a specific object panel.
 */
export class DoiObjectRef
{
	readonly objectType: string;
	readonly objectID: number;
	readonly subviewName: string;

	/**
	 * Construct a new object reference.
	 */
	constructor(objectType: string, objectID: number, subviewName?: string)
	{
		this.objectType = objectType;
		this.objectID = objectID;
		this.subviewName = subviewName;
	}

	/**
	 * Test if the specified object references has the same type and ID.
	 */
	static equal(ref1: DoiObjectRef, ref2: DoiObjectRef): boolean
	{
		if (ref1)
			return ref1.equals(ref2);
		else
			return !ref2;
	}

	/**
	 * Test if the specified object reference has the same type and ID.
	 */
	equals(other: DoiObjectRef)
	{
		return other && this.objectType == other.objectType && this.objectID == other.objectID && this.subviewName == other.subviewName;
	}

	/**
	 * Create an object reference representing the specified object.
	 */
	static forObject(objectType: string, objectID: number, subviewName?: string): DoiObjectRef
	{
		return new DoiObjectRef(objectType, objectID, subviewName);
	}

	/**
	 * Create an object reference with the same type and ID has this reference and a specified subview name.
	 */
	withSubviewName(subviewName?: string): DoiObjectRef
	{
		return new DoiObjectRef(this.objectType, this.objectID, subviewName);
	}
}

export abstract class DoiObject
{
	objectID: number;
	objectUUID: string;
	objectPermissions: number = DoiObjectPermission.read;
	objectDelete: boolean;

	hasParts = new Set<string>();
	partProbes = new Map<string, boolean>();

	constructor(objectID: number)
	{
		this.objectID = objectID;
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return 'file';
	}

	/**
	 * Test if the specified part has been read from the server.
	 */
	hasPart(partName: string)
	{
		return this.hasParts.has(partName);
	}

	/**
	 * Test if this object represents a new object that hasn't yet been saved.
	 * The default implementation returns true if there is no object ID.
	 */
	isNew(): boolean
	{
		return this.objectID ? false : true;
	}

	/**
	 * Return a local object path as "/objectTypeLC/objectID".
	 */
	objectPath(): string
	{
		return '/'+this.objectType.toLowerCase()+'/'+this.objectID;
	}

	/**
	 * Return an object reference path from the root, corresponding to the navigation structure, or null.
	 * The default implementation return null.
	 */
	objectRefPath(): DoiObjectRef[]
	{
		return null;
	}

	/**
	 * Return an object reference for this object.
	 */
	objectRef(subviewName?: string): DoiObjectRef
	{
		return DoiObjectRef.forObject(this.objectType, this.objectID, subviewName);
	}

	/**
	 * Return a text suitable for additional text in search results etc. Override to return a short description, notes, etc.
	 * The default implementation return null.
	 */
	objectText(): string
	{
		return null;
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 * The default implementation return null.
	 */
	objectTitle(): string
	{
		return null;
	}

	/**
	 * The symbolic object type name, e g 'Customer'.
	 */
	abstract get objectType(): string;

	/**
	 * Invoked by an object view after the object has been refreshed. The default implementation does nothing.
	 */
	objectRefreshed(): void
	{
	}

	/**
	 * Populate an object from a data object received from the server. The default implementation handles object permissions.
	 * @param data The data object received from the server.
	 * @param partName The requested part name, e g General, or null for selection rows.
	 */
	parseData(data: any, partName: string): void
	{
		this.hasParts.add(partName);

		if (data.ObjectPermissions !== undefined)
			this.objectPermissions = data.ObjectPermissions;
	}

	/**
	 * Build a data object, with all loaded object parts, for writing.
	 */
	buildDataParts(): any
	{
		let dataParts: {[attr: string]: any} = {};

		for (let partName of Array.from(this.hasParts.values())) {
			let part = this.buildDataPart(partName);
			if (part) {
				dataParts[partName] = part;
			}
		}
		return dataParts;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(partName: string): any
	{
		return null;
	}

	/**
	 * Test if the specified object permission is available.
	 */
	permit(permissions: number)
	{
		return (this.objectPermissions & permissions) == permissions;
	}

	/**
	 * Test if the "read" object permission is available.
	 */
	permitRead()
	{
		return (this.objectPermissions & DoiObjectPermission.read) == DoiObjectPermission.read;
	}

	/**
	 * Test if the "write" object permission is available.
	 */
	permitWrite()
	{
		return (this.objectPermissions & DoiObjectPermission.write) == DoiObjectPermission.write;
	}

	/**
	 * Test if the "delete" object permission is available.
	 */
	permitDelete()
	{
		return (this.objectPermissions & DoiObjectPermission.delete) == DoiObjectPermission.delete;
	}

	/**
	 * Construct a router link as [ "/objectTypeLC", objectID ].
	 */
	routerLink(subviewName?: string)
	{
		let p0 = '/'+this.objectType.toLowerCase();
		return subviewName ? [p0, this.objectID, subviewName] : [p0, this.objectID];
	}

}
