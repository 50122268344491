import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {switchMap, tap} from "rxjs/operators";

import {DoiBrokerService, DoiService} from '../../doi/DoiModule';
import {DoiSearchBrokerService} from '../../doi-search/DoiSearchModule';
import {DoiObjectRef, DoiSelectionCriteria} from '../../doi/DoiModule';

import {ArchiveOrigService, ArchiveOrigObject} from '../../archiveorig/ArchiveOrigModule';
import {ProcessStructureService, ProcessStructureObject} from '../../processstructure/ProcessStructureModule';

import {KlaraBrokerService, RoleName} from '../../klara/KlaraModule';
import {ProcessPart, ProcessObject} from './ProcessObject';

/**
 * Manages Process objects.
 */
@Injectable()
export class ProcessService extends KlaraBrokerService<ProcessObject>
{
	/**
	 * Indicates if process codes are included in object titles. Initialied to true.
	 */
	showProcessCodes = true;

	/**
	 * Indicates if a negative theme is used. Affects the model image part.
	 */
	themeNegative = false;

	/**
	 * Construct a new Process service.
	 */
	constructor(doi: DoiService, private archiveOrigService: ArchiveOrigService, private processStructureService: ProcessStructureService)
	{
		super(doi, 'Process');
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return ProcessObject.iconName;
	}

	/**
	 * Create a new Process object.
	 */
	newObject(objectID: number): ProcessObject
	{
		return new ProcessObject(objectID, this);
	}

	/**
	 * Create and return an observable that fetches the parent structure.
	 * This implementation returns either an observable of the parent structure of the ProcessStructure,
	 * if the Process has no parent process,
	 * or an observable that fetches the parent Process and switches to its ProcessService.parentObjectObservable.
	 */
	parentObjectObservable(process: ProcessObject): Observable<any>
	{
		if (!process.parentProcessID)
			return this.processStructureService.readObjectPart(process.procStructID, ProcessPart.Label, process.processStructure).pipe(
				switchMap((procStruct: ProcessStructureObject) => {
					process.processStructure = procStruct;
					return this.processStructureService.parentObjectObservable(procStruct);
				})
			);

		return this.readObjectPart(process.parentProcessID, ProcessPart.Label, process.parent).pipe(
			switchMap((parent: ProcessObject) => {
				process.parent = parent;
				return this.parentObjectObservable(parent).pipe(
					tap((p: any) => {
						process.processStructure = parent.processStructure;
					})
				);
			})
		);
	}

	/**
	 * Test if the user is allowed to create new objects. The default implementation returns false. Overridden to
	 * check role memberships.
	 */
	permitNew(): boolean
	{
		if (!super.permitNew())
			return false;
		//	TODO: Check role.
		//	return this.doi.auth.hasAnyRole(RoleName.Admin, RoleName.StructAdm);
	}

	/**
	 * Read an object part.
	 */
	readObjectPart(id: number, partName: string, object?: ProcessObject): Observable<ProcessObject>
	{
		let params = new HttpParams();

		switch (partName) {
			case ProcessPart.Label:
			case ProcessPart.Description:
			case ProcessPart.ActKind:
			case ProcessPart.General:
				params = params.append('related', 'ProcessStructure').append('related', 'ProcessType').append('related', 'Parent');
				break;
			case ProcessPart.Activities:
				params = params.append('related', 'ActTypes').append('related', 'LinkedProcess');
				break;
			case ProcessPart.Series:
				params = params.append('related', 'Archive');
				break;
			case ProcessPart.ModelImage:
				params = params.append('related', 'ModelImagePNG').append('related', 'ModelImageMap');
				if (this.themeNegative)
					params = params.append('related', 'ModelImageNegative');
				break;
			case ProcessPart.Units:
				params = params.append('related', 'PropertyValues');
				break;
			case ProcessPart.UnitsNav:
				params = params.append('related', 'PropertyValues');
				break;

		}

		return super.readObjectPart(id, partName, object, { params: params });
	}

	/**
	 * Read property values for edit.
	 */
	readPropertyValuesForEdit(id: number, object?: ProcessObject): Observable<ProcessObject>
	{
		let options = {
			params: new HttpParams().append('related', 'PropertyType')
		};

		return super.readObjectPart(id, ProcessPart.PropertyValues, object, options);
	}

	/**
	 * Read search result entries.
	 */
	readSearchResultEntries(objectIDs?: number[]): Observable<ProcessObject[]>
	{
		let params = new HttpParams();

		params = params
			.append('col', 'Process_ProcessID')
			.append('col', 'Process_Code')
			.append('col', 'Process_NameList')
			.append('col', 'Process_DescriptionShort')
			.append('col', 'Process_ProcessTypeID')
			.append('col', 'ProcessType_Name');

		for (let objectID of objectIDs) {
			params = params.append('id', objectID.toString());
		}

		return this.readObjectSelection(params, null);
	}

	/**
	 * Check if an attribute should be included in changed data even if unchanged, provided some other attribute in the same data object
	 * is changed. Normally used to force inclusion of key attributes.
	 *
	 */
	changesForceAttribute(attr : string)
	{
		switch (attr) {
			case 'Activity_ActivityID':
				return true;
		}
		return super.changesForceAttribute(attr);
	}
}

// gT4l6SzLySH8uLhFC3rcgNUc5uc=
