import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {map, switchMap, tap} from "rxjs/operators";

import {DoiService, DoiBrokerService} from '../../doi/DoiModule';

import {ArchiveService, ArchivePart, ArchiveObject} from '../../archive/ArchiveModule';
import {SeriesGroupPart, SeriesGroupObject} from './SeriesGroupObject';

import {AppInfo, DocStoreLink, KlaraBrokerService} from '../../klara/KlaraModule';

/**
 * Manages synthetic SeriesGroup objects.
 */
@Injectable()
export class SeriesGroupService extends KlaraBrokerService<SeriesGroupObject>
{
	/**
	 * Construct a new SeriesGroup service.
	 */
	constructor(doi: DoiService, private archiveService: ArchiveService)
	{
		super(doi, 'SeriesGroup')
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return SeriesGroupObject.iconName;
	}

	/**
	 * Create a new SeriesGroup object.
	 */
	newObject(objectID: number): SeriesGroupObject
	{
		return new SeriesGroupObject(objectID);
	}

	/**
	 * Create and return an observable that fetches the parent structure.
	 * This implementation returns an observable that fetches the Archive, connects it to the Series
	 * and then switches to an ArchiveService.parentObjectObservable.
	 */
	parentObjectObservable(seriesGroup: SeriesGroupObject): Observable<any>
	{
		return this.archiveService.readObjectPart(seriesGroup.archiveID, ArchivePart.Label, seriesGroup.archive).pipe(
			switchMap((archive: ArchiveObject) => {
				seriesGroup.archive = archive;
				return this.archiveService.parentObjectObservable(archive);
			})
		);
	}
}

// dLMFr0hucx2g7YNTwG+DFhOhYAs=
