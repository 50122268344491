import {Component, OnInit} from '@angular/core';

import {DoiAlertMessage, DoiAlertService} from '../service/DoiAlertService';
import {DoiLogLevel} from '../service/DoiLogService';

@Component({
	selector: 'doi-alert',
	template: `
		<div *ngIf="message" class="doi-alert" [ngClass]="classes()">
			<span class="doi-alert-message">{{message.text}}</span>
			<button type="button" class="btn-close doi-alert-close" aria-label="Close" (click)="onClose()"></button>
		</div>
	`
})
export class DoiAlertComponent implements OnInit
{
	message: DoiAlertMessage;

	constructor(private alertService: DoiAlertService)
	{
	}

	ngOnInit()
	{
		this.alertService.subject().subscribe(message => {this.message = message;});
	}

	classes(): string
	{
		if (this.message) {
			if (this.message.level >= DoiLogLevel.SEVERE)
				return 'alert alert-danger';
			else if (this.message.level >= DoiLogLevel.WARNING)
				return 'alert alert-warning';
			else if (this.message.level >= DoiLogLevel.INFO)
				return 'alert alert-success';
			else
				return 'alert alert-info';
		}
	}

	onClose()
	{
		this.alertService.clear(true);

		return false;
	}
}
