import {Component} from '@angular/core';

/**
 * A component for showing a title in a view.
 */
@Component({
	selector: 'doi-title',
	host: { 'class' : 'doi-title' },
	template: `
		<div>
			<h2><ng-content></ng-content></h2>
			<span><ng-content select="section"></ng-content></span>
		</div>
	`,
	styles: [ `
		div { display: flex; justify-content: space-between; align-items: center; margin-top: 0.5rem; width: 100%; }
		h2 { margin-top: 0 }
	` ]
})
export class DoiTitleComponent
{
}
