import {Observable} from 'rxjs';
import {map} from "rxjs/operators";

import {DoiIconMapper, DoiObjectRef, DoiSelectionCriteria} from '../doi/DoiModule';
import {DoiNavNode, DoiNavNodeChildSupplier} from '../doi-navigator/DoiNavigatorModule';

import {UnitService, UnitObject} from '../unit/UnitModule';

/**
 * A navigator node child supplier for the unit folder node containing top level units.
 */
export class UnitFolderNodeChildSupplier extends DoiNavNodeChildSupplier
{
	constructor(
		private unitService: UnitService,
		private unitNodeCS: DoiNavNodeChildSupplier)
	{
		super();
	}

	readChildren(node: DoiNavNode): Observable<DoiNavNode[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Unit_UnitID', 'Unit_UnitTypeID', 'Unit_Code', 'Unit_Title', 'Unit_NavText')
			.where('Unit_ParentUnitID', '=')
			.and('UnitType_NavigatorNode', '=true')
			.orderBy('Unit_Code', 'Unit_UnitID');

		return this.unitService.readObjectSelection(null, criteria).pipe(
			map((units: UnitObject[]) =>
			{
				let nodes = new Array<DoiNavNode>();
				for (let unit of units) {
					let objectRef = DoiObjectRef.forObject('Unit', unit.objectID);
					nodes.push(
						new DoiNavNode(unit.navText, '/unit/'+unit.objectID)
							.setObjectRef(objectRef)
							.setIconName(unit.iconName)
							.setIconMapper(node.getIconMapper())
							.setChildNodeSupplier(this.unitNodeCS)
					);
				}
				return nodes;
			})
		);
	}
}
