import {Component} from '@angular/core';

/**
 * A toolbar container.
 */
@Component({
	selector: '[doi-toolbar]',
	host: {
		'class' : 'doi-tools d-print-none'
	},
	template: `
		<ng-content></ng-content>
  `,
})
export class DoiToolbarComponent
{
}
