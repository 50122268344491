import {Component, HostBinding, EventEmitter, Input, Output} from '@angular/core';

import {DoiSearchResult} from '../model/DoiSearchResult';
import {DoiSearchResultCat, DoiSearchResultCatGroup} from '../model/DoiSearchResultCatGroup';

/**
 * A search result category component with a checkbox for inclusion or exclusion and a count that is displayed when larger than XS.
 */
@Component({
	selector: 'doi-search-cat',
	host: { 'class' : 'doi-search-cat' },
	template: `
		<div *ngIf="cat.countDisplayed">
			<doi-checkbox [(ngModel)]="cat.selected" [implied]="cat.implied" [edit]="true" (change)="onChange(cat)" [tooltip]="cat.text">{{cat.text}}</doi-checkbox>
			<div class="doi-search-cat-count" [ngClass]="countHiddenXS ? 'doi-hidden-xs-down' : null">
				<span>{{searching ? '&nbsp;&nbsp;' : cat.countLimited ? '-' : cat.countDisplayed}}</span>
			</div>
		</div>
	`
})
export class DoiSearchCatComponent
{
	@Input()
	cat: DoiSearchResultCat;

	@Input()
	countTooltip: string;

	@Input()
	countTooltipFormat: string;

	@Input()
	countTooltipLimited: string;

	@Input()
	countHiddenXS = true;

	/**
	 * Indicates if a search is in progress.
	 */
	@Input()
	@HostBinding('class.doi-searching')
	searching: boolean = false;

	@Output() change = new EventEmitter<DoiSearchResultCat>();

	get countTitle(): string
	{
		if (this.countTooltip)
			return this.countTooltip;
		else if (this.cat.countLimited && this.countTooltipLimited)
			return this.countTooltipLimited;
		else
			return this.countTooltipFormat
				.replace('{0}', this.cat.countIncluded.toString())
				.replace('{1}', this.cat.countTotal.toString());
	}

	onChange(cat: DoiSearchResultCat)
	{
		this.change.emit(cat);
	}
}

/**
 * A search result category group component.
 */
@Component({
	selector: 'doi-search-cat-group',
	host: { 'class' : 'doi-search-cat-group' },
	template: `
		<div *ngIf="catGroup.text && catGroup.countDisplayed()">
			<h4>{{catGroup.text}}</h4>
			<doi-search-cat *ngFor="let cat of cats()" [cat]="cat" (change)="onChange()"
				[countTooltip]="countTooltip"
				[countTooltipFormat]="countTooltipFormat"
				[countTooltipLimited]="countTooltipLimited"
				[countHiddenXS]="countHiddenXS"
				[searching]="searching">
			</doi-search-cat>
			<div *ngIf="catsLimited()" class="doi-search-cat-group-ec">
				<a href="" [title]="titleCollapseExpand()" (click)="onCollapseExpand()" role="button"><i [ngClass]="iconClassCollapseExpand()"></i>{{textCollapseExpand()}}</a>
			</div>
		</div>
	` // I18N
})
export class DoiSearchCatGroupComponent
{
	@Input() catGroup: DoiSearchResultCatGroup;

	@Input()
	countTooltip: string;

	@Input()
	countTooltipFormat: string;

	@Input()
	countTooltipLimited: string;

	@Input()
	countHiddenXS = true;

	/**
	 * Indicates if a search is in progress.
	 */
	@Input()
	@HostBinding('class.doi-searching')
	searching: boolean = false;

	@Output() change = new EventEmitter<DoiSearchResultCat>();

	expanded: boolean = false;

	private _cats: DoiSearchResultCat[];

	catsLimited(): boolean
	{
		let count = 0;
		for (let cat of this.catGroup.cats) {
			if (cat.countDisplayed)
				++count;
		}

		return count > this.catGroup.limitDisplay+1;
	}

	cats(): DoiSearchResultCat[]
	{
		if (!this.catsLimited())
			return this.catGroup.cats;

		if (!this._cats) {
			if (this.expanded)
				this._cats = this.catGroup.cats;
			else
				this._cats = this.catGroup.cats.slice(0, this.catGroup.limitDisplay);
		}

		return this._cats;
	}

	show(): boolean
	{
		if (!this.catGroup)
			return false;

		if (this.catGroup.suppressSingle && this.catGroup.cats && this.catGroup.cats.length == 1)
			return false;

		return this.catGroup.countDisplayed() > 0;
	}

	onChange()
	{
		this.change.emit();
	}

	onCollapseExpand()
	{
		if (this.expanded) {
			this.expanded = false;
			this._cats = undefined;
		} else {
			this.expanded = true;
			this._cats = undefined;
		}
	}

	iconClassCollapseExpand(): string
	{
		return this.expanded ? 'far fa-minus-circle': 'far fa-plus-circle';
	}

	titleCollapseExpand(): string
	{
		return  this.expanded ? 'Visa bara '+this.catGroup.limitDisplay : 'Visa alla kategorier'; // I18N
	}

	textCollapseExpand(): string
	{
		return this.expanded ? 'visa färre': 'visa fler'; // I18N
	}
}

/**
 * A search result categories component containg group components.
 */
@Component({
	selector: 'doi-search-categories',
	host: { 'class' : 'doi-search-categories' },
	template: `
		<doi-search-cat-group *ngFor="let catGroup of result?.catGroups"
			[catGroup]="catGroup"
			(change)="onChange()"
			[countTooltip]="countTooltip"
			[countTooltipFormat]="countTooltipFormat"
			[countTooltipLimited]="countTooltipLimited"
			[countHiddenXS]="countHiddenXS"
			[searching]="searching">
		</doi-search-cat-group>
	`
})
export class DoiSearchCategoriesComponent
{
	/**
	 * The search result.
	 */
	@Input() result: DoiSearchResult;

	@Input()
	countTooltip: string;

	@Input()
	countTooltipFormat: string;

	@Input()
	countTooltipLimited: string;

	@Input()
	countHiddenXS = true;

	/**
	 * Indicates if a search is in progress.
	 */
	@Input()
	@HostBinding('class.doi-searching')
	searching: boolean = false;

	@Output() change = new EventEmitter<DoiSearchResultCat>();

	onChange()
	{
		this.change.emit();
	}
}
