<div class="doi-view doi-object-view unit-object-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start">
			<li doi-breadcrumb [link]="routerLinkUnits()" text="Förvaring"></li>
			<li *ngIf="routerLinkParentParentUnit()" doi-breadcrumb [text]="object?.parent?.parent?.objectTitle()" [link]="routerLinkParentParentUnit()"></li>
			<li *ngIf="routerLinkParentUnit()" doi-breadcrumb [text]="object?.parent?.objectTitle()" [link]="routerLinkParentUnit()"></li>
		</ol>
		<ul doi-toolbar>
			<doi-object-edit-tools [objectView]="this"></doi-object-edit-tools>
		</ul>
		<doi-title><span>{{object?.unitTypeName}} {{object?.code}} - {{object?.title}}</span></doi-title>
	</doi-view-title>

	<ul doi-view-tabs [iconMapper]="this">
		<li doi-view-tab name="general" [iconName]="probedIconName('general')" #focusFirst>Allmänt</li>
		<li doi-view-tab name="processesandacttypes" [iconName]="probedIconName('processesandacttypes')">Förvarar</li>
		<li doi-view-tab name="children"[iconName]="probedIconName('children')">Ingående</li>
	</ul>

	<router-outlet (activate)="tabActivate($event)" (deactivate)="tabDeactivate($event)"></router-outlet>

</div>

<!-- WZNX594kWxZxZA+uA/QKGMs3qDc= -->
