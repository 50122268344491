<div class="doi-subview">

	<form #form="ngForm" class="h-100" role="form">
		<div *ngIf="object?.activities && object?.activities?.length > 0" class="h-100 doi-scroll-container">
			<table class="table table-striped table-hover">
				<thead>
					<tr>
						<th class="doi-table-row-header" *ngIf="editingAsStructAdm()">&nbsp;</th>
						<th class="doi-grow-2">Aktivitet</th>
						<th class="doi-grow-3">Beskrivning</th>
						<th class="doi-grow-3 doi-hidden-xs-down ps-3">Handlingstyper</th>
						<th class="doi-grow-3 doi-hidden-xs-down">Hänvisning till</th>
				</thead>
				<tbody>
					<tr *ngFor="let activity of object?.activities; let ai = index">
						<td *ngIf="editingAsStructAdm()" class="doi-table-row-header">
							<a href="" (click)="deleteActivity(activity)" name="objectDelete{{ai}}" *ngIf="editingAsStructAdm()">
								<i [ngClass]="activityDeleteClass(activity)"></i>
							</a>
						</td>
						<td class="doi-grow-2 doi-wrap">
							<doi-textarea name="nameML{{ai}}" maxLength="100" label="" [autosize]="true" [required]="true" [(ngModel)]="activity.nameML" [edit]="editingAsEditor()" [ngClass]="{delete:activity.objectDelete}" [readonly]="activity.objectDelete"></doi-textarea>
						</td>
						<td class="doi-grow-3 doi-line-breaks pb-3">
							<doi-textarea name="notes{{ai}}" label="" [autosize]="true" [(ngModel)]="activity.notes" [edit]="editingAsEditor()" [ngClass]="{delete:activity.objectDelete}" [readonly]="activity.objectDelete"></doi-textarea>
						</td>
						<td class="doi-grow-3 doi-wrap doi-hidden-xs-down ps-3 align-items-start justify-content-between"><!-- Alt:  flex-column -->
							<ul class="cell-list" *ngIf="!editingAsEditor()">
								<li *ngFor="let actType of activity.actTypes"><a [routerLink]="routerLinkActType(actType)">{{actType.nameList}}</a></li>
							</ul>
							<ul class="cell-list" *ngIf="editingAsEditor()">
								<li *ngFor="let actType of activity.actTypes">{{actType.nameList}}</li>
							</ul>
							<ul doi-toolbar *ngIf="editingAsEditor()">
								<li doi-tool [target]="this" action="EditActTypes" [arg]="activity" [compact]="true"></li>
							</ul>
						</td>
						<td class="doi-grow-3 doi-wrap doi-hidden-xs-down">
							<a [routerLink]="routerLinkLinkedProcess(activity)" *ngIf="activity.linkedProcessID">{{activity.linkedProcessCode}} - {{activity.linkedProcessName}}</a>
						</td>
					</tr>
					<tr class="doi-table-tools-row" *ngIf="editingAsStructAdm()">
						<td class="doi-table-row-header">
							<ul doi-toolbar>
								<li doi-tool [target]="this" action="AddActivity" [compact]="true"></li>
							</ul>
						</td>
						<td colspan="4">
							&nbsp;
						</td>
					</tr>
				</tbody>
			</table>

		</div>
	</form>
</div>