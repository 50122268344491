import { DoiActionSet } from './DoiActionSet';
import { DoiActionTarget } from './DoiActionTarget';
import { DoiIconMapper } from './DoiIconMapper';
import { DoiKeyMapping } from './DoiKeyMapping';

/**
 * An action.
 */
export class DoiAction
{
	target: DoiActionTarget;
	name: string;

	private _icon: string;
	private _title: string;
	private _tooltip: string;
	private _mobile: boolean;

	/**
	 * Function that checks if enabled.
	 */
	private _enabledHandler: () => boolean;

	/**
	 * Function that checks if selected.
	 */
	private _selectedHandler: () => boolean;

	/**
	 * Function that returns the title.
	 */
	private _titleHandler: () => string;

	/**
	 * Function that checks if working.
	 */
	private _workingHandler: () => boolean;

	/**
	 * Function that returns the icon name.
	 */
	private _iconHandler: () => string;

	/**
	 * Function that is invoked when executed.
	 */
	private _executeHandler: (action?: DoiAction) => any;

	/**
	 * Function that returns a link.
	 */
	private _linkHandler: (action?: DoiAction) => string;

	/**
	 * Key mappings.
	 */
	private _keyMappings = new Map<string, DoiKeyMapping[]>();

	/**
	 * Construct a new action.
	 */
	constructor(target: DoiActionTarget, name: string, icon?: string, title?: string, tooltip?: string)
	{
		this.target = target;
		this.name = name;
		this._icon = icon;
		this._title = title;
		if (tooltip)
			this._tooltip = tooltip;
	}

	/**
	 * The enabled state.
	 */
	get enabled(): boolean
	{
		if (this._enabledHandler)
			return this._enabledHandler();
		else
			return true;
	}

	/**
	 * Set the function that checks if enabled.
	 */
	enabledHandler(enabledHandler: () => boolean): DoiAction
	{
		this._enabledHandler = enabledHandler;
		return this;
	}

	/**
	 * The icon name.
	 */
	get icon(): string
	{
		if (this._iconHandler)
			return this._iconHandler();
		else
			return this._icon;
	}

	set icon(icon: string)
	{
		this._icon = icon;
	}

	/**
	 * Set the function that returns the icon name.
	 */
	iconHandler(iconHandler: () => string): DoiAction
	{
		this._iconHandler = iconHandler;
		return this;
	}

	/**
	 * Indicates if the action should be available on mobile devices. Initially true.
	 */
	get mobile(): boolean
	{
		return this._mobile;
	}

	/**
	 * Specify if the action should be available on mobile devices. Initially true.
	 */
	mobileAvailable(available: boolean): DoiAction
	{
		this._mobile = available;
		return this;
	}

	/**
	 * The selected state.
	 */
	get selected(): boolean
	{
		if (this._selectedHandler)
			return this._selectedHandler();
		else
			return false;
	}

	/**
	 * Set the function that checks if selected.
	 */
	selectedHandler(selectedHandler: () => boolean): DoiAction
	{
		this._selectedHandler = selectedHandler;
		return this;
	}

	/**
	 * The working state.
	 */
	get working(): boolean
	{
		if (this._workingHandler)
			return this._workingHandler();
		else
			return false;
	}

	/**
	 * The action title.
	 */
	get title(): string
	{
		if (this._titleHandler)
			return this._titleHandler();
		else
			return this._title;
	}

	set title(title: string)
	{
		this._title = title;
	}

	/**
	 * Set the function that returns the action title.
	 */
	titleHandler(titleHandler: () => string): DoiAction
	{
		this._titleHandler = titleHandler;
		return this;
	}

	/**
	 * The action tooltip.
	 */
	get tooltip(): string
	{
		if (this._tooltip)
			return this._tooltip;
		else
			return this.title;
	}

	set tooltip(tooltip: string)
	{
		this._tooltip = tooltip;
	}

	/**
	 * Set the function that checks if working.
	 */
	workingHandler(workingHandler: () => boolean): DoiAction
	{
		this._workingHandler = workingHandler;
		return this;
	}

	/**
	 * Set the function that executes the action.
	 */
	executeHandler(executeHandler: (arg?: any) => any): DoiAction
	{
		this._executeHandler = executeHandler;
		return this;
	}

	/**
	 * Test if the action has any key mapping.
	 */
	hasKeyMapping(): boolean
	{
		return this._keyMappings.size > 0;
	}

	/**
	 * Add a key mapping.
	 * @param keyCode Key code. Examples: "KeyS", "Escape".
	 * @param modifiers Modifiers, "A" for Alt, "C" for "Ctrl", "S" for "Shift". Example: "CA" for Ctrl+Alt.
	 */
	keyMapping(keyCode : string, modifiers: string = null): DoiAction
	{
		let keyMapping = new DoiKeyMapping(keyCode, modifiers);
		let keyMappings = this._keyMappings.get(keyCode);
		if (!keyMappings) {
			keyMappings = new Array<DoiKeyMapping>();
			this._keyMappings.set(keyCode, keyMappings);
		}
		keyMappings.push(keyMapping);
		return this;
	}

	/**
	 * Add a key mapping with both control and meta. On a Mac, the Meta is the preferred mapping used for tooltips.
	 * @param keyCode Key code. Examples: "KeyS", "Escape".
	 */
	keyMappingCtrl(keyCode : string): DoiAction
	{
		if (navigator.platform.startsWith('Mac')) {
			this.keyMapping(keyCode, "M");
			this.keyMapping(keyCode, "C");
		} else {
			this.keyMapping(keyCode, "C");
			this.keyMapping(keyCode, "M");
		}
		return this;
	}

	/**
	 * Return the mappings.
	 */
	keyMappings(): DoiKeyMapping[]
	{
		let mappings = new Array<DoiKeyMapping>();
		for (let [keyCode, ma] of this._keyMappings) {
			mappings.push(...ma);
		}
		return mappings;
	}

	/**
	 * Test if any key mapping matches the specified event.
	 */
	keyMappingMatchesEvent(event: KeyboardEvent): boolean
	{
		let mappings = this._keyMappings.get(event.code);
		if (mappings) {
			for (let mapping of mappings) {
				if (mapping.matchesEvent(event))
					return true;
			}
		}
		return false;
	}

	/**
	 * Return the key mapping text for the first key mapping.
	 */
	keyMappingText(): string
	{
		for (let [keyCode, ma] of this._keyMappings) {
			for (let mapping of ma) {
				return mapping.text();
			}
		}
		return null;
	}

	/**
	 * Set the function that returns a link, if used.
	 */
	linkHandler(linkHandler: (action?: DoiAction) => any): DoiAction
	{
		this._linkHandler = linkHandler;
		return this;
	}

	/**
	 * Add this action to the specified set.
	 */
	addTo(actionSet: DoiActionSet): DoiAction
	{
		actionSet.add(this);
		return this;
	}

	/**
	 * Execute this action.
	 */
	execute(arg?: any): any
	{
		if (this._executeHandler)
			return this._executeHandler(arg);
		else
			return undefined;
	}

	/**
	 * Return the link, if used.
	 */
	link(): string
	{
		if (this._linkHandler)
			return this._linkHandler(this);
		else
			return undefined;
	}
}
