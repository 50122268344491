import {DoiObjectRef, DoiTreeNode} from '../../doi/DoiModule';

/**
 * A navigators node.
 */
export class DoiNavNode extends DoiTreeNode<DoiNavNode, DoiObjectRef>
{
	/**
	 * The target URL.
	 */
	url: string;

	/**
	 * Construct a new node.
	 */
	constructor(label?: string, url?: string)
	{
		super(label);
		this.url = url;
		this.parent = null;
	}

	/**
	 * Test if this node has the same label, url and object reference as the specified node.
	 */
	equals(node: DoiNavNode): boolean
	{
		if (this.url != node.url)
			return false;
		return super.equals(node);
	}

	/**
	 * Test if the specified object references has the same type and ID.
	 */
	equalsPathElement(element1: DoiObjectRef, element2: DoiObjectRef): boolean
	{
		return DoiObjectRef.equal(element1, element2);
	}

	/**
	 * Find a node by the URL, recursively.
	 */
	findByUrl(url: string): DoiNavNode
	{
		if (!url)
			return null;

		if (this.url) {
			if (this.url == url)
				return this;
			let p1 = this.url.indexOf('(');
			let p2 = url.indexOf('(');
			if (p1 != -1 || p2 != -1) {
				let u1 = (p1 != -1) ? this.url.substring(0, p1) : this.url;
				let u2 = (p2 != -1) ? url.substring(0, p2): url;
				if (u1 == u2)
					return this;
			}
		}

		if (this.children) {
			for (let child of this.children) {
				let node = child.findByUrl(url);
				if (node)
					return node;
			}
		}

		return null;
	}

	/**
	 * Find an immediate child by the URL.
	 */
	findChildByUrl(url: string): DoiNavNode
	{
		if (this.children) {
			for (let child of this.children) {
				if (child.url == url)
					return child;
			}
		}

		return null;
	}

	/**
	 * Return the node class. If a class has been explicitly set, it is returned. Otherwise, if the node has an
	 * object reference,  'doi-nav-node-Xxx' is returned, where Xxx is the object type.
	 */
	nodeClass(): string
	{
		let nc = super.nodeClass();
		if (nc)
			return nc;
		let pe = this.getPathElement();
		if (pe)
			return 'doi-nav-node-'+pe.objectType+(pe.subviewName ? '-'+pe.subviewName : '');
		return undefined;
	}

	/**
	 * Associate this node with an object and optionally an URL.
	 */
	setObjectRef(objectRef: DoiObjectRef, url?:string): DoiNavNode
	{
		this.setPathElement(objectRef);
		if (url !== undefined)
			this.url = url;
		return this;
	}
}
