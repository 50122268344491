import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

import {DoiModule} from '../doi/DoiModule';

import {DoiThemeService} from './model/DoiThemeService';

export * from './model/DoiTheme';
export * from './model/DoiThemeService';

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule,
		DoiModule
	],
	declarations: [
	],
	exports: [
	],
	providers: [
		DoiThemeService
	],

})
export class DoiThemeModule
{
}
