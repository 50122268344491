import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {DoiModule, DoiObjectEditGuard, DoiRedirectGuard} from '../doi/DoiModule';
import {KlaraModule} from '../klara/KlaraModule';
import {ProcessStructureService} from './model/ProcessStructureService';
import {ProcessStructureObjectView} from './view/ProcessStructureObjectView';
import {ProcessStructureObjectGeneralPanel} from './view/ProcessStructureObjectGeneralPanel';
import {ProcessStructureObjectProcessesPanel} from './view/ProcessStructureObjectProcessesPanel';

export * from './model/ProcessStructureObject';
export * from './model/ProcessStructureSelectable';
export * from './model/ProcessStructureService';

export const processStructureRoutes: Routes = [
	{path: 'processstructure/:id', component: ProcessStructureObjectView, canActivate: [DoiRedirectGuard], canDeactivate: [DoiObjectEditGuard], data: { defaultChild: 'general' }, children: [
		{path: 'general', component: ProcessStructureObjectGeneralPanel, data: {title: 'Allmänt'}},
		{path: 'processes', component: ProcessStructureObjectProcessesPanel, data: {title: 'Strukturenheter'}},

	]},
];

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(processStructureRoutes),
		DoiModule,KlaraModule
	],
	declarations: [
		ProcessStructureObjectView, ProcessStructureObjectGeneralPanel,ProcessStructureObjectProcessesPanel,
	],
	exports: [
	],
	providers: [
		ProcessStructureService,
	],

})
export class ProcessStructureModule
{
}

// axVvASTYhsEcVNKDuJdImWgu5Wc=
