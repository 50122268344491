import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiAuthSession, DoiService, DoiTopView} from '../doi/DoiModule';

import {KlaraDialogTopView, KlaraTopView} from '../klara/KlaraModule';

@Component({
	templateUrl: 'AccountView.html'
})

export class AccountView extends KlaraTopView implements OnInit, KlaraDialogTopView
{
	returnUrl: string;

	constructor(doi: DoiService, route: ActivatedRoute,	private router: Router)
	{
		super(doi, route);

		this.formGroup = new FormGroup({});

		this.formGroup.addControl('userName', new FormControl());
		this.formGroup.addControl('userIdentityName', new FormControl());
		this.formGroup.addControl('userLoginName', new FormControl());
		this.formGroup.addControl('name', new FormControl());
		this.formGroup.addControl('roles', new FormControl());
		this.formGroup.addControl('expires', new FormControl());
	}

	ngOnInit()
	{
		this.refresh();
	}

	/**
	 * Return the corresponding path in Klara Dialog, or an empty string for the same URL.
	 */
	dialogPath() : string
	{
		return '';
	}

	showIdentityAndLoginNames(): boolean
	{
		let userName = this.formGroup.get('userName').value;
		let userIdentityName = this.formGroup.get('userIdentityName').value;
		let userLoginName = this.formGroup.get('userLoginName').value;

		return userIdentityName != null && userIdentityName != userName || userLoginName != null && userLoginName != userName;
	}

	/**
	 * Test if this action target is displayed on a mobile device.
	 */
	mobileDevice(): boolean
	{
		return this.appView.mobileDevice();
	}

	navigatorPlatform(): string
	{
		return navigator.platform;
	}

	navigatorUserAgent(): string
	{
		return navigator.userAgent;
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * The default implementation returns an empty observable.
	 */
	refreshView(): Observable<any>
	{
		return of(true).pipe(
			tap(() => {
				let session = this.session();
				if (session) {
					this.formGroup.get('userName').setValue(this.session().userName);
					this.formGroup.get('userIdentityName').setValue(this.session().userIdentityName);
					this.formGroup.get('userLoginName').setValue(this.session().userLoginName);
					this.formGroup.get('name').setValue(this.session().name);
					this.formGroup.get('roles').setValue(this.session().roles.join(', '));
					this.formGroup.get('expires').setValue(this.session().expires);
				} else {
					this.formGroup.get('userName').setValue(null);
					this.formGroup.get('userIdentityName').setValue(null);
					this.formGroup.get('userLoginName').setValue(null);
					this.formGroup.get('name').setValue(null);
					this.formGroup.get('roles').setValue(null);
					this.formGroup.get('expires').setValue(null);
				}
			})
		);
	}

	session(): DoiAuthSession
	{
		return this.doi.auth.session;
	}

	submit()
	{
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Mitt konto'; // I18N
	}
}
