import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";

import {DoiService} from './DoiService';

/**
 * An HTTP interceptor that attaches locale information to requests.
 */
@Injectable()
export class DoiLocaleInterceptor implements HttpInterceptor
{
	/**
	 * Construct a new HTTP interceptor.
	 */
	constructor(private doi: DoiService)
	{
	}

	/**
	 * Intercept a request.
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		if (this.doi.locale)
			request = request.clone({ setParams: { locale: this.doi.locale } });
		return next.handle(request);
	}
}
