import {Component, Input} from '@angular/core';

import {DoiIconMapper} from '../../doi/DoiModule';

import {DocStoreLink} from '../model/DocStoreLink';

/*
 * A document store links component.
 */
@Component({
	selector: 'docstore-links',
	template: `
		<p *ngFor="let docStoreLink of docStoreLinks">
			<docstore-link [docStoreLink]="docStoreLink" [iconMapper]="iconMapper"></docstore-link>
		</p>
	`,
	styles: [ 'p { margin-bottom: 0; }' ]
})
export class DocStoreLinksComponent
{
	/**
	 * The document store links.
	 */
	@Input()
	docStoreLinks: DocStoreLink[];

	/**
	 * Optional icon mapper.
	 */
	@Input()
	iconMapper: DoiIconMapper;
}
