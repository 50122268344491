import {Injectable} from '@angular/core';
import {CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, switchMap, tap} from "rxjs/operators";

import {DoiService} from '../service/DoiService';
import {DoiObjectView} from './DoiObjectView';
import {DoiObject} from '../service/DoiObject';
import {DoiModalType, DoiModalResult} from './DoiModalDialog';

/**
 * A navigation guard that disallows deactivation of an object view if the view is being edited.
 */
@Injectable()
export class DoiObjectEditGuard implements CanDeactivate<DoiObjectView<DoiObject>>
{
	constructor()
	{
	}

	canDeactivate(objectView: DoiObjectView<DoiObject>, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Observable<boolean> | boolean
	{
		return objectView.editCancelObservable(false);
	}
}


