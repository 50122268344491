<div class="doi-subview doi-scroll">

	<form role="form">
		<div *ngIf="image">
			<img [src]="image" usemap="#imagemap"/>
			<map name="imagemap" *ngIf="object.modelImageMap">
				<area *ngFor="let a of object.modelImageMap" [alt]="a.name" shape="rect" [coords]="a.coords" [routerLink]="routerLinkArea(a)"/>
			</map>
		</div>
	</form>
</div>