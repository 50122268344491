import {Component, Input} from '@angular/core';

import {DoiTopView} from '../view/DoiTopView'

/**
 * Tool for bookmarking an object.
 */
@Component({
	selector: 'doi-object-bookmark-tool',
	template: `
		<li doi-tool [target]="target" action="Bookmark" [compact]="compact"></li>
  `,
})
export class DoiObjectBookmarkToolComponent
{
	/**
	 * The target view.
	 */
	@Input() target: DoiTopView;

	/**
	 * Indicates if the tool is compact and suppresses any title.
	 */
	@Input() compact: boolean = true;

	/**
	 * Construct a new component.
	 */
	constructor()
	{
	}
}
