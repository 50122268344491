import {AfterContentInit, Component, ContentChildren, ElementRef, Input, Output, EventEmitter, QueryList, ViewChild, ViewChildren} from '@angular/core';

import {DoiActionTarget} from '../core/DoiActionTarget';
import {DoiFocusFirst} from '../core/DoiFocusFirst';
import {DoiView} from '../view/DoiView';

/**
 * A component for showing a single breadcrumb.
 * It can either specify the link attribute, to build a link element with the contents or specified text, or it can contain a link element.
 */
@Component({
	selector: '[doi-breadcrumb]',
	host: { 'class' : 'doi-breadcrumb' },
	template: `
		<a #breadcrumbAnchor *ngIf="text && routerLink" href="" [routerLink]="routerLinkValue()" (click)="onClick($event)">
			<span *ngIf="type" class="doi-hidden-xs-down">{{type}} </span>{{text}}
		</a>
		<span *ngIf="text && !routerLink">
			<span *ngIf="type" class="doi-hidden-xs-down">{{type}} </span>{{text}}
		</span>
		<ng-content select="a"></ng-content>
	`
})
export class DoiBreadcrumbComponent implements DoiFocusFirst
{
	/**
	 * Optional type text before link text, shown above XS. Used to clarify what the link is.
	 */
	@Input()
	type: string = null;

	/**
	 * Link text.
	 */
	@Input()
	text: string = null;

	/**
	 * The router link.
	 */
	@Input('link')
	routerLink: any[] = null;

	/**
	 * Click event emitter.
	 */
	@Output() click: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * The breadcrumb anchor element.
	 */
	@ViewChild('breadcrumbAnchor')
	breadcrumbAnchorRef: ElementRef<HTMLAnchorElement>;

	/**
	 * Focus the first inner component.
	 */
	focusFirst(): boolean
	{
		if (this.breadcrumbAnchorRef) {
			this.breadcrumbAnchorRef.nativeElement.focus();
			return true;
		}

		return false;
	}

	/**
	 * Invoked when the breadcrumb is clicked. An event to "click" is emitted.
	 */
	onClick(event: Event)
	{
		event.stopPropagation();

		if (!this.routerLink)
			this.click.emit(event);
	}

	/**
	 * Return the router link or an empty array. Angular doesn't like a null router link.
	 */
	routerLinkValue(): any[]
	{
		return this.routerLink || [];
	}
}

/**
 * A component for showing breadcrumbs.
 */
@Component({
	selector: '[doi-breadcrumbs]',
	host: { 'class' : 'doi-breadcrumbs breadcrumb' },
	template: `
		<li *ngIf="navActionTarget" doi-tool [target]="navActionTarget" action="Navigator" compact="true"></li>
		<li *ngIf="home" class="doi-breadcrumb doi-breadcrumbs-home d-print-none"><a #homeAnchor routerLink="/"><i *ngIf="homeIcon" [ngClass]="homeIconClass()"></i><span *ngIf="homeText">{{homeText}}</span></a></li>
		<ng-content></ng-content>
		<li *ngIf="tailImplied" doi-breadcrumb></li>
	`,
	styles: [ `
		.doi-breadcrumbs-home span { margin-left: 0.25rem; }
	` ]
})
export class DoiBreadcrumbsComponent implements DoiFocusFirst
{
	/**
	 * The parent view.
	 */
	@Input()
	view: DoiView;

	/**
	 * The navigator action target.
	 */
	@Input()
	navActionTarget = <DoiActionTarget>undefined; // Workaround for Angular CLI bug #2034

	/**
	 * Show a home tool.
	 */
	@Input() home: boolean = true;

	/**
	 * Show icon in home tool.
	 */
	@Input() homeIcon: boolean = true;

	/**
	 * Text for home tool.
	 */
	@Input() homeText: string;

	/**
	 * Add an additional empty entry representing an implied tail.
	 */
	@Input() tailImplied: boolean = true;

	/**
	 * The home anchor element.
	 */
	@ViewChild('homeAnchor')
	homeAnchorRef: ElementRef<HTMLAnchorElement>;

	/**
	 * The inner breadcrumbs.
	 */
	@ContentChildren(DoiBreadcrumbComponent)
	breadcrumbs: QueryList<DoiBreadcrumbComponent>;

	/**
	 * Return the home icon class.
	 */
	homeIconClass(): string
	{
		if (this.view)
			return this.view.iconClass('home');
		else
			return 'fad fa-home';
	}

	/**
	 * Focus the first inner component.
	 */
	focusFirst(): boolean
	{
		if (this.breadcrumbs) {
			let breadCrumb: DoiBreadcrumbComponent = null;
			this.breadcrumbs.forEach((bc) => {
				if (bc.breadcrumbAnchorRef)
					breadCrumb = bc;
			});
			if (breadCrumb && breadCrumb.focusFirst())
				return true;
		}

		if (this.homeAnchorRef) {
			this.homeAnchorRef.nativeElement.focus();
			return true;
		}

		return false;
	}
}
