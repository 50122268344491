<div class="doi-subview doi-scroll">

	<form role="form">
		<div class="row" *ngIf="object?.notes">
			<div class="col-12 col-xl-11">
				<doi-textarea name = "Beskrivning" labelEdit="Beskrivning" size="60" [(ngModel)]="object.notes" [edit]="editing()" [ngModelOptions]="{standalone: true}"></doi-textarea>
			</div>
		</div>
		<property-values [view]="this" [propertyValues]="object?.propertyValues"></property-values>
		<h3 *ngIf="docStoreLinks?.length">Dokumentlager</h3>
		<docstore-links [docStoreLinks]="docStoreLinks"></docstore-links>
		<h3 *ngIf="object.objectFiles?.length">Referenser</h3>
		<object-files [objectFiles]="object.objectFiles" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files>
		<h3 *ngIf="series?.length">Serier</h3>
		<div doi-object-grid [iconMapper]="this" [objects]="series" moreView="series"></div>
		<h3 *ngIf="seriesGroups?.length">Grupper</h3>
		<div doi-object-grid [iconMapper]="this" [objects]="seriesGroups"></div>

	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
