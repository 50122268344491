import {Injectable} from '@angular/core';

/**
 * Handle local and session storage with context prefix.
 */
@Injectable()
export class DoiStorageService
{
	/**
	 * The preferred storage for settings. Initially local storage, which is OK for applications that don't need "cookie consent".
	 */
	preferredStorage: Storage = localStorage;
	preferredLocalStorage: boolean = true;

	/**
	 * Context for local and session storage. Initialized to 'doi' but should be changed by the application, to e g 'doistudio'.
	 */
	context: string = 'doi';

	/**
	 * Variant suffix. Appended to the context for settings that are specific to a variant of the application. Initialized to ''.
	 */
	variant: string = '';

	/**
	 * Construct a new storage service.
	 */
	constructor()
	{
	}

	/**
	 * Get the specified item from the preferred storage, either local or session.
	 * The actual item name is constructed from the context, variant and the specifed item name.
	 */
	getItem(item: string, def?: any): any
	{
		return this.get(this.preferredStorage, item, def);
	}

	/**
	 * Set the specified item in the preferred storage, either local or session.
	 * The actual item name is constructed from the context, variant and the specifed item name.
	 */
	setItem(item: string, value: any): any
	{
		this.set(this.preferredStorage, item, value);
	}

	/**
	 * Remove the specified item from the preferred storage, either local or session.
	 * The actual item name is constructed from the context, variant and the specifed item name.
	 */
	removeItem(item: string): any
	{
		this.remove(this.preferredStorage, item);
	}

	/**
	 * Get the specified item from the preferred storage, either local or session.
	 * The actual item name is constructed from the context and the specifed item name.
	 * Used for settings that are common for all application variants, such as user login information.
	 */
	getContextItem(item: string, def?: any): any
	{
		return this.getStorageContextItem(this.preferredStorage, item, def);
	}

	/**
	 * Set the specified item in the preferred storage, either local or session.
	 * The actual item name is constructed from the context and the specifed item name.
	 * Used for settings that are common for all application variants, such as user login information.
	 */
	setContextItem(item: string, value: any): any
	{
		this.setStorageContextItem(this.preferredStorage, item, value);
	}

	/**
	 * Remove the specified item value from the preferred storage, either local or session.
	 * The actual item name is constructed from the context and the specifed item name.
	 * Used for settings that are common for all application variants, such as user login information.
	 */
	removeContextItem(item: string): any
	{
		this.removeStorageContextItem(this.preferredStorage, item);
	}

	/**
	 * Get the specified item from local or session storage.
	 * The actual item name is constructed from the context and the specifed item name.
	 * Used for settings that are common for all application variants, such as user login information.
	 */
	getStorageContextItem(storage: Storage, item: string, def?: any): any
	{
		let value = JSON.parse(storage.getItem(this.context+'.'+item));
		if (!value && def)
			return def;
		return value;
	}

	/**
	 * Set the specified item in local or session storage.
	 * The actual item name is constructed from the context and the specifed item name.
	 * Used for settings that are common for all application variants, such as user login information.
	 */
	setStorageContextItem(storage: Storage, item: string, value: any): any
	{
		if (value !== undefined)
			storage.setItem(this.context+'.'+item, JSON.stringify(value));
		else
			storage.removeItem(this.context+'.'+item);
	}

	/**
	 * Remove the specified item value from local or session storage.
	 * The actual item name is constructed from the context and the specifed item name.
	 * Used for settings that are common for all application variants, such as user login information.
	 */
	removeStorageContextItem(storage: Storage, item: string): any
	{
		storage.removeItem(this.context+'.'+item);
	}

	/**
	 * Get the specified item from local or session storage. The actual item name is constructed from the context, variant and the specifed item name.
	 */
	get(storage: Storage, item: string, def?: any): any
	{
		let value = JSON.parse(storage.getItem(this.context+this.variant+'.'+item));
		if (!value && def)
			return def;
		return value;
	}

	/**
	 * Set the specified item in local or session storage. The actual item name is constructed from the context, variant and the specifed item name.
	 */
	set(storage: Storage, item: string, value: any): any
	{
		if (value !== undefined)
			storage.setItem(this.context+this.variant+'.'+item, JSON.stringify(value));
		else
			storage.removeItem(this.context+this.variant+'.'+item);
	}

	/**
	 * Remove the specified item from local or session storage. The actual item name is constructed from the context, variant and the specifed item name.
	 */
	remove(storage: Storage, item: string): any
	{
		storage.removeItem(this.context+this.variant+'.'+item);
	}

	/**
	 * Remove all item values from local or session storage.
	 * The actual item names are constructed from the context.
	 */
	removeAll(storage: Storage): any
	{
		this.removeAllWithPrefix(storage, this.context+'.');
		if (this.variant.length)
			this.removeAllWithPrefix(storage, this.context+this.variant+'.');
	}

	/**
	 * Remove all item values from local or session storage whose item names start with the specified prefix.
	 */
	removeAllWithPrefix(storage: Storage, prefix: string): any
	{
		let index = 0;
		let remove = new Array<string>();

		for (;;) {
			let key = storage.key(index);
			if (key == null)
				break;
			if (key.startsWith(prefix)) {
				remove.push(key);
			}
			++index;
		}

		for (let item of remove) {
			storage.removeItem(item);
		}
	}

	/**
	 * Select the preferred storage for settings. Initially local storage, which is OK for applications that don't
	 * need "cookie consent".
	 */
	useLocalStorage(consent: boolean)
	{
		this.preferredLocalStorage = !!consent;
		this.preferredStorage = consent ? localStorage : sessionStorage;
	}


	/**
	 * Test if the user has consented to use local storage. Initially true, which is OK for applications that don't
	 * need "cookie consent".
	 */
	usesLocalStorage()
	{
		return this.preferredLocalStorage;
	}
}
