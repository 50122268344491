import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';

import {ArchivePart, ArchiveObject} from '../model/ArchiveObject';

@Component({
	templateUrl: 'ArchiveObjectHistoryPanel.html'
})
export class ArchiveObjectHistoryPanel extends DoiObjectPanel<ArchiveObject>
{
	name = 'history';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openArchive(archiveID : number)
	{
		this.appView.openObject('Archive', archiveID);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ArchivePart.General),
			this.refreshObjectPart(ArchivePart.History),
		);

	}
}

// FIekkHEDGNH9VTbO3U8zM7VmpcA=
