import {Component, Directive, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {Observer, Subscriber, Subscription} from 'rxjs';

import {DoiService, DoiTopView} from '../../doi/DoiModule';
import {DoiIdentityProvider} from '../model/DoiIdentityProvider';

import {DoiLoginService} from '../model/DoiLoginService';

@Directive()
export abstract class DoiLoginView extends DoiTopView implements OnInit
{
	submitting: boolean;
	returnUrl: string;

	login: DoiLoginService;
	providers: DoiIdentityProvider[];

	private timerSubscription: Subscription;

	/**
	 * Selected provider, or null for user name and passsword. Initially undefined.
	 */
	private provider: DoiIdentityProvider = undefined;

	constructor(doi: DoiService, doilogin: DoiLoginService, route: ActivatedRoute)
	{
		super(doi, route);

		this.login = doilogin;
		this.returnUrl = doi.lastUrl;
		this.formGroup = new FormGroup({});

		this.formGroup.addControl('userLoginName', new FormControl(null, [Validators.required]));
		this.formGroup.addControl('password', new FormControl(null, [Validators.required]));
		this.formGroup.addControl('keep', new FormControl(null));
	}

	/**
	 * Invoked when the view has been initialized.
	 */
	ngOnInit()
	{
		super.ngOnInit();

		this.fetchProviders();
	}

	/**
	 * Invoked when the view is being destroyed.
	 */
	ngOnDestroy()
	{
		this.disposeTimer();

		super.ngOnDestroy();
	}

	disposeTimer()
	{
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
			this.timerSubscription = null;
		}
	}

	/**
	 * Invoked when path parameters are received.
	 */
	paramsRecieved(pm: ParamMap): void
	{
		let returnUrl = pm.get('returnUrl');
		if (returnUrl)
			this.returnUrl = returnUrl;
	}

	/**
	 * Test if the form is submittable.
	 */
	canSubmit(): boolean
	{
		return !this.submitting && this.formGroup.valid;
	}

	/**
	 * Close the login page. Invoked when the users presses Cancel or when login has completed.
	 * Navigates to the return URL if it is available and doesn't refer to this page. Otherwise to the start page.
	 */
	close()
	{
		if (this.returnUrl && !this.returnUrl.startsWith('/login'))
			this.doi.router.navigateByUrl(this.returnUrl);
		else
			this.doi.router.navigateByUrl('/');
	}

	/**
	 * Fetch identity providers. The default implementation invokes '/login/auth/providers'.
	 */
	fetchProviders()
	{
		this.doi.http.get<DoiIdentityProvider[]>(this.doi.urlContext()+'/login/auth/providers')
		.subscribe((providers) => {
			this.providers = providers;
		});
	}

	/**
	 * Return the provider icon data URL.
	 */
	providerIcon(provider: DoiIdentityProvider)
	{
		return this.doi.sanitizer.bypassSecurityTrustResourceUrl('data:image;base64,'+provider.providerIcon);
	}

	/**
	 * Navigate to the page where a password reset can be requested.
	 */
	requestpwd()
	{
		let url = this.requestpwdURL();
		if (!url)
			return;

		let userLoginName = this.formGroup.value.userLoginName;
		if (userLoginName)
			this.doi.router.navigate([url, {user: userLoginName}]);
		else
			this.doi.router.navigate([url]);
	}

	/**
	 * Return the URL to to the page where a password reset can be requested, or null if not supported.
	 */
	requestpwdURL(): string
	{
		return null;
	}

	/**
	 * Create an observer that handles a login result, either null for success, an empty string if cancelled or an error message.
	 */
	loginResultObserver(): Observer<any>
	{
		return Subscriber.create(
			result => {
				this.submitting = false;
				if (result == null) {
					this.close();
					return;
				}
				if (result.length)
					this.doi.alert.severe(result);
			},
			(error:HttpErrorResponse) => {
				this.submitting = false;
				this.doi.showError(error);
			},
			() => {
				this.submitting = false;
			}
		);
	}

	/**
	 * Log in with form credentials, i e user name and password.
	 */
	loginWithForm()
	{
		this.submitting = false;

		if (!this.formGroup.valid) {
			for (let cn in this.formGroup.controls) {
				let control = this.formGroup.get(cn);
				let errors = control.errors;
				if (errors && errors['required']) {
					this.doi.alert.severe('Ett obligatoriskt värde saknas.');
					return;
				}
			}
			return;
		}

		let userLoginName = this.formGroup.value.userLoginName;
		let password = this.formGroup.value.password;

		let expires: Date = null;
		if (this.formGroup.value.keep)
			expires = this.doi.auth.expires();

		this.login.loginWithCredentials(userLoginName, password, expires, this.loginResultObserver());

		this.submitting = true;
	}

	/**
	 * Log in using the specified provider. Starts the authentication process and opens a window showing the provider's login page, then
	 * invokes loginWait to wait for credentials to be available.
	 * If the provider is null nothing happens. May be used to show password login fields.
	 */
	loginWithProvider(provider: DoiIdentityProvider)
	{
		this.submitting = false;
		this.provider = provider;

		if (!provider)
			return;

		let expires: Date = null;
		if (this.formGroup.value.keep)
			expires = this.doi.auth.expires();

		//	Force prompt if the user has logged out explicitly.
		let promptLogin = this.doi.storage.getContextItem('loggedIn') != 'true';

		this.login.loginWithProvider(provider, expires, promptLogin, this.appView.appTitle(), this.loginResultObserver());

		this.submitting = true;
	}

	/**
	 * Test if the user name and password fields should be visible.
	 * They are visible if there is not provider or if provider null has been selected.
	 */
	showPasswordLogin(): boolean
	{
		return this.provider === null || (this.providers && !this.providers.length);
	}
}
