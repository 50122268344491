import { OnInit, OnDestroy, AfterViewInit, ViewChild, Directive } from '@angular/core';
import {FormGroup, NgForm} from '@angular/forms';
import {Observable, Subscription, EMPTY} from 'rxjs';

import {environment} from '../../../environments/environment';

import {DoiAppView} from './DoiAppView';
import {DoiAction} from '../core/DoiAction';
import {DoiActionSet} from '../core/DoiActionSet';
import {DoiActionTarget} from '../core/DoiActionTarget';
import {DoiFocusFirst} from '../core/DoiFocusFirst';
import {DoiIconMapper} from '../core/DoiIconMapper';
import {DoiLocalStorageOwner} from '../core/DoiLocalStorageOwner';
import {DoiLogLevel} from '../service/DoiLogService';
import {DoiService} from '../service/DoiService';
import {DoiStorageOwner} from '../core/DoiStorageOwner';

export const enum DoiViewMode
{
	Grid, Table
}

/**
 * Abstract base class for all views.
 */
@Directive()
export abstract class DoiView implements OnInit, OnDestroy, AfterViewInit, DoiActionTarget, DoiFocusFirst, DoiIconMapper, DoiLocalStorageOwner
{
	name = 'DoiView';

	doi: DoiService;

	/**
	 * The environment.
	 */
	get environment(): typeof environment { return environment }

	/**
	 * Reference to the application view. Set by the DoiAppView constructor.
	 */
	private _appView: DoiAppView;
	get appView(): DoiAppView { return this._appView };
	set appView(appView: DoiAppView) { this._appView = appView };

	/**
	 * Reference to the parent view. Set by attachParentView which is invoked by subviews when their tab is activated.
	 */
	parentView: DoiView;

	actions: DoiActionSet = new DoiActionSet();
	formGroup: FormGroup;

	@ViewChild('form')
	form: NgForm;

	refreshing: boolean;

	viewModeDefault = DoiViewMode.Grid;
	private _viewMode: DoiViewMode;
	private _formChanges: Subscription;

	/**
	 * Construct a new view.
	 */
	constructor(doi: DoiService)
	{
		this.doi = doi;

		this.actions.add(
			new DoiAction(this, 'ViewModeGrid', 'fas-th', 'Visa rutnät')
			.enabledHandler(() => this.viewModeSupported(DoiViewMode.Grid))
			.selectedHandler(() => this.viewModeGrid)
			.executeHandler(() => this.viewMode = DoiViewMode.Grid));
		this.actions.add(
			new DoiAction(this, 'ViewModeTable', 'th-list', 'Visa tabell')
			.enabledHandler(() => this.viewModeSupported(DoiViewMode.Table))
			.selectedHandler(() => this.viewModeTable)
			.executeHandler(() => this.viewMode = DoiViewMode.Table));
	}

	/**
	 * Invoked when the view has been initialized. Restores view settings.
	 */
	ngOnInit()
	{
		this.viewModeRestore();
	}

	/**
	 * Invoked after the view has been initialized. Subscribes to form changes.
	 */
	ngAfterViewInit()
	{
		if (this.form)
			this.formGroup = this.form.form;
		if (this.formGroup)
			this._formChanges = this.formGroup.valueChanges.subscribe(value => this.formOnChange(this.formGroup.dirty));
	}

	/**
	 * Invoked when the view is being destroyed. Unsubscribes from form changes.
	 */
	ngOnDestroy()
	{
		if (this._formChanges)
			this._formChanges.unsubscribe();
	}

	/**
	 * Return the specified action. The default implementation fetches from the action set.
	 */
	action(actionName: string): DoiAction
	{
		return this.actions.get(actionName);
	}

	/**
	 * Return the action set.
	 */
	actionSet(): DoiActionSet
	{
		return this.actions;
	}

	/**
	 * Attach this view to the application view.
	 */
	attachAppView(appView: DoiAppView): void
	{
		this.appView = appView;
	}

	/**
	 * Attach this view to the specified parent view.
	 */
	attachParentView(parentView: DoiView): void
	{
		this.attachAppView(parentView.appView);
		this.parentView = parentView;
	}

	/**
	 * Test if the user is authenticated.
	 */
	authenticated(): boolean
	{
		return this.doi.auth.isAuthenticated();
	}

	/**
	 * Focus the first component. The default implementation returns false.
	 */
	focusFirst(): boolean
	{
		return false;
	}

	error(error: any)
	{
		if (this.appView)
			this.appView.error(error);
		else
			this.log('Error', error);
	}

	/**
	 * Invoked when the form is changed.
	 */
	formOnChange(dirty: boolean)
	{
		this.log('Form changed', dirty, this.formGroup);
		if (this.parentView)
			this.parentView.formOnChange(dirty);
	}

	/**
	 * Mark the form as pristine.
	 */
	formMarkPristine()
	{
		if (this.formGroup) {
			this.formGroup.markAsPristine();
			this.formOnChange(false);
		}
	}

	/**
	 * Return the period separated full view name. The default implementation returns the value of the name property. Override to
	 * prepend a parent name.
	 */
	fullName(): string
	{
		return this.name;
	}

	/**
	 * Translate an icon name to one or more class names separated by spaces.
	 * The default implementation delegates to the application view, or returns null if not attached.
	 */
	iconClass(iconName: string): string
	{
		if (this.appView)
			return this.appView.iconClass(iconName);

		return null;
	}

	/**
	 * Get the specified item from local storage. The actual item name is constructed from the view name and the item.
	 * @deprecated Use storageGetItem which uses the preferred storage, either local or session storage.
	 */
	localStorageGet(item: string, def?: any): any
	{
		return this.doi.storage.getItem(this.fullName()+'.'+item, def);
	}

	/**
	 * Set the specified item in local storage. The actual item name is constructed from the view name and the item.
	 * @deprecated Use storageSetItem which uses the preferred storage, either local or session storage.
	 */
	localStorageSet(item: string, value: any): any
	{
		return this.doi.storage.setItem(this.fullName()+'.'+item, value);
	}

	/**
	 * Remove the specified item from local storage. The actual item name is constructed from the view name and the item.
	 * @deprecated Use storageRemoveItem which uses the preferred storage, either local or session storage.
	 */
	localStorageRemove(item: string): any
	{
		return this.doi.storage.remove(localStorage, this.fullName()+'.'+item);
	}

	/**
	 * Log the view name, the specified message and zero or more objects with level FINE.
	 */
	log(message: string, ...objects: any[]): void
	{
		if (this.doi.log.isLoggable(DoiLogLevel.FINE))
			this.doi.log.fine(this.name+': '+message, ...objects);
	}

	/**
	 * Return the action names for the application menu. The default implementation currently returns an empty array.
	 * @param full Indicates if the full menu should be returned, visible on a reduced screen.
	 */
	menuAppActionNames(full: boolean): string[]
	{
		return new Array<string>();
	}

	/**
	 * Test if this action target is displayed on a mobile device. The default implementation delegates to the application view.
	 */
	mobileDevice(): boolean
	{
		if (this.appView)
			return this.appView.mobileDevice()
		else
			return false;
	}

	/**
	 * Refresh this view.
	 */
	refresh(): void
	{
		this.log('refresh');

		this.doi.alert.clear();

		this.refreshing = true;
		this.refreshView().subscribe(
			result => {
			},
			error => {
				this.refreshing = false;
				this.error(error);

			},
			() => {
				this.refreshDone();
				this.refreshing = false;
			});
	}

	/**
	 * Invoked by refresh when done. The default implementation does nothing.
	 */
	refreshDone(): void
	{
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * The default implementation returns an empty observable.
	 */
	refreshView(): Observable<any>
	{
		return EMPTY;
	}

	/**
	 * Get the specified item from the preferred storage, either local or session.
	 * The actual item name is constructed from the view name and the item.
	 */
	storageGetItem(item: string, def?: any): any
	{
		return this.doi.storage.getItem(this.fullName()+'.'+item, def);
	}

	/**
	 * Set the specified item in local storage, either local or session.
	 * The actual item name is constructed from the view name and the item.
	 */
	storageSetItem(item: string, value: any): any
	{
		return this.doi.storage.setItem(this.fullName()+'.'+item, value);
	}

	/**
	 * Remove the specified item from the preferred storage, either local or session.
	 * The actual item name is constructed from the view name and the item.
	 */
	storageRemoveItem(item: string): any
	{
		return this.doi.storage.removeItem(this.fullName()+'.'+item);
	}

	/**
	 * Return a title describing the type of view without data dependencies. A top view typically returns
	 * a view type name such as 'Search', 'Settings' or 'Issue'. A sub view could return the title
	 * used on tabs.
	 * The default implementation returns undefined.
	 */
	typeTitle(): string
	{
		return undefined;
	}

	/**
	 * Return the current view mode.
	 */
	get viewMode()
	{
		return this._viewMode != undefined ? this._viewMode : this.viewModeDefault;
	}

	/**
	 * Change the view mode.
	 */
	set viewMode(viewMode: DoiViewMode)
	{
		if (this.viewModeSupported(viewMode)) {
			this._viewMode = viewMode;
			this.storageSetItem('viewMode', viewMode);
		}
	}

	/**
	 * Test if the current view mode is Grid.
	 */
	get viewModeGrid(): boolean
	{
		return this.viewMode == DoiViewMode.Grid;
	}

	/**
	 * Test if the current view mode is Table.
	 */
	get viewModeTable(): boolean
	{
		return this.viewMode == DoiViewMode.Table;
	}

	/**
	 * Restore the saved view mode.
	 */
	viewModeRestore()
	{
		let viewMode = this.storageGetItem('viewMode');
		if (viewMode != undefined) {
			this.viewMode = viewMode;
		} else {
			if (this.viewModeSupported(this.viewModeDefault))
				this.viewMode = this.viewModeDefault;
			else if (this.viewModeSupported(DoiViewMode.Grid))
				this.viewMode = DoiViewMode.Grid;
			else if (this.viewModeSupported(DoiViewMode.Table))
				this.viewMode = DoiViewMode.Table;
		}
	}

	/**
	 * Test if the specified view mode is supported by this view.
	 */
	viewModeSupported(viewMode: DoiViewMode): boolean
	{
		return false;
	}

	t(key: string): string
	{
		if (this.appView)
			return this.appView.t(key);
		else
			return undefined;
	}

}
