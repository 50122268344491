import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

import {DoiIconMapper, DoiView} from '../../doi/DoiModule';

import {PropertyValue} from '../model/PropertyValue';

/*
 * A property value component for highlighted boolean properties. Renders as a checkbox if editing, otherwise only the label text.
 */
@Component({
	selector: 'property-value-hb',
	template: `
		<form ngForm>
			<div *ngIf="!editing()"><i [ngClass]="iconClass('exclamation-circle')"></i>{{propertyValue.label}}</div>
			<div *ngIf="editing()">
				<doi-checkbox name="pv{{propertyValue.propTypeID}}" [(ngModel)]="value" [ngModelOptions]="{standalone:true}" [edit]="true" (change)="valueChanged($event)">{{propertyValue.label}}</doi-checkbox>
				<property-value-revert [view]="view" *ngIf="used" [propertyValue]="propertyValue" (change)="valueReverted($event)"></property-value-revert>
			</div>
		</form>
	`,
	host: { 'class' : 'property-value property-value-hb' },
	styles: [ 'form div { display: flex; align-items: baseline; }' ],
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class PropertyValueHBComponent
{
	/**
	 * The containing view.
	 */
	@Input()
	view: DoiView;

	/**
	 * The property value.
	 */
	@Input()
	propertyValue: PropertyValue;

	/**
	 * Indicates of the component is being edited.
	 */
	@Input()
	@HostBinding('class.property-value-edited')
	edit: boolean;

	/**
	 * Value change emitter.
	 */
	@Output()
	change = new EventEmitter<any>();

	@HostBinding('class.property-value-used')
	private get used(): boolean
	{
		return this.propertyValue && this.propertyValue.used;
	}

	/**
	 * Test if editing.
	 */
	editing(): boolean
	{
		return this.edit;
	}

	/**
	 * Construct an icon class for the specified icon name.
	 */
	iconClass(iconName : string)
	{
		if (!iconName)
			return null;

		return this.view.iconClass(iconName);
	}

	/**
	 * The current value of the attached property value object.
	 */
	get value(): any
	{
		return this.propertyValue.value;
	}

	set value(value: any)
	{
		this.propertyValue.value = value;
	}

	/**
	 * Invoked when the inner checkbox changes. Flags the value as used and emits a change event.
	 */
	valueChanged(value: any)
	{
		this.propertyValue.used = true;

		this.change.next(this.value);
	}

	/**
	 * Invoked by the revert tool when the value has been reverted. Emits a change event.
	 */
	valueReverted(value: any)
	{
		this.change.next(this.value);
	}
}
