import {Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable, forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';

import {ActivatedRoute} from '@angular/router';
import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';

import {ProcessPart, ProcessObject, ProcessModelArea} from '../model/ProcessObject';

@Component({
	templateUrl: 'ProcessObjectDiagramPanel.html'
})
export class ProcessObjectDiagramPanel extends DoiObjectPanel<ProcessObject>
{
	name = 'diagram';

	image: any;

	constructor(doi: DoiService, route: ActivatedRoute, private sanitizer: DomSanitizer)
	{
		super(doi, route);
	}

	routerLinkArea(area : ProcessModelArea)
	{
		if (!area || !area.objectID)
			return null;

		if (area.objectType == 'Process')
			return ['/process', area.objectID, 'diagram'];
		if (area.objectType == 'Activity')
			return ['/process', this.object.objectID, 'activities'];

		return ['/'+area.objectType.toLowerCase(), area.objectID];
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessPart.ModelImage).pipe(
				tap((process: ProcessObject) => {
					if (process.modelImage) {
						this.image = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + process.modelImage);
					} else {
						this.image = null;
					}
				})
			));
	}
}
