import {HostListener, Directive, Input} from '@angular/core';

/**
 * Prevents the default action on links with empty href attributes.
 */
@Directive({
    selector: '[href]'
})
export class DoiEmptyHrefDirective
{
	/**
	 * The href attribute.
	 */
    @Input() href: string;
	
	/**
	 * Invoked on click. Returns false to prevent the default action if the href is empty.
	 */
    @HostListener('click', ['$event'])
	onClick(event: any)
	{
        if (this.href.length === 0 || this.href === '#') {
            return false;
        }
	}
}
