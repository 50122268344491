<div class="doi-scroll">

	<form class="doi-subview" #form="ngForm" role="form">

		<div class="row">
			<div class="col-12 col-md-8">
				<div class="row object-title-pane">
					<div class="col-12">
						<doi-title>
							<span class="title-object-type"><i class="fal fa-file-alt"></i>Handlingstyp</span>
							<span class="title-object-name">{{object.objectTitle()}}</span></doi-title> <!-- I18N -->
					</div>
					<div class="col-12">
						<doi-textarea *ngIf="editing() || object?.descriptionShort" name="descriptionShort" labelEdit="Kort beskrivning" rows="2" maxLength="225" size="60" [(ngModel)]="object.descriptionShort" [edit]="editing()"></doi-textarea>
						<div class="time-period" *ngIf="object?.validPeriod">{{object.validPeriod}}</div>
						<property-values [view]="this" [propertyValues]="propertyValues()" [normal]="false" [cellsLG]="6" [edit]="editing()"></property-values>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4" *ngIf="object.objectFiles?.length">
				<div class="group-pane object-files">
					<h3>Relaterade dokument</h3>
					<div class="row">
						<div class="col-12"><object-files [objectFiles]="object.objectFiles" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files></div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class ="col-12" [ngClass]="bodyLeftColClasses()">
				<div class="row" *ngIf="object?.description">
					<div class="col-12">
						<description [description]="object?.description" [iconMapper]="this" header="Mer om handlingstypen"></description>
					</div>
				</div>
				<div class="row">
					<div class ="col-12" *ngIf="propertyValues().length > 0">
						<div class="group-pane">
							<property-values [view]="this" groupTitle="Hantering" groupTitleOther="Övrig hantering" [propertyValues]="propertyValues()" [highlighted]="false" [cellsLG]="6"></property-values>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12" [ngClass]="bodyRightColClasses()" >
				<div class="row">
					<div class="col-12" *ngIf="showUnits()">
						<div class="group-pane">
							<h3 class="mb-2">Förvaringsinformation</h3>
							<p>Visar var handlingarna finns</p>
							<div *ngFor="let unit of units">
								<unit [view]="this" class="group-pane" [unit]="unit" [unitValueReader]="this"></unit>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
