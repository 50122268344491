import { DoiAction } from './DoiAction';
import { DoiIconMapper } from './DoiIconMapper';

/**
 * An action target. Implemented by views that can have actions.
 */
export interface DoiActionTarget extends DoiIconMapper
{
	/**
	 * Return the specified action. May delegate to an active inner view.
	 */
	action(actionName: string): DoiAction;

	/**
	 * Test if the target, usually a view, is displayed on a mobile device.
	 */
	mobileDevice(): boolean;
}
