import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {environment} from '../../environments/environment';

import {DoiModule, DoiObjectEditGuard, DoiRedirectGuard} from '../doi/DoiModule';

import {KlaraModule} from '../klara/KlaraModule';
import {UnitService} from './model/UnitService';
import {UnitSelectionView} from './view/UnitSelectionView';
import {UnitObjectView} from './view/UnitObjectView';
import {UnitObjectGeneralPanel} from './view/UnitObjectGeneralPanel';
import {UnitObjectProcessesAndActTypesPanel} from './view/UnitObjectProcessesAndActTypesPanel';
import {UnitObjectChildrenPanel} from './view/UnitObjectChildrenPanel';

export * from './model/UnitObject';
export * from './model/UnitService';

export const unitRoutes: Routes = environment.dialog ? [] : [
	{path: 'units', component: UnitSelectionView, data: {title: 'Förvaringsenheter'}},
	{path: 'unit/:id', component: UnitObjectView, canActivate: [DoiRedirectGuard], canDeactivate: [DoiObjectEditGuard], data: { defaultChild: 'general' }, children: [
		{path: 'general', component: UnitObjectGeneralPanel, data: {title: 'Allmänt'}},
		{path: 'processesandacttypes', component: UnitObjectProcessesAndActTypesPanel, data: {title: 'Förvarar'}},
		{path: 'children', component: UnitObjectChildrenPanel, data: {title: 'Ingående'}},
	]},
];

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(unitRoutes),
		DoiModule,
		KlaraModule,
	],
	declarations: [
		UnitSelectionView, UnitObjectView, UnitObjectGeneralPanel, UnitObjectProcessesAndActTypesPanel, UnitObjectChildrenPanel,
	],
	exports: [
	],
	providers: [
		UnitService,
	],

})
export class UnitModule
{
}

// YuHXtea5IPbLQP0sJBc3YU4Xhkc=
