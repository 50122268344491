import {DoiObject, DoiObjectRef, DoiObjectPermission} from '../../doi/DoiModule';

import {ArchiveObject} from '../../archive/ArchiveModule';

/**
 *	Object part names for the SeriesGroup entity object.
 */
export const SeriesGroupPart =
{
	Label : 'Label',
	General : 'General',
	Series : 'Series',
}

/**
 *	The SeriesGroup entity object.
 */
export class SeriesGroupObject extends DoiObject
{
	static readonly iconName = 'container-storage';

	/**
	 *	Attribute values.
	 */
	archiveID: number;
	code: string;
	title: string;
	seriesCount: number;

	/**
	 *	Parent.
	 */
	archive: ArchiveObject;

	/**
	 * Construct a new SeriesGroup object.
	 */
	constructor(objectID: number)
	{
		super(objectID);
	}

	/**
	 * The icon name, i e 'info-circle'.
	 */
	get iconName(): string
	{
		return SeriesGroupObject.iconName;
	}

	/**
	 * The symbolic object type name, i e 'SeriesGroup'.
	 */
	get objectType(): string
	{
		return 'SeriesGroup';
	}

	/**
	 * Return an object reference path from the root, corresponding to the navigation structure, or null.
	 */
	objectRefPath(): DoiObjectRef[]
	{
		if (this.archive) {
			let path = this.archive.objectRefPath();
			if (path)
				path.push(this.objectRef());
			return path;
		}

		return null;
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectTitle(): string
	{
		return this.code+' - '+this.title;
	}

	/**
	 * Populate an object from a data object.
	 */
	parseData(data: any, partName: string): SeriesGroupObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, SeriesGroupPart.Label);
				break;
			case SeriesGroupPart.Label:
				this.objectID = data.SeriesGroup_SeriesGroupID;
				this.archiveID = data.SeriesGroup_ArchiveID;
				this.code = data.SeriesGroup_Code;
				this.title = data.SeriesGroup_Title;
				this.seriesCount = data.SeriesGroup_SeriesCount;
				break;
			case SeriesGroupPart.General:
				this.parseData(data, SeriesGroupPart.Label);
		}
		return this;
	}
}

// YnQn6+tLul1nvhMP2oiIUPxruMw=
