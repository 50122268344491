import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {UnitPart, UnitObject, Child} from '../../unit/model/UnitObject';

@Component({
	templateUrl: 'UnitObjectChildrenPanel.html'
})
export class UnitObjectChildrenPanel extends DoiObjectPanel<UnitObject>
{
	name = 'children';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	routerLinkArchiveOrig(unit : Child)
	{
		return unit.archiveOrigID ? [ '/archiveorig', unit.archiveOrigID ] : null;
	}

	routerLinkUnit(unit : Child)
	{
		return unit.unitID ? [ '/unit', unit.unitID ] : null;
	}

	openUnit(unit : Child)
	{
		this.doi.router.navigate(this.routerLinkUnit(unit));
	}

	refreshView(): Observable<any>
	{
		return forkJoin(this.refreshObjectPart(UnitPart.Children));
	}

	/**
	 * Return the icon class for the specified object.
	 */
	unitIconClass()
	{
		return this.iconClass(Child.iconName);
	}
}

// K8VaWiG2oveAj55HqaFcBZMmvTY=
