import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {ProcessPart, ProcessObject} from '../model/ProcessObject';
import {PropertyValue} from '../../klara/model/PropertyValue';
import { KlaraObjectPanel } from '../../klara/KlaraModule';

@Component({
	templateUrl: 'ProcessObjectDetailPanel.html'
})
export class ProcessObjectDetailPanel extends KlaraObjectPanel<ProcessObject>
{
	name = 'details';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openProcess(processID : number)
	{
		this.appView.openObject('Process', processID);
	}

	/**
	* För beskrivningsält som inte är hanteringsanvisningar
	*/
/*	propertyValues() : PropertyValue[]
	{

		if (!this.object  || this.object.propertyValues == null)
			return new Array<PropertyValue>();

		return PropertyValue.propertyValues(this.object.propertyValues, null, false, !this.editing());
	}
*/
	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessPart.General),
			this.refreshObjectPart(ProcessPart.ProcAltNames),
			this.refreshObjectPart(ProcessPart.Description),
		);
	}
}

// rcT9DIxhYj6XC14VqboZUpIT5aA=
