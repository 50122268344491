import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, Observable, forkJoin} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiObjectView, DoiService} from '../../doi/DoiModule';

import {KlaraObjectView} from '../../klara/KlaraModule';
import {ProcessActTypeObjectView} from './ProcessActTypeObjectView';
import {ProcessActTypeService} from '../model/ProcessActTypeService';
import {ProcessActTypePart, ProcessActTypeObject} from '../model/ProcessActTypeObject';
import {ProcessPart, ProcessObject, ProcessService} from '../../process/ProcessModule';
import {KlaraDialogTopView} from '../../klara/KlaraModule';

/**
 *
 */
@Component({
	templateUrl: 'ProcessActTypeObjectDialogView.html'
})
export class ProcessActTypeObjectDialogView extends ProcessActTypeObjectView
{

	/**
	 * Construct a new object view.
	 */
	constructor(doi: DoiService, processActTypeService: ProcessActTypeService, route: ActivatedRoute, processService: ProcessService)
	{
		super(doi, processActTypeService, route, processService);
	}
}

// GtLHY+JUzyXoT9DpYNumwFk0PRA=
