<div class="doi-subview">

	<div *ngIf="object?.units?.length" class="doi-scroll-container">
		<table class="table table-striped table-hover">
			<thead>
				<tr class="doi-clickable">
					<th class="doi-grow-2">Typ</th>
					<th class="doi-grow-2">Beteckning/Benämning</th>
					<th class="doi-grow-1 doi-hidden-xs-down">Tid</th>
					<th class="doi-grow-5 doi-hidden-xs-down">Arkivbildare</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let unit of object.units" (click)="openUnit(unit)" class="doi-clickable">
					<td class="doi-grow-2 doi-wrap">{{unit.unitTypeName}}</td>
					<td class="doi-grow-2 doi-wrap"><a [routerLink]="routerLinkUnit(unit)">{{unit.navText}}</a></td>
					<td class="doi-grow-1 doi-wrap doi-hidden-xs-down">{{unit.date}}</td>
					<td class="doi-grow-5 doi-wrap doi-hidden-xs-down"><a *ngIf="routerLinkArchiveOrig(unit)" [routerLink]="routerLinkArchiveOrig(unit)">{{unit.archiveOrigName}}</a></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
