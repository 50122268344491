import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';

import {DoiObjectRef, DoiService} from '../doi/DoiModule';
import {DoiSearchCriteria, DoiSearchService, DoiSearchResult, DoiSearchResultEntry, DoiSearchView} from '../doi-search/DoiSearchModule';
import {DoiNavNode} from '../doi-navigator/DoiNavigatorModule';

import {KlaraDialogTopView} from '../klara/KlaraModule';
import {ProcessObject} from '../process/ProcessModule';

import {AppBaseView} from '../AppBaseView';

enum SearchType
{
    All
}

@Component({
	templateUrl: 'SearchView.html'
})
export class SearchView extends DoiSearchView implements OnInit, KlaraDialogTopView
{
	name = 'SearchView';

	searchType = SearchType.All;
	//	The scope based on the navigation.
	scope: string;
	//	The scope that can be activated or deactivated.
	scopeOptional: string;
	//	The node representing the scope.
	scopeNode: DoiNavNode;

	searchCategoriesHidden = true;

	/**
	 * Construct a new search view.
	 */
	constructor(doi: DoiService, searchService: DoiSearchService, route: ActivatedRoute)
	{
		super(doi, searchService, route);

		let st: SearchType = this.storageGetItem('searchType');
		if (st)
			this.searchType = st;

		if (this.formGroup)
			this.formGroup.addControl('searchScope', new FormControl(false));
	}

	/**
	 * Reference to the application view.
	 */
	get appView(): AppBaseView { return super.appView as AppBaseView };
	set appView(appView: AppBaseView) { super.appView = appView };

	/**
	 * Test if the environment specifies that Klara Dialog is running.
	 */
	isAppDialog(): boolean
	{
		return this.environment.dialog;
	}

	/**
	 * Test if the environment specifies that Klara WebUI is running.
	 */
	isAppWebUI(): boolean
	{
		return !this.environment.dialog;
	}

	/**
	 * Create a search criteria from query parameters.
	 */
	criteriaFromParams(pm: ParamMap): DoiSearchCriteria
	{
		let criteria = super.criteriaFromParams(pm);
		criteria.client = this.isAppDialog() ? "Dialog" : "WebUI";

		return criteria;
	}

	/**
	 * Create a search criteria from the current form values.
	 */
	criteriaFromForm(): DoiSearchCriteria
	{
		let criteria = super.criteriaFromForm();

		criteria.client = this.isAppDialog() ? "Dialog" : "WebUI";

		if (this.formGroup.value.searchScope)
			criteria.scope = this.scope;

		return criteria;
	}

	/**
	 * Return the corresponding path in Klara Dialog, or an empty string for the same URL.
	 */
	dialogPath() : string
	{
		return '';
	}

	/**
	 * Return the corresponding path in Klara WebUI, null if not available or an empty string for the same URL.
	 */
	klaraPath() : string
	{
		return '';
	}

	/**
	 * Populate the search form from a search criteria.
	 * Overridden to set up a node from the scope, to restore state if the criteria is received from query parameters.
	 */
	populateForm(criteria: DoiSearchCriteria)
	{
		super.populateForm(criteria);

		if (criteria.scope) {

			this.scope = criteria.scope;

			let scopeParts = criteria.scope.split('/');
			let scopeType = null;
			let scopeID = null;
			if (scopeParts.length > 1) {
				scopeType = scopeParts[0];
				scopeID = parseInt(scopeParts[1]);
			}

			if (scopeType) {
				if (this.scopeNode == null || this.scopeNode.pathElement.objectID != scopeID) {
					this.scopeNode = new DoiNavNode(null);
					this.scopeNode.setObjectRef(DoiObjectRef.forObject(scopeType, scopeID));
					this.refreshScopeNode();
				}
				this.log('populateForm node:', this.scopeNode);
			}

		}

		if (this.scope != null && this.scope != this.scopeOptional) {
			this.formGroup.get('searchScope').setValue(true);
			this.scopeOptional = this.scope;
		}
	}

	/**
	 * Fetch the scope node label.
	 */
	refreshScopeNode()
	{
		if (this.scopeNode && this.scopeNode.label == null) {
			let objectType = this.scopeNode.pathElement.objectType;
			let service = this.doi.brokerService(objectType);
			if (service) {
				service.readObjectPart(this.scopeNode.pathElement.objectID, 'Label').subscribe(
					object => {
						this.scopeNode.label = object.objectTitle();
					}
				)
			}
		}
	}

	/**
	 * Search for the current search text by invoking {@link DoiSearchService#search}.
	 * Overridden to fetch scope node label.
	 */
	refreshView(): Observable<any>
	{
		this.refreshScopeNode();

		if (this.formGroup && !this.formGroup.valid)
			return EMPTY;

		return super.refreshView();
	}

	scopeLabel(): string
	{
		switch (this.scope) {
			case 'structure': return 'Struktur';
			case 'storage': return 'Förvaring';
			default:
				if (this.scopeNode)
					return this.scopeNode.pathLabels(' / ');
		}
		return null;
	}

	search()
	{
		this.storageSetItem('searchType', this.searchType);
		super.search();
	}

	searchAll()
	{
		this.searchType = SearchType.All;
		this.search();
	}

	searchButtonClassAll(): string
	{
		return (this.searchType == SearchType.All ? 'btn-primary' : 'btn-secondary');
	}

	searchButtonTypeAll(): string
	{
		return this.searchType == SearchType.All ? 'submit' : 'button';
	}

	/**
	 * Build entry text for the specified entry. Overridden to build object type specific entry texts.
	 */
	buildEntryText(entry: DoiSearchResultEntry): void
	{
		entry.typeText = '';
		entry.headerText = '';

		for (let catID of entry.catIDs) {
			let cat = this.result.catIndex.get(catID);
			if (cat) {
				switch (cat.group.name) {
					case 'ObjectType':
						if (entry.typeText == '')
						 	entry.typeText = cat.text+' ';
						break;
					case 'Process':
						let process = entry.object as ProcessObject;
						if (process.processTypeName) {
							entry.typeText = process.processTypeName+' ';
						}
						entry.props.processText = cat.text;
						break;
					case 'ProcessStructure':
						entry.props.procStructText = cat.text;
						break;
				}
			}
		}

		super.buildEntryText(entry);
	}

	/**
	 * Process a search result delivered by the search service. Overridden to translate category names.
	 */
	processResult(result: DoiSearchResult)
	{
		super.processResult(result);

		//	Translate category names and sort by sequence.

		for (let catGroup of result.catGroups) {
			if (catGroup.cats.length && catGroup.cats[0].text == null) {
				catGroup.text = null;
				catGroup.seq = 99;
				continue;
			}
			switch (catGroup.name) {
				case 'ObjectType':
					catGroup.seq = 1;
					catGroup.text = 'Typ av objekt';
					for (let cat of catGroup.cats) {
						switch (cat.text) {
							case 'ArchiveOrig':			cat.seq = 1; cat.text = 'Arkivbildare'; break;
							case 'ProcessStructure':	cat.seq = 2; cat.text = 'Klassificeringsstruktur'; break;
							case 'Process':				cat.seq = 3; cat.text = 'Strukturenhet'; break;
							case 'ProcessActType':		cat.seq = 4; cat.text = 'Handlingstyp'; break;
							case 'Unit':				cat.seq = 5; cat.text = 'Förvaringsenhet'; break;
							case 'Archive':				cat.seq = 6; cat.text = 'Arkiv'; break;
							case 'Series':				cat.seq = 7; cat.text = 'Serie'; break;
							case 'Volume':				cat.seq = 8; cat.text = 'Volym'; break;
						}
					}
					catGroup.cats.sort((c1, c2) => c1.seq - c2.seq);
					break;
				case 'ArchiveOrig':
					catGroup.seq = 2;
					catGroup.text = 'Arkivbildare';
					catGroup.limitDisplay = 10;
					break;
				case 'ProcessStructure':
					catGroup.seq = 3;
					catGroup.text = 'Klassificeringsstruktur';
					break;
				case 'Process':
					catGroup.seq = 4;
					catGroup.text = 'Strukturenhet';
					catGroup.limitDisplay = 10;
					break;
				case 'Modified':
					catGroup.seq = 5;
					catGroup.text = 'Ändrad';
					catGroup.limitDisplay = 3;
					break;
			}
		}

		result.catGroups.sort((g1, g2) => g1.seq - g2.seq);
	}

	/**
	 * Open the specified result entry.
	 */
	openResultEntry(entry : DoiSearchResultEntry)
	{
		this.appView.openObject(entry.objectType, entry.objectID);
	}

	/**
	 * Open the specified process.
	 */
	openProcess(processID: number)
	{
		this.appView.openObject('Process', processID);
	}

	/**
	 * Open the specified process structure.
	 */
	openProcessStructure(procStructID: number)
	{
		this.appView.openObject('ProcessStructure', procStructID);
	}

	/**
	 * Test if a search tools should be shown in the application toolbar. Override to return false if the view has its own search field.
	 */
	searchToolVisible()
	{
		return false;
	}

	searchCategoriesClass(): string
	{
		return this.searchCategoriesHidden ? 'search-categories-hidden' : null;
	}

	searchCategoriesExpandClass(): string
	{
		return this.searchCategoriesHidden ? 'fa-caret-right' : 'fa-caret-down';
	}

	searchCategoriesToggle()
	{
		this.searchCategoriesHidden = !this.searchCategoriesHidden;
	}
}
