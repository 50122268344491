import {Observable, concat} from 'rxjs';
import {map} from "rxjs/operators";

import {DoiIconMapper, DoiObjectRef, DoiSelectionCriteria} from '../doi/DoiModule';
import {DoiNavNode, DoiNavNodeChildSupplier} from '../doi-navigator/DoiNavigatorModule';

import {ProcessService, ProcessObject} from '../process/ProcessModule';
import {ProcessActTypeService, ProcessActTypeObject} from '../processacttype/ProcessActTypeModule';

/**
 * A navigator node child supplier for a process node.
 */
export class ProcessNodeChildSupplier extends DoiNavNodeChildSupplier
{
	constructor(
		private processService: ProcessService,
		private processActTypeService: ProcessActTypeService)
	{
		super();
	}

	readChildren(node: DoiNavNode): Observable<DoiNavNode[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Process_ProcessID', 'Process_Code', 'Process_NameList')
			.orderBy('Process_Code', 'Process_ProcessID');

		criteria.where('Process_ParentProcessID', '='+node.pathElement.objectID);

		let processObservable = this.processService.readObjectSelection(null, criteria).pipe(
			map((processes: ProcessObject[]) =>
			{
				let nodes = new Array<DoiNavNode>();
				for (let process of processes) {
					let objectRef = DoiObjectRef.forObject('Process', process.objectID);
					nodes.push(
						new DoiNavNode(process.objectTitle(), '/process/'+process.objectID)
							.setObjectRef(objectRef)
							.setIconName(process.iconName)
							.setIconMapper(node.getIconMapper())
							.setChildNodeSupplier(this)
					);
				}
				return nodes;
			})
		);

		criteria = new DoiSelectionCriteria()
			.select('ProcessActType_ProcessActTypeID', 'ProcessActType_Name', 'ProcessActType_NameShort', 'ProcessActType_Sequence')
			.where('ProcessActType_ProcessID', '='+node.pathElement.objectID)
			.orderBy('ProcessActType_Sequence', 'ProcessActType_ProcessActTypeID');

		let processActTypeObservable = this.processActTypeService.readObjectSelection(null, criteria).pipe(
			map((processActTypes: ProcessActTypeObject[]) =>
			{
				let nodes = new Array<DoiNavNode>();
				for (let processActType of processActTypes) {
					let objectRef = DoiObjectRef.forObject('ProcessActType', processActType.objectID);
					nodes.push(
						new DoiNavNode(processActType.objectTitle(), '/processacttype/'+processActType.objectID)
							.setObjectRef(objectRef)
							.setIconName(processActType.iconName)
							.setIconMapper(node.getIconMapper())
					);
				}
				return nodes;
			})
		);

		return concat(processObservable, processActTypeObservable);
	}
}
