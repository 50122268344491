import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * A pager component.
 */
@Component({
	selector: 'doi-search-pager',
	host: { 'class' : 'doi-search-pager' },
	template: `
		<ul *ngIf="pageCount > 1" class="pagination pagination-sm">
			<li class="page-item">
				<a class="page-link" href="" title="Föregående sida" (click)="selectPage(pageIndex)"><i class="fas fa-step-backward"></i></a>
			</li>
			<li class="page-item" *ngFor="let pn of pageNumbers" [ngClass]="{active: pageIndex+1 == pn}">
				<a class="page-link" href="" title="{{pn > 0 ? pn : -pn}}" (click)="selectPage(pn)">{{pn > 0 ? pn : '..'}}</a>
			</li>
			<li class="page-item">
				<a class="page-link" href="" title="Nästa sida" (click)="selectPage(pageIndex+2)"><i class="fas fa-step-forward"></i></a>
			</li>
		</ul>
	`// I18N
})
export class DoiSearchPagerComponent
{
	/**
	 * The maximum number of page selector items. Initially 7.
	 */
	@Input()
	maxPageItems: number = 7;

	/**
	 * The page count.
	 */
	@Input()
	pageCount: number;

	/**
	 * The current 0 based page index.
	 */
	@Input()
	pageIndex: number;

	/**
	 * Emits the new 1 based page number when a page item is clicked.
	 */
	@Output() click = new EventEmitter<number>();

	get pageNumbers(): number[]
	{
		let pagerMax = this.maxPageItems;
		if (pagerMax > this.pageCount) {
			pagerMax = this.pageCount;
		} else {
			if (pagerMax % 2 == 0)
				++pagerMax;
		}

		let pageNos: number[] = [];

		if (this.pageCount > pagerMax) {
			if (this.pageIndex < pagerMax-3) {
				for (let pi = 0; pi < pagerMax-2; ++pi) {
					pageNos.push(pi+1);
				}
				pageNos.push(-(pagerMax-2+1));
				pageNos.push(this.pageCount);
			} else if (this.pageIndex > this.pageCount-pagerMax+2) {
				pageNos.push(1);
				pageNos.push(-(this.pageCount-pagerMax+2));
				for (let pi = this.pageCount-pagerMax+2; pi < this.pageCount; ++pi) {
					pageNos.push(pi+1);
				}
			} else {
				pageNos.push(1);
				let relix = (pagerMax-5)/2;
				pageNos.push(-(this.pageIndex-relix));
				for (let pi = this.pageIndex-relix; pi <= this.pageIndex+relix; ++pi) {
					pageNos.push(pi+1);
				}
				pageNos.push(-(this.pageIndex+relix+2));
				pageNos.push(this.pageCount);
			}
		} else {
			for (let pi = 0; pi < pagerMax; ++pi) {
				pageNos.push(pi+1);
			}
		}

		return pageNos;
	}

	/**
	 * Emit the new 1 based page number.
	 */
	selectPage(pageNo: number)
	{
		pageNo = Math.max(1, Math.min(this.pageCount, Math.abs(pageNo)));

		this.click.emit(pageNo);
	}
}
