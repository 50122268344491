import {Directive, HostListener} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbModal, NgbModalRef, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {from as observableFrom, throwError as observableThrowError, Observable, of, EMPTY} from 'rxjs';
import {map, switchMap, tap} from "rxjs/operators";

import {DoiService} from '../service/DoiService';
import {DoiBrokerService} from '../service/DoiBrokerService';
import {DoiObject} from '../service/DoiObject';
import {DoiAction} from '../core/DoiAction';
import {DoiActionTarget} from '../core/DoiActionTarget';
import {DoiIconMapper} from '../core/DoiIconMapper';
import {DoiLogLevel} from '../service/DoiLogService';
import {DoiModalDialog, DoiModalResult, DoiModalType} from '../view/DoiModalDialog';
import {DoiTopView} from '../view/DoiTopView';
import {DoiView} from '../view/DoiView';

export class DoiAppSettings
{
	debugMode: boolean;
	navigator: boolean;
	navigatorDocked: boolean;
	navigatorWidth: number;
	themeName: string;
}

@Directive()
export class DoiAppView extends DoiView implements DoiActionTarget, DoiIconMapper
{
	name = 'DoiAppView';

	doi: DoiService;

	activeView: DoiTopView;
	lastUrl: string = '/';

	settings: DoiAppSettings;

	/**
	 * Icon class regular expression.
	 */
	private _iconClassRE = new RegExp('^(fa.)-([^\\s]+)(.*)');

	/**
	 * The current modal observable.
	 */
	private _modalObservable: Observable<DoiModalResult>;

	constructor(router: Router, doi: DoiService)
	{
		super(doi);

		let context = this.urlContext();
		if (context)
			doi.storage.context = context.substring(1);

		this.appView = this;

		router.events.subscribe(event => {
			if (event instanceof NavigationEnd)
				this.urlNavigated(event.urlAfterRedirects)
		});
	}

	/**
	 * Handle unload.
	 */
	@HostListener('window:beforeunload', ['$event'])
	unloadHandler($event: any)
	{
		if (this.activeView && this.activeView.checkPromptClose()) {
			$event.returnValue = '';
			$event.preventDefault();
		}
	}

	/**
	 * Return the specified action. Delegates to the active view.
	 */
	action(actionName: string): DoiAction
	{
		if (this.activeView) {
			let action = this.activeView.action(actionName);
			if (action)
				return action;
		}

		return super.action(actionName);
	}

	/**
	 * Return the symbolic name of the active view, or null.
	 */
	activeViewName(): string
	{
		return this.activeView ? this.activeView.name : null;
	}

	/**
	 * Return the app container classes. The default implementation returns the active view name.
	 */
	appContainerClasses(): string
	{
		return this.activeViewName();
	}

	/**
	 * Return the application title. The default implementation return null.
	 */
	appTitle(): string
	{
		return null;
	}

	/**
	 * Test if editing the specified object is possible and allowed.
	 * The default implementation test if the object is defined and not null, and then delegates to the object's permitWrite.
	 */
	canEdit(object: DoiObject): boolean
	{
		return object && object.permitWrite();
	}

	/**
	 * Create a new empty settings object. Override to create an application specific subclass.
	 */
	createSettings(): DoiAppSettings
	{
		return new DoiAppSettings();
	}

	/**
	 * Show an error and return an error emitter.
	 */
	error(error: any): Observable<never>
	{
		this.doi.showError(error);

		return observableThrowError(error);
	}

	/**
	 * Ask the current view to focus its first component.
	 */
	focusFirstActiveView(event: Event)
	{
		if (this.activeView) {
			setTimeout(() => {
				if (this.activeView.focusFirst()) {
					if (event)
						event.preventDefault();
					return;
				}
			});
		}
	}

	/**
	 * Forget my settings and reload the application.
	 */
	forgetSettings()
	{
		this.doi.forgetSettings();
		window.location.reload();
	}

	/**
	 * Translate an icon name to one or more FontAwesome class names separated by spaces.
	 * The default implementation translates an icon name matching "fa?-nnn" to "fa? fa-nnn" and all others to "fad fa-xxx".
	 */
	iconClass(iconName: string): string
	{
		if (iconName == null)
			return null;
		let match = this.iconClassMatch(iconName);
		if (match)
			return match[1]+' fa-'+this.iconName(match[2]+match[3]);
		else
			return 'fad fa-'+this.iconName(iconName);
	}

	/**
	 * Matches an icon name against the RE '^(fa.)-([^\\s]+)(.*)' and returns the parts, or null if no match.
	 */
	iconClassMatch(iconName: string): RegExpExecArray
	{
		if (iconName == null)
			return null;
		return this._iconClassRE.exec(iconName);
	}

	/**
	 * Return a possibly replaced icon name. The name must be a plain name without style prefix.
	 * Used by iconClass.
	 * The default implementation returns the same name.
	 */
	iconName(iconName: string): string
	{
		return iconName;
	}

	/**
	 * Log the view name, the specified message and zero or more objects with level FINE.
	 */
	log(message: string, ...objects: any[]): void
	{
		if (this.doi.log.isLoggable(DoiLogLevel.FINE))
			this.doi.log.fine(this.name+': '+message, ...objects);
	}

	/**
	 * Test if this action target is displayed on a mobile device.
	 * Returns true if the navigator platform does not contain "Linux", "X11", "Win" or "Mac", or if it contains "Android" or "CrOS".
	 */
	mobileDevice(): boolean
	{
		return navigator.platform.indexOf('Linux') == -1
			&& navigator.platform.indexOf('X11') == -1
			&& navigator.platform.indexOf('Win') == -1
			&& navigator.platform.indexOf('Mac') == -1
			|| navigator.platform.indexOf('CrOS') != -1
			|| navigator.platform.indexOf('Android') != -1;
	}

	/**
	 * Open a modal dialog and return an observable for capturing the result.
	 */
	modalDialog(type: DoiModalType, title: string, body: string, options?: NgbModalOptions, content?: any, data?: any): Observable<DoiModalResult>
	{
		if (this._modalObservable)
			return EMPTY;

		if (!content)
			content = DoiModalDialog;

		let modalOptions: NgbModalOptions = {
			centered: true,
			beforeDismiss: () => {
				this._modalObservable = null;
				return true;
			}
		};
		if (options)
			Object.assign(modalOptions, options);

		let modalRef: NgbModalRef = this.doi.modal.open(content, modalOptions);

		modalRef.componentInstance.type = type;
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.body = body;
		if (data)
			modalRef.componentInstance.data = data;

		this._modalObservable = observableFrom(modalRef.result).pipe(
			tap((r) => {
				this._modalObservable = null;
			})
		);

		return this._modalObservable;
	}

	/**
	 * Open a modal dialog and return an observable for capturing the result.
	 */
	modalDialogOkCancel(title: string, body: string): Observable<boolean>
	{
		return this.modalDialog(DoiModalType.OK_CANCEL, title, body).pipe(
			switchMap(
				(result) => {
					return of(result == DoiModalResult.OK);
				}
			)
		);
	}

	/**
	 * Open a modal dialog and return an observable for capturing the result.
	 */
	modalDialogYesNo(title: string, body: string): Observable<boolean>
	{
		return this.modalDialog(DoiModalType.YES_NO, title, body).pipe(
			switchMap(
				(result) => {
					return of(result == DoiModalResult.YES);
				}
			)
		);
	}

	/**
	 * Open a modal dialog and return an observable for capturing the result.
	 */
	modalDialogYesNoCancel(title: string, body: string): Observable<boolean>
	{
		return this.modalDialog(DoiModalType.YES_NO_CANCEL, title, body).pipe(
			switchMap(
				(result) => {
					return of(result != null ? result == DoiModalResult.YES : null);
				}
			)
		);
	}

	/**
	 * Invoked by an object view to indicate that an object is visible.
	 * The default implementation does nothing.
	 */
	objectVisible(object: DoiObject, subviewName?: string): void
	{
	}

	/**
	 * Invoked by an object view when an object has been refreshed.
	 * The default implementation does nothing.
	 */
	objectRefreshed(object: DoiObject): void
	{
		this.log('objectRefreshed', object.objectRefPath());
	}

	/**
	 * Create a path (URL commands) for navigating to the specified object, and optionally pass path options.
	 * The default implementation create an array with the path "objectType/id", where "objectType" is the lower case object type name.
	 * If a subview name is specified without an outlet name, the subview name is appended to the path.
	 * @param objectType The symbolic object type name, e g 'Issue'.
	 * @param objectID The object ID.
	 * @param subviewName The subview name, or null, e g 'general'.
	 * @param options Path options, or null.
	 * @param outlet The outlet name for the subview, or null.
	 */
	objectPath(objectType: string, objectID: number, subviewName?: string, options?: any, outlet?: string): any[]
	{
		let path:any[] = ['/'+objectType.toLowerCase(), objectID];
		if (subviewName && !outlet)
			path.push(subviewName);
		if (options)
			path.push(options);
		if (subviewName && outlet) {
			let outlets: any = {};
			outlets[outlet] = [subviewName];
			path.push({outlets: outlets});
		}
		return path;
	}

	/**
	 * Open the specified object. Invokes objectPath and navigates.
	 * @param objectType The symbolic object type name, e g 'Issue'.
	 * @param objectID The object ID, or 0 for a new object.
	 * @param subviewName The subview name, or null, e g 'general'.
	 * @param options Path options, or null.
	 * @param outletName The outlet name for the subview, or null.
	 */
	openObject(objectType: string, objectID: number, subviewName?: string, options?: any, outletName?: string)
	{
		let path = this.objectPath(objectType, objectID, subviewName, options, outletName);

		this.doi.router.navigate(path);
	}

	/**
	 * Return the icon name indicating a probe result.
	 */
	probedIconName(probe: boolean): string
	{
		if (probe === undefined)
			return 'fas-square doi-icon-muted';
		else if (probe)
			return 'fas-square';
		else
			return 'far-square';
	}

	/**
	 * Remember my settings in local storage.
	 */
	rememberSettings()
	{
		this.doi.rememberSettings();
		this.saveSettings();
	}

	/**
	 * Save settings in preferred storage.
	 */
	saveSettings()
	{
		this.storageSetItem('settings', this.settings);
	}


	/**
	 * Test if a search tools should be shown in the application toolbar. Used by applications that provide some global search function.
	 * Normally returns true but delegates to the active view, which may return false if it has its own search field.
	 */
	searchToolVisible()
	{
		if (this.activeView)
			return this.activeView.searchToolVisible();
		return true;
	}

	/**
	 *	Returns the servlet context part of the document location, e g "/doistudio" for "http://localhost:8080/doistudio/webui/…".
	 */
	urlContext(): string
	{
		return this.doi.urlContext();
	}

	/**
	 * Invoked when a URL has been navigated to. The default implementation sets the lastUrl property.
	 */
	urlNavigated(url: string)
	{
		this.lastUrl = url;
	}

	viewActivate(event: DoiTopView): void
	{
		this.activeView = event;
		this.activeView.attachAppView(this);
	}

	viewDeactivate(event: DoiView): void
	{
		this.activeView = null;
	}

	/**
	 * Invoked by an object view when it is visited or refreshed. Used to update last visited list and provide a value for a bookmark.
	 * Delegates to the DOI service.
	 * @param object The object.
	 * @param title The title to use for bookmarks.
	 * @param iconName Optional icon name.
	 * @param options Application defined options.
	 */
	visitingObject(object: DoiObject, options?: any)
	{
		this.doi.visitingObject(object);
	}

	/**
	 * Invoked by a view when it is visited or refreshed. Used to update last visited list and provide a value for a bookmark.
	 * Delegates to the DOI service.
	 * @param path The navigation path.
	 * @param title The title to use for bookmarks.
	 * @param iconName Optional icon name.
	 * @param options Application defined options.
	 */
	visitingPath(path: string, title: string, iconName?: string, options?: any)
	{
		this.doi.visitingPath(path, title, iconName);
	}

	private _texts = new Map<string, string>();

	/**
	 * Return the text for the specified key.
	 */
	t(key: string): string
	{
		let text = this._texts.get(key);
		if (text === undefined) {
			let texts = this.texts(key);
			texts.forEach((v, k, m) => {
				if (v === undefined)
					v = null;
				this._texts.set(k, v);
				if (k == key)
					text = v;
			});
		}

		return text;
	}

	/**
	 * Return a map of text keys to texts. The map returned should at least contain the key specified but more texts may be fetched at the same time.
	 */
	texts(key: string): Map<string, string>
	{
		return new Map([[ key, this.text(key) ]]);
	}

	/**
	 * Return the text for the specified key. The default implementation returns the key.
	 */
	text(key: string): string
	{
		return key;
	}
}
