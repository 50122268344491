import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Router, NavigationEnd} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {from as observableFrom, throwError, Observable, of} from 'rxjs';
import {map, switchMap, tap} from "rxjs/operators";

import {DoiAlertService} from './DoiAlertService';
import {DoiAuthService} from './DoiAuthService';
import {DoiBrokerService} from './DoiBrokerService';
import {DoiBookmark} from '../bookmark/DoiBookmark';
import {DoiBookmarkList} from '../bookmark/DoiBookmarkList';
import {DoiObjectBookmark} from '../bookmark/DoiObjectBookmark';
import {DoiLogService} from './DoiLogService';
import {DoiObject} from './DoiObject';
import {DoiObjectCache} from './DoiObjectCache';
import {DoiModalDialog} from '../view/DoiModalDialog';
import {DoiModalResult} from '../view/DoiModalDialog';
import {DoiModalType} from '../view/DoiModalDialog';
import {DoiRedirectTarget} from '../view/DoiRedirectGuard';
import {DoiStorageService} from './DoiStorageService';


@Injectable({
	providedIn: 'root',
})
export class DoiService
{
	log: DoiLogService;
	alert: DoiAlertService;
	auth: DoiAuthService;
	storage: DoiStorageService;
	redirect: DoiRedirectTarget;
	sanitizer: DomSanitizer;

	/**
	 * The router.
	 */
	router: Router;

	/**
	 * The last navigated URL.
	 */
	lastUrl: string;

	/**
	 * The HTTP client.
	 */
	http: HttpClient;

	/**
	 * The Modal service.
	 */
	modal: NgbModal;

	/**
	 * The locale. Initially undefined.
	 */
	locale: string;

	/**
	 * The PWA Install event.
	 */
	private _pwaInstallEvent: any;

	/**
	 * The bookmark list.
	 */
	private _bookmarkList: DoiBookmarkList;

	/**
	 * The recently visited list.
	 */
	private _visitedList: DoiBookmarkList;

	/**
	 * Broker services.
	 */
	private _brokerServices = new Map<string, DoiBrokerService<any>>();

	/**
	 * Object caches.
	 */
	private _objectCaches = new Map<string, DoiObjectCache<any>>();

	/**
	 * Text area for HTML entity decoding.
	 */
	private decoderTextArea: HTMLTextAreaElement;

	/**
	 * Construct a new DOI service.
	 */
	constructor(
		log: DoiLogService, alert: DoiAlertService, auth: DoiAuthService, storage: DoiStorageService, redirect: DoiRedirectTarget,
		router: Router, http: HttpClient, modal: NgbModal, sanitizer: DomSanitizer)
	{
		this.log = log;
		this.alert = alert;
		this.auth = auth;
		this.storage = storage;
		this.redirect = redirect;
		this.router = router;
		this.http = http;
		this.modal = modal;
		this.sanitizer = sanitizer;

		router.events.subscribe(event =>
		{
			if (event instanceof NavigationEnd) {
				this.lastUrl = event.url;
			}
		});

		window.addEventListener('beforeinstallprompt', (event) => {
			this._pwaInstallEvent = event;
		});

		this.decoderTextArea = document.createElement('textarea');
	}

	/**
	 * Return the specified broker service, or undefined.
	 */
	brokerService(name: string): DoiBrokerService<any>
	{
		return this._brokerServices.get(name);
	}

	/**
	 * Add broker services. Normaly invoked by the application view consructor.
	 */
	brokerServices(...brokerServices: DoiBrokerService<any>[])
	{
		for (let bs of brokerServices) {
			this._brokerServices.set(bs.name, bs);
		}
	}

	/**
	 * Return the broker service names.
	 */
	brokerServiceNames(): IterableIterator<string>
	{
		return this._brokerServices.keys();
	}

	/**
	 * Forget my settings.
	 */
	forgetSettings()
	{
		this.storage.useLocalStorage(false);
		this.storage.removeAll(localStorage);
		this.storage.removeAll(sessionStorage);
		this._bookmarkList = null;
		this._visitedList = null;
	}

	/**
	 * Handle an HTTP error.
	 */
	handleError(error: HttpErrorResponse): Observable<any>
	{
		this.showError(error);
		return throwError(error);
	}

	/**
	 * Ensure that HTTP options contains HTTP parameters and append the specified parameter.
	 */
	static httpOptionsWithParam(options: any, name: string, value: string): any
	{
		if (!options)
			options = {};
		if (!options.params)
			options.params = new HttpParams().append(name, value);
		else
			options.params = options.params.append(name, value);
		return options;
	}

	/**
	 * Open a modal dialog and return an observable for capturing the result.
	 */
	modalDialog(type: DoiModalType, title: string, body: string): Observable<DoiModalResult>
	{
		let modalRef: NgbModalRef = this.modal.open(DoiModalDialog, { centered: true })

		modalRef.componentInstance.type = type;
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.body = body;

		return observableFrom(modalRef.result);
	}

	/**
	 * Open a modal dialog and return an observable for capturing the result.
	 */
	modalDialogOkCancel(title: string, body: string): Observable<boolean>
	{
		return this.modalDialog(DoiModalType.OK_CANCEL, title, body).pipe(
			switchMap(
				(result) => {
					return of(result == DoiModalResult.OK);
				}
			)
		);
	}

	/**
	 * Open a modal dialog and return an observable for capturing the result.
	 */
	modalDialogYesNo(title: string, body: string): Observable<boolean>
	{
		return this.modalDialog(DoiModalType.YES_NO, title, body).pipe(
			switchMap(
				(result) => {
					return of(result == DoiModalResult.YES);
				}
			)
		);
	}

	/**
	 * Open a modal dialog and return an observable for capturing the result.
	 */
	modalDialogYesNoCancel(title: string, body: string): Observable<boolean>
	{
		return this.modalDialog(DoiModalType.YES_NO_CANCEL, title, body).pipe(
			switchMap(
				(result) => {
					return of(result != null ? result == DoiModalResult.YES : null);
				}
			)
		);
	}

	/**
	 * Remember my settings in local storage.
	 */
	rememberSettings()
	{
		this.storage.useLocalStorage(true);
		this.storage.setItem('remember', true);
	}

	/**
	 * Show an HTTP error.
	 */
	showError(error: HttpErrorResponse)
	{
		if (error instanceof HttpErrorResponse) {

			let exm = error.headers.get('ExceptionMsg');
			if (exm) {
				this.decoderTextArea.innerHTML = exm;
				this.alert.severe(this.decoderTextArea.value);
				return;
			}

			let friendlyUrl = this.auth.friendlyAuthErrorUrl();
			if (friendlyUrl)
				this.alert.warning('Du är inte behörig att se denna sida.'); // I18N
			else
				this.alert.errorResponse(error);

		} else {

			this.alert.severe(error);
		}
	}

	/**
	 * Builds a URL for an icon.
	 * @param iconName The icon name.
	 * @param iconSize The icon size. 16 if undefined.
	 * @returns The URL.
	 */
	iconURL(iconName: string, iconSize?: number)
	{
		if (!iconName)
			return null;
		if (iconSize === undefined)
			iconSize = 16;
		if (iconSize)
			return this.auth.urlContextPub() + '/icon/' + iconName + '/' + iconSize + '/'+ iconName + iconSize + '.png';
		else
			return this.auth.urlContextPub() + '/icon/' + iconName + '/0/' + iconName + '.svg';
	}

	/**
	 * Return the specified object cache. It is created if necessary.
	 */
	objectCache(name: string): DoiObjectCache<any>
	{
		let cache = this._objectCaches.get(name);
		if (!cache)
			this._objectCaches.set(name, cache = new DoiObjectCache<any>(name));
		return cache;
	}

	/**
	 * Test if PWA Install is available.
	 */
	pwaInstallCanPrompt(): boolean
	{
		return this._pwaInstallEvent && true;
	}

	/**
	 * Prompt for PWA Install.
	 */
	pwaInstallPrompt(): boolean
	{
		if (!this._pwaInstallEvent)
			return false;

		this._pwaInstallEvent.prompt();

		this._pwaInstallEvent.userChoice.then((choice) => {
			if (choice.outcome === 'accepted') {
				console.log('User accepted the A2HS prompt');
			} else {
				console.log('User dismissed the A2HS prompt');
			}
			this._pwaInstallEvent = null;
		});
	}

	/**
	 *	Returns the servlet context part of the document location, e g "/doistudio" for "http://localhost:8080/doistudio/webui/…".
	 */
	urlContext(): string
	{
		let contextRE = new RegExp('http(s)?://[^/]+(/[^/]+)/.*');
		let match = document.location.href.match(contextRE);
		return match ? match[2] : null;
	}

	/**
	 * Return the bookmark list.
	 */
	bookmarkList(refresh?: boolean): DoiBookmarkList
	{
		if (!this._bookmarkList || refresh) {
			this._bookmarkList = new DoiBookmarkList('bookmark');
			try {
				this.bookmarksLoad(this._bookmarkList);
			} catch (ex) {
				this.log.warning(ex);
			}
		}

		return this._bookmarkList;
	}

	/**
	 * Find and return a bookmark, or null.
	 * @param path The URL, usually the value of lastUrl.
	 */
	bookmarkGet(path: string): DoiBookmark
	{
		return this.bookmarkList().get(path);
	}

	/**
	 * Add a bookmark.
	 * @param bookmark The bookmark.
	 */
	bookmarkAdd(bookmark: DoiBookmark): void
	{
		this.bookmarkList().add(bookmark);

		this.storage.setItem(this._bookmarkList.name, this._bookmarkList.toJson());
	}

	/**
	 * Remove a bookmark.
	 * @param bookmark The bookmark.
	 */
	bookmarkRemove(bookmark: DoiBookmark): void
	{
		this.bookmarkList().remove(bookmark);

		this.storage.setItem(this._bookmarkList.name, this._bookmarkList.toJson());
	}

	/**
	 * Load the bookmark list from the preferred storage.
	 */
	bookmarksLoad(bookmarks: DoiBookmarkList)
	{
		bookmarks.load(this.storage.getItem(bookmarks.name));
	}

	/**
	 * Return the visited list.
	 */
	visitedList(): DoiBookmarkList
	{
		if (!this._visitedList) {
			this._visitedList = new DoiBookmarkList('visited', 10);
			try {
				this._visitedList.load(this.storage.getItem(this._visitedList.name));
			} catch (ex) {
				this.log.warning(ex);
			}
		}

		return this._visitedList;
	}

	/**
	 * Invoked by an object view when it is visited or refreshed. Used to update last visited list and provide a value for a bookmark.
	 * @param object The Object.
	 * @param title The title to use for bookmarks.
	 * @param iconName Optional icon name.
	 */
	visitingObject(object: DoiObject)
	{
		this.visitedList().add(DoiObjectBookmark.forObject(object));

		this.storage.setItem(this._visitedList.name, this._visitedList.toJson());
	}

	/**
	 * Invoked by a view when it is visited or refreshed. Used to update last visited list and provide a value for a bookmark.
	 * @param path The URL, usually the value of lastUrl.
	 * @param title The title to use for bookmarks.
	 * @param iconName Optional icon name.
	 */
	visitingPath(path: string, title: string, iconName?: string)
	{
		this.visitedList().add(new DoiBookmark(path, title, iconName));

		this.storage.setItem(this._visitedList.name, this._visitedList.toJson());
	}
}
