import {Component} from '@angular/core';
import {Observable, forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators/';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {ActivatedRoute} from '@angular/router';

import {ProcessStructurePart, ProcessStructureObject, ProcessStructureProcessObject} from '../model/ProcessStructureObject';

@Component({

	templateUrl: 'ProcessStructureObjectProcessesPanel.html'
})
export class ProcessStructureObjectProcessesPanel extends DoiObjectPanel<ProcessStructureObject>
{
	name = 'processes';
	processes: ProcessStructureProcessObject[];

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openProcess(process : ProcessStructureProcessObject)
	{
		this.doi.router.navigate(this.routerLinkProcess(process))
	}

	routerLinkProcess(process : ProcessStructureProcessObject)
	{
		return process.processID ? ['/process', process.processID] : null;
	}

	/**
	 * Return the icon class for the specified object.
	 */
	processIconClass()
	{
		return this.iconClass(ProcessStructureProcessObject.iconName);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessStructurePart.ProcessesTop),
		);
	}
}
