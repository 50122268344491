<div class="doi-subview doi-scroll">

	<form role="form">
		<div class="row">
			<div class="col-12 col-xl-11">
				<doi-textarea name = "Beskrivning" labelEdit="Volymanmärkning" size="60" [(ngModel)]="object.title" [edit]="editing()" [ngModelOptions]="{standalone: true}"></doi-textarea>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field type="string" name="Tid" label="Tid" size="60" [(ngModel)]="object.date"  [edit]="editing()"></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field type="string" name="TidFran" label="Tid från" size="60" [(ngModel)]="object.dateBegin"  [edit]="editing()"></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field type="string" name="TidTill" label="Tid till" size="60" [(ngModel)]="object.dateEnd" [edit]="editing()"></doi-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<doi-field type="string" name="Plats" label="Plats" size="60" [(ngModel)]="object.location"  [edit]="editing()"></doi-field>
			</div>
		</div>

		<h3 *ngIf="docStoreLinks?.length">Dokumentlager</h3>
		<docstore-links [docStoreLinks]="docStoreLinks"></docstore-links>

		<h3 *ngIf="object.objectFiles?.length">Referenser</h3>
		<object-files [objectFiles]="object.objectFiles" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files>

		<h3 *ngIf="object.mediaTypes && object.mediaTypes.length">Media</h3>
		<div class="row" *ngFor="let mediaType of object.mediaTypes">
			<div class="col-12">{{mediaType.extent}} {{mediaType.name}}</div>
		</div>
	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
