import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {ProcessActTypePart, ProcessActTypeObject, ConfidentialTimeUnit, CullPeriodTimeUnit} from '../model/ProcessActTypeObject';
import {PropertyValue} from '../../klara/model/PropertyValue';
import {KlaraObjectPanel, ObjectFile, PropertyValueList, UnitValueReader, Unit} from '../../klara/KlaraModule';


@Component({
	templateUrl: 'ProcessActTypeObjectDetailPanel.html'
})
export class ProcessActTypeObjectDetailPanel extends KlaraObjectPanel<ProcessActTypeObject>
{
	name = 'detail';

	propertyValueList = new PropertyValueList(false);

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openProcessActType(processActTypeID : number)
	{
		this.appView.openObject('ProcessActType', processActTypeID);
	}

	/**
	 * Return the text for the specified key.
	 */
	ctuText(): string
	{
		if (!this.object)
			return null;

		switch (this.object.confidentialTimeUnit) {
			case ConfidentialTimeUnit.Days:	return this.object.confidentialAmount + ' dagar';
			case ConfidentialTimeUnit.Months:	return this.object.confidentialAmount + ' månader';
			case ConfidentialTimeUnit.Years:	return this.object.confidentialAmount + ' år';
		}

	}

	/**
	* Fetch property values (non handling). No merge with owning process.
	*/
	propertyValues() : PropertyValue[]
	{

		if (!this.object || !this.object.propertyValues || !this.object.process)
			return PropertyValueList.empty;

		return this.propertyValueList.propertyValues(this.object.propertyValues, null, this.editing(), this.editingAsUser());

	}

	/**
	 * Return the text for the specified key.
	 */
	cpuText(): string
	{
		if (!this.object)
			return null;

		switch (this.object.cullPeriodTimeUnit) {
			case CullPeriodTimeUnit.Days:	return this.object.cullPeriodAmount + ' dagar';
			case CullPeriodTimeUnit.Months:	return this.object.cullPeriodAmount + ' månader';
			case CullPeriodTimeUnit.Years:	return this.object.cullPeriodAmount + ' år';
		}
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessActTypePart.General),
			this.refreshObjectPart(ProcessActTypePart.AltNames),
			this.refreshObjectPart(ProcessActTypePart.Description),
			this.refreshObjectPart(ProcessActTypePart.PropertyValues),
		);
	}
}

// rcT9DIxhYj6XC14VqboZUpIT5aA=
