import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {switchMap} from "rxjs/operators";

import {DoiService, DoiBrokerService} from '../../doi/DoiModule';

import {DocStoreLink, KlaraBrokerService} from '../../klara/KlaraModule';
import {ArchivePart, ArchiveObject} from './ArchiveObject';
import {ArchiveOrigService, ArchiveOrigObject, ArchiveOrigPart} from '../../archiveorig/ArchiveOrigModule';

/**
 * Manages Archive objects.
 */
@Injectable()
export class ArchiveService extends KlaraBrokerService<ArchiveObject>
{
	/**
	 * Construct a new Archive service.
	 */
	constructor(doi: DoiService, private archiveOrigService: ArchiveOrigService)
	{
		super(doi, 'Archive')
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return ArchiveObject.iconName;
	}

	/**
	 * Create a new Archive object.
	 */
	newObject(objectID: number): ArchiveObject
	{
		return new ArchiveObject(objectID);
	}

	/**
	 * Create and return an observable that fetches the parent structure.
	 * This implementation returns an observable that fetches the ArchiveOrig, connects it to the Archive
	 * and then switches to an ArchiveOrigService.parentObjectObservable.
	 */
	parentObjectObservable(archive: ArchiveObject): Observable<any>
	{
		return this.archiveOrigService.readObjectPart(archive.archiveOrigID, ArchiveOrigPart.Label, archive.archiveOrig).pipe(
			switchMap((archiveOrig: ArchiveOrigObject) => {
				archive.archiveOrig = archiveOrig;
				return this.archiveOrigService.parentObjectObservable(archiveOrig);
			})
		);
	}

	/**
	 * Read an object part.
	 */
	readObjectPart(id: number, partName: string, object?: ArchiveObject): Observable<ArchiveObject>
	{
		let options = undefined;
		switch (partName) {
			case ArchivePart.Label:
				options = {
					params: new HttpParams().append('related', 'Type').append('related', 'Orig')
				};
				break;
			case ArchivePart.General:
				options = {
					params: new HttpParams().append('related', 'Type').append('related', 'Orig').append('related', 'ArchiveInst')
				};
				break;
			case ArchivePart.Depots:
				options = {
					params: new HttpParams().append('related', 'Depot')
				};
				break;
			case ArchivePart.Extents:
				options = {
					params: new HttpParams().append('related', 'ExtentUnit')
				};
				break;
			case ArchivePart.Subjects:
				options = {
					params: new HttpParams().append('related', 'SubjectType')
				};
				break;
			case ArchivePart.Acts:
				options = {
					params: new HttpParams().append('related', 'ActType')
				};
				break;

		}

		return super.readObjectPart(id, partName, object, options);
	}

	/**
	 * Read search result entries.
	 */
	readSearchResultEntries(objectIDs?: number[]): Observable<ArchiveObject[]>
	{
		let params = new HttpParams();

		params = params
			.append('col', 'Archive_ArchiveID')
			.append('col', 'Archive_ArchiveOrigID')
			.append('col', 'Archive_Name')
			.append('col', 'Archive_Notes');

		for (let objectID of objectIDs) {
			params = params.append('id', objectID.toString());
		}

		return this.readObjectSelection(params, null);
	}
}

// 64CHPNapDD7U3PIqileJdmGBiLU=
