import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

import {DoiIconMapper, DoiView} from '../../doi/DoiModule';

import {PropertyValue} from '../model/PropertyValue';

/*
 * A property value tool for reverting to the default value.
 */
@Component({
	selector: 'property-value-revert',
	template: `
		<a href="" title="Återställ till standardvärde" (click)="valueRevert()">
			<i [ngClass]="iconClass('undo')"></i>
		</a>
	`,
	host: { 'class' : 'property-value-revert' },
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class PropertyValueRevertComponent
{
	/**
	 * The containing view.
	 */
	@Input()
	view: DoiView;

	/**
	 * The property value.
	 */
	@Input()
	propertyValue: PropertyValue;

	/**
	 * Value change emitter.
	 */
	@Output()
	change = new EventEmitter<any>();

	/**
	 * Construct an icon class for the specified icon name.
	 */
	iconClass(iconName : string)
	{
		if (!iconName)
			return null;

		return this.view.iconClass(iconName);
	}

	/**
	 * Invoked when the revert tool is clicked.
	 */
	valueRevert()
	{
		this.view.appView.modalDialogYesNo(this.propertyValue.label, 'Återställ till standardvärde?')
			.subscribe((yes) => {
				if (yes) {
					this.propertyValue.value = this.propertyValue.valueDefault;
					this.propertyValue.used = false;
					this.change.next(this.propertyValue.value);
				}
			});
	}
}
