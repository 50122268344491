<div class="doi-view doi-object-view series-object-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start">
			<li doi-breadcrumb link="/archiveorigs" text="Struktur"></li>
			<li doi-breadcrumb *ngIf="routerLinkArchiveOrig()" [text]="routerLinkArchiveOrigTitle()" [link]="routerLinkArchiveOrig()"></li>
			<li doi-breadcrumb *ngIf="routerLinkArchive()" [text]="routerLinkArchiveTitle()" [link]="routerLinkArchive()"></li>
			<li doi-breadcrumb *ngIf="routerLinkSeriesGroup()" [text]="routerLinkSeriesGroupTitle()" [link]="routerLinkSeriesGroup()"></li>
		</ol>
		<ul doi-toolbar>
			<doi-object-edit-tools [objectView]="this"></doi-object-edit-tools>
		</ul>
		<doi-title>Serie {{object.isNew() && !object.objectID ? '(ny)' : object.objectTitle()}}</doi-title> <!-- I18N -->
	</doi-view-title>

	<ul doi-view-tabs [iconMapper]="this">
		<li doi-view-tab name="general" [iconName]="probedIconName('general')" #focusFirst>Allmänt</li>
		<li doi-view-tab name="volumes" [iconName]="probedIconName('volumes')">Volymer</li>
		<li doi-view-tab name="acttypes" [iconName]="probedIconName('acttypes')">Förvarar</li>
	</ul>

	<router-outlet (activate)="tabActivate($event)" (deactivate)="tabDeactivate($event)"></router-outlet>

</div>

<!-- TLskSEDsnvkMhkSltWKLbduzZUg= -->
