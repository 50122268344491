import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {environment} from '../../environments/environment';

import {DoiModule, DoiObjectEditGuard, DoiRedirectGuard} from '../doi/DoiModule';
import {KlaraModule} from '../klara/KlaraModule';
import {ProcessActTypeService} from './model/ProcessActTypeService';
import {ProcessActTypeObjectView} from './view/ProcessActTypeObjectView';
import {ProcessActTypeObjectDialogView} from './view/ProcessActTypeObjectDialogView';
import {ProcessActTypeObjectGeneralPanel} from './view/ProcessActTypeObjectGeneralPanel';
import {ProcessActTypeObjectGeneralDialogPanel} from './view/ProcessActTypeObjectGeneralDialogPanel';
import {ProcessActTypeObjectUnitsPanel} from './view/ProcessActTypeObjectUnitsPanel';
import {ProcessActTypeObjectDetailPanel} from './view/ProcessActTypeObjectDetailPanel';

export * from './model/ProcessActTypeObject';
export * from './model/ProcessActTypeService';

export const processActTypeRoutes: Routes = [
	{path: 'processacttype/:id', component: environment.dialog ? ProcessActTypeObjectDialogView : ProcessActTypeObjectView, canActivate: [DoiRedirectGuard], canDeactivate: [DoiObjectEditGuard], data: { defaultChild: 'general' }, children: [
		{path: 'general', component: environment.dialog ? ProcessActTypeObjectGeneralDialogPanel : ProcessActTypeObjectGeneralPanel, data: {title: 'Allmänt'}},
		{path: 'detail', component: ProcessActTypeObjectDetailPanel, data: {title: 'Detaljer'}},
		{path: 'units', component: ProcessActTypeObjectUnitsPanel, data: {title: 'Förvaras i'}},
	]},
];

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(processActTypeRoutes),
		DoiModule,KlaraModule
	],
	declarations: [
		ProcessActTypeObjectView, ProcessActTypeObjectDialogView, ProcessActTypeObjectGeneralPanel, ProcessActTypeObjectGeneralDialogPanel, ProcessActTypeObjectUnitsPanel, ProcessActTypeObjectDetailPanel,
	],
	exports: [
	],
	providers: [
		ProcessActTypeService,
	],

})
export class ProcessActTypeModule
{
}

// hPXEvbZ/6cgdCbGIwenEyH21uYI=
