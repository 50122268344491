import {Component, Input} from '@angular/core';

import {DoiIconMapper} from '../../doi/DoiModule';

import {DocStoreLink} from '../model/DocStoreLink';

/*
 * A document store link component.
 */
@Component({
	selector: 'docstore-link',
	template: `
		<a [attr.href]="docStoreLink.URL" target="_blank"><i [class]="iconClass()"></i>{{docStoreLink.name}}</a>
	`,
	styles: [
		'i { min-width: 1em; text-align: center; margin-right: 0.4em; }',
		'a { white-space: nowrap; }'
	]
})
export class DocStoreLinkComponent
{
	/**
	 * The object files.
	 */
	@Input()
	docStoreLink: DocStoreLink;

	/**
	 * Optional icon mapper.
	 */
	@Input()
	iconMapper: DoiIconMapper;

	/**
	 * The icon name.
	 */
	iconName: string = 'external-link';

	/**
	 * Return the icon class for the file.
	 */
	iconClass(): string
	{
		if (this.iconMapper)
			return this.iconMapper.iconClass(this.iconName);
		else
			return "fad fa-"+this.iconName;
	}
}
