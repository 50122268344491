import {Component} from '@angular/core';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {ActivatedRoute} from '@angular/router';

import {ProcessPart, ProcessObject, ProcessActType} from '../model/ProcessObject';

@Component({

	templateUrl: 'ProcessObjectActTypesPanel.html'
})
export class ProcessObjectActTypesPanel extends DoiObjectPanel<ProcessObject>
{
	name = 'acttypes';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	routerLinkActtype(acttype : ProcessActType): any[]
	{
		return [ '/processacttype', acttype.processActTypeID ];
	}

	openActtype(acttype : ProcessActType)
	{
		this.doi.router.navigate(this.routerLinkActtype(acttype));
	}

	/**
	 * Return the icon class for the specified object.
	 */
	processActTypeIconClass()
	{
		return this.iconClass(ProcessActType.iconName);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessPart.ActTypes));
	}

}
