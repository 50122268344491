import { Component, Input, Output, EventEmitter, forwardRef, HostBinding, Directive } from '@angular/core';
import {DatePipe} from '@angular/common';
import {FormControl} from '@angular/forms';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable, of} from 'rxjs';
import {tap} from "rxjs/operators";

import {DoiValueComponent} from './DoiValueComponent';

@Directive()
export abstract class DoiCheckValueComponent extends DoiValueComponent
{
	/**
	 * Indicates if the component is visibly checked even if the value is falsy.
	 */
	_implied: boolean = false;

	/**
	 * The checked state, normally corresponding to the component value unless an implied value is used.
	 */
	get checked(): boolean
	{
		return this.implied || this.value;
	}

	/**
	 * The implied value, used as the checked state if the value is falsy.
	 */
	@Input()
	get implied(): boolean
	{
		return this._implied;
	}

	set implied(implied: boolean)
	{
		this._implied = implied;
	}

	@HostBinding('class.doi-checked-implied')
	get impliedClass(): boolean
	{
		return this.checked && this.implied;
	}

	toggle()
	{
		if (!this.readonly)
			this.value = !this.value;
	}
}

/**
 * A checkbox component.
 */
@Component({
	selector: 'doi-checkbox',
	host: { 'class' : 'doi-checkbox' },
	template: `
		<div [ngClass]="{'doi-editing':edit, 'doi-not-editing':!edit, 'doi-checked': checked, 'doi-unchecked': !checked}">
			<a href="" (click)="toggle()" (blur)="focusLost($event)" [title]="tooltip">
				<span class="doi-checkbox-box fas fa-check"></span>
				<label class="doi-checkbox-label form-check-label"><ng-content></ng-content></label>
			</a>
		</div>
	`,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DoiCheckBoxComponent),
		multi: true
	}]
})
export class DoiCheckBoxComponent extends DoiCheckValueComponent
{
	/**
	 * Return the displayed value text.
	 */
	valueText(): any
	{
		return this.label;
	}
}

/**
 * A checkbox field component.
 */
@Component({
	selector: 'doi-checkfield',
	host: { 'class' : 'doi-checkfield' },
	template: `
		<div class="form-group" [ngClass]="{'doi-editing':edit, 'doi-not-editing':!edit, 'doi-checked': checked, 'doi-unchecked': !checked}">
			<label *ngIf="label" class="doi-label">{{label}}<br/></label>
			<a *ngIf="edit" class="form-control" href="" (click)="toggle()" (blur)="focusLost($event)">
				<span class="doi-checkbox-box fas fa-check"></span>
			</a>
			<span *ngIf="!edit" ngbTooltip="{{tooltip}}" placement="bottom"><i *ngIf="checked" class="fas fa-check"></i></span>
		</div>
	`,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DoiCheckFieldComponent),
		multi: true
	}]
})
export class DoiCheckFieldComponent extends DoiCheckValueComponent
{
	/**
	 * Return the displayed value text.
	 */
	valueText(): any
	{
		return this.label;
	}
}

