import {Component} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {catchError, map, tap} from "rxjs/operators";

import {DoiService, DoiAction, DoiSelectionView, DoiSelectionCriteria, DoiViewMode} from '../../doi/DoiModule';

import {ArchiveOrigObject} from '../model/ArchiveOrigObject';
import {ArchiveOrigService} from '../model/ArchiveOrigService';

@Component({
	templateUrl: 'ArchiveOrigSelectionView.html'
})
export class ArchiveOrigSelectionView extends DoiSelectionView<ArchiveOrigObject>
{
	name = 'ArchiveOrigSelectionView';

	searchText: string;
	filter: string = '';
	inactive: boolean;

	archiveOrigs: ArchiveOrigObject[];

	constructor(doi: DoiService, private archiveOrigService: ArchiveOrigService, route: ActivatedRoute)
	{
		super(doi, archiveOrigService, route);

		this.actions.add(
			new DoiAction(this, 'Search', 'search', 'Sök')
				.executeHandler(() => this.actionSearch()));
	}

	ngOnInit()
	{
		super.ngOnInit();

		this.refresh();
	}

	ngOnDestroy()
	{
		super.ngOnDestroy();
	}

	actionSearch()
	{
		this.doi.router.navigate(['/search'], {queryParams: {returnUrl: this.appView.lastUrl, text: this.searchText}});
	}

	/**
	 * Return the icon class for the specified object.
	 */
	archiveOrigIconClass(archiveOrig: ArchiveOrigObject)
	{
		return this.iconClass(archiveOrig.iconName);
	}

	/**
	 * Return a text representing the specified object, used for filtering the grid view.
	 */
	filterGridText(archiveOrig: ArchiveOrigObject): string
	{
		return archiveOrig.name;
	}

	/**
	 * Return a text representing the specified object, used for filtering the table view.
	 */
	filterTableText(archiveOrig: ArchiveOrigObject): string
	{
		return archiveOrig.name+' '+archiveOrig.existFrom+' '+archiveOrig.existTo;
	}

	/**
	 * Open the specified object.
	 */
	openObject(archiveOrig: ArchiveOrigObject)
	{
		this.appView.openObject('ArchiveOrig', archiveOrig.objectID);
	}


	routerLinkArchiveOrig(archiveOrig : ArchiveOrigObject)
	{
		return ['/archiveorig', archiveOrig.objectID];
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * Overridden to refresh object parts presented by the object view itself.
	 */
	refreshView(): Observable<any>
	{
		let criteria = new DoiSelectionCriteria()
			.select('ArchiveOrig_ArchiveOrigID', 'ArchiveOrig_Name', 'ArchiveOrig_ExistFrom', 'ArchiveOrig_ExistTo')
			.where('ArchiveOrig_ParentArchiveOrigID', '=')
			.orderBy('ArchiveOrig_Name', 'ArchiveOrig_ArchiveOrigID');

		return this.archiveOrigService.readObjectSelection(null, criteria).pipe(
			tap((archiveOrigs: ArchiveOrigObject[]) => this.archiveOrigs = archiveOrigs)
		);
	}

	/**
	 * Test if the specified view mode is supported.
	 */
	viewModeSupported(viewMode: DoiViewMode): boolean
	{
		switch (viewMode) {
			case DoiViewMode.Grid:
			case DoiViewMode.Table:
				return true;
		}
		return false;
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Struktur'; // I18N
	}
}

// dyS8mv0g5UA4fiBeGAl1yK23eAM=
