<div class="doi-view doi-object-view process-object-view" [ngClass]="object?.processTypeName">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start" [tailImplied]="true">
			<li doi-breadcrumb link="/archivorigs" text="Struktur"></li>
			<li doi-breadcrumb [text]="object?.processStructure?.archiveOrig?.objectTitle()" [link]="routerLinkArchiveOrig()"></li>
			<li doi-breadcrumb [text]="object?.processStructure?.objectTitle()" [link]="routerLinkProcessStructure()"></li>
			<li *ngIf="object?.parent?.parent" doi-breadcrumb [text]="object?.parent?.parent?.objectName()" [link]="routerLinkParentProcess()"></li>
			<li *ngIf="object?.parent" doi-breadcrumb [text]="object?.parent?.objectName()" [link]="routerLinkParentProcess()"></li>
		</ol>
		<ul doi-toolbar>
			<doi-object-edit-tools [objectView]="this"></doi-object-edit-tools>
		</ul>
		<doi-title><span class="title-object-type">{{object?.processTypeName}}</span><span class="title-object-name">{{object?.objectTitle()}} </span></doi-title>
	</doi-view-title>

	<ul doi-view-tabs [iconMapper]="this">
		<li doi-view-tab name="general" [iconName]="probedIconName('general')" #focusFirst>Allmänt</li>
		<li doi-view-tab name="details" [iconName]="probedIconName('details')">Detaljer</li>
		<li doi-view-tab name="actkind" [iconName]="probedIconName('actkind')">Handlingsslag</li>
		<li doi-view-tab name="acttypes" [iconName]="probedIconName('acttypes')">Handlingstyper</li>
		<li doi-view-tab name="activities" [iconName]="probedIconName('activities')">Aktiviteter</li>
		<li doi-view-tab name="units" [iconName]="probedIconName('units')">Förvaras i</li>
		<li doi-view-tab name="diagram" [iconName]="probedIconName('diagram')">Diagram</li>
	</ul>

	<router-outlet (activate)="tabActivate($event)" (deactivate)="tabDeactivate($event)"></router-outlet>

</div>

<!-- 5PWD65q2c0Mu+qy1LaO48A5KlJQ= -->
