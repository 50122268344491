import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiModalResult, DoiModalType, DoiService, DoiTopView} from '../doi/DoiModule';
import {DoiThemeService, DoiTheme} from '../doi-theme/DoiThemeModule';

import {KlaraDialogTopView, KlaraTopView} from '../klara/KlaraModule';

import {AppBaseView} from '../AppBaseView';

@Component({
	templateUrl: 'SettingsView.html'
})

export class SettingsView extends KlaraTopView implements OnInit, KlaraDialogTopView
{
	themeService: DoiThemeService;
	themeList: DoiTheme[];

	debugMode: boolean;
	rememberSettings: boolean;

	constructor(doi: DoiService, themeService: DoiThemeService, route: ActivatedRoute,	private router: Router)
	{
		super(doi, route);

		this.themeService = themeService;
		this.formGroup = new FormGroup({});
	}

	/**
	 * Invoked when the view has been initialized. Fetches available themes.
	 */
	ngOnInit()
	{
		super.ngOnInit();

		this.refresh();
	}

	/**
	 * Test if debug mode is available. Currently uses the experimental feature.
	 */
	debugModeAvailable(): boolean
	{
		if (this.debugMode)
			return true;

		if (this.appView)
			return this.appView.hasFeatureExperimental();

		return false;
	}

	/**
	 * Invoked when the debug mode is changed. Propagates the setting to the application.
	 */
	debugModeChanged()
	{
		if (this.appView && this.debugModeAvailable())
			this.appView.actionDebugMode(this.debugMode);
	}

	/**
	 * Return the corresponding path in Klara Dialog, or an empty string for the same URL.
	 */
	dialogPath() : string
	{
		return '';
	}

	/**
	 * Forget my settings.
	 */
	forgetSettings()
	{
		return this.doi.modalDialogYesNo('Glöm sparade inställningar?',
			'Vill du att dina sparade inställningar och besökta sidor raderas från den här enheten?').
			subscribe(
				(result) => {
					if (result) {
						this.appView.forgetSettings();
					} else {
						this.rememberSettings = true;
					}
				}
			);
	}

	/**
	 * Forget me and log out.
	 */
	forgetSettingsAndLogOut()
	{
		return this.doi.modalDialogYesNo('Logga ut och glöm sparade inställningar?',
			'Vill du logga ut och radera dina sparade inställningar och besökta sidor från den här enheten?').
			subscribe(
				(result) => {
					if (result) {
						this.appView.actionLogout(true);
						this.appView.forgetSettings();
					} else {
						this.rememberSettings = true;
					}
				}
			);
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * Fetches available themes.
	 */
	refreshView(): Observable<any>
	{
		this.debugMode = this.appView ? this.appView.settings.debugMode : undefined;
		this.rememberSettings = this.doi.storage.usesLocalStorage();

		return this.themeService.fetchAvailable().pipe(
			tap(themeList => {
				this.themeList = new Array<DoiTheme>();
				for (let t of themeList) {
					this.themeList.push(t);
				}
			})
		);
	}

	/**
	 * Remember my settings in local storage.
	 */
	rememberSettingsChanged()
	{
		if (this.rememberSettings) {
			this.appView.rememberSettings();
		} else {
			this.forgetSettings();
		}
	}

	/**
	 * Test if the specified theme is the currently used and explicitly selected by the user.
	 */
	isSelectedTheme(theme: DoiTheme): boolean
	{
		return !this.themeService.themeImplied && this.themeService.theme && this.themeService.theme.name == theme.name;
	}

	/**
	 * Test if the specified theme is the currently used.
	 */
	isUsedTheme(theme: DoiTheme): boolean
	{
		return this.themeService.theme && this.themeService.theme.name == theme.name;
	}

	/**
	 * Select the specified theme. Selecting the currently selected theme switches to the default theme.
	 */
	selectTheme(theme: DoiTheme)
	{
		if (this.isSelectedTheme(theme)) {
			this.appView.settings.themeName = null;
			this.themeService.useTheme(this.themeList[0], true);
		} else {
			this.appView.settings.themeName = theme.name;
			this.themeService.useTheme(theme, false);
		}
		this.appView.saveSettings();
	}

	submit()
	{
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Inställningar'; // I18N
	}
}
