import {Component, Input, Output, EventEmitter} from '@angular/core';

import {DoiAction} from '../core/DoiAction';
import {DoiActionTarget} from '../core/DoiActionTarget';
import {DoiActionComponent} from './DoiActionComponent';

class DoiDropdownItemAction
{
	action: DoiAction;
	separator: boolean;

	constructor(action: DoiAction, separator?: boolean)
	{
		this.action = action;
		this.separator = separator ? true : false;
	}
}

/**
 * A dropdown menu component.
 */
@Component({
	selector: '[doi-dropdown-menu]',
	host: { 'class' : 'dropdown-menu' },
	template: `
		<ng-container *ngFor="let a of actions()">
			<li doi-dropdown-item [separator]="a.separator" [target]="target" [action]="a.action.name"></li>
		</ng-container>
		<ng-content></ng-content>
  `,
})
export class DoiDropdownMenuComponent
{
	/**
	 * The action target.
	 */
	@Input() target = <DoiActionTarget>undefined; // Workaround for Angular CLI bug #2034

	/**
	 * The action names. May include actions from inner views.
	 */
	@Input('actions')
	actionNames: string[];

	actions(): DoiDropdownItemAction[]
	{
		let actionList = new Array<DoiDropdownItemAction>();
		let separator = false;

		if (this.target && this.actionNames) {
			for (let actionName of this.actionNames) {
				if (actionName && actionName != '-') {
					let action = this.target.action(actionName);
					if (action && action.enabled)
						actionList.push(new DoiDropdownItemAction(action, separator));
					separator = false;
				} else {
					separator = true;
				}
			}
		}
		return actionList;
	}
}
