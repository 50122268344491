import {Component, Input, Output, EventEmitter} from '@angular/core';

import {DoiAction} from '../core/DoiAction';
import {DoiActionTarget} from '../core/DoiActionTarget';
import {DoiActionComponent} from './DoiActionComponent';

/**
 * A dropdown item component.
 */
@Component({
	selector: '[doi-dropdown-item]',
	host: { 'class' : 'doi-dropdown-item dropdown-item' },
	template: `
		<a href="" data-bs-target="" title="{{tooltip}}" (click)="onClick($event)">
			<span class="doi-dropdown-item-check"><span *ngIf="selected" class="fas fa-check"></span></span>
			<span class="doi-dropdown-item-icon" [ngClass]="iconClass()"></span>
			<span class="doi-dropdown-item-title">{{title}}</span>
		</a>
  `,
})
export class DoiDropdownItemComponent extends DoiActionComponent
{
}

