import {Observable, concat} from 'rxjs';
import {map} from "rxjs/operators";

import {environment} from '../../environments/environment';

import {DoiIconMapper, DoiObjectRef, DoiSelectionCriteria} from '../doi/DoiModule';
import {DoiNavNode, DoiNavNodeChildSupplier} from '../doi-navigator/DoiNavigatorModule';

import {ArchiveOrigService, ArchiveOrigObject} from '../archiveorig/ArchiveOrigModule';
import {ArchiveService, ArchiveObject} from '../archive/ArchiveModule';
import {ProcessStructureService, ProcessStructureObject} from '../processstructure/ProcessStructureModule';

/**
 * A navigator node child supplier for an archive originator node.
 */
export class ArchiveOrigNodeChildSupplier extends DoiNavNodeChildSupplier
{
	constructor(
		private archiveOrigService: ArchiveOrigService,
		private processStructureService: ProcessStructureService,
		private archiveService: ArchiveService,
		private processStructureNodeChildSupplier: DoiNavNodeChildSupplier,
		private archiveNodeChildSupplier: DoiNavNodeChildSupplier,
		)
	{
		super();
	}

	readChildren(node: DoiNavNode): Observable<DoiNavNode[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('ArchiveOrig_ArchiveOrigID', 'ArchiveOrig_ParentArchiveOrigID', 'ArchiveOrig_Sequence', 'ArchiveOrig_Name', 'ArchiveOrig_ForGrouping')
			.where('ArchiveOrig_ParentArchiveOrigID', '='+node.pathElement.objectID)
			.orderBy('ArchiveOrig_Sequence', 'ArchiveOrig_Name', 'ArchiveOrig_ArchiveOrigID');

		let archiveOrigObservable = this.archiveOrigService.readObjectSelection(null, criteria).pipe(
			map((archiveOrigs: ArchiveOrigObject[]) =>
			{
				let nodes = new Array<DoiNavNode>();
				for (let archiveOrig of archiveOrigs) {
					let objectRef = DoiObjectRef.forObject('ArchiveOrig', archiveOrig.objectID);
					nodes.push(
						new DoiNavNode(archiveOrig.name, '/archiveorig/'+archiveOrig.objectID)
							.setObjectRef(objectRef)
							.setIconName(archiveOrig.iconName)
							.setIconMapper(node.getIconMapper())
							.setChildNodeSupplier(this)
					);
				}
				return nodes;
			})
		);

		criteria = new DoiSelectionCriteria()
			.select('ProcessStructure_ProcStructID', 'ProcessStructure_Name')
			.where('ProcessStructure_ArchiveOrigID', '='+node.pathElement.objectID)
			.orderBy('ProcessStructure_Name', 'ProcessStructure_ProcStructID');

		let processStructureObservable = this.processStructureService.readObjectSelection(null, criteria).pipe(
			map((processStructures: ProcessStructureObject[]) =>
			{
				let nodes = new Array<DoiNavNode>();
				for (let processStructure of processStructures) {
					let objectRef = DoiObjectRef.forObject('ProcessStructure', processStructure.objectID);
					nodes.push(
						new DoiNavNode(processStructure.name, '/processstructure/'+processStructure.objectID)
							.setObjectRef(objectRef)
							.setIconName(processStructure.iconName)
							.setIconMapper(node.getIconMapper())
							.setChildNodeSupplier(this.processStructureNodeChildSupplier)
					);
				}
				return nodes;
			})
		);

		criteria = new DoiSelectionCriteria()
			.select('Archive_ArchiveID', 'Archive_Name', 'Archive_Sequence', 'Archive_ArchiveTypeID', 'ArchiveType_SignumTreeStrict')
			.where('Archive_ArchiveOrigID', '='+node.pathElement.objectID)
			.orderBy('Archive_Name', 'Archive_Sequence', 'Archive_ArchiveID');

		if (environment.dialog)
			return concat(archiveOrigObservable, processStructureObservable);

		let archiveObservable = this.archiveService.readObjectSelection(null, criteria).pipe(
			map((archives: ArchiveObject[]) =>
			{
				let nodes = new Array<DoiNavNode>();
				for (let archive of archives) {
					let objectRef = DoiObjectRef.forObject('Archive', archive.objectID);
					nodes.push(
						new DoiNavNode(archive.name, '/archive/'+archive.objectID)
							.setObjectRef(objectRef)
							.setIconName(archive.iconName)
							.setIconMapper(node.getIconMapper())
							.setChildNodeSupplier(this.archiveNodeChildSupplier)
							.setValue('signumTreeStrict', archive.signumTreeStrict)
							.setValue('archiveID', archive.objectID)
					);
				}
				return nodes;
			})
		);

		return concat(archiveOrigObservable, processStructureObservable, archiveObservable);
	}
}
