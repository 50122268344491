import {Component, EventEmitter, Input, Output, HostBinding} from '@angular/core';

import {DoiNavNode} from '../model/DoiNavNode';

/**
 * A navigator root component.
 */
@Component({
	selector: '[doi-nav-root]',
	host: { 'class' : 'doi-nav-root' },
	template: `
		<ng-container *ngIf="root.children">
			<li doi-nav-node *ngFor="let child of root.children" [node]="child" (select)="onSelect($event)"></li>
		</ng-container>
	`
})
export class DoiNavRootComponent
{
	@Input()
	root: DoiNavNode;

	@Output() select = new EventEmitter<DoiNavNode>();

	@HostBinding('class.doi-nav-expanded')
	get expanded()
	{
		return this.root && this.root.expanded;
	}

	@HostBinding('class.doi-nav-expanded-always')
	get expandedAlways()
	{
		return this.root && this.root.expandedAlways;
	}

	onSelect(node: DoiNavNode)
	{
		this.select.emit(node);
	}
}
