import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';

import {ArchivePart, ArchiveObject, ArchiveSeries} from '../model/ArchiveObject';


@Component({
	templateUrl: 'ArchiveObjectSeriesPanel.html'
})
export class ArchiveObjectSeriesPanel extends DoiObjectPanel<ArchiveObject>
{
	name = 'series';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openSeries(series : ArchiveSeries)
	{
		this.appView.openObject('Series', series.seriesID);
	}

	routerLinkSeries(series : ArchiveSeries)
	{
		return ['/series', series.seriesID];
	}

	/**
	 * Return the icon class for the specified object.
	 */
	seriesIconClass()
	{
		return this.iconClass(ArchiveSeries.iconName);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ArchivePart.Series),
		);
	}
}

// rS9PI84cysHrMnRkC1Y62Cfx+ZY=
