import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {ObjectFile} from '../../klara/KlaraModule';
import {DocStoreLink} from '../../klara/KlaraModule';

import {VolumePart, VolumeObject} from '../model/VolumeObject';
import {VolumeService} from '../../volume/model/VolumeService';

@Component({
	templateUrl: 'VolumeObjectGeneralPanel.html'
})
export class VolumeObjectGeneralPanel extends DoiObjectPanel<VolumeObject>
{
	name = 'general';
	docStoreLinks: DocStoreLink[];

	constructor(doi: DoiService, route: ActivatedRoute, private volumeService: VolumeService)
	{
		super(doi, route);
	}

	/**
	 * Download an object file.
	 */
	downloadObjectFile(objectFile: ObjectFile): void
	{
		this.volumeService.downloadObjectFile(objectFile);
	}

	openVolume(volumeID : number)
	{
		this.appView.openObject('Volume', volumeID);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(VolumePart.General),
			this.refreshObjectPart(VolumePart.ObjectFiles),
			this.refreshObjectPart(VolumePart.MediaTypes),
			this.volumeService.readDocumentStoreLinks(this.objectID).pipe(tap((links: DocStoreLink[]) => {this.docStoreLinks = links}))
		);
	}
}

// Z1XBnSNZUa3W4Ds54kQ65/zMrN0=
