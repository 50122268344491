import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';

import {DoiService, DoiObject, DoiSelectionCriteria, DoiObjectPanel} from '../../doi/DoiModule';

import {DocStoreLink, ObjectFile} from '../../klara/KlaraModule';

import {ProcessStructurePart, ProcessStructureObject} from '../../processstructure/model/ProcessStructureObject';

import {ArchiveOrigPart, ArchiveOrigObject, Child, ArchOrigProcStructObject} from '../model/ArchiveOrigObject';
import {ArchiveOrigService} from '../model/ArchiveOrigService';

@Component({
	templateUrl: 'ArchiveOrigObjectGeneralPanel.html'
})
export class ArchiveOrigObjectGeneralPanel extends DoiObjectPanel<ArchiveOrigObject>
{
	name = 'general';
	docStoreLinks: DocStoreLink[];
	children: ArchiveOrigObject[];
	archives: DoiObject[];

	constructor(doi: DoiService, route: ActivatedRoute, private archiveOrigService: ArchiveOrigService)
	{
		super(doi, route);
	}

	/**
	 * Download an object file.
	 */
	downloadObjectFile(objectFile: ObjectFile): void
	{
		this.archiveOrigService.downloadObjectFile(objectFile);
	}

	openArchiveOrig(archiveOrigID : number)
	{
		this.appView.openObject('ArchiveOrig', archiveOrigID);
	}

	routerLinkArchiveOrig(archOrig : Child)
	{
		return archOrig.archiveOrigID ? [ '/archiveorig', archOrig.archiveOrigID ] : null;
	}

	routerLinkProcessStructure(procStruct : ArchOrigProcStructObject)
	{
		return procStruct.processStructureID ? ['/processstructure', procStruct.processStructureID] : null;
	}

	/**
	 * Return the icon class for the specified object.
	 */
	archiveOrigIconClass()
	{
		return this.iconClass(ArchiveOrigObject.iconName);
	}

	/**
	 * Return the icon class for the specified object.
	 */
	processStructureIconClass()
	{
		return this.iconClass(ProcessStructureObject.iconName);
	}

	readChildren(): Observable<ArchiveOrigObject[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('ArchiveOrig_ArchiveOrigID', 'ArchiveOrig_ParentArchiveOrigID', 'ArchiveOrig_Name', 'ArchiveOrig_Code', 'ArchiveOrig_ForGrouping', 'ArchiveOrig_Sequence' )
			.where('ArchiveOrig_ParentArchiveOrigID', '='+this.objectID)
			.orderBy('ArchiveOrig_Sequence', 'ArchiveOrig_Name', 'ArchiveOrig_ParentArchiveOrigID')
			.limit(10);

		return this.service().readObjectSelection(null, criteria);
	}

	readArchives(): Observable<DoiObject[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Archive_ArchiveID', 'Archive_Name')
			.where('Archive_ArchiveOrigID', '='+this.objectID)
			.orderBy('Archive_Name')
			.limit(7);

		return this.doi.brokerService('Archive').readObjectSelection(null, criteria);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ArchiveOrigPart.General),
			this.refreshObjectPart(ArchiveOrigPart.Notes),
			this.refreshObjectPart(ArchiveOrigPart.PropertyValues),
			this.refreshObjectPart(ArchiveOrigPart.ObjectFiles),
			this.readChildren().pipe(tap((origs: ArchiveOrigObject[]) => {this.children = origs})),
			this.readArchives().pipe(tap((archives: DoiObject[]) => {this.archives = archives})),
			this.refreshObjectPart(ArchiveOrigPart.ProcessStructures),
			this.archiveOrigService.readDocumentStoreLinks(this.objectID).pipe(tap((links: DocStoreLink[]) => {this.docStoreLinks = links}))
		);
	}
}

// rS9PI84cysHrMnRkC1Y62Cfx+ZY=
