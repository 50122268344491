<div class="doi-subview">

	<div *ngIf="object?.volumes && object?.volumes?.length > 0" class="doi-scroll-container">
		<table class="table table-striped table-hover">
			<thead>
				<tr class="doi-clickable">
					<th class="doi-width-10">Beteckning</th>
					<th class="doi-width-15">Tid</th>
					<th class="doi-grow-2">Plats</th>
					<th class="doi-grow-4 doi-hidden-xs-down">Anmärkning</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let volume of object.volumes" (click)="openVolume(volume)" class="doi-clickable">
					<td class="doi-width-10"><a [routerLink]="routerLinkVolume(volume)">{{volume.volumeCode}}</a></td>
					<td class="doi-width-15"><a [routerLink]="routerLinkVolume(volume)">{{volume.date}}</a></td>
					<td class="doi-grow-2 doi-wrap">{{volume.location}}</td>
					<td class="doi-grow-4 doi-wrap doi-hidden-xs-down">{{volume.title}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
