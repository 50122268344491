import {Directive, HostBinding, Input} from '@angular/core';

/**
 * Apply the conditional hidden-class. 
 */
@Directive({
	selector: '[doi-hidden]'
})
export class DoiHiddenDirective
{
	@Input('doi-hidden') hidden: boolean;
	
	@HostBinding('class.doi-hidden')
	private get hiddenClass(): boolean
	{
		return this.hidden;
	}
}
