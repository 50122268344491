import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DoiService, DoiObjectPanel, DoiSelectionCriteria } from '../../doi/DoiModule';
import { ObjectFile, DocStoreLink } from '../../klara/KlaraModule';
import { SeriesObject } from '../../series/SeriesModule';
import { SeriesGroupPart, SeriesGroupObject } from '../model/SeriesGroupObject';
import { SeriesGroupService} from '../model/SeriesGroupService';

@Component({
	templateUrl: 'SeriesGroupObjectGeneralPanel.html'
})
export class SeriesGroupObjectGeneralPanel extends DoiObjectPanel<SeriesGroupObject>
{
	name = 'general';

	series: SeriesObject[];

	constructor(doi: DoiService, route: ActivatedRoute, private seriesGroupService: SeriesGroupService)
	{
		super(doi, route);
	}

	openSeriesGroup(seriesGroupID : number)
	{
		this.appView.openObject('SeriesGroup', seriesGroupID);
	}

	readSeries(): Observable<SeriesObject[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Series_SeriesID', 'Series_Signum', 'Series_Title')
			.where('Series_SeriesGroupID', '='+this.objectID)
			.orderBy('Series_Signum')
			.limit(20);

		return this.doi.brokerService('Series').readObjectSelection(null, criteria);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(SeriesGroupPart.General),
			this.refreshObjectPart(SeriesGroupPart.Label),
			this.readSeries().pipe(tap((series: SeriesObject[]) => {this.series = series})),
		);

	}
}

// MmdBQZcnOwOPzGJpwuwFNUe0Pbk=
