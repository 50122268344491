import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";

import {DoiAuthPrincipalService} from './DoiAuthPrincipalService';

/**
 * An HTTP interceptor that attaches authorization information to requests.
 */
@Injectable()
export class DoiAuthInterceptor implements HttpInterceptor
{
	/**
	 * Construct a new HTTP interceptor.
	 */
	constructor(private principalService: DoiAuthPrincipalService)
	{
	}

	/**
	 * Intercept a request.
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		request = this.principalService.authorizeRequest(request);
		return next.handle(request).pipe(
			catchError((error: any) => {
				if (error instanceof HttpErrorResponse)
					this.principalService.error(error, request.url);
				return throwError(error);
			})
		);
	}
}
