import {Component, Input, forwardRef, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {DoiValueComponent} from './DoiValueComponent';

/**
 * A text area component.
 */
@Component({
	selector: 'doi-textarea',
	host: { 'class' : 'doi-textarea' },
	template: `
		<div class="form-group doi-field" [ngClass]="{'doi-editing':edit, 'doi-not-editing':!edit}">
			<div class="doi-field-header">
				<label *ngIf="labelText()" class="doi-label" ngbTooltip="{{labelTooltip}}" [tooltipClass]="labelTooltipClass">{{labelText()}}</label>
				<ng-content></ng-content>
			</div>
			<div *ngIf="edit" class="doi-textarea-wrapper">
				<textarea #textarea class="form-control" [ngClass]="textareaClasses()" name="{{name}}" [rows]="textareaRows()" cols="{{size}}" [(ngModel)]="value" [readonly]="readonly" [required]="required" [attr.maxLength]="maxLength" (blur)="focusLost($event)"></textarea>
				<i *ngIf="autosize" class="fat fa-arrow-turn-down-left"></i>
			</div>
			<span *ngIf="!edit" style="{{color ? 'color:'+color+';':''}}" ngbTooltip="{{tooltip}}" placement="bottom">{{valueText()}}</span>
		</div>
	`,
	styles: [ `
		.autosize { resize: none; }
	`],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DoiTextAreaComponent),
		multi: true
	}]
})
export class DoiTextAreaComponent extends DoiValueComponent implements ControlValueAccessor, AfterViewInit
{
	/**
	 * Automatically adjust the height of the text area
	 */
	@Input() autosize: boolean;

	/**
	 * The minumum displayed row count when autosizing.
	 */
	@Input() minrows: number = 2;

	/**
	 * The displayed row count.
	 */
	@Input() rows: number = 4;

	/**
	 * The maximum # of characters.
	 */
	@Input() maxLength: number = undefined;

	@Input() labelTooltip: string;
	@Input() labelTooltipClass: string;

	_textareaRef: ElementRef<HTMLTextAreaElement>;

	/**
	 * The textarea element.
	 */
	@ViewChild('textarea')
	textareaRef: ElementRef<HTMLTextAreaElement>;

	/**
	 * Construct a new field component.
	 */
	constructor(private sanitizer: DomSanitizer)
	{
		super();
	}

	/**
	 * Invoked after the view has been checked. Autosize.
	 */
	ngAfterViewChecked()
	{
		this.adjustHeight();
	}

	/**
	 * Invoked after the view has been initialized.
	 */
	ngAfterViewInit()
	{
	}

	/**
	 * Adjust the height of the text area if autosize is enabled.
	 */
	adjustHeight()
	{
		if (!this.autosize)
			return;

		if (this.textareaRef) {
			this.textareaRef.nativeElement.style.height = 'auto';
			this.textareaRef.nativeElement.style.height = (this.textareaRef.nativeElement.scrollHeight+2)+'px';
			this.textareaRef.nativeElement.style.maxHeight = ((this.rows+1)*1.5)+'em';
		}
	}

	textareaClasses(): string
	{
		if (this.autosize) {
			return 'autosize';
		}
	}

	textareaRows(): number
	{
		if (this.autosize) {
			return this.minrows;
		} else {
			return this.rows;
		}
	}

	/**
	 * Invoked when the value has been changed by the user. Overridden to autosize.
	 */
	protected valueChanged()
	{
		super.valueChanged();

		this.adjustHeight();
	}

	/**
	 * Return the displayed value text.
	 */
	valueText(): any
	{
		let value = this.value;
		if (value)
			return ''+value;
		else
			return '';
	}
}
