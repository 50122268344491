import {Component, Input, Output, EventEmitter} from '@angular/core';

/**
 * A switch component.
 */
@Component({
	selector: 'doi-switch',
	template: `
		<div class="btn-group btn-group-sm d-print-none" role="group">
			<button type="button" [disabled]="!enabled" class="btn" [ngClass]="{'btn-success': state == true, 'btn-default': state != true}" (click)="clickOn()">{{textOn}}</button>
			<button type="button" [disabled]="!enabled" class="btn" [ngClass]="{'btn-default': state != false, 'btn-warning': state == false}" (click)="clickOff()">{{textOff}}</button>
		</div>
  `,
})
export class DoiSwitchComponent
{
	/**
	 * The state.
	 */
	@Input()
	state: boolean;

	/**
	 * Indicates if enabled.
	 */
	@Input()
	enabled = true;

	@Input('text-on') textOn: string;
	@Input('text-off') textOff: string;
	@Output('switch-on') switchOn = new EventEmitter();
	@Output('switch-off') switchOff = new EventEmitter();

	/**
	 * Construct a new switch component.
	 */
	constructor()
	{
	}

	/**
	 * Invoked when the "on" button is clicked. Emits an event to "switch-on".
	 */
	clickOn()
	{
		if (this.enabled)
			this.switchOn.emit();
	}

	/**
	 * Invoked when the "off" button is clicked. Emits an event to "switch-off".
	 */
	clickOff()
	{
		if (this.enabled)
			this.switchOff.emit();
	}
}
