import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin, of} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {KlaraObjectPanel, ObjectFile, PropertyValue, UnitValueReader, Unit} from '../../klara/KlaraModule';
import {ProcessPart, ProcessObject, ProcessService} from '../../process/ProcessModule';
import {ProcessActTypePart, ProcessActTypeObject, ProcessActTypeUnitObject} from '../model/ProcessActTypeObject';
import {ProcessActTypeObjectGeneralPanel} from './ProcessActTypeObjectGeneralPanel';
import {ProcessActTypeService} from '../../processacttype/model/ProcessActTypeService';
import {UnitPart, UnitService} from '../../unit/UnitModule';

@Component({
	templateUrl: 'ProcessActTypeObjectGeneralDialogPanel.html'
})
export class ProcessActTypeObjectGeneralDialogPanel extends ProcessActTypeObjectGeneralPanel implements UnitValueReader
{

	/**
	 * ID för de FE som är expanderade.
	 */
	private expandedUnits = new Set<number>();
	private expanded: boolean;

	constructor(doi: DoiService, processService: ProcessService, processActTypeService: ProcessActTypeService, unitService: UnitService, route: ActivatedRoute)
	{
		super(doi, processService, processActTypeService, unitService, route);
	}

	/**
	 * Returnera
	 */
	isUnitExpanded(unit: ProcessActTypeUnitObject): boolean
	{
		return this.expandedUnits.has(unit.unitID);
	}

	showUnits(): boolean
	{
		return this.units && this.units.length > 0;
	}

	refreshViewObservables(): Observable<any>[]
	{
		let observables = super.refreshViewObservables();

		observables.push(
			this.refreshObjectPart(ProcessActTypePart.UnitsNav).pipe(
				tap(procActType => {
					this.units = new Array<Unit>();
					for (let u of procActType.units) {
						this.units.push(u.toUnit());
					}
				})
			),
		);

		return observables;
	}

	bodyLeftVisible(): boolean
	{
		return (this.object.description != null) || (this.propertyValues().length > 0);
	}

	bodyLeftColClasses(): string
	{
		if (this.bodyRightVisible())
			return "col-md-7";
		else
			return 'col-12';
	}

	bodyRightVisible(): boolean
	{
		return this.showUnits();
	}

	bodyRightColClasses(): string
	{
		//Hanteringsanvisningar saknas
		if (this.bodyLeftVisible())
			return "col-md-5";
		else
			return 'col-12';
	}

	expandCollapse()
	{
		this.expanded = !this.expanded;

		if (this.expanded) {
			if (!this.object.description) {
				this.readDescription(this.object).subscribe(
					(description => {
						this.object.description = description;
					}),
					(error) => console.error(error)
				);
			}
			if (!this.object.descriptionShort) {
				this.readDescription(this.object).subscribe(
					(descriptionShort => {
						this.object.descriptionShort = descriptionShort;
					}),
					(error) => console.error(error)
				);
			}
		}
	}

	readDescription(procactype: ProcessActTypeObject): Observable<string>
	{
		return this.processActTypeService.readObjectPart(this.objectID, ProcessActTypePart.Description).pipe(
			switchMap((procactype) => {
				return of(procactype.description)
			})
		);
	}
}

// rcT9DIxhYj6XC14VqboZUpIT5aA=
