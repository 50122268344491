import {DoiObject} from '../../doi/DoiModule';

/**
 * A search result category, belonging to a category group.
 */
export class DoiSearchResultCat
{
	/**
	 * The category group.
	 */
	group: DoiSearchResultCatGroup;

	/**
	 * The category text.
	 */
	text: string;

	/**
	 * The sort sequence.
	 */
	seq: number;

	/**
	 * The 0 based category ID used by the entries.
	 */
	catID: number;

	/**
	 * Indicates if the category is limited by count.
	 */
	countLimited: boolean = false;

	/**
	 * Indicates if the category is explicitly selected.
	 */
	selected: boolean;

	/**
	 * Indicates if the category is implicitly selected because no category in the group isexplicitly selected .
	 */
	implied: boolean;

	/**
	 * The # of entries in this category.
	 */
	countTotal: number;

	/**
	 * The # of entries in this category that is included in the filtered result.
	 */
	countIncluded: number;

	/**
	 * The # of entries in this category that is not included in the filtered result but would be if the category were selected. The entry must be
	 * included in all other groups but this.
	 */
	countAvailable: number;

	/**
	 * The # of entries in this category that is displayed. Either the included or available count.
	 */
	countDisplayed: number;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): DoiSearchResultCat
	{
		this.text = data.Cat;
		this.catID = data.ID;

		return this;
	}
}

/**
 * A category group, containing categories.
 */
export class DoiSearchResultCatGroup
{
	/**
	 * The symbolic name of the group, e g 'ObjectType'.
	 */
	name: string;

	/**
	 * The categories in the group, indexed by category ID.
	 */
	cats: DoiSearchResultCat[];

	/**
	 * The displated category group text, e g 'Type of object'.
	 */
	text: string;

	/**
	 * The sort sequence.
	 */
	seq: number;

	/**
	 * Indicates if any category in the group has been selected. Set by {@link DoiSearchResult#checkCatSelection}.
	 */
	anySelected: boolean;

	/**
	 * The maximum number of categories to show before expansion.
	 */
	limitDisplay: number = 5;

	/**
	 * Indicates if the group should be hidden if its would show a single category. Useful with scoped searches where the categoty is obvious.
	 */
	suppressSingle: boolean = false;

	/**
	 * Calculate the total displayed count for the group.
	 */
	countDisplayed(): number
	{
		if (!this.cats)
			return 0;

		let count = 0;
		for (let c of this.cats) {
			count += c.countDisplayed;
		}

		return count;
	}

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): DoiSearchResultCatGroup
	{
		if (!data.Group)
			throw new Error("No Group array in search result.");

		this.name = this.text = data.Group;
		this.cats = new Array<DoiSearchResultCat>();
		for (let de of data.Categories) {
			let cat = new DoiSearchResultCat();
			cat.group = this;
			cat.parseData(de);
			this.cats.push(cat);
		}

		return this;
	}
}
