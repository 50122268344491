import {Component, Input, Output, EventEmitter, forwardRef, HostBinding} from '@angular/core';
import {DatePipe} from '@angular/common';
import {FormControl} from '@angular/forms';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable, of} from 'rxjs';
import {map, tap} from "rxjs/operators";

import {DoiLabeledValue} from '../core/DoiLabeledValue';
import {DoiValueComponent} from './DoiValueComponent';

/**
 * A dropdown select list component.
 */
@Component({
	selector: 'doi-select',
	host: { 'class' : 'doi-select' },
	template: `
		<div class="form-group doi-field" [ngClass]="{'doi-editing':edit, 'doi-not-editing':!edit}">
			<div class="doi-field-header">
				<label *ngIf="label" class="doi-label">{{label}}</label>
				<ng-content></ng-content>
			</div>
			<select *ngIf="edit && !readonly" class="form-control form-select" name="{{name}}" [(ngModel)]="value">
				<option *ngFor="let v of valueList()|async" [ngValue]="v.value">{{v.label}}</option>
			</select>
			<div class="input-group" *ngIf="edit && readonly">
				<input class="form-control" name="{{name}}" size="{{size}}" type="text" [(ngModel)]="text" [disabled]="true"/>
			</div>
			<span *ngIf="!edit" style="{{color ? 'color:'+color+';':''}}"><a class="doi-nolink" href="" title="{{tooltip}}" [innerHtml]="valueText()"></a></span>
		</div>
	`,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DoiSelectComponent),
		multi: true
	}]
})
export class DoiSelectComponent extends DoiValueComponent implements ControlValueAccessor
{
	/**
	 * Indicates if enabled.
	 */
	@Input()
	enabled = true;

	/**
	 * Allow the user to filter the value list.
	 */
	@Input() filter: boolean = false;

	/**
	 * The value list function.
	 */
	@Input('values')
	valueListFunction = (filter?: string) => { return of(<DoiLabeledValue<any>[]>[]); };

	/**
	 * The display text for the current value.
	 */
	private _text: string;

	/**
	 * The value list observable.
	 */
	private valueListObs: Observable<DoiLabeledValue<any>[]>;

	/**
	 * The filter value corresponding to the above observable.
	 */
	private valueListFilter: string;

	/**
	 * The values corresponding to the above observable.
	 */
	private valueListResult: DoiLabeledValue<any>[];

	/**
	 * Construct a new component.
	 */
	constructor()
	{
		super();
	}

	/**
	 * The text for the current value.
	 */
	@Input()
	get text(): any
	{
		return this._text;
	}
	set text(text: any)
	{
		this._text = text;
	}

	select(value: DoiLabeledValue<any>)
	{
		console.log('DoiSelectComponent.select '+value)
		if (this.readonly)
			return;

		this.value = value ? value.value : null;
		this.text = value ? value.label : null;
	}

	/**
	 * Return the value list.
	 */
	valueList(): Observable<DoiLabeledValue<any>[]>
	{
		//	Discard any old result if there is no value list observable or if the filter text has been edited.

		if (!this.valueListObs || this.valueListFilter != this._text) {
			this.valueListFilter = this._text;
			this.valueListResult = null;
			this.valueListObs = this.valueListFunction(this.filter ? this.valueListFilter : null);
		}

		if (this.valueListResult)
			return of(this.valueListResult);

		return this.valueListObs.pipe(
			map(values => {
				let result = values;
				if (!this.required) {
					result = new Array<DoiLabeledValue<any>>();
					result.push(DoiLabeledValue.of(null, ''));
					if (values) {
						for (let value of values) {
							if (value && value.value != null)
								result.push(value);
						}
					}
				}
				let v = this.value;
				if (result && v != null && !this.filter) {
					for (let value of result) {
						if (value && value.value == v) {
							this.text = value.label;
							break;
						}
					}
				}
				this.valueListResult = result;
				return result;
			})
		);
	}

	/**
	 * Return the displayed value text.
	 */
	valueText(): any
	{
		return this.text;
	}
}
