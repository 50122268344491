import {Injectable} from '@angular/core';
import {HttpParams, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError, of} from 'rxjs';
import {catchError, map, startWith, switchMap, tap} from "rxjs/operators";

import {DoiBrokerService, DoiService} from '../../doi/DoiModule';
import {DoiSearchBrokerService} from '../../doi-search/DoiSearchModule';

import {DocStoreLink, KlaraBrokerService} from '../../klara/KlaraModule';

import {UnitPart, UnitObject} from './UnitObject';

/**
 * Manages Unit objects.
 */
@Injectable()
export class UnitService extends KlaraBrokerService<UnitObject>
{
	/**
	 * Construct a new Unit service.
	 */
	constructor(doi: DoiService)
	{
		super(doi, 'Unit')
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return UnitObject.iconName;
	}

	/**
	 * Create a new Unit object.
	 */
	newObject(objectID: number): UnitObject
	{
		return new UnitObject(objectID);
	}

	/**
	 * Create and return an observable that fetches the parent structure.
	 * This implementation returns either an observable of the specified Unit, if it has no parent,
	 * or an observable that fetches the parent Unit
	 * and then switches to its UnitService.parentObjectObservable.
	 */
	parentObjectObservable(unit: UnitObject): Observable<any>
	{
		if (!unit.parentUnitID)
			return of(unit);

		return this.readObjectPart(unit.parentUnitID, UnitPart.Label, unit.parent).pipe(
			switchMap((parent: UnitObject) => {
				unit.parent = parent;
				return this.parentObjectObservable(parent);
			})
		);
	}

	/**
	 * Read an object part.
	 */
	readObjectPart(id: number, partName: string, object?: UnitObject): Observable<UnitObject>
	{
		let options = undefined;
		switch (partName) {
			case UnitPart.Label:
			case UnitPart.General:
				options = {
					params: new HttpParams().append('related', 'UnitType').append('related', 'ArchiveOrig')
				};
				break;
			case UnitPart.ProcActTypes:
				options = {
					params: new HttpParams().append('related', 'Process').append('related', 'ProcessType').append('related', 'ProcessStructure')
				};
				break;
			case UnitPart.Processes:
				options = {
					params: new HttpParams().append('related', 'ProcessType').append('related', 'ProcessStructure')
				};
				break;
			case UnitPart.RelatedUnits:
				options = {
					params: new HttpParams().append('related', 'UnitRelType').append('related', 'UnitType').append('related', 'ArchiveOrig')
				};
				break;
		}
		return super.readObjectPart(id, partName, object, options);
	}

	/**
	 * Read property values for edit.
	 */
	readPropertyValuesForEdit(id: number, object?: UnitObject): Observable<UnitObject>
	{
		let options = {
			params: new HttpParams().append('related', 'PropertyType')
		};

		return super.readObjectPart(id, UnitPart.PropertyValues, object, options);
	}

	/**
	 * Read search result entries.
	 */
	readSearchResultEntries(objectIDs?: number[]): Observable<UnitObject[]>
	{
		let params = new HttpParams();

		params = params
			.append('col', 'Unit_UnitID')
			.append('col', 'Unit_UnitTypeID')
			.append('col', 'Unit_Code')
			.append('col', 'Unit_NavText')
			.append('col', 'Unit_Notes');

		for (let objectID of objectIDs) {
			params = params.append('id', objectID.toString());
		}

		return this.readObjectSelection(params, null);
	}
}

// Re3DoFaevi/to+brDn2UBaEZpq4=
