import {Injectable} from '@angular/core';
import {HttpParams, HttpErrorResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, catchError, switchMap} from "rxjs/operators";

import {DoiService, DoiBrokerService} from '../../doi/DoiModule';
import {DoiSearchBrokerService} from '../../doi-search/DoiSearchModule';

import {KlaraBrokerService} from '../../klara/KlaraModule';

import {ArchiveOrigService, ArchiveOrigObject, ArchiveOrigPart} from '../../archiveorig/ArchiveOrigModule';

import {ProcessStructureObject, ProcessStructurePart} from './ProcessStructureObject';
import {ProcessStructureSelectable} from './ProcessStructureSelectable';

/**
 * Manages ProcessStructure objects.
 */
@Injectable()
export class ProcessStructureService extends KlaraBrokerService<ProcessStructureObject>
{
	/**
	 * Construct a new ProcessStructure service.
	 */
	constructor(doi: DoiService, private archiveOrigService: ArchiveOrigService)
	{
		super(doi, 'ProcessStructure')
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return ProcessStructureObject.iconName;
	}

	/**
	 * Create a new ProcessStructure object.
	 */
	newObject(objectID: number): ProcessStructureObject
	{
		return new ProcessStructureObject(objectID);
	}

	/**
	 * Create and return an observable that fetches the parent structure.
	 * This implementation returns an observable that fetches the ArchiveOrig, connects it to the ProcessStructure
	 * and then switches to an ArchiveOrig.parentObjectObservable.
	 */
	parentObjectObservable(procStruct: ProcessStructureObject): Observable<any>
	{
		return this.archiveOrigService.readObjectPart(procStruct.archiveOrigID, ArchiveOrigPart.Label, procStruct.archiveOrig).pipe(
			switchMap((archiveOrig: ArchiveOrigObject) => {
				procStruct.archiveOrig = archiveOrig;
				return this.archiveOrigService.parentObjectObservable(archiveOrig);
			})
		);
	}

	/**
	 * Read processed structures available to the caller. The table is sorted in preference order,
	 * with the preferred at index 0, selected if the user doesn't have a preselected.
	 */
	readSelectable(): Observable<ProcessStructureSelectable[]>
	{
		let params = new HttpParams();

		return this.doi.http.get(this.url() + '/selectable/'+'selectable.json', { params: params }).pipe(
			map((response: any[]) => {
				let list = new Array<ProcessStructureSelectable>();
				for (let row of response) {
					let object = new ProcessStructureSelectable(row);
					list.push(object);
				}
				return list;
			}),
			catchError((error: HttpErrorResponse) =>
				this.handleError(error))
		);
	}

	/**
	 * Read search result entries.
	 */
	readSearchResultEntries(objectIDs?: number[]): Observable<ProcessStructureObject[]>
	{
		let params = new HttpParams();

		params = params
			.append('col', 'ProcessStructure_ProcStructID')
			.append('col', 'ProcessStructure_Name')
			.append('col', 'ProcessStructure_Description');

		for (let objectID of objectIDs) {
			params = params.append('id', objectID.toString());
		}

		return this.readObjectSelection(params, null);
	}
}

// /4ihZWlCHizkg42HwzrmNRMPyaY=
