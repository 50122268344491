import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, Observable, forkJoin, of} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiObjectView, DoiService} from '../../doi/DoiModule';

import {KlaraObjectView} from '../../klara/KlaraModule';

import {ArchiveOrigService, ArchiveOrigObject} from '../../archiveorig/ArchiveOrigModule';
import {ProcessStructureService, ProcessStructureObject} from '../../processstructure/ProcessStructureModule';

import {ProcessService} from '../model/ProcessService';
import {ProcessPart, ProcessObject} from '../model/ProcessObject';
import {ProcessObjectView} from './ProcessObjectView';
import {KlaraDialogTopView} from '../../klara/KlaraModule';

import {environment} from '../../../environments/environment';

/**
 *
 */
@Component({
	templateUrl: 'ProcessObjectDialogView.html'
})
export class ProcessObjectDialogView extends ProcessObjectView
{
	/**
	 * Construct a new object view.
	 */
	constructor(doi: DoiService, processService: ProcessService, route: ActivatedRoute)
	{
		super(doi, processService, route);
	}
}

// oIng7sGnb2hZG77nJSYKe/hJNI8=
