import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, Observable, forkJoin} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiObjectView, DoiService} from '../../doi/DoiModule';

import {KlaraObjectView} from '../../klara/KlaraModule';

import {ProcessStructureService} from '../model/ProcessStructureService';
import {ProcessStructurePart, ProcessStructureObject} from '../model/ProcessStructureObject';
import {ArchiveOrigPart, ArchiveOrigObject, ArchiveOrigService} from '../../archiveorig/ArchiveOrigModule';

import {KlaraDialogTopView} from '../../klara/KlaraModule';

/**
 *
 */
@Component({
	templateUrl: 'ProcessStructureObjectView.html'
})
export class ProcessStructureObjectView extends KlaraObjectView<ProcessStructureObject> implements KlaraDialogTopView
{
	name = 'ProcessStructureObjectView';

	/**
	 * Template for a new object.
	 */
	objectTemplate: ProcessStructureObject;

	/**
	 * Construct a new object view.
	 */
	constructor(doi: DoiService, private processStructureService: ProcessStructureService, route: ActivatedRoute)
	{
		super(doi, processStructureService, route);
	}

	/**
	 * Return the corresponding path in Klara Dialog, null if not available or an empty string for the same URL.
	 * This implementation causes navigation to the start page with the PSID as a query parameter.
	 */
	dialogPath(): string
	{
		return this.object && this.object.objectID ? '/start?psid='+this.object.objectID : null;
	}

	routerLinkArchiveOrig()
	{
		return this.object && this.object.archiveOrigID ? ['/archiveorig', this.object.archiveOrigID] : null;
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * Overridden to refresh object parts presented by the object view itself.
	 */
	refreshView(): Observable<any>
	{
		if (this.doi.auth.isAuthenticating())
			return EMPTY;

		return forkJoin(
			this.refreshObjectPart(ProcessStructurePart.Label).pipe(
				switchMap((procStruct: ProcessStructureObject) => {
					return this.processStructureService.parentObjectObservable(procStruct);
				})
			),

			this.probeObjectPart(ProcessStructurePart.General),
			this.probeObjectPart(ProcessStructurePart.Processes),
			super.refreshView()
		);
	}

	/**
	 * Test if any of the object parts used by the specified object panel has been found to have backend values as a result of an object probe. If not
	 * known, undefined is returned.
	 * Overriddene to invoke objectPartHasValues with the part name or part names used by the panel.
	 */
	probedPanel(panelName: string): boolean
	{
		switch (panelName) {
			case 'general': return this.probedPart('General');
			case 'processes': return this.probedPart('Processes');
		}
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Klassificeringsstruktur'; // I18N
	}
}

// oJm5NyU5mqM5ZIjj0Ez9iKAYPEU=
