import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {EMPTY, Observable, of} from 'rxjs';

import {DoiIconMapper, DoiLabeledValue, DoiView} from '../../doi/DoiModule';

import {PropertyValue} from '../model/PropertyValue';

/*
 * A property value component. Renders a property according to the following rules:
 *	- The property is shown if editing or if it has a value.
 *	- A boolean is shown as a checkbox if editing.
 *	- A non boolean is always shown as a field. An ordinary boolean is shown as a field if not editing.
 */
@Component({
	selector: 'property-value',
	template: `
		<form ngForm>
			<!-- A boolean is shown as a checkbox if editing. -->
			<ng-container *ngIf="propertyValue.valueType == 'Boolean' && editing()">
				<doi-checkbox [(ngModel)]="value" [ngModelOptions]="{standalone:true}" [edit]="true" (change)="valueChanged($event)">{{propertyValue.label}}</doi-checkbox>
			</ng-container>
			<!-- An non boolean is always shown as a field. An boolean is shown as a field if not editing. -->
			<ng-container *ngIf="propertyValue.valueType != 'Boolean' || !editing()">
				<!-- An non dropdown is shown as a field or text area. -->
				<ng-container *ngIf="!propertyValue.dropDownEntries">
					<doi-field *ngIf="propertyValue.valueType != 'Text'" [(ngModel)]="value" [ngModelOptions]="{standalone:true}" [labelTooltip]="propertyValue.description" labelTooltipClass="property-value-description" label="{{propertyValue.label}}" [displayText]="propertyValue.valueText()" [edit]="editing()"
						[clearButton]="false" (change)="valueChanged($event)">
						<property-value-revert [view]="view" *ngIf="used && edit" [propertyValue]="propertyValue" (change)="valueReverted($event)"></property-value-revert>
					</doi-field>
					<doi-textarea *ngIf="propertyValue.valueType == 'Text'" [(ngModel)]="value" [ngModelOptions]="{standalone:true}" [labelTooltip]="propertyValue.description" label="{{propertyValue.label}}" labelTooltipClass="property-value-description" [displayText]="propertyValue.valueText()" [edit]="editing()"
						[clearButton]="false" (change)="valueChanged($event)" [rows]="5" [minrows]="1" [autosize]="true">
						<property-value-revert [view]="view" *ngIf="used && edit" [propertyValue]="propertyValue" (change)="valueReverted($event)"></property-value-revert>
					</doi-textarea>
				</ng-container>
				<!-- A dropdown is shown as a select. -->
				<ng-container *ngIf="propertyValue.dropDownEntries">
					<doi-select [(ngModel)]="value" [ngModelOptions]="{standalone:true}" label="{{propertyValue.label}}" [displayText]="propertyValue.valueText()" [edit]="editing()"
						(change)="valueChanged($event)" [values]="dropDownValues()">
						<property-value-revert [view]="view" *ngIf="used && edit" [propertyValue]="propertyValue" (change)="valueReverted($event)"></property-value-revert>
					</doi-select>
				</ng-container>
			</ng-container>
		</form>
	`,
	host: { 'class' : 'property-value' },
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class PropertyValueComponent
{
	/**
	 * The containing view.
	 */
	@Input()
	view: DoiView;

	/**
	 * The property value.
	 */
	@Input()
	propertyValue: PropertyValue;

	/**
	 * Indicates if the component is being edited.
	 */
	@Input()
	@HostBinding('class.property-value-edited')
	edit: boolean;

	/**
	 * Value change emitter.
	 */
	@Output()
	change = new EventEmitter<any>();

	@HostBinding('class.property-value-used')
	get used(): boolean
	{
		return this.propertyValue && this.propertyValue.used;
	}

	/**
	 * Return a function that returns the drop down values.
	 */
	dropDownValues(): (filterText?: string) => Observable<DoiLabeledValue<any>[]>
	{
		return (filterText?: string) => {
			if (!this.propertyValue || !this.propertyValue.dropDownEntries)
				return EMPTY;
			let values = new Array<DoiLabeledValue<any>>();
			for (let e of this.propertyValue.dropDownEntries) {
				values.push(DoiLabeledValue.of(e, e));
			}
			return of(values);
		};
	}

	/**
	 * Test if editing.
	 */
	editing(): boolean
	{
		return this.edit;
	}

	/**
	 * Construct an icon class for the specified icon name.
	 */
	iconClass(iconName : string)
	{
		if (!iconName)
			return null;

		return this.view.iconClass(iconName);
	}

	/**
	 * Construct an icon class for the revert icon.
	 */
	iconClassRevert()
	{
		return this.iconClass('undo-alt');
	}

	/**
	 * The current value of the attached property value object.
	 */
	get value(): any
	{
		return this.propertyValue.value;
	}

	set value(value: any)
	{
		this.propertyValue.value = value;

		//	TODO: Used flag should have separate UI-component.
		this.propertyValue.used = (value && true);
	}

	toggleUsed()
	{
		if (this.propertyValue.used) {
			this.propertyValue.used = false;
		} else {
			this.propertyValue.used = true;
		}
	}

	/**
	 * Invoked when the inner checkbox or field changes. Flags the value as used and emits a change event.
	 */
	valueChanged(value: any)
	{
		this.propertyValue.used = true;

		this.change.next(this.value);
	}

	/**
	 * Invoked by the revert tool when the value has ben reverted. Emits a change event.
	 */
	valueReverted(value: any)
	{
		this.change.next(this.value);
	}
}
