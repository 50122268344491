import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";

import {DoiService, DoiAction, DoiSelectionCriteria, DoiSelectionView, DoiViewMode} from '../../doi/DoiModule';

import {UnitObject} from '../model/UnitObject';
import {UnitService} from '../model/UnitService';

@Component({
	templateUrl: 'UnitSelectionView.html'
})
export class UnitSelectionView extends DoiSelectionView<UnitObject>
{
	name = 'UnitSelectionView';

	searchText: string;
	filter: string = '';
	inactive: boolean;

	units: UnitObject[];

	constructor(doi: DoiService, private unitService: UnitService, route: ActivatedRoute)
	{
		super(doi, unitService, route);

		this.actions.add(
			new DoiAction(this, 'Search', 'search', 'Sök')
				.executeHandler(() => this.actionSearch()));
	}

	ngOnInit()
	{
		super.ngOnInit();

		this.refresh();
	}

	ngOnDestroy()
	{
		super.ngOnDestroy();
	}

	actionSearch()
	{
		this.doi.router.navigate(['/search'], {queryParams: {returnUrl: this.appView.lastUrl, text: this.searchText}});
	}

	/**
	 * Return a text representing the specified object, used for filtering the grid view.
	 */
	filterGridText(unit: UnitObject): string
	{
		return unit.navText;
	}

	/**
	 * Return a text representing the specified object, used for filtering the table view.
	 */
	filterTableText(unit: UnitObject): string
	{
		return unit.unitTypeName+' '+unit.navText+' '+unit.date+' '+unit.archiveOrigName;
	}

	/**
	 * Open the specified object.
	 */
	openObject(unit: UnitObject)
	{
		this.appView.openObject('Unit', unit.objectID);
	}

	routerLinkArchiveOrig(unit : UnitObject)
	{
		return unit.archiveOrigID ? [ '/archiveorig', unit.archiveOrigID ] : null;
	}

	routerLinkUnit(unit : UnitObject)
	{
		return unit.objectID ? [ '/unit', unit.objectID ] : null;
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * Overridden to refresh object parts presented by the object view itself.
	 */
	refreshView(): Observable<any>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Unit_UnitID', 'Unit_UnitTypeID', 'UnitType_Name','Unit_Code', 'Unit_Title', 'Unit_NavText', 'Unit_Date', 'ArchiveOrig_Name')
			.where('Unit_ParentUnitID', '=')
			.and('UnitType_NavigatorNode', '=true')
			.orderBy('Unit_Code', 'Unit_UnitID');

		return this.unitService.readObjectSelection(null, criteria).pipe(
			tap((units: UnitObject[]) => this.units = units)
		);
	}

	/**
	 * Return the icon class for the specified unit.
	 */
	unitIconClass(unit: UnitObject)
	{
		return this.iconClass(unit.iconName);
	}

	/**
	 * Test if the specified view mode is supported.
	 */
	viewModeSupported(viewMode: DoiViewMode): boolean
	{
		switch (viewMode) {
			case DoiViewMode.Grid:
			case DoiViewMode.Table:
				return true;
		}
		return false;
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Förvaring'; // I18N
	}
}

// sEolCKzmCTn09lTSS53YnZMpTt8=
