import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {switchMap} from "rxjs/operators";

import {DoiService, DoiBrokerService, DoiFileCache} from '../../doi/DoiModule';
import {DoiSearchBrokerService} from '../../doi-search/DoiSearchModule';

import {KlaraBrokerService} from '../../klara/KlaraModule';
import {ArchiveOrigPart, ArchiveOrigObject} from './ArchiveOrigObject';

/**
 * Manages ArchiveOrig objects.
 */
@Injectable()
export class ArchiveOrigService extends KlaraBrokerService<ArchiveOrigObject>
{
	/**
	 * Cached logos.
	 */
	logoCache: DoiFileCache;

	/**
	 * Construct a new ArchiveOrig service.
	 */
	constructor(doi: DoiService)
	{
		super(doi, 'ArchiveOrig')

		this.logoCache = new DoiFileCache(doi.http);
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return ArchiveOrigObject.iconName;
	}

	/**
	 * Builds a URL for an archive orig logo.
	 * @param archiveOrigID The archive orig ID.
	 * @returns The URL.
	 */
	logoURL(archiveOrigID: number)
	{
		if (!archiveOrigID)
			return null;
		return this.url() + '/logo/' + archiveOrigID + '/ArchiveOrigLogo';
	}

	/**
	 * Create a new ArchiveOrig object.
	 */
	newObject(objectID: number): ArchiveOrigObject
	{
		return new ArchiveOrigObject(objectID);
	}

	/**
	 * Create and return an observable that fetches the parents of the specified object and attaches it.
	 * This implementation returns either an observable of the specified ArchiveOrig, if it has no parent,
	 * or an observable that fetches the ArchiveOrig and switches to an ArchiveOrigService.parentObjectObservable.
	 */
	parentObjectObservable(archiveOrig: ArchiveOrigObject): Observable<any>
	{
		if (!archiveOrig.parentArchiveOrigID)
			return of(archiveOrig);

		return this.readObjectPart(archiveOrig.parentArchiveOrigID, ArchiveOrigPart.Label, null).pipe(
			switchMap((parent: ArchiveOrigObject) => {
				archiveOrig.parent = parent;
				return this.parentObjectObservable(parent);
			})
		);
	}

	/**
	 * Read an object part.
	 */
	readObjectPart(id: number, partName: string, object?: ArchiveOrigObject): Observable<ArchiveOrigObject>
	{
		let options = undefined;
		switch (partName) {
			case ArchiveOrigPart.Label:
			case ArchiveOrigPart.LabelText:
			case ArchiveOrigPart.General:
				options = {
					params: new HttpParams().append('related', 'Category')
				};
				break;
			case ArchiveOrigPart.Archives:
				options = {
					params: new HttpParams().append('related', 'ArchiveInst')
				};
				break;
			case ArchiveOrigPart.Notes:
				options = {
					params: new HttpParams().append('related', 'Category')
				};
				break;
			case ArchiveOrigPart.Children:
				options = {
					params: new HttpParams().append('related', 'Category')
				};
				break;

		}

		return super.readObjectPart(id, partName, object, options);
	}

	/**
	 * Read search result entries.
	 */
	readSearchResultEntries(objectIDs?: number[]): Observable<ArchiveOrigObject[]>
	{
		let params = new HttpParams();

		params = params
			.append('col', 'ArchiveOrig_ArchiveOrigID')
			.append('col', 'ArchiveOrig_Code')
			.append('col', 'ArchiveOrig_Name')
			.append('col', 'ArchiveOrig_Notes');

		for (let objectID of objectIDs) {
			params = params.append('id', objectID.toString());
		}

		return this.readObjectSelection(params, null);
	}
}

// jf0/ab5esbqEx7hJ6VLdzooglq8=
