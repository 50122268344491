import {Observable} from 'rxjs';

import {DoiTreeNode} from './DoiTreeNode';

/**
 * A tree node child supplier.
 */
export abstract class DoiTreeNodeChildSupplier<TN extends DoiTreeNode<TN, PET>, PET>
{
	/**
	 * Return an observable that when subscribed reads all child nodes of the specified node.
	 */
	abstract readChildren(node: TN):  Observable<TN[]>;
}