/**
 *	Role names.
 */
export enum RoleName
{
	Admin = "Admin",
	Audit = "Audit",
	Editor = "Editor",
	Guest = "Guest",
	Monitor = "Monitor",
	Person = "Person",
	StructAdm = "StructAdm",
	Sync = "Sync",
	System = "System",
	User = "User",
	Web = "Web"
}
