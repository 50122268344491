<div class="doi-view doi-search-view no-search-tool">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start"></ol>
		<doi-title><label for="search-input" class="mb-0">Sök</label></doi-title>
	</doi-view-title>

	<div class="doi-search-form" role="form">
		<form name="search" (ngSubmit)="search()" [formGroup]="formGroup" novalidate>
			<div class="row">
				<div class="col-12">
					<doi-field inputId="search-input" class="doi-search-text" formControlName="searchText" [edit]="true" [clearButton]="true" (clear)="clear()" #focusFirst></doi-field>
					<doi-select formControlName="searchWordMode" size="6" required="true" [values]="searchWordModeValues()" [edit]="true" [enabled]="searchWordModeEnabled()" (change)="searchWordModeSelected()"></doi-select>
					<button [type]="searchButtonTypeAll()" (click)="searchAll()" [disabled]="!searchButtonEnabled()" class="btn" [ngClass]="searchButtonClassAll()"><i class="fas fa-search"></i>Sök</button>
				</div>
			</div>
			<div *ngIf="scope" class="row mt-2">
				<div class="col-12">
					<doi-checkbox formControlName="searchScope" (click)="refresh()">Sök bara i {{scopeLabel()}}</doi-checkbox>
				</div>
			</div>
		</form>
		<div class="mt-3" *ngIf="noMatch">
			<p>
				Inget objekt matchade din sökning.
			</p>
		</div>
	</div>

	<div class="doi-scroll">

		<div class="doi-subview">

			<div class="row doi-search-result" *ngIf="result">
				<div class="col-12 col-lg-4">
					<div class="doi-search-result-filter-pane">
						<h3 class="search-result-filter-header" (click)="searchCategoriesToggle()">
							<span>Filtrera sökresultat</span>
							<i class="fas" [ngClass]="searchCategoriesExpandClass()"></i>
						</h3>
						<doi-search-categories [countHiddenXS]="false" [ngClass]="searchCategoriesClass()" [result]="result" (change)="buildResultPage()"
							countTooltipFormat="Visar {0} av {1}" countTooltipLimited="För många träffar" [searching]="refreshing">
						</doi-search-categories>
					</div>
				</div>
				<div class="col-12 col-lg-8">
					<div class="doi-search-result-page">
						<doi-search-result-page-header [result]="result" [searching]="refreshing">></doi-search-result-page-header>
						<div class="doi-search-entries">
							<div class="doi-search-entry doi-search-entry-{{entry.objectType}}" *ngFor="let entry of result.pageEntries">
								<div class="doi-search-entry-header">
									<div class="doi-search-entry-link">
										<a href="" (click)="openResultEntry(entry)">
											<i *ngIf="entry.iconName" class="doi-search-entry-icon" [ngClass]="iconClass(entry.iconName)"></i>
											<div>
												<span class="doi-search-entry-title doi-hidden-sm-down">{{entry.typeText}}</span>
												<span class="doi-search-entry-title">{{entry.headerText}}</span>
												<span *ngIf="appView.settings.debugMode">&nbsp;<span class="doi-debug">({{entry.weight}})</span></span>
											</div>
										</a>
									</div>
									<div class="search-entry-cat-link doi-hidden-sm-down">
										<a *ngIf="entry.props?.PID"href="" (click)="openProcess(entry.props.PID)">
											<span class="search-entry-process">{{entry.props.processText}}</span>
										</a>
										<a *ngIf="entry.props?.PSID"href="" (click)="openProcessStructure(entry.props.PSID)">
											<span class="search-entry-process">{{entry.props.procStructText}}</span>
										</a>
									</div>
								</div>
								<p class="doi-search-entry-body">{{entry.bodyText}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<doi-search-result-page-footer [result]="result" (pageChanged)="buildResultPage()"></doi-search-result-page-footer>

</div>
