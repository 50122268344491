<div class="doi-scroll">

	<form class="doi-subview" #form="ngForm" role="form">

		<div class="row">
			<div class="col-12 col-md-8" *ngIf="object">
				<div class="row object-title-pane">
					<div class="col-12">
						<doi-title>
							<span *ngIf="object?.processTypeID == 3" class="title-object-type"><i class="far fa-rectangle-landscape" ng-reflect-ng-class="far fa-rectangle-landscape"></i>{{object?.processTypeName}}</span>
							<span class="title-object-name">{{object?.objectTitle()}}</span>
						</doi-title>
					</div>
					<div class="col-12">
						<doi-textarea name="descriptionShort" rows="2" [(ngModel)]="object.descriptionShort"></doi-textarea>
					</div>
					<div class="col-12">
						<property-values [view]="this" [propertyValues]="propertyValues()" [normal]="false" [cellsLG]="6" [edit]="editing()"></property-values>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4" *ngIf="object?.objectFiles?.length > 0">
				<div class="group-pane object-files">
					<h3>Relaterade dokument</h3>
					<div *ngIf="object.objectFiles?.length > 0" class="row">
						<div class="col-12"><object-files [objectFiles]="object.objectFiles" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files></div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div [ngClass]="bodyLeftColClasses()" *ngIf="bodyLeftVisible()">

				<div class="row" *ngIf="object?.processTypeID == 3 && object?.description">
					<div class="col-12">
						<description [description]="object?.description" [iconMapper]="this" header="Mer om processen"></description>
					</div>
				</div>

				<div class="row">
					<div class ="col-12" *ngIf="propertyValues().length > 0">
						<div class="group-pane">
							<property-values [view]="this" groupTitle="Hantering" groupTitleOther="Övrig hantering" [propertyValues]="propertyValues()" [highlighted]="false" [cellsLG]="6"></property-values>
						</div>
					</div>
					<div class="col-12" *ngIf="object?.activities && object?.activities?.length">
						<div class="activities group-pane">
							<h3>Steg i processen</h3>
							<div *ngFor="let activity of object?.activities" class="activity" [ngClass]="activityExpanded(activity) ? 'expanded' : null">
								<div class="activity-line-wrapper">
									<div class="activity-line"></div>
								</div>
								<div class="activity-body group-pane">
									<div class="activity-name" (click)="activityExpandCollapse(activity)">
										<div class="activity-sequence-wrapper">
											<div class="activity-sequence">{{activity.sequence}}</div>
										</div>
										<h4>{{activity.name}}</h4>
										<button class="btn btn-light expand-button" ><i [ngClass]="activityExpandIconClass(activity)"></i></button>
									</div>
									<div *ngIf="activityExpanded(activity)" class="group-pane">
										<div class="notes">{{activity.notes}}</div>
										<div *ngIf="activity.actTypes && activity.actTypes.length > 0" class="acttypes">
											<div class="doi-label">Relaterade handlingstyper</div>
											<a class="group-pane activity-type" [routerLink]="routerLinkActType(actType)" *ngFor="let actType of activity.actTypes">
												<i [ngClass]="processActTypeIconClass()"></i>
												<span>{{actType.nameList}}</span>
											</a>
										</div>
										<div *ngIf="activity.linkedProcessID" class="related doi-label">Hänvisning till</div>
											<a *ngIf="activity.linkedProcessID" class="related" [routerLink]="routerLinkLinkedProcess(activity)">
												<span *ngIf="this.appView.showProcessCodes()">{{activity.linkedProcessCode}} - </span><span>{{activity.linkedProcessName}}</span></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	 		<div [ngClass]="bodyRightColClasses()" *ngIf="bodyRightVisible()">
				<div class="row">
					<div class="col-12 process-act-types" *ngIf="processActTypes?.length > 0">
						<div class="group-pane">
							<h3>Handlingstyper i processen</h3>
							<div doi-object-grid [iconClass]="processActTypeIconClass()" [iconMapper]="this" [objects]="processActTypes" moreView="processActTypes" [cellsLG]="12" [cellsXL]="12"></div>
						</div>
					</div>
					<div class="col-12" *ngIf="showUnits()">
						<div class="group-pane">
							<h3 class="mb-2">Förvaringsinformation</h3>
							<p>Visar var handlingarna finns</p>
							<div *ngFor="let unit of units">
								<unit [view]="this" class="group-pane" [unit]="unit" [unitValueReader]="this"></unit>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div doi-object-grid [iconMapper]="this" [objects]="children" moreView="children" [textSupplier]="processText"></div>

	</form>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->