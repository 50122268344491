<div class="doi-view doi-account-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start" [homeIcon]="isAppWebUI()" [tailImplied]="isAppWebUI()" #focusFirst>
			<li doi-breadcrumb text="Mitt konto" *ngIf="isAppDialog()"></li>
		</ol>
	</doi-view-title>

	<doi-title><span>Mitt konto</span></doi-title>

	<div class="doi-scroll">
		<form class="doi-subview" name="account" (ngSubmit)="submit()" [formGroup]="formGroup" role="form" novalidate>
			<div class="row">
				<div class="col-12">
					<h3>Inloggning</h3>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-6 col-lg-5 col-xl-4">
					<doi-field formControlName="name" label="Namn" size="30"></doi-field>
		        </div>
				<div class="col-12 col-md-6 col-lg-5 col-xl-4">
					<doi-field formControlName="userName" label="Användarnamn" size="30"></doi-field>
		        </div>
			</div>
			<div class="row" *ngIf="showIdentityAndLoginNames()">
				<div class="col-12 col-md-6 col-lg-5 col-xl-4">
					<doi-field formControlName="userIdentityName" label="Användaridentitet" size="30"></doi-field>
		        </div>
				<div class="col-12 col-md-6 col-lg-7 col-xl-8">
					<doi-field formControlName="userLoginName" label="Inloggningsnamn" size="30"></doi-field>
		        </div>
			</div>
			<div class="row">
				<div class="col-12">
					<doi-field formControlName="roles" label="Roller" size="30"></doi-field>
				</div>
			</div>
			<div class="row" *ngIf="formGroup.value.expires">
				<div class="col-12">
					<doi-field formControlName="expires" label="Hålls inloggad till" size="30" type="datetime-local"></doi-field>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<h3>Dator/Enhet</h3>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-6 col-lg-5 col-xl-4">
					<doi-label>Operativsystem</doi-label>
					<span>{{navigatorPlatform()}}</span>
				</div>
				<div class="col-12 col-md-6 col-lg-7 col-xl-8">
					<doi-label>Web-läsare{{mobileDevice() ? ' (mobil)' : ''}}</doi-label>
					<span>{{navigatorUserAgent()}}</span>
				</div>
			</div>
		</form>
	</div>

</div>