import {DoiAction} from './DoiAction';
import {DoiKeyMapping} from './DoiKeyMapping';

/**
 * An action set (container).
 */
export class DoiActionSet
{
	/**
	 * Actions.
	 */
	private _actions = new Map<string, DoiAction>();

	/**
	 * Key mappings.
	 */
	private _keyMappings = new Map<string, DoiAction[]>();

	/**
	 * Add the specified action.
	 */
	private _add(action: DoiAction)
	{
		this._actions.set(action.name, action);

		for (let keyMapping of action.keyMappings()) {
			let actions = this._keyMappings.get(keyMapping.keyCode);
			if (!actions) {
				actions = new Array<DoiAction>();
				this._keyMappings.set(keyMapping.keyCode, actions);
			}
			actions.push(action);
		}
	}

	/**
	 * Add the specified action or actions to this set.
	 */
	add(action: DoiAction, ...actions: DoiAction[])
	{
		this._add(action);
		if (actions) {
			for (let a of actions) {
				this._add(a);
			}
		}
	}

	/**
	 * Return the specified action, or undefined.
	 */
	get(actionName: string): DoiAction
	{
		return this._actions.get(actionName);
	}

	/**
	 * Find the first enabled action with a key mapping matching the specified event, and execute it.
	 * If a mapping is found, preventDefault is invoked on the event event if no action is enabled.
	 * Currently supports only letter and digit keys combined with a modifier key.
	 */
	executeMappedAction(event: KeyboardEvent): boolean
	{
		if ((event.code.startsWith('Key') || event.code.startsWith('Digit')) && !(event.altKey || event.ctrlKey || event.metaKey || event.shiftKey))
			return false;

		let mappedActions = this._keyMappings.get(event.code);
		if (!mappedActions)
			return false;

		for (let action of mappedActions) {
			if (action.keyMappingMatchesEvent(event)) {
				event.preventDefault();
				if (action.enabled) {
					action.execute();
					return true;
				}
			}
		}

		return false;
	}
}


