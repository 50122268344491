import {Component, Input} from '@angular/core';

/**
 * A component for showing a label on a single row.
 */
@Component({
	selector: 'doi-label',
	template: `
		<label class="doi-label"><ng-content></ng-content></label><br/>
	`
})
export class DoiLabelComponent
{
}
