<div class="doi-view doi-login-view">

	<div class="card">
		<div class="card-body">

			<h2 class="mb-3">Logga in</h2>

			<form name="login" (ngSubmit)="loginWithForm()" [formGroup]="formGroup" novalidate role="form">

				<div class="row mt-4" *ngIf="localStorageChoice()">
					<div class="col-12">
						<doi-checkbox formControlName="keep" tooltip="Spara krypterade inloggningsuppgifter på enheten och logga in automatiskt nästa gång.">
							Håll mig inloggad på den här enheten
						</doi-checkbox>
					</div>
				</div>

				<div class="row mt-4">
					<div class="col-12">
						<strong>Inloggningsalternativ</strong>
					</div>
				</div>

				<div *ngIf="providers && providers.length">
					<div class="row mt-2" *ngFor="let provider of providers">
						<div class="col-12 doi-login-provider">
							<a href="" (click)="loginWithProvider(provider)">
								<img *ngIf="provider.providerIcon" [src]="providerIcon(provider)"/>
								<i *ngIf="!provider.providerIcon" [ngClass]="iconClass('sign-in')"></i>
								<span>Logga in med {{provider.providerName}}</span>
							</a>
						</div>
					</div>
				</div>

				<div class="row mt-2" *ngIf="!showPasswordLogin()">
					<div class="col-12 doi-login-provider">
						<a href="" (click)="loginWithProvider(null)">
							<i [ngClass]="iconClass('key')"></i>
							<span>Logga in med användarnamn och lösenord</span>
						</a>
					</div>
				</div>

				<div class="row mt-4" *ngIf="showPasswordLogin()">
					<div class="col-12">
						<doi-field class="doi-field--alt" inputId="userLoginName" formControlName="userLoginName" label="Användarnamn" size="30" [edit]="true" #focusFirst></doi-field>
					</div>
					<div class="col-12 mt-2">
						<doi-field class="doi-field--alt" inputId="password" formControlName="password" label="Lösenord" size="30" type="password" [edit]="true"></doi-field>
					</div>
				</div>

				<div class="row mt-5 doi-dialog-buttons">
					<div class="col-12">
						<button type="submit" [disabled]="!canSubmit()" class="btn btn-primary me-2" *ngIf="showPasswordLogin()">
							<i [ngClass]="iconClass('sign-in')"></i>
							<span class="ms-2">Logga in</span>
						</button>
						<button type="button" [disabled]="submitting" class="btn btn-secondary" (click)="close()">
							<i [ngClass]="iconClass('times')"></i>
							<span class="ms-2">Avbryt</span>
						</button>
						<span *ngIf="submitting" class="ms-2 fad fa-spinner fa-spin" aria-hidden="true"></span>
					</div>
				</div>

				<div class="row mt-4" *ngIf="requestpwdURL()">
					<div class="col-12">
						<a href="" (click)="requestpwd()">Jag har glömt mitt lösenord</a>
					</div>
				</div>

			</form>

		</div>
	</div>

</div>