<div class="doi-subview doi-scroll">

	<form role="form">
		<div class="row">
			<div class="col-6 col-md-4 col-xl-3">
				<doi-field name="ArchiveTypeName" label="Arkivschema" size="60" [(ngModel)]="object.archiveTypeName" [edit]="editing()"></doi-field>
			</div>
			<div class="col-6 col-md-4 col-xl-3">
				<doi-field name="ArchiveInstName" label="Arkivinstitution" size="60" [(ngModel)]="object.archiveInstName"  [edit]="editing()"></doi-field>
			</div>
			<div class="col-6 col-md-4 col-xl-3">
				<doi-field name="RefCode" label="Beståndskod" size="60" [(ngModel)]="object.refCode" [edit]="editing()"></doi-field>
			</div>
			<div class="col-6 col-md-4 col-xl-3">
				<doi-field name="CountryCode" label="Landskod" size="60" [(ngModel)]="object.countryCode" [edit]="editing()"></doi-field>
				<a href="ArchiveObjectSeriesPanel.html"></a>
			</div>
		</div>
		<div class="row">
			<div class="col-6 col-md-4 col-xl-3">
				<doi-field type="date" name="RegDate" label="Registreringsdatum" size="60" [(ngModel)]="object.regDate"  [edit]="editing()"></doi-field>
			</div>
			<div class="col-6 col-md-4 col-xl-3">
				<doi-field type="date" name="OpenFrom" label="Giltig fr o m" size="60" [(ngModel)]="object.openFrom" [edit]="editing()"></doi-field>
			</div>
			<div class="col-6 col-md-4 col-xl-3">
				<doi-field type="date" name="OpenTo" label="Giltig t o m" size="60" [(ngModel)]="object.openTo" [edit]="editing()"></doi-field>
			</div>
			<div class="col-6 col-md-4 col-xl-3">
				<doi-field name="AccessRestrict" label="Allmänt tillgänglig" size="30" [(ngModel)]="object.accessRestrictText" [edit]="editing()"></doi-field>
			</div>
		</div>
		<div class="row">
			<div class="col-6 col-md-4 col-xl-3">
<!--				<doi-field name="HandlingsTid" label="Handlingarnas tid" size="60" [(ngModel)]="object.dateBegin" [(ngModel)]="object.dateEnd"  [edit]="editing()"></doi-field> -->
				<doi-label>Handlingarnas tid</doi-label>
				<p>{{object.dateBegin}}-{{object.dateEnd}}</p>
			</div>
			<div class="col-6 col-md-4 col-xl-3">
<!--				<doi-field name="HuvudTid" label="Huvudsaklig tid" labelEdit="Huvudsaklig tid" size="60" [(ngModel)]="object.bulkDateBegin" [(ngModel)]="object.bulkDateEnd" [edit]="editing()"></doi-field> -->
				<doi-label>Huvudsaklig tid</doi-label>
				<p>{{object.bulkDateBegin}}-{{object.bulkDateEnd}}</p>
			</div>
			<div class="col-6 col-md-4 col-xl-3">
				<doi-field name="OnLocation" label="Förvaras av reg institution" size="60" [(ngModel)]="object.onLocationCondText" [edit]="editing()"></doi-field>
			</div>
			<div class="col-6 col-md-4 col-xl-3">
				<doi-field name="AltFormAvail" label="Reproduktion tillgänglig" size="60" [(ngModel)]="object.altFormAvailCondText" [edit]="editing()"></doi-field>
			</div>
		</div>
		<div class="row" *ngIf="object?.depots && object?.depots?.length > 0">
			<div class="col-12 col-md-6">
				<h3>Depåer</h3>
				<table class="table table-striped">
					<thead>
						<tr>
							<th class="doi-width-10 doi-wrap">Namn</th>
							<th class="doi-width-5">Kod</th>
							<th class="doi-width-20 doi-wrap">Anmärkning</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let depot of object?.depots">
							<td class="doi-width-10 doi-wrap">{{depot.name}}</td>
							<td  class="doi-width-5">{{depot.code}}</td>
							<td class="doi-width-20 doi-wrap">{{depot.notes}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row" *ngIf="object?.extents && object?.extents?.length > 0">
			<div class="col-12 col-md-6">
				<div class="doi-titled-list">
					<h3>Omfång</h3>
					<table class="table table-striped">
						<thead>
							<tr>
								<th class="doi-width-15">Omfång</th>
								<th  class="doi-width-20">Värde</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let extent of object?.extents">
								<td  class="doi-width-15">{{extent.extentUnitName}}</td>
								<td  class="doi-width-20 doi-wrap">{{extent.value}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="object?.subjects && object?.subjects?.length > 0">
			<div class="col-12 col-md-6">
				<div class="doi-titled-list">
					<h3>Ämnesord</h3>
					<table class="table table-striped">
						<thead>
							<tr>
								<th  class="doi-width-15">Ämnesord</th>
								<th  class="doi-width-20 doi-wrap">Text</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let subject of object?.subjects">
								<td  class="doi-width-15">{{subject.subjectTypeName}}</td>
								<td  class="doi-width-20">{{subject.text}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="object?.acts && object?.acts?.length > 0">
			<div class="col-12 col-md-6">
				<div class="doi-titled-list">
					<h3>Arkivhandlingstyper</h3>
					<table class="table table-striped">
						<thead>
							<tr>
								<th class="doi-width-10">Handlingstyp</th>
								<th class="doi-width-5">Kod</th>
								<th class="doi-width-20 doi-wrap">Anmärkning</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let act of object?.acts">
								<td class="doi-width-10 doi-wrap">{{act.actTypeName}}</td>
								<td class="doi-width-5">{{act.actTypeCode}}</td>
								<td class="doi-width-20 doi-wrap">{{act.notes}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
