/**
 * A component that can focus its first inner component.
 */
export interface DoiFocusFirst
{
	/**
	 * Focus the first inner component.
	 * @true If an inner component that can be focused exists.
	 */
	focusFirst(): boolean;
}
