import {ActivatedRoute, ParamMap} from '@angular/router';

import {DoiService} from '../service/DoiService';
import {DoiTopView} from './DoiTopView';
import {DoiBrokerService} from '../service/DoiBrokerService';
import {DoiObject} from '../service/DoiObject';
import { Directive } from "@angular/core";

/**
 * Abstract base class for top level views that handles objects managed by a broker service.
 */
@Directive()
export abstract class DoiBinderView<T extends DoiObject> extends DoiTopView
{
	/**
	 * The broker service.
	 */
	private _service: DoiBrokerService<T>

	constructor(doi: DoiService, brokerService: DoiBrokerService<T>, route: ActivatedRoute)
	{
		super(doi, route);

		this._service = brokerService;
	}

	get service(): DoiBrokerService<T>
	{
		return this._service;
	}
}
