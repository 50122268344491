import {DoiTopView} from '../../doi/DoiModule';

import {AppBaseView} from '../../AppBaseView';

/*
 * Abstract base class for top views. Extended by non object views.
 */
export abstract class KlaraTopView extends DoiTopView
{
	/**
	 * Reference to the application view.
	 */
	get appView(): AppBaseView { return super.appView as AppBaseView };
	set appView(appView: AppBaseView) { super.appView = appView };

	/**
	 * Test if the environment specifies that Klara Dialog is running.
	 */
	isAppDialog(): boolean
	{
		return this.environment.dialog;
	}

	/**
	 * Test if the environment specifies that Klara WebUI is running.
	 */
	isAppWebUI(): boolean
	{
		return !this.environment.dialog;
	}

	/**
	 * Return the corresponding path in Klara WebUI, null if not available or an empty string for the same URL.
	 * The default implementation returns ''.
	 */
	klaraPath() : string
	{
		return '';
	}
}
