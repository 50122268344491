import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

import {DoiIconMapper, DoiView} from '../../doi/DoiModule';

import {PropertyValue} from '../model/PropertyValue';
import { PropertyValueGroup } from '../model/PropertyValueGroup';

/*
 * A property values component. Renders all properties according to the following rules:
 *	- Highlighted booleans are shown in separate grid rows.
 *		- Highlighted booleans are shown only if editing or if it is checked.
 *	- Ordinary properties are shown in a single grid row with multiple cells.
 *		- Ordinary properties are shown only if editing or if it has a value.
 */
@Component({
	selector: 'property-values',
	template: `
		<form ngForm *ngIf="highlighted">
			<ng-container *ngFor="let propertyValue of propertyValuesHighlightedBoolean">
				<div class="row property-value-highlighted">
					<div class="col-12">
						<property-value-hb [view]="view" [propertyValue]="propertyValue" [edit]="edit" (change)="valueChanged($event)"></property-value-hb>
					</div>
				</div>
			</ng-container>
		</form>
		<form ngForm *ngIf="normal">
			<ng-container *ngFor="let group of propertyValueGroups">
				<h3 *ngIf="group.title">{{group.title}}</h3>
				<div class="row property-values-normal">
					<ng-container *ngFor="let propertyValue of group.values; let i = index">
						<div class="col-12" [ngClass]="cellClasses(propertyValue, i)">
							<property-value [view]="view" *ngIf="propertyValue" [propertyValue]="propertyValue" [edit]="edit" (change)="valueChanged($event)"></property-value>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</form>
	`,
	host: { 'class' : 'property-values' },
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class PropertyValuesComponent
{
	/**
	 * The containing view that is notified when a value changes.
	 */
	@Input()
	view: DoiView;

	/**
	 * The property values.
	 */
	@Input()
	get propertyValues(): PropertyValue[]
	{
		//console.log('get propertyValues '+(this._propertyValues ? this._propertyValues.length : 'null'));
		return this._propertyValues;
	}
	set propertyValues(propertyValues: PropertyValue[])
	{
		this._propertyValues = propertyValues;
		//console.log('set propertyValues '+(this._propertyValues ? this._propertyValues.length : 'null')+' '+this.groupTitle+' '+this.groupTitleOther);
		this.buildPropertyValueGroups();
		this.buildPropertyValuesHighlightedBoolean();
	}
	private _propertyValues: PropertyValue[];

	/**
	 * Indicates if the component is being edited.
	 */
	@Input()
	get edit(): boolean
	{
		return this._edit;
	}
	set edit(edit: boolean)
	{
		if (this._edit != edit) {
			this._edit = edit;
			this.buildPropertyValuesHighlightedBoolean();
		}
	}
	private _edit: boolean;

	/**
	 * The group title used if there are no titled groups.
	 */
	@Input()
	groupTitle: string;

	/**
	 * The group title used for untitled groups when there are titled groups.
	 */
	@Input()
	groupTitleOther: string;

	/**
	 * Indicates if the highlighted boolean property values are shown.
	 */
	@Input()
	highlighted: boolean = true;

	/**
	 * Indicates if the normal property values are shown.
	 */
	@Input()
	normal: boolean = true;

	/**
	 * Cells in MD mode.
	 */
	@Input() cellsMD: string = '12';

	/**
	 * Cells in LG mode.
	 */
	@Input() cellsLG: string = '6';

	/**
	 * Cells in XL mode.
	 */
	@Input() cellsXL: string = '6';

	/**
	 * Value change emitter.
	 */
	@Output()
	change = new EventEmitter<any>();

	/**
	 * Property value groups, or null if not yet built.
	 */
	propertyValueGroups: PropertyValueGroup[];

	/**
	 * Highligted booleans, or null if not yet built.
	 */
	propertyValuesHighlightedBoolean: PropertyValue[];

	/**
	 * Construct classes for a cell containing a property value.
	 */
	cellClasses(propertyValue: PropertyValue, i: number): string
	{
		return 'col-md-'+this.cellsMD+' col-lg-'+this.cellsLG+' col-xl-'+this.cellsXL+' mod2'+(i%2)+' mod4'+(i%4)+' mod6'+(i%6)
		+ (propertyValue == null ? ' property-value-filler-cell' : '');
	}

	/**
	 * Build an array of groups with normal property values. Each group has a value array paddded with nulls to ensure
	 * that grid cells are created for each row.
	 */
	buildPropertyValueGroups(): void
	{
		let groups = new Array<PropertyValueGroup>();
		if (!(this._propertyValues && this._propertyValues.length)) {
			this.propertyValueGroups = groups;
			//console.log('Rebuilt propertyValueGroups: none');
			return;
		}

		let values = new Array<PropertyValueGroup>();
		let group: PropertyValueGroup = null;
		for (let pv of this._propertyValues) {
			if (pv) {
				if (pv.highlightedBoolean())
					continue;
				if (!group || group.sequence != pv.groupSequence) {
					//	Commit and start new group.
					if (group)
						groups.push(group.padded());
					group = new PropertyValueGroup(pv.groupTitle || this.groupTitleOther || this.groupTitle, pv.groupSequence);
				}
				group.values.push(pv);
			}
		}
		//	Commit last group.
		if (group)
			groups.push(group.padded());

		//	Title for single untitled group.
		if (groups.length == 1 && group.title == this.groupTitleOther && this.groupTitle)
			group.title = this.groupTitle;

		this.propertyValueGroups = groups;

		//console.log('Rebuilt propertyValueGroups: '+groups.length);
	}

	/**
	 * Build an array with highlighted boolean property values.
	 */
	buildPropertyValuesHighlightedBoolean(): PropertyValue[]
	{
		this.propertyValuesHighlightedBoolean = new Array<PropertyValue>();
		if (!this._propertyValues || !this._propertyValues.length)
			return;

		for (let pv of this._propertyValues) {
			if (pv.highlightedBoolean() && (this.edit || pv.highlightedBooleanChecked()))
				this.propertyValuesHighlightedBoolean.push(pv);
		}

		//console.log('Rebuilt propertyValuesHighlightedBoolean: '+this.propertyValuesHighlightedBoolean.length);
	}

	/**
	 * Invoked when an inner property value component changes. Emits a change event and notifies the view, if attached.
	 */
	valueChanged(value: any)
	{
		this.change.next(value);

		if (this.view)
			this.view.formOnChange(true);
	}
}
