import { Input, HostBinding, Directive } from '@angular/core';
import { DoiAction } from '../core/DoiAction';
import { DoiActionTarget } from '../core/DoiActionTarget';

/**
 * Base class for components that trigger actions.
 */
@Directive()
export abstract class DoiActionComponent
{
	/**
	 * The action target.
	 */
	@Input()
	target = <DoiActionTarget>undefined; // Workaround for Angular CLI bug #2034

	/**
	 * The action name.
	 */
	@Input('action')
	actionName: string;

	/**
	 * Indicates if a separator should precede the item in the menu.
	 */
	@Input()
	@HostBinding('class.doi-separator-before')
	separator: boolean;

	/**
	 * Indicates if a separator should succeed the item in the menu.
	 */
	@Input()
	@HostBinding('class.doi-separator-after')
	separatorAfter: boolean;

	/**
	 * Icon for dropdown tools not associated with an action.
	 */
	private _icon: string;

	/**
	 * Title for dropdown tools not associated with an action.
	 */
	private _title: string;

	/**
	 * Tooltip for dropdown tools not associated with an action.
	 */
	private _tooltip: string;

	/**
	 * Indicates if the action has been clicked but not yet executed.
	 */
	private _armed: boolean;

	/**
	 * Indicates if the action has been clicked but not yet executed.
	 */
	get armed()
	{
		return this._armed;
	}

	/**
	 * The enabled state of the target action. An armed component is not enabled.
	 */
	get enabled()
	{
		return !this.armed && this.enabledUnarmed;
	}

	/**
	 * The enabled state of the target action when unarmed. Also true if there is a target but no action name.
	 */
	get enabledUnarmed()
	{
		if (this.target) {
			if (this.actionName) {
				let action = this.target.action(this.actionName);
				if (action)
					return action.enabled;
				else
					return false;
			} else {
				return true;
			}
		}
		return true;
	}

	@HostBinding('class.disabled')
	get disabled()
	{
		return !this.enabled;
	}

	/**
	 * Indicates if the tool should be hidden.
	 * If the action shouldn't be available on mobile devices, the platform is checked for Linux, etc.
	 */
	@HostBinding('class.doi-hidden')
	get hidden(): boolean
	{
		let mobileHidden = false;

		let action = this.action();
		if (this.target) {
			let action = this.target.action(this.actionName);
			if (action)
				mobileHidden = action.mobile == false;
		}

		if (mobileHidden && this.target)
			return this.target.mobileDevice();

		return false;
	}

	/**
	 * The selected state of the target action.
	 */
	@HostBinding('class.selected')
	get selected()
	{
		let action = this.action();
		if (this.target) {
			let action = this.target.action(this.actionName);
			if (action)
				return action.enabled && action.selected;
			else
				return false;
		}
		return false;
	}

	/**
	 * The working state of the target action.
	 */
	@Input()
	@HostBinding('class.doi-working')
	get working()
	{
		let action = this.action();
		if (this.target) {
			let action = this.target.action(this.actionName);
			if (action && action.working)
				return true;
			else
				return false;
		}
		return false;
	}

	/**
	 * The icon of the target action.
	 */
	get icon(): string
	{
		if (this._icon)
			return this._icon;

		let action = this.action();
		if (this.target) {
			let action = this.target.action(this.actionName);
			if (action)
				return action.icon;
			else
				return null;
		}
	}

	@Input()
	set icon(icon: string)
	{
		this._icon = icon;
	}

	/**
	 * Return the action, if defined. Delegates to the action target.
	 */
	action(): DoiAction
	{
		if (this.target && this.actionName)
			return this.target.action(this.actionName);
		else
			return undefined;
	}

	/**
	 * Translate the icon name to one or more class names separated by spaces.
	 * Delegates to the action target.
	 */
	iconClass(): string
	{
		if (this.target)
			return this.target.iconClass(this.icon);
		return null;
	}

	/**
	 * The title of the target action.
	 */
	get title(): string
	{
		if (this._title)
			return this._title;

		let action = this.action();
		if (action)
			return action.title;
		else
			return null;
	}

	@Input()
	set title(title: string)
	{
		this._title = title;
	}

	/**
	 * The explicit tooltip or the tooltip of the target action.
	 */
	get tooltip(): string
	{
		if (this._tooltip)
			return this._tooltip;

		let action = this.action();
		if (action)
			return action.tooltip;
		else
			return null;
	}

	@Input()
	set tooltip(tooltip: string)
	{
		this._tooltip = tooltip;
	}

	/**
	 * Invoked when the tool is clicked. If an action name and target is set, the action is located and executed.
	 */
	onClick(event: Event, arg?: any)
	{
		if (!this.enabled)
			return;

		this._armed = true;

		setTimeout(() => {
			this._armed = false;
			let action = this.action();
			if (action)
				action.execute(arg);
		})
	}
}
