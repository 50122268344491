
export class DoiLabeledValue<T>
{
	value: T;
	label: string;
	
	constructor(value: T, label: string)
	{
		this.value = value;
		this.label = label;
	}
	
	static of<T>(value: T, label: string): DoiLabeledValue<T>
	{
		return new DoiLabeledValue(value, label);
	}
}
