import { Component, OnInit, Directive } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiBrokerService, DoiLabeledValue, DoiObject, DoiService, DoiTopView} from '../../doi/DoiModule';
import {DoiSearchBrokerService} from '../../doi-search/DoiSearchModule';

import {DoiSearchCriteria} from '../model/DoiSearchCriteria';
import {DoiSearchResult} from '../model/DoiSearchResult';
import {DoiSearchResultEntry} from '../model/DoiSearchResultEntry';
import {DoiSearchService} from '../model/DoiSearchService';
import {DoiSearchWordMode} from '../model/DoiSearchWordMode';

@Directive()
export abstract class DoiSearchView extends DoiTopView implements OnInit
{
	name = 'DoiSearchView';

	/**
	 * The search service.
	 */
	searchService: DoiSearchService;

	/**
	 * The activated route.
	 */
	route: ActivatedRoute;

	/**
	 * Indicates that a search has been performed and there were no match. Used to show some warning message.
	 */
	noMatch: boolean = false;

	/**
	 * The broker services that can provide entry values.
	 */
	typeServices = new Map<string, DoiSearchBrokerService<DoiObject>>();

	/**
	 * The current search result.
	 */
	result: DoiSearchResult = null;

	/**
	 * Labels for a search word mode select component.
	 */
	searchWordModeLabeledValues: DoiLabeledValue<any>[];

	/**
	 * Construct a new search view.
	 * @param doi The DOI service.
	 * @param searchService The search service.
	 * @param route The activated route.
	 * @param brokerServices The broker services that can provide entry values.
	 */
	constructor(doi: DoiService, searchService: DoiSearchService, route: ActivatedRoute, ...brokerServiceNames: string[])
	{
		super(doi, route);

		this.searchService = searchService;
		this.route = route;

		for (let bs of doi.brokerServiceNames()) {
			let brokerService = doi.brokerService(bs)
			if ('readSearchResultEntries' in brokerService)
				this.typeServices.set(bs, brokerService as DoiSearchBrokerService<DoiObject>);
		}

		this.buildForm();

		this.searchWordModeLabeledValues = new Array<DoiLabeledValue<any>>();
		this.searchWordModeLabeledValues.push(DoiLabeledValue.of(DoiSearchWordMode.All, 'Alla ord'));
		this.searchWordModeLabeledValues.push(DoiLabeledValue.of(DoiSearchWordMode.Any, 'Något ord'));
		this.searchWordModeLabeledValues.push(DoiLabeledValue.of(DoiSearchWordMode.Phrase, 'Hel fras'));
	}

	/**
	 * Invoked when the view has been initialized.
	 * Overridden to check if an existing result should be reused.
	 */
	ngOnInit()
	{
		super.ngOnInit();

		this.result = this.searchService.result;

		if (this.result) {
			this.log('ngOnInit reuse result', this.result.criteria);
			this.populateForm(this.result.criteria);
			this.populateQuery(this.result.criteria);
			this.processResult(this.result);
		}
	}

	/**
	 * Invoked after the view has been initialized. Focuses the search field.
	 */
	ngAfterViewInit()
	{
		super.ngAfterViewInit();

		setTimeout(() => this.focusFirst());
	}

	/**
	 * Build the form containing search text, word mode and count limit.
	 * Override to use another search field, etc.
	 */
	buildForm()
	{
		this.formGroup = new FormGroup({
			searchText: new FormControl('', [Validators.required, Validators.minLength(2)]),
			searchWordMode: new FormControl(null),
			countLimit: new FormControl('1000', [Validators.required])
		});
	}

	/**
	 * The count limit in the form.
	 */
	get formCountLimit(): number
	{
		return this.formGroup ? this.formGroup.value.countLimit : null;
	}
	set formCountLimit(countLimit: number)
	{
		if (this.formGroup)
			this.formGroup.get('countLimit').setValue(countLimit);
	}

	/**
	 * The search text in the form.
	 */
	get formSearchText(): string
	{
		return this.formGroup ? this.formGroup.value.searchText : null;
	}
	set formSearchText(searchText: string)
	{
		if (this.formGroup)
			this.formGroup.get('searchText').setValue(searchText);
	}

	/**
	 * The Search word mode in the form.
	 */
	get formSearchWordMode(): DoiSearchWordMode
	{
		return this.formGroup ? this.formGroup.value.searchWordMode : null;
	}
	set formSearchWordMode(searchWordMode: DoiSearchWordMode)
	{
		if (this.formGroup)
			this.formGroup.get('searchWordMode').setValue(searchWordMode);
	}

	/**
	 * The search text in the form, if valid.
	 */
	get formSearchValidText(): string
	{
		return this.formGroup && this.formGroup.valid ? this.formGroup.value.searchText : null;
	}

	/**
	 * Translate an icon name to one or more class names separated by spaces.
	 * Overridden to add
	 */
	entryIconClass(entry: DoiSearchResultEntry): string
	{
		let c = entry.iconName ? this.iconClass(entry.iconName) : '';

		if (entry.objectType)
			return c+' '+entry.objectType.toLowerCase();
		else
			return c;
	}

	/**
	 * Invoked when query parameters are received.
	 * Overridden to create a criteria, populate the search form and finally refresh the view.
	 */
	queryParamsRecieved(pm: ParamMap): void
	{
		let criteria = this.criteriaFromParams(pm);
		this.log('queryParamsRecieved', criteria);

		this.populateForm(criteria);

		//	Throw away a result with a different criteria to prevent it from being reused.
		if (!this.searchService.result || !criteria.equals(this.searchService.result.criteria))
			this.searchService.result = null;

		this.search();
	}

	/**
	 * Build entry text for the specified entry. Invoked by {@link DoiSearchService#fetchResultEntries} for each entry when object data has been fetched.
	 * The default implementation builds an entry with the object title, icon name and object text.
	 */
	buildEntryText(entry: DoiSearchResultEntry): void
	{
		entry.headerText = entry.object.objectTitle();
		entry.iconName = entry.object.iconName;
		entry.bodyText = entry.object.objectText();

		if (entry.headerText && entry.headerText.length > 75)
			entry.headerText = entry.headerText.substring(0, 97)+'...';
		if (entry.bodyText && entry.bodyText.length > 200)
			entry.bodyText = entry.bodyText.substring(0, 197)+'...';
	}

	/**
	 * Build a page with entries in selected categories.
	 */
	buildResultPage()
	{
		this.result.filterResultPage();

		this.searchService.fetchResultEntries(this.result.pageEntries, this.typeServices, (entry: DoiSearchResultEntry) => this.buildEntryText(entry));
	}

	/**
	 * Clears the search field, creates a new empty result and invokes {@link #processResult}.
	 */
	clear()
	{
		this.doi.router.navigate(['/search']);

		this.formSearchText = null;
		this.processResult(new DoiSearchResult());

		setTimeout(() => this.focusFirst());
	}

	/**
	 * Create a search criteria from query parameters.
	 */
	criteriaFromParams(pm: ParamMap): DoiSearchCriteria
	{
		let wmp = pm.get('wordMode');

		let criteria = new DoiSearchCriteria(pm.get('text'), pm.get('scope'), wmp ? parseInt(wmp) : DoiSearchWordMode.All);

		let objectTypes = pm.getAll('objectTypes');
		if (objectTypes.length)
			criteria.objectTypes = objectTypes;

		this.log('criteriaFromParams', criteria);

		return criteria;
	}

	/**
	 * Create a search criteria from the current form values. Invoked by {@link #refreshView}.
	 */
	criteriaFromForm(): DoiSearchCriteria
	{
		let criteria = new DoiSearchCriteria(this.formSearchValidText, null, this.formSearchWordMode);

		criteria.objectTypes = this.searchObjectTypes();

		this.log('criteriaFromForm', criteria);
		return criteria;
	}

	/**
	 * Populate the search form from a search criteria. Invoked by {@link #queryParamsRecieved}.
	 * The default implementation sets the 'searchText' field.
	 * Override to handle, e g scope.
	 */
	populateForm(criteria: DoiSearchCriteria)
	{
		this.log('populateForm criteria:', criteria);
		this.formSearchText = criteria.text;
		this.formSearchWordMode = criteria.wordMode;
	}

	/**
	 * Populate the navigation query with parameters from a search criteria.
	 */
	populateQuery(criteria: DoiSearchCriteria)
	{
		this.log('populateQuery criteria:', criteria);
		if (criteria)
			this.doi.router.navigate(['/search'], {queryParams: criteria.queryParams()});
	}

	/**
	 * Process a search result delivered by the search service or an empty result from {@link #clear}.
	 * Saves the current search criteria in the result and invokes {@link #buildResultPage}.
	 * Finally sets the result as the current result in the search service.
	 */
	processResult(result: DoiSearchResult)
	{
		result.criteria.text = this.formSearchText;
		result.pageSize = 20;

		this.result = result;
		this.noMatch = result.searchID > 0 && result.countTotal == 0;

		this.buildResultPage();

		this.searchService.result = this.result;
	}

	/**
	 * Invoked by the Search button or form submit to perform a search. Validates the form and invokes {@link DoiView#refresh}, which invokes {@link #refreshView}.
	 */
	search()
	{
		if (this.refreshing)
			return;

		this.noMatch = false;

		if (this.formGroup && !this.formGroup.valid) {
			for (let cn in this.formGroup.controls) {
				let control = this.formGroup.get(cn);
				let errors = control.errors;
				if (errors && errors['required'])
					return;
			}
			return;
		}

		this.refresh();
	}

	/**
	 * Return the object type names for the object types that should be searched. The default implementation returns null, for all supported object types.
	 */
	searchObjectTypes(): string[]
	{
		return null;
	}

	/**
	 * Search for the current search text by invoking {@link DoiSearchService#search}. When a result is received {@link #processResult} is invoked.
	 */
	refreshView(): Observable<any>
	{
		let criteria = this.criteriaFromForm();

		this.log('refreshView', criteria, this.result)

		this.populateQuery(criteria);

		if (criteria && criteria.text) {
			this.log('refreshView search', criteria);
			let result = this.result || this.searchService.result;
			let pageIndex = result ? result.pageIndex : 0;
			return this.searchService.search(criteria, this.formCountLimit, this.searchObjectTypes()).pipe(
				tap(
					(result: DoiSearchResult) => {
						if (criteria.equals(this.criteriaFromForm())) {
							result.pageIndex = pageIndex;
							this.processResult(result);
						}
					}
				)
			);
		} else {
			return super.refreshView();
		}
	}

	/**
	 * Test if the search button should be enabled.
	 */
	searchButtonEnabled(): boolean
	{
		if (this.refreshing)
			return false;

		if (this.formGroup)
			return this.formGroup.valid;

		return true;
	}

	/**
	 * Return word mode values.
	 */
	searchWordModeValues(): (filterText?: string) => Observable<DoiLabeledValue<any>[]>
	{
		return (filterText?: string) => {
			return of(this.searchWordModeLabeledValues);
		};
	}

	/**
	 * Invoked when a search word mode is selected. If the form is valid, search is invoked.
	 */
	searchWordModeSelected(): void
	{
		if (this.formGroup && this.formGroup.valid)
			this.search();
	}

	/**
	 * Test if the search word mode selector is enabled.
	 */
	searchWordModeEnabled(): boolean
	{
		let text : string = this.formSearchText;
		if (!text)
			return true;
		let enabled = text.match(".*[\\s,].*") != null;
		return enabled;
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Sök'; // I18N
	}
}

