import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {map, tap} from "rxjs/operators";

import {DoiService, DoiBrokerService} from '../../doi/DoiModule';

import {SystemInfoPart, SystemInfoObject} from './SystemInfoObject';

import {AppInfo, DocStoreLink, KlaraBrokerService} from '../../klara/KlaraModule';

/**
 *	Object part names for the Process entity object.
 */
export enum PublishLevel {
	No,
	Internal,
	External
}

/**
 * Manages SystemInfo objects.
 */
@Injectable()
export class SystemInfoService extends KlaraBrokerService<SystemInfoObject>
{
	/**
	 * Construct a new SystemInfo service.
	 */
	constructor(doi: DoiService)
	{
		super(doi, 'SystemInfo')
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return SystemInfoObject.iconName;
	}

	/**
	 * Create a new SystemInfo object.
	 */
	newObject(objectID: number): SystemInfoObject
	{
		return new SystemInfoObject(objectID);
	}

	/**
	 * Read application version and license information.
	 */
	readAppInfo(): Observable<AppInfo>
	{
		return this.doi.http.get<AppInfo>(this.doi.auth.urlContextPub()+'/info/info.json').pipe(
			map((info: AppInfo) => {
				return new AppInfo(info);
			})
		);
	}


	/**
	 * Read the requested publish level for the user.
	 */
	readPublishLevel(): Observable<PublishLevel>
	{
		return this.doi.http.get(this.doi.auth.urlContext()+'/publishLevel/publishLevel.json').pipe(
			map((result: any) => {
				return result.publishLevel;
			})
		);
	}
}

// dLMFr0hucx2g7YNTwG+DFhOhYAs=
