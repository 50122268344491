<div class="doi-subview doi-scroll">

	<form role="form">
		<div class="row">
			<div class="col-12 col-xl-11">
				<doi-textarea name="Notes" labelEdit="Anmärkning" size="100" [(ngModel)]="object.notes" [edit]="editing()"></doi-textarea>
			</div>
		</div>
		<div class="row" >
			<div *ngIf="object?.location" class="col-12 col-lg-9 col-xl-6">
				<doi-field name="Location" label="Plats" size="60" [(ngModel)]="object.location" [edit]="editing()"></doi-field>
			</div>
		</div>

		<h3 *ngIf="docStoreLinks?.length">Dokumentlager</h3>
		<docstore-links [docStoreLinks]="docStoreLinks"></docstore-links>

		<h3 *ngIf="object.objectFiles?.length">Referenser</h3>
		<object-files [objectFiles]="object.objectFiles" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files>

	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
