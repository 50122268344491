<div class="doi-subview doi-scroll">

	<form #form="ngForm" role="form">
		<div class="row">
			<div class="col-12 col-xl-11">
				<doi-textarea name="name" label="Namn" labelEdit="Namn" maxLength="100" rows="2" [required]="true" [(ngModel)]="object.nameML" [edit]="editingAsStructAdm()"></doi-textarea>
			</div>
		</div>
		<div class="row" *ngIf="editingAsStructAdm() || object?.description">
			<div class="col-12 col-xl-11">
				<doi-textarea name="description" label="Beskrivning" [(ngModel)]="object.description"
					[edit]="editingAsStructAdm()"></doi-textarea>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field type="date" name="TidFran" label="Tid från" [(ngModel)]="object.dateBegin" ></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field type="date" name="TidTill" label="Tid till" [(ngModel)]="object.dateEnd" ></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="PrevCode" label="Tidigare beteckning" [(ngModel)]="object.prevCode" ></doi-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-xl-11">
				<doi-field name="AltNames" label="Publika namn" [(ngModel)]="object.altNameList" ></doi-field>
			</div>
		</div>
	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
