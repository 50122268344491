/**
 *	Licensed feature.
 */
export enum Feature
{
	Process,
	Unused,
	ObjectAuth,
	Web,
	Person,
	Demo,
	Integration,
	Culling,
	AuditLog,
	Dialog,
	WebFull,
	Experimental
}
