
/**
 * A key mapping.
 */
export class DoiKeyMapping
{
	/**
	 * The key code. Examples: "KeyS", "Escape".
	 */
	readonly keyCode: string;

	/**
	 * Modifiers.
	 */
	readonly modifierAlt: boolean;
	readonly modifierCtrl: boolean;
	readonly modifierMeta: boolean;
	readonly modifierShift: boolean;

	/**
	 * Construct a new key mapping.
	 * @param keycode Key code. Examples: "KeyS", "Escape".
	 * @param modifiers Modifiers. "A" for Alt, "C" for "Ctrl", "M" for "Meta", "S" for "Shift". Example: "CA" for Ctrl+Alt.
	 */
	constructor(keyCode : string, modifiers: string = null)
	{
		this.keyCode = keyCode;

		if (modifiers) {
			for (let i = 0; i < modifiers.length; ++i) {
				switch (modifiers.charAt(i)) {
					case 'A':	this.modifierAlt = true; break;
					case 'C':	this.modifierCtrl = true; break;
					case 'M':	this.modifierMeta = true; break;
					case 'S':	this.modifierShift = true; break;
				}
			}
		}
	}

	/**
	 * Test if this key mapping matches the specified event.
	 */
	matchesEvent(event: KeyboardEvent): boolean
	{
		if (this.keyCode != event.code)
			return false;
		if (this.modifierAlt && !event.altKey)
			return false;
		if (this.modifierCtrl && !event.ctrlKey)
			return false;
		if (this.modifierMeta && !event.metaKey)
			return false;
		if (this.modifierShift && !event.shiftKey)
			return false;
		return true;
	}

	/**
	 * Return the key mapping text.
	 */
	text(): string
	{
		let text = new Array<string>();
		if (this.modifierCtrl)
			text.push('Ctrl');
		if (this.modifierShift)
			text.push('Shift');
		if (this.modifierAlt)
			text.push('Alt');
		if (this.modifierMeta)
			text.push('Cmd');
		if (this.keyCode.startsWith('Key'))
			text.push(this.keyCode.substring(3));
		else
			text.push(this.keyCode);
		return text.join('+');
	}
}