import {Observable} from 'rxjs';
import {map} from "rxjs/operators";

import {DoiIconMapper, DoiObjectRef, DoiSelectionCriteria} from '../doi/DoiModule';
import {DoiNavNode, DoiNavNodeChildSupplier} from '../doi-navigator/DoiNavigatorModule';

import {ArchiveOrigService, ArchiveOrigObject} from '../archiveorig/ArchiveOrigModule';

/**
 * A navigator node child supplier for the structure folder node containing top level archive originators.
 */
export class StructureFolderNodeChildSupplier extends DoiNavNodeChildSupplier
{
	constructor(
		private processStructureService: ArchiveOrigService,
		private archiveOrigCS: DoiNavNodeChildSupplier)
	{
		super();
	}

	readChildren(node: DoiNavNode): Observable<DoiNavNode[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('ArchiveOrig_ArchiveOrigID', 'ArchiveOrig_Sequence', 'ArchiveOrig_Name', 'ArchiveOrig_ParentArchiveOrigID', 'ArchiveOrig_ForGrouping')
			.where('ArchiveOrig_ParentArchiveOrigID', '=')
			.orderBy('ArchiveOrig_Sequence', 'ArchiveOrig_Name', 'ArchiveOrig_ArchiveOrigID');

		return this.processStructureService.readObjectSelection(null, criteria).pipe(
			map((archiveOrigs: ArchiveOrigObject[]) =>
			{
				let nodes = new Array<DoiNavNode>();
				for (let archiveOrig of archiveOrigs) {
					let objectRef = DoiObjectRef.forObject('ArchiveOrig', archiveOrig.objectID);
					nodes.push(
						new DoiNavNode(archiveOrig.name, '/archiveorig/'+archiveOrig.objectID)
							.setObjectRef(objectRef)
							.setIconName(archiveOrig.iconName)
							.setIconMapper(node.getIconMapper())
							.setChildNodeSupplier(this.archiveOrigCS)
					);
				}
				return nodes;
			})
		);
	}
}
