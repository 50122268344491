import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';

import {DoiModule} from '../doi/DoiModule';

import {DoiNavNodeComponent} from './view/DoiNavNodeComponent';
import {DoiNavRootComponent} from './view/DoiNavRootComponent';

export * from './model/DoiNavNode';
export * from './model/DoiNavNodeChildSupplier';
export * from './view/DoiNavigatorAppView';

@NgModule({
	imports: [
		BrowserModule, DoiModule, RouterModule
	],
	declarations: [
		DoiNavNodeComponent, DoiNavRootComponent
	],
	exports: [
		DoiNavNodeComponent, DoiNavRootComponent
	],
	providers: [
	],

})
export class DoiNavigatorModule
{
}
