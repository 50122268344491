import {Injectable} from '@angular/core';
import {Router, NavigationStart} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

import {DoiLogLevel, DoiLogService} from './DoiLogService';

export class DoiAlertMessage
{
	text: string;
	level: DoiLogLevel;

	constructor(level: DoiLogLevel, message: string)
	{
		this.level = level;
		this.text = message;
	}
}

@Injectable()
export class DoiAlertService
{
	private _subject = new Subject<DoiAlertMessage>();
	private _keep = false;

	/**
	 * Ignore authorization errors during login.
	 */
	ignoreErrors: boolean;

	constructor(
		private log: DoiLogService,
		private router: Router)
	{
		router.events.subscribe(event =>
		{
			if (event instanceof NavigationStart) {
				if (!this._keep) {
					// Clear alert.
					this._subject.next(null);
				}
			}
		});
	}

	clear(force = false)
	{
		if (force || !this._keep)
			this._subject.next(null);
	}

	subject(): Observable<any>
	{
		return this._subject.asObservable();
	}

	message(level: DoiLogLevel, text: string, keep = false)
	{
		this.log.log(level, 'Alert: '+text);
		this._keep = keep;
		this._subject.next(new DoiAlertMessage(level, text));
	}

	info(text: string, keep = false)
	{
		this.message(DoiLogLevel.INFO, text, keep);
	}

	warning(text: string, keep = false)
	{
		this.message(DoiLogLevel.WARNING, text, keep);
	}

	severe(text: string, keep = false)
	{
		if (this.ignoreErrors)
			return;

		this.message(DoiLogLevel.SEVERE, text, keep);
	}

	errorResponse(error: HttpErrorResponse, keep = false)
	{
		//	Check for ignored error during login.
		if (this.ignoreErrors)
			return;
		if (error.status == 0)
			return;
		this.message(DoiLogLevel.SEVERE, error.message, keep);
	}
}
