/**
 * An icon mapper. Translates an icon name and optional category to one or more class names.
 */
export interface DoiIconMapper
{
	/**
	 * Translate an icon name to one or more class names separated by spaces.
	 */
	iconClass(iconName: string): string;
}
