import {KlaraTopView} from '../../klara/KlaraModule';

/*
 * Implemented by views that have a correspondence in Klara Dialog.
 */
export interface KlaraDialogTopView extends KlaraTopView
{
	/**
	 * Return the corresponding path in Klara Dialog, null if not available or an empty string for the same URL.
	 */
	dialogPath() : string;
}
