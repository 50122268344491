<div class="doi-subview doi-scroll">

	<form role="form">
		<div class="row" *ngIf="object.notes">
			<div class="col-12 col-xl-11">
				<doi-textarea name = "Beskrivning" labelEdit="Beskrivning" size="60" [(ngModel)]="object.notes" [edit]="editing()" [ngModelOptions]="{standalone: true}"></doi-textarea>
			</div>
		</div>
	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
