import {Component, EventEmitter, Input, Output} from '@angular/core';

import {DoiSearchResult} from '../model/DoiSearchResult';

/**
 * A search result page footer.
 */
@Component({
	selector: 'doi-search-result-page-footer',
	host: { 'class' : 'doi-search-result-page-footer' },
	template: `
		<doi-search-pager [pageCount]="result?.pageCount" [pageIndex]="result?.pageIndex" (click)="onPagerClick($event)"></doi-search-pager>
	`
})
export class DoiSearchResultPageFooterComponent
{
	/**
	 * The search result.
	 */
	@Input() result: DoiSearchResult;

	/**
	 * The maximum number of page selector items. Initially 7.
	 */
	@Input()
	maxPageItems: number = 7;

	/**
	 * Emits when a page item is clicked and the page index has been changed.
	 */
	@Output() pageChanged = new EventEmitter();

	/**
	 * Set the current page index and notify.
	 */
	onPagerClick(pageNo: number)
	{
		if (pageNo >= 1 && pageNo <= this.result.pageCount) {
			this.result.pageIndex = pageNo-1;
			this.pageChanged.emit();
		}
	}
}
