<div class="doi-subview">

	<div *ngIf="object?.series && object?.series?.length > 0" class="doi-scroll-container">
		<table class="table table-striped table-hover">
			<thead>
				<tr class="doi-clickable">
					<th class="doi-grow-1">Signum</th>
					<th class="doi-grow-2">Titel</th>
					<th class="doi-grow-3 doi-hidden-xs-down">Plats</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let series of object.series" (click)="openSeries(series)" class="doi-clickable">
					<td class="doi-grow-1 doi-wrap"><a [routerLink]="routerLinkSeries(series)">{{series.signum}}</a></td>
					<td class="doi-grow-2 doi-wrap"><a [routerLink]="routerLinkSeries(series)">{{series.title}}</a></td>
					<td class="doi-grow-3 doi-wrap doi-hidden-xs-down">{{series.location}}</td>
					<td>&nbsp;</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
