<div class="doi-view settings-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start" [homeIcon]="isAppWebUI()" [tailImplied]="isAppWebUI()" #focusFirst>
			<li doi-breadcrumb text="Inställningar" *ngIf="isAppDialog()"></li>
		</ol>
	</doi-view-title>

	<doi-title><span>Inställningar</span></doi-title>

	<div class="doi-scroll">

		<form class="doi-subview" name="settings" (ngSubmit)="submit()" [formGroup]="formGroup" role="form" #focusFirst novalidate>
			<div class="row">
				<div class="col-12">
					<h3>Tema</h3>
				</div>
			</div>
			<div *ngIf="themeList" class="row themes">
				<div *ngFor="let theme of themeList" class="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
					<a href="" (click)="selectTheme(theme)" class="doi-card-container">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title d-flex justify-content-between">{{theme.label}}<i class="fas" [ngClass]="isSelectedTheme(theme)?'fa-check':null"></i></h5>
								<p class="card-text">{{theme.description}}</p>
							</div>
						</div>
					</a>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-12">
					<h3>Sparade inställningar</h3>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<doi-checkbox [(ngModel)]="rememberSettings" [ngModelOptions]="{standalone: true}" [edit]="true" (change)="rememberSettingsChanged()">Kom ihåg mina inställningar och besökta sidor</doi-checkbox>
				</div>
			</div>
			<div class="row doi-dialog-buttons mt-2">
				<div class="col-12">
					<button class="btn btn-primary" *ngIf="authenticated()" (click)="forgetSettingsAndLogOut()">Logga ut och glöm mig på den här enheten</button>
				</div>
			</div>
			<div class="row mt-3" *ngIf="debugModeAvailable()">
				<div class="col-12">
					<h3>Övrigt</h3>
				</div>
			</div>
			<div class="row">
				<div class="col-12" *ngIf="debugModeAvailable()">
					<doi-checkbox [(ngModel)]="debugMode" [ngModelOptions]="{standalone: true}" [edit]="true" (change)="debugModeChanged()">Utvecklingsläge</doi-checkbox>
				</div>
			</div>
		</form>
	</div>

</div>