import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';

import {ArchiveOrigPart, ArchiveOrigObject, Archive, ArchOrigProcStructObject} from '../model/ArchiveOrigObject';

@Component({
	templateUrl: 'ArchiveOrigObjectArchivesPanel.html'
})
export class ArchiveOrigObjectArchivesPanel extends DoiObjectPanel<ArchiveOrigObject>
{
	name = 'archives';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openArchive(archive : Archive)
	{
		this.appView.openObject('Archive', archive.archiveID);
	}

	routerLinkArchive(archive : Archive)
	{
		return archive.archiveID ? ['/archive', archive.archiveID] :null;
	}

	/**
	 * Return the icon class for the specified object.
	 */
	archiveIconClass()
	{
		return this.iconClass(Archive.iconName);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ArchiveOrigPart.Archives),
		);
	}
}

// rS9PI84cysHrMnRkC1Y62Cfx+ZY=
