<div class="doi-subview doi-scroll">

	<form #form="ngForm" role="form">
		<div class="row" *ngIf="editing()">
			<div class="col-12 col-xl-11">
				<doi-field name="NameShort" labelEdit="Kortnamn" size="60" maxLength="50" [(ngModel)]="object.nameShort" [edit]="editingAsEditorOrUser()"></doi-field>
			</div>
		</div>
		<div class="row" *ngIf="editing() || object?.descriptionShort">
			<div class="col-12 col-xl-11">
				<doi-textarea name="descriptionShort" labelEdit="Kort beskrivning" rows="2" maxLength="225" size="60" [(ngModel)]="object.descriptionShort" [edit]="editingAsEditorOrUser()"></doi-textarea>
			</div>
		</div>
		<div class="row" *ngIf="!editing() && (!object?.descriptionShort && object?.description)">
			<div class="col-12 col-xl-11">
				<doi-textarea name="description" labelEdit="Beskrivning" size="60" [(ngModel)]="object.description" [edit]="editingAsEditorOrUser()"></doi-textarea>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<property-values [view]="this" groupTitle="Hantering" groupTitleOther="Övrig hantering" [propertyValues]="propertyValues()" [cellsLG]="4" [edit]="editingAsEditorOrUser()"></property-values>
			</div>
		</div>
		<h3 *ngIf="object.objectFiles?.length">Referenser</h3>
		<div class="row">
			<div class="col-12"><object-files [objectFiles]="object.objectFiles" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files></div>
		</div>
	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
