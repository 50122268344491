import {EventEmitter} from '@angular/core';
import {Observable, Observer, BehaviorSubject, Subscription, timer} from 'rxjs';

import {DoiService} from '../service/DoiService';

/**
 * Emits refresh events periodically or when a web socket sends a message.
 */
export class DoiRefreshHandler
{
	doi: DoiService;

	private refreshEventEmitter: EventEmitter<string> = new EventEmitter<string>();

	private periodSubject = new BehaviorSubject<number>(undefined);

	private timerAlways: boolean = false;
	private timerPeriod: number = 5000;
	private timerSubscription: Subscription;
	private wsURI: string;
	private websocket: WebSocket;
	private websocketPossible: boolean = undefined;
	private websocketOpen: boolean = false;
	private refreshEnabled: boolean = true;

	/**
	 * Construct a new refresh handler.
	 */
	constructor(doi: DoiService, private messageFilterRE: RegExp)
	{
		this.doi = doi;

		//	Determine the servlet context part of the document location, e g "/doistudio" for ""http://localhost:8080/doistudio/monitor/…".
		let contextRE = new RegExp('http(s)?://[^/]+(/[^/]+)/.*');
		let match = document.location.href.match(contextRE);
		let urlContext = match ? match[2] : '';

		//	Build the WebService URI.
		let wsProtocol = document.location.protocol == 'https:' ? 'wss:' : 'ws:';
		this.wsURI = wsProtocol + '//' + document.location.host + urlContext + '/monitorevents';
	}

	dispose()
	{
		if (this.timerSubscription)
			this.timerSubscription.unsubscribe();
		this.disposeWebSocket();
	}

	/**
	 * Switch automatic refresh on or off.
	 */
	autoRefresh(enabled: boolean)
	{
		this.refreshEnabled = enabled;
		if (enabled)
			this.afterRefresh();
	}

	/**
	 * Test if automatic refresh is switched on.
	 */
	autoRefreshEnabled(): boolean
	{
		return this.refreshEnabled;
	}

	/**
	 * Specify if a timer should be used even if a web socket could be opened.
	 */
	setTimerAlways(always: boolean)
	{
		this.timerAlways = always;
	}

	/**
	 * Set the timer period in ms.
	 */
	setTimerPeriod(period: number)
	{
		this.timerPeriod = period;
	}

	/**
	 * Return the refresh period to use.
	 * If the "timerAlways" property is true, the configured period is always returned.
	 * If the "timerAlways" property is false, which is the default, the configured timer period is returned only if a web socket has been determined to be unavailable.
	 * If a web socket is open, 0 is returned to indicate that no refresh timer should be started.
	 * If it is not yet known if a web socket can be opened, undefined is returned to indicate that a timer should be used for now.
	 */
	refreshPeriod(): number
	{
		if (this.timerAlways)
			return this.timerPeriod;

		if (this.websocketPossible == undefined)
			return undefined;

		return this.websocketOpen ? 0 : this.timerPeriod;
	}

	period(): Observable<number>
	{
		return this.periodSubject;
	}

	/**
	 * Start observing this refresh handler. Returns an observable that will receive web socket messages or periodic nulls, if a timer is used instead.
	 */
	start(): Observable<string>
	{
		this.openWebSocket();

		return this.refreshEventEmitter;
	}

	emitRefresh(data: string)
	{
		this.disposeTimer();
		if (this.refreshEnabled)
			this.refreshEventEmitter.emit(data);
	}

	/**
	 * Must be invoked by the application after a refresh has completed. Attempts to open a web socket if possible, or uses a timer.
	 */
	afterRefresh()
	{
		this.disposeTimer();

		if (!this.refreshEnabled)
			return;

		let period = this.refreshPeriod();

		if (this.websocketOpen) {
			if (period != 0)
				this.createTimer();
		} else {
			if (period == undefined || period != 0)
				this.createTimer();
			this.openWebSocket();
		}
	}

	isWebSocketOpen(): boolean
	{
		return this.websocketOpen;
	}

	createTimer()
	{
		this.disposeTimer();

		if (this.timerPeriod >= 2000) {
			let t = timer(this.timerPeriod);
			this.timerSubscription = t.subscribe(tick =>
			{
				this.emitRefresh(null);
			});
		} else {
			this.doi.log.warning("Invalid timerPeriod: "+this.timerPeriod);
		}
	}

	disposeTimer()
	{
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
			this.timerSubscription = null;
		}
	}

	disposeWebSocket()
	{
		if (this.websocket) {
			this.doi.log.config('Closing WebSocket: ' + this.wsURI);
			this.websocket.close();
		}

		this.websocket = null;
		this.websocketOpen = false;
		this.periodSubject.next(this.refreshPeriod());
	}

	error()
	{
		this.disposeWebSocket();
		this.afterRefresh();
	}

	openWebSocket()
	{
		if (this.websocket)
			return;

		if (this.websocketPossible == false)
			return;

		if (!this.doi.auth.isAuthenticated())
			return;

		try {

			this.websocket = new WebSocket(this.wsURI);
			let _this = this;

			this.websocket.onopen = function (evt)
			{
				_this.websocketPossible = true;
				_this.doi.log.config('Successfully opened WebSocket: ' + _this.wsURI);
				_this.websocketOpen = true;
				_this.periodSubject.next(_this.refreshPeriod());
			};

			this.websocket.onerror = function (evt)
			{
				_this.doi.log.warning('Failed to open WebSocket, using periodical refresh:', evt);
				_this.disposeWebSocket();
			};

			this.websocket.onmessage = function (evt)
			{
				if (_this.messageFilterRE == null || evt.data.match(_this.messageFilterRE)) {
					_this.emitRefresh(evt.data);
				}
			};

		} catch (ex) {
			this.websocketPossible = false;
			this.doi.log.severe('WebSocket error: ', ex);
		}
	}
}

