import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';
import { tap } from 'rxjs/operators';

import {DoiService, DoiObjectPanel, DoiSelectionCriteria} from '../../doi/DoiModule';
import { SeriesObject } from '../../series/SeriesModule';

import { SeriesGroupObject } from '../SeriesGroupModule';


@Component({
	templateUrl: 'SeriesGroupObjectSeriesPanel.html'
})
export class SeriesGroupObjectSeriesPanel extends DoiObjectPanel<SeriesGroupObject>
{
	name = 'series';

	series: SeriesObject[];

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openSeries(series : SeriesObject)
	{
		this.appView.openObject('Series', series.objectID);
	}

	routerLinkSeries(series : SeriesObject)
	{
		return ['/series', series.objectID];
	}

	readSeries(): Observable<SeriesObject[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Series_SeriesID', 'Series_Signum', 'Series_Title')
			.where('Series_SeriesGroupID', '='+this.objectID)
			.orderBy('Series_Signum')
			.limit(20);

		return this.doi.brokerService('Series').readObjectSelection(null, criteria);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.readSeries().pipe(tap((series: SeriesObject[]) => {this.series = series})),
		);
	}
}

// rS9PI84cysHrMnRkC1Y62Cfx+ZY=
