<div class="doi-subview">

	<div *ngIf="object.archives?.length" class="doi-scroll-container">
		<table class="table table-striped table-hover">
			<thead>
				<tr class="doi-clickable">
					<th class="doi-grow-3">Namn</th>
					<th class="doi-grow-1">Inst.kod</th>
					<th class="doi-grow-1 doi-hidden-xs-down">Best.Kod</th>
					<th class="doi-grow-5 doi-hidden-xs-down">Landskod</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let archive of object.archives" (click)="openArchive(archive)" class="doi-clickable">
					<td class="doi-grow-3 doi-wrap"><a [routerLink]="routerLinkArchive(archive)">{{archive.name}}</a></td>
					<td class="doi-grow-1 doi-wrap">{{archive.archiveInstCode}}</td>
					<td class="doi-grow-1 doi-wrap doi-hidden-xs-down">{{archive.refCode}}</td>
					<td class="doi-grow-5 doi-wrap doi-hidden-xs-down">{{archive.countryCode}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
