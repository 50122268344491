import {NgModule} from '@angular/core';

import {DoiModule} from '../doi/DoiModule';

import {SystemInfoService} from './model/SystemInfoService';

export * from './model/SystemInfoObject';
export * from './model/SystemInfoService';

@NgModule({
	imports: [
		DoiModule,
	],
	declarations: [
	],
	exports: [
	],
	providers: [
		SystemInfoService,
	],

})
export class SystemInfoModule
{
}

// c0HJ2XSPJ1towrX7slIR633RkVw=
