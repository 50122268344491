import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

/**
 * A search form.
 */
@Component({
	selector: 'doi-search-form',
	host: { 'class' : 'doi-search-form' },
	template: `
		<div class="form-inline" role="form">
			<form name="search" (ngSubmit)="onSearch()" [formGroup]="formGroup" novalidate>
				<div class="row">
					<div class="col-12 col-lg-6 col-xl-4">
						<doi-field inputId="search-input" class="doi-search-text" formControlName="searchText" [edit]="true"></doi-field>
						<button id="search-submit" type="submit" [disabled]="searching || !formGroup?.valid" class="btn btn-primary">Sök</button>
						<button type="button" class="btn" (click)="onClear()">Rensa</button>
					</div>
				</div>
			</form>
		</div>
	`
})
export class DoiSearchFormComponent
{
	@Input()
	formGroup: FormGroup;

	@Input()
	searching: boolean;
	
	@Output() search = new EventEmitter();
	
	@Output() clear = new EventEmitter();
	
	onSearch()
	{
		this.search.emit();
	}	
	
	onClear()
	{
		this.clear.emit();
	}	
}
