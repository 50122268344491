<div class="doi-subview doi-scroll">

	<form role="form">
		<div class="row" *ngIf="object.notes">
			<div class="col-12 col-lg-9 col-xl-6">
				<doi-textarea name = "Beskrivning" labelEdit="Beskrivning" size="60" [(ngModel)]="object.notes" [edit]="editing()" [ngModelOptions]="{standalone: true}"></doi-textarea>
			</div>
		</div>
		<property-values [view]="this" [propertyValues]="object?.propertyValues"></property-values>

		<h3 *ngIf="docStoreLinks?.length">Dokumentlager</h3>
		<docstore-links [docStoreLinks]="docStoreLinks"></docstore-links>

		<h3 *ngIf="object.objectFiles?.length">Referenser</h3>
		<div class="row">
			<div class="col-12"><object-files [objectFiles]="object.objectFiles" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files></div>
		</div>

		<h3 *ngIf="children?.length">Ingående arkivbildare</h3>
		<div doi-object-grid [iconMapper]="this" [objects]="children" moreView="children"></div>

		<h3 *ngIf="object.processStructures?.length">Klassificeringsstrukturer</h3>
		<div doi-object-grid>
			<div doi-object-grid-item *ngFor="let procStruct of object.processStructures"
				[link]="routerLinkProcessStructure(procStruct)" [iconClass]="processStructureIconClass()" title="{{procStruct.name}}">
			</div>
		</div>

		<h3 *ngIf="archives?.length">Arkiv</h3>
		<div doi-object-grid [iconMapper]="this" [objects]="archives" moreView="archives"></div>

	</form>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
