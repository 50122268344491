/**
 * An identity provider.
 */
export class DoiIdentityProvider
{
	/**
	 * The provider ID.
	 */
	providerID: string;

	/**
	 * The provider name.
	 */
	providerName: string;

	/**
	 * The provider icon as BASE64 encoded image bytes.
	 */
	providerIcon: string;
}

