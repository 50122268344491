import {ActivatedRoute, ParamMap} from '@angular/router';
import {Observable, EMPTY, of} from 'rxjs';
import {concatMap, map, switchMap, tap} from "rxjs/operators";

import {DoiAction} from '../core/DoiAction';
import {DoiService} from '../service/DoiService';
import {DoiBrokerService} from '../service/DoiBrokerService';
import {DoiModalType, DoiModalResult} from './DoiModalDialog';
import {DoiObject} from '../service/DoiObject';
import {DoiObjectBookmark} from '../bookmark/DoiObjectBookmark';
import {DoiBinderView} from './DoiBinderView';
import {DoiObjectPanel} from './DoiObjectPanel';
import {Directive, HostListener} from "@angular/core";

@Directive()
export abstract class DoiObjectView<T extends DoiObject> extends DoiBinderView<T>
{
	name = 'DoiObjectView';

	private _objectID: number = null;
	private _object: T;
	private _cleanDataParts: any;
	private _cancelID: number = null;

	activePanel: DoiObjectPanel<T>;

	private _dirty: boolean;
	private _editing: boolean = false;
	private _editPending: boolean = false;
	private _editPendingRestartPanel: DoiObjectPanel<T>;

	constructor(doi: DoiService, brokerService: DoiBrokerService<T>, route: ActivatedRoute)
	{
		super(doi, brokerService, route);

		this.actions.add(
			new DoiAction(this, 'Bookmark', 'fas-bookmark', 'Bokmärk detta objekt')
				.enabledHandler(() => this.bookmarkAllowed())
				.titleHandler(() => this.bookmarkGet() ? 'Ta bort detta bokmärke' : 'Bokmärk detta objekt')
				.iconHandler(() => this.bookmarkGet() ? 'fas-bookmark' : 'far-bookmark')
				.executeHandler(() => this.bookmarkToggle()));
		this.actions.add(
			new DoiAction(this, 'EditNew', 'fas-plus', 'Ny')
				.enabledHandler(() => this.canEditNew())
				.executeHandler(() => this.editNew()));
		this.actions.add(
			new DoiAction(this, 'Edit', 'edit', 'Ändra')
				.enabledHandler(() => this.canEdit())
				.executeHandler(() => this.editStart())
				.keyMappingCtrl("KeyE"));
		this.actions.add(
			new DoiAction(this, 'EditSave', 'fas-check text-success', 'Klar')
				.enabledHandler(() => this.canEditSave())
				.executeHandler(() => this.editSaveAction(false))
				.keyMappingCtrl("KeyD"));
		this.actions.add(
			new DoiAction(this, 'EditSaveAndContinue', 'save text-success', 'Spara')
				.enabledHandler(() => this.canEditSave())
				.executeHandler(() => this.editSaveAction(true))
				.keyMappingCtrl("KeyS"));
		this.actions.add(
			new DoiAction(this, 'EditCancel', 'fas-xmark text-danger', 'Avbryt')
				.enabledHandler(() => this.canEditCancel())
				.executeHandler(() => this.editCancelAction())
				.titleHandler(() => this.dirty() ? 'Avbryt' : 'Stäng')
				.keyMapping("Escape")
				.keyMappingCtrl("KeyD"));
		this.actions.add(
			new DoiAction(this, 'EditDelete', 'trash', 'Ta bort') // I18N
				.enabledHandler(() => this.canDelete())
				.executeHandler(() => this.editDeleteAction()));
	}

	/**
	 * Invoked when the view has been initialized.
	 */
	ngOnInit()
	{
		super.ngOnInit();
	}

	/**
	 * Test if the object may be bookmarked. The default implementation return true if the object exists, i e saved, and
	 * the user is authenticated.
	 */
	bookmarkAllowed(): boolean
	{
		return (this.objectID && this.doi.auth.isAuthenticated());
	}

	/**
	 * Return the bookmark for the object, or null.
	 */
	bookmarkGet(): DoiObjectBookmark
	{
		if (this.object) {
			let bm = this.doi.bookmarkGet(this.object.objectPath());
			if (bm instanceof DoiObjectBookmark)
				return bm;
		}

		return null;
	}

	/**
	 * Toggle bookmark for the object.
	 */
	bookmarkToggle(): void
	{
		if (this.object) {
			let bm = this.bookmarkGet();
			if (bm)
				this.doi.bookmarkRemove(bm);
			else
				this.doi.bookmarkAdd(DoiObjectBookmark.forObject(this.object));
		}
	}

	/**
	 * Return the action names for the application menu. The default implementation delegates to the active subview.
	 * @param full Indicates if the full menu should be returned, visible on a reduced screen.
	 */
	menuAppActionNames(full: boolean): string[]
	{
		let actionNames = super.menuAppActionNames(full);

		//	TODO: Tool in edit mode instead.
//		if (this.canDelete()) {
//			if (actionNames.length)
//				actionNames.push('-');
//			actionNames.push('EditDelete');
//		}

		return actionNames;
	}

	/**
	 * Invoked when parameters are received. Overridden to set the objectID and check for edit mode.
	 */
	processParams(pm: ParamMap): void
	{
		let id = pm.get('id');
		if (id)
			this.objectID = parseInt(id);

		if (pm.get('edit'))
			this._editPending = true;

		super.processParams(pm);
	}

	/**
	 * Invoked when path parameters are received. The default implementation invokes super, then refresh.
	 */
	paramsRecieved(pm: ParamMap): void
	{
		super.paramsRecieved(pm);

		this.object = null;

		this.refresh();
	}

	get objectID(): number
	{
		return this._objectID;
	}

	set objectID(objectID: number)
	{
		this._objectID = objectID;
	}

	get object(): T
	{
		return this._object;
	}

	set object(object: T)
	{
		this._object = object;
	}

	/**
	 * Test if deleting the object is possible and allowed.
	 */
	canDelete(): boolean
	{
		return this.object && this.object.permitDelete();
	}

	/**
	 * Test if editing the object is possible and allowed.
	 */
	canEdit(): boolean
	{
		if (this.editing())
			return false;

		return this.appView.canEdit(this.object);
	}

	/**
	 * Test if creating a new object is possible and allowed. Delegates to DoiBrokerService.permitNew().
	 */
	canEditNew()
	{
		if (this.editing())
			return false;

		return this.service.permitNew();
	}

	/**
	 * Test if saving the editied object is relevant.
	 */
	canEditSave()
	{
		return this.editing() && this.dirty();
	}

	/**
	 * Test if cancelling editing of the object is relevant.
	 */
	canEditCancel()
	{
		return this.editing();
	}

	/**
	 * Test if this view recommends the user to be prompted if the window is about to be closed.
	 * Overridden to test if editing and dirty.
	 */
	checkPromptClose(): boolean
	{
		return this.editing() && this.dirty();
	}

	/**
	 * Test if the object is dirty. Returns undefined if form changes are not used by the form.
	 */
	dirty()
	{
		return this._dirty;
	}

	/**
	 * Test if the object is currently being edited.
	 */
	editing()
	{
		return this._editing;
	}

	/**
	 * Test if the object is currently being edited and the user has any of the specified roles.
	 */
	editingWithRole(...roles: string[])
	{
		if (!this.editing())
			return false;

		return this.doi.auth.hasAnyRole(...roles);
	}

	/**
	 * Test if an existing object is currently being edited.
	 */
	editingExisting()
	{
		return this._editing && this._objectID;
	}

	/**
	 * Test if a new object is currently being edited.
	 */
	editingNew()
	{
		return this._editing && !this._objectID;
	}

	/**
	 * Start editing a new object.
	 */
	editNew()
	{
		this._cancelID = this.objectID;
		this.objectID = null;
		this.object = null;

		this._cleanDataParts = {};
		this._editing = true;

		if (this.activePanel)
			this.activePanel.editStart();

		this.refresh();
	}

	/**
	 * Start editing the object.
	 * @param subviewName The name of the subview to activate.
	 */
	editStart(subviewName?: string)
	{
		if (this._editing || !this.object)
			return;

		this.object.hasParts.clear();
		this.refreshView().pipe(
			concatMap((v) =>
				this.editStartObservable(subviewName)
			)
		).subscribe(
			(subviewName: string) => {
				this.object.objectDelete = false;
				this._cancelID = this.objectID;
				this._editing = true;

				this._cleanDataParts = this.object.buildDataParts();
				this._dirty = undefined;

				if (this.activePanel) {
					this.activePanel.formMarkPristine();
					this.activePanel.editStart();
				}

				if (subviewName) {
					this.appView.openObject(this.service.name, this.objectID, subviewName);
				}
			}
		);;
/*
		this.editStartObservable(subviewName).subscribe(
			(subviewName: string) => {
				this.object.objectDelete = false;
				this._cancelID = this.objectID;
				this._editing = true;

				this._cleanDataParts = this.object.buildDataParts();
				this._dirty = undefined;

				if (this.activePanel) {
					this.activePanel.formMarkPristine();
					this.activePanel.editStart();
				}

				if (subviewName) {
					this.appView.openObject(this.service.name, this.objectID, subviewName);
				}
			}
		);*/
	}

	/**
	 * Create an observable that when subscribed starts editing the object and activates the optional subview.
	 * @param subviewName The name of the subview to activate.
	 */
	editStartObservable(subviewName?: string): Observable<string>
	{
		return of(subviewName);
	}

	/**
	 * Cancel editing the object.
	 * @param done Indicates if editDone should be invoked to refresh the object.
	 */
	editCancel(done: boolean)
	{
		this.objectID = this._cancelID;
		this._editing = false;
		this._dirty = undefined;

		if (this.activePanel)
			this.activePanel.editCancel();

		if (done)
			this.editDone();
	}

	/**
	 * Cancel editing the object. Invoked by the Cancel button.
	 */
	editCancelAction()
	{
		this.editCancelObservable(true).subscribe();
	}

	/**
	 * Return an observable that when subscribed prompts the user to save changes and stops editing the object.
	 * The result indicates if the operation completed or if the user cancelled.
	 * @param done Indicates if editDone should be invoked to refresh the object.
	 */
	editCancelObservable(done: boolean): Observable<boolean>
	{
		if (this.editing() && this.dirty()) {
			return this.appView.modalDialog(DoiModalType.YES_NO_CANCEL, 'Spara', this.editSaveMessage()).pipe( // I18N
				switchMap(
					(result) => {
						switch (result) {
							case DoiModalResult.YES:
								return this.editSaveObservable(done);
							case DoiModalResult.NO:
								this.editCancel(done);
								return of(true);
							default:
								this.editContinue();
								return of(false);
						}
					}
				)
			)
		} else {
			this.editCancel(done);
			return of(true);
		}
	}

	/**
	 * Continue editing the object.
	 * Invoked when the user cancels the prompt to save the object when navigating away from it.
	 * Invokes DoiAppView.objectVisible.
	 */
	editContinue()
	{
		this.appView.objectVisible(this.object, this.activeSubView ? this.activeSubView.name : undefined);
	}

	/**
	 * Delete the object. Invoked by the Delete action.
	 */
	editDeleteAction()
	{
		this.editDeleteObservable().subscribe();
	}

	/**
	 * Return an observable that when subscribed prompts the user to delete the object.
	 * The result indicates if the operation completed or if the user cancelled.
	 * @param done Indicates if editDone should be invoked to refresh the object.
	 */
	editDeleteObservable(): Observable<boolean>
	{
		return this.appView.modalDialog(DoiModalType.YES_NO, 'Ta bort', this.editDeleteMessage()).pipe( // I18N
			switchMap(
				(result) => {
					switch (result) {
						case DoiModalResult.YES:
							return this.service.deleteObject(this.objectID, this.object).pipe(
								switchMap(
									(response) =>
									{
										this.objectID = response;
										this._editing = false;
										this._dirty = undefined;
										if (this.activePanel)
											this.activePanel.editCancel();
										this.editDone();
										return of(true);
									}
								)
							);
						case DoiModalResult.NO:
							return of(false);
						default:
							return of(false);
					}
				}
			)
		)
	}

	/**
	 * Invoked when editing an object has been cancelled or completed.
	 * The default implementation reopens this object to ensure that the location is correct and then invokes refresh.
	 * Override to e g navigate to the parent if the object is new.
	 */
	editDone()
	{
		let subviewName = this.activePanel ? this.activePanel.name : undefined;

		this._editPending = this._editPendingRestartPanel && true;
		this.appView.openObject(this.service.name, this.objectID, subviewName);
		this.refresh();
	}

	/**
	 * Return the message (question) to show in the delete confirmation dialog.
	 */
	editDeleteMessage(): string
	{
		return 'Vill du ta bort detta objekt?'; // I18N
	}

	/**
	 * Return the message (question) to show in the confirmation dialog shown when the user is implicitly cancelling an edit.
	 */
	editSaveMessage(): string
	{
		return 'Vill du spara dina ändringar?'; // I18N
	}

	/**
	 * Save changes and stop editing the object.
	 */
	editSaveAction(restart: boolean)
	{
		this._editPendingRestartPanel = restart ? this.activePanel : undefined;
		this.editSaveObservable(true).subscribe();
	}

	/**
	 * Return an observable that when subscribed saves changes and stops editing the object. The result indicates if the operation completed or if the user cancelled.
	 * @param done Indicates if editDone should be invoked to refresh the object.
	 */
	editSaveObservable(done: boolean): Observable<boolean>
	{
		return this.service.writeObject(this.objectID, this.object, this._cleanDataParts).pipe(
			switchMap(
				(response) =>
				{
					this.objectID = response;
					this._dirty = undefined;
					this._editing = false;
					if (this.activePanel)
						this.activePanel.editCancel();
					if (done)
						this.editDone();
					return of(true);
				}
			)
		);
	}

	/**
	 * Invoked when the form is changed.
	 */
	formOnChange(dirty: boolean)
	{
		if (dirty || this._dirty === undefined)
			this._dirty = dirty;
	}

	/**
	 * Test if the view is being edited and has the specified part. Used to prevent overwriting an edited part when the view is refreshed.
	 * Invoked by refreshObjectPart.
	 */
	hasEditedPart(partName: string): boolean
	{
		return this._editing && this.object && this.object.objectID == this.objectID && this.object.hasPart(partName);
	}

	/**
	 * Initialize a new object. Invoked by refresh when a new empty object has been created. The default implementation does nothing.
	 */
	initObject(object: T)
	{
	}

	/**
	 * Invoked when an object part is received from the service. The object and objectID is set. If the view is currently being edited,
	 * the clean data parts are updated with object data.
	 * Override to act on the data.
	 */
	objectPartReceived(partName: string, object: T): void
	{
		this.doi.log.fine('objectPartReceived: ' + partName, object);

		this.object = object;
		this.objectID = object.objectID;

		if (this._editing)
			this._cleanDataParts[partName] = this.object.buildDataPart(partName);

		if (!object.isNew())
			this.visitingObject(object);
	}

	/**
	 * Return the icon name indicating a probe result for the specified panel.
	 */
	probedIconName(panelName: string): string
	{
		if (this.appView)
			return this.appView.probedIconName(this.probedPanel(panelName));
		else
			return null;
	}

	/**
	 * Test if any of the object parts used by the specified object panel has been found to have backend values as a result of an object probe. If not
	 * known, undefined is returned.
	 * Override to invoke probedPart with the part name or part names used by the panel.
	 */
	probedPanel(panelName: string): boolean
	{
		return undefined;
	}

	/**
	 * Return the result of an object part probe received at some earlier time.
	 * Invokes partProbes on the objject with each of the part names.
	 * Returns true if any probe is true.
	 * Return false if all probes are false.
	 * Returns undefined if any probes is undefined but none is true.
	 */
	probedPart(...partNames: string[]): boolean
	{
		if (!this.object)
			return undefined;

		let result = false;

		for (let partName of partNames) {
			let p = this.object.partProbes.get(partName);
			if (p)
				return p;
			if (p === undefined)
				result = undefined;
		}

		return result;
	}

	/**
	 * Invoked by implementations of refreshView. Invokes probeObjectPart on the service and returns an observable.
	 */
	probeObjectPart(partName: string): Observable<T>
	{
		if (this.hasEditedPart(partName))
			return of(this.object);

		return this.service.probeObjectPart(this.objectID, partName, this.object);
	}

	/**
	 * Refresh the view. Overridden to first ensure that there is an object to place results into, then delegates to super.
	 */
	refresh(): void
	{
		if (!this.object) {
			this.object = this.service.initObject(this.objectID);
			if (!this.objectID)
				this.initObject(this.object);
		}

		super.refresh();
	}

	/**
	 * Invoked by refresh when done. Invokes objectRefreshed on the object and the application view.
	 * If the edit parameter was specified (edit pending) editing is started, if allowed.
	 */
	refreshDone(): void
	{
		if (this.object)
			this.object.objectRefreshed();

		if (this.appView) {
			this.appView.objectRefreshed(this.object);
			this.appView.objectVisible(this.object, this.activeSubView ? this.activeSubView.name : undefined);
		}

		if (this.activePanel)
			this.activePanel.refreshDone();

		if (this._editPending) {
			let subviewName = this._editPendingRestartPanel ? this._editPendingRestartPanel.name : undefined;
			this._editPending = false;
			this._editPendingRestartPanel = undefined;
			if (this.canEdit())
				this.editStart(subviewName);
		}
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * This implementation delegates to the active object panel, if any, or returns an empty observable.
	 * Override to also refresh any object part presented by the object view itself.
	 */
	refreshView(): Observable<any>
	{
		if (this.activePanel)
			return this.activePanel.refreshView();
		else
			return EMPTY;

	}

	/**
	 * Invoked by implementations of refreshView. Invokes readObjectPart on the service and returns an observable. The object received is intercepted
	 * and objectPartReceived is invoked.
	 */
	refreshObjectPart(partName: string): Observable<T>
	{
		if (this.hasEditedPart(partName))
			return of(this.object);

		return this.service.readObjectPart(this.objectID, partName, this.object).pipe(
			tap((object: T) =>
			{
				return this.objectPartReceived(partName, object);
			})
		);
	}

	tabActivate(panel: DoiObjectPanel<T>): void
	{
		super.tabActivate(panel);

		//	Remember for later automatic redirection if parent is navigated.
		this.doi.redirect.childNames.set(this.name, panel.name);

		this.activePanel = panel;
		this.activePanel.attachParentObjectView(this);

		//	Refresh only if an object is available, not when activation is caused by reload.
		if (this.object)
			this.refresh();

		if (this.editing())
			this.doi.router.navigate([], {queryParams: {edit: 1}});
	}

	tabDeactivate(event: DoiObjectPanel<T>): void
	{
		super.tabDeactivate(event);

		this.activePanel = null;
	}
}
