import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {UnitPart, UnitObject, ProcActTypeObject, ProcessObject} from '../../unit/model/UnitObject';

@Component({
	templateUrl: 'UnitObjectProcessesAndActTypesPanel.html'
})
export class UnitObjectProcessesAndActTypesPanel extends DoiObjectPanel<UnitObject>
{
	name = 'processesandacttypes';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openProcess(process : ProcessObject)
	{
		this.doi.router.navigate(this.routerLinkProcess(process))
	}

	routerLinkProcess(process : ProcessObject)
	{
		return process.processID ? ['/process', process.processID] : null;
	}

	routerLinkProcessStructure(process: ProcessObject)
	{
		return ['/processstructure', process.procStructID];
	}

	openActType(procActType: ProcActTypeObject)
	{
		this.doi.router.navigate(this.routerLinkProcActType(procActType))
	}

	routerLinkProcActType(procActType : ProcActTypeObject)
	{
		return procActType.processActTypeID ? ['/processacttype', procActType.processActTypeID] : null;
	}

	routerLinkActTypeProcess(procActType : ProcActTypeObject)
	{
		return procActType.processID ? ['/process', procActType.processID] : null;
	}

	routerLinkActTypeProcStruct(procActType: ProcActTypeObject)
	{
		return ['/processstructure', procActType.procStructID];
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(UnitPart.ProcActTypes),
			this.refreshObjectPart(UnitPart.Processes),);
	}

	/**
	 * Return the icon class for the specified object.
	 */
	getActTypeIconClass()
	{
		return this.iconClass(ProcActTypeObject.iconName);
	}
	/**
	 * Return the icon class for the specified object.
	 */
	getProcessIconClass()
	{
		return this.iconClass(ProcessObject.iconName);
	}
}

// K8VaWiG2oveAj55HqaFcBZMmvTY=
