/**
 * A property value.
 */
export class PropertyValue
{
	propTypeID: number;
	propValueID: number;
	label: string;
	value: any;
	valueDefault: any;
	handlInst: boolean;
	editor: boolean; // At least Editor role is required.
	highlighted: boolean;
	sequence: number;
	valueClassName: string;
	valueType: string;
	dropDownEntries: string[];
	used: boolean;
	description: string;
	groupTitle: string;
	groupSequence: number;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): PropertyValue
	{
		this.propTypeID = data.PropertyType_PropTypeID;
		this.propValueID = data.PropertyValue_PropValueID;
		this.label = data.PropertyType_Label;
		var viewTypes:string = data.PropertyType_ViewTypes;
		this.handlInst = viewTypes != null && viewTypes.indexOf("HandlInstr") != -1;
		this.editor = data.PropertyType_Editor;
		this.highlighted = data.PropertyType_Highlighted;
		this.sequence = data.PropertyType_Sequence;
		this.valueClassName = data.PropertyType_ValueClassName;
		this.dropDownEntries = data.PropertyType_DropDownEntries;
		this.value = data.PropertyValue_Value;
		this.valueDefault = data.PropertyValue_Default;
		this.valueType = data.PropertyType_ValueType;
		this.used = data.PropertyValue_Used;
		this.description = data.PropertyType_Description;
		this.groupTitle = data.PropertyType_GroupTitle;
		this.groupSequence = data.PropertyType_GroupSequence;

		return this;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(): any
	{
		let data = {
			PropertyType_PropTypeID: this.propTypeID,
			PropertyValue_PropValueID: this.propValueID,
			PropertyValue_Value: this.value,
			PropertyValue_Used: this.used
		}

		return data;
	}

	/**
	 * Test if this is a highlighted boolean property type.
	 */
	highlightedBoolean(): boolean
	{
		return this.highlighted && this.valueClassName == 'java.lang.Boolean';
	}

	/**
	 * Test if this is a highlighted boolean property type whose value is true.
	 */
	highlightedBooleanChecked(): boolean
	{
		return this.highlightedBoolean() && this.value;
	}

	valueText() : string
	{
		if (this.value == null)
			return null;

		if (typeof this.value == 'string')
			return this.value;

		if (typeof this.value == 'boolean')
			return this.value ? 'Ja' : 'Nej';
	}

	/**
	 * Sort highlighted first, then by group and sequence.
	 */
	static sort(propertyValues: PropertyValue[]): void
	{
		propertyValues.sort((a, b) => {
			if (a.highlightedBoolean() && b.highlightedBoolean()) {
				return a.sequence - b.sequence;
			} else {
				if (a.highlightedBoolean())
					return -1;
				else if (b.highlightedBoolean())
					return 1;
				else {
					let c = a.groupSequence - b.groupSequence;
					if (c != 0)
						return c;
					else
						return a.sequence - b.sequence;
				}
			}
		});
	}
}