import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

import {DoiModule} from '../doi/DoiModule';

import {DoiSearchService} from './model/DoiSearchService';
import {DoiSearchCatComponent, DoiSearchCatGroupComponent, DoiSearchCategoriesComponent} from './view/DoiSearchCategoriesComponent';
import {DoiSearchFormComponent} from './view/DoiSearchFormComponent';
import {DoiSearchPagerComponent} from './view/DoiSearchPagerComponent';
import {DoiSearchResultPageFooterComponent} from './view/DoiSearchResultPageFooterComponent';
import {DoiSearchResultPageHeaderComponent} from './view/DoiSearchResultPageHeaderComponent';
import {DoiSearchToolComponent} from './view/DoiSearchToolComponent';

export * from './model/DoiSearchBrokerService';
export * from './model/DoiSearchCriteria';
export * from './model/DoiSearchResult';
export * from './model/DoiSearchResultCatGroup';
export * from './model/DoiSearchResultEntry';
export * from './model/DoiSearchService';
export * from './model/DoiSearchWordMode';
export * from './view/DoiSearchView';
export * from './view/DoiSearchToolComponent';

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule,
		DoiModule
	],
	declarations: [
		DoiSearchCatComponent, DoiSearchCatGroupComponent, DoiSearchCategoriesComponent, DoiSearchFormComponent, DoiSearchPagerComponent,
		DoiSearchResultPageHeaderComponent, DoiSearchResultPageFooterComponent, DoiSearchToolComponent
	],
	exports: [
		DoiSearchCatComponent, DoiSearchCatGroupComponent, DoiSearchCategoriesComponent, DoiSearchFormComponent, DoiSearchPagerComponent,
		DoiSearchResultPageHeaderComponent, DoiSearchResultPageFooterComponent, DoiSearchToolComponent
	],
	providers: [
		DoiSearchService
	],

})
export class DoiSearchModule
{
}
