import {Injectable, EventEmitter} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {EMPTY, Observable} from 'rxjs';
import {tap} from "rxjs/operators";

import {DoiService} from '../../doi/DoiModule';

import {DoiTheme} from '../model/DoiTheme';

/**
 * The theme service.
 */
@Injectable()
export class DoiThemeService
{
	name = 'DoiThemeService';

	doi: DoiService;

	theme: DoiTheme;
	themeImplied: boolean;
	themeList: DoiTheme[];

	private sanitizer: DomSanitizer;
	private themeUrlSafe: SafeResourceUrl;

	private urlTemplate: string;
	private themeNameEmitter = new EventEmitter<string>();
	private themeObservable: (name: string) => Observable<DoiTheme>;
	private themelistObservable: Observable<DoiTheme[]>;

	constructor(doi: DoiService, sanitizer: DomSanitizer)
	{
		this.doi = doi;
		this.sanitizer = sanitizer;
	}

	/**
	 * Configure this theme service.
	 * @param fileUrl The URL template for fetching the theme file. The placeholder {name} is used for the symbolic theme name and
	 * {file} is for the file, e g "theme.css".
	 * @param themeObservable An observable that fetches the specified theme when subscribed.
	 * @param listObservable An observable that fetches the available themes when subscribed.
	 */
	configure(fileUrl: string, themeObservable: (name: string) => Observable<DoiTheme>, listObservable: Observable<DoiTheme[]>)
	{
		this.urlTemplate = fileUrl;
		this.themeObservable = themeObservable;
		this.themelistObservable = listObservable;
	}

	/**
	 * Return the sanitized theme CSS URL.
	 */
	themeUrl(): SafeResourceUrl
	{
		return this.themeUrlSafe;
	}

	/**
	 * Return the sanitized CSS URLs.
	 */
	cssUrls(): Array<SafeResourceUrl>
	{
		return this.themeUrlSafe ? [ this.themeUrlSafe ] : [];
	}

	/**
	 * Return the event emitter for theme change. Emits the symbolic name of the new theme.
	 */
	themeChange(): EventEmitter<string>
	{
		return this.themeNameEmitter;
	}

	/**
	 * Fetches the list of available themes when subscribed. The theme list is tapped from the result.
	 */
	fetchAvailable(): Observable<DoiTheme[]>
	{
		if (this.themelistObservable)
			return this.themelistObservable.pipe(tap(themes => this.themeList = themes));
		else
			return EMPTY;
	}

	/**
	 * Fetches the specified theme, or the default theme if the name is null.
	 */
	fetch(name: string): Observable<DoiTheme>
	{
		if (this.themeObservable)
			return this.themeObservable(name ? name : 'Default');
		else
			return EMPTY;
	}

	/**
	 * Use the specified theme and emit a theme name change to allow the name to be saved in settings.
	 * @param theme The Theme to use.
	 * @param implied Indicates if the theme is used by default.
	 */
	useTheme(theme: DoiTheme, implied?: boolean): void
	{
		if (theme)
			this.themeUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlTemplate.replace('{name}', theme.name).replace('{file}', 'theme.css'));
		else
			this.themeUrlSafe = null;

		this.theme = theme;
		this.themeImplied = implied;
		this.themeNameEmitter.emit(theme && !implied ? theme.name : null);
	}
}

