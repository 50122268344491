import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import {switchMap} from "rxjs/operators";

/**
 * Fetches and caches files (blobs) from the server.
 */
export class DoiFileCache
{
	/**
	 * The HTTP client.
	 */
	http: HttpClient;

	/**
	 * Cached file contents URLS. If a file has been requested but not yet received, null is cached.
	 */
	cache = new Map<string, any>();

	/**
	 * Construct a new file cache.
	 */
	constructor(http: HttpClient)
	{
		this.http = http;
	}

	/**
	 * Fetch a file blob URL from the cache. If not fetched it is requested from the backend.
	 * @param url The file URL.

	 */
	get(url: string): any
	{
		//	Check cache.

		let fileURL = this.cache.get(url);
		if (fileURL)
			return fileURL;

		//	A null value indicates that it is on its way.

		if (fileURL === null)
			return null;

		//	Request it and cache null.

		this.file(url).subscribe();
		this.cache.set(url, null);

		return null;
	}

	/**
	 * Fetch a file from the backend, create a file contents URL and cache it.
	 * @param url The backend URL.
	 * @return An observable that returns the file data url, e g: "data:image/png;base64,iVBORw0KGgo..."
	 */
	file(url: string): Observable<string>
	{
		if (url == null)
			return of(null);

		return this.http.get(url, {responseType: 'blob'}).pipe(
			switchMap((response: File) =>
			{
				let subject = new Subject<any>();
				let reader = new FileReader();
				reader.addEventListener("load", () => {
					this.cache.set(url, reader.result);
					subject.next(reader.result)
				}, false);
				if (response) {
					reader.readAsDataURL(response);
				}
				return subject;
			}),
		);
	}
}
