import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';


import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';

import {ProcessStructurePart, ProcessStructureObject} from '../../processstructure/model/ProcessStructureObject';

import {ArchiveOrigPart, ArchiveOrigObject, } from '../model/ArchiveOrigObject';

@Component({
	templateUrl: 'ArchiveOrigObjectDetailPanel.html'
})
export class ArchiveOrigObjectDetailPanel extends DoiObjectPanel<ArchiveOrigObject>
{
	name = 'general';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	/**
	 * Return the icon class for the specified object.
	 */
	archiveOrigIconClass()
	{
		return this.iconClass(ArchiveOrigObject.iconName);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ArchiveOrigPart.General),
			this.refreshObjectPart(ArchiveOrigPart.BusinessPlaces),
			this.refreshObjectPart(ArchiveOrigPart.BusinessTypes),
			this.refreshObjectPart(ArchiveOrigPart.AltNames),
		);
	}
}

// rS9PI84cysHrMnRkC1Y62Cfx+ZY=
