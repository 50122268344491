import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin, of} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {KlaraObjectPanel, ObjectFile, PropertyValue, PropertyValueList, UnitValueReader, Unit} from '../../klara/KlaraModule';
import {ProcessPart, ProcessObject, ProcessService} from '../../process/ProcessModule';
import {ProcessActTypePart, ProcessActTypeObject, ProcessActTypeUnitObject} from '../model/ProcessActTypeObject';
import {ProcessActTypeService} from '../../processacttype/model/ProcessActTypeService';
import {UnitPart, UnitService} from '../../unit/UnitModule';

@Component({
	templateUrl: 'ProcessActTypeObjectGeneralPanel.html'
})
export class ProcessActTypeObjectGeneralPanel extends KlaraObjectPanel<ProcessActTypeObject>
{
	name = 'general';
	processService: ProcessService;
	units: Unit[];
	processActTypeService: ProcessActTypeService;
	unitService: UnitService;

	propertyValueList = new PropertyValueList(true);

	constructor(doi: DoiService, processService: ProcessService, processActTypeService: ProcessActTypeService, unitService: UnitService, route: ActivatedRoute)
	{
		super(doi, route);
		this.processService = processService;
		this.processActTypeService = processActTypeService;
		this.unitService = unitService;
	}

	/**
	 * Download an object file.
	 */
	downloadObjectFile(objectFile: ObjectFile): void
	{
		this.processActTypeService.downloadObjectFile(objectFile);
	}

	openProcessActType(processActTypeID : number)
	{
		this.appView.openObject('ProcessActType', processActTypeID);
	}

	openUnit(unitID : number)
	{
		this.appView.openObject('Unit', unitID);
	}

	propertyValues() : PropertyValue[]
	{
		if (!this.object || !this.object.propertyValues || !this.object.process)
			return PropertyValueList.empty;

		return this.propertyValueList.propertyValues(this.object.propertyValues, this.object.process.propertyValues, this.editing(), this.editingAsUser());
	}

	readUnitPropertyValues(unit: Unit): Observable<PropertyValue[]>
	{
		return this.unitService.readObjectPart(unit.unitID, UnitPart.PropertyValues).pipe(
			switchMap((unit) => {
				return of(unit.propertyValues)
			})
		);
	}

	readUnitNotes(unit: Unit): Observable<string>
	{
		return this.unitService.readObjectPart(unit.unitID, UnitPart.Notes).pipe(
			switchMap((unit) => {
				return of(unit.notes)
			})
		);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(this.refreshViewObservables());
	}

	refreshViewObservables(): Observable<any>[]
	{
		let observables = new Array<Observable<any>>();

		observables.push(
			this.refreshObjectPart(ProcessActTypePart.General).pipe(
				switchMap(procActType => {
					return forkJoin(
						this.processService.readObjectPart(procActType.processID, ProcessPart.PropertyValues, procActType.process).pipe(
							tap(process => {procActType.process = process})),
						this.processService.readObjectPart(procActType.processID, ProcessPart.ActKind, procActType.process).pipe(
							tap(process => {procActType.process = process})),
					)
				})
			),
			this.refreshObjectPart(ProcessActTypePart.AltNames),
			this.refreshObjectPart(ProcessActTypePart.Description),
			this.refreshObjectPart(ProcessActTypePart.PropertyValues),
			this.refreshObjectPart(ProcessActTypePart.ObjectFiles),
		);

		return observables;
	}
}

// rcT9DIxhYj6XC14VqboZUpIT5aA=
