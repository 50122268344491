import {DoiObject, DoiObjectRef, DoiObjectPermission} from '../../doi/DoiModule';

import {ObjectFile} from '../../klara/KlaraModule';

/**
 *	Object part names for the SystemInfo entity object.
 */
export const SystemInfoPart =
{
	Label : 'Label',
	General : 'General',
	Logo : 'Logo',
	CookieConsentText: 'CookieConsentText',
	ObjectFiles : 'ObjectFiles',
}

/**
 *	The SystemInfo entity object.
 */
export class SystemInfoObject extends DoiObject
{
	static readonly iconName = 'info-circle';

	/**
	 *	Attribute values.
	 */
	systemName: string;
	appTitle: string;
	errorReportRecipient: string;
	licenseKey: string;
	cookieConsentEnabled: boolean;
	cookieConsentText: string;
	cookieConsentButtonText: string;
	cookieDeclineButtonText: string;
	webProcesses: boolean;
	webTitle: string;
	webSubTitle: string;
	dialogTitle: string;
	dialogSubTitle: string;
	searchFieldPlaceholder: string;
	showProcessCode: boolean;
	showMaxVisited: number;
	showMaxRelated: number;

	objectFiles: Array<ObjectFile>;

	/**
	 * Construct a new SystemInfo object.
	 */
	constructor(objectID: number)
	{
		super(objectID);
	}

	/**
	 * The icon name, i e 'info-circle'.
	 */
	get iconName(): string
	{
		return SystemInfoObject.iconName;
	}

	/**
	 * The symbolic object type name, i e 'SystemInfo'.
	 */
	get objectType(): string
	{
		return 'SystemInfo';
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectTitle(): string
	{
		return this.systemName;
	}

	/**
	 * Populate an object from a data object.
	 */
	parseData(data: any, partName: string): SystemInfoObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, SystemInfoPart.Label);
				break;
			case SystemInfoPart.Label:
				this.objectID = data.SystemInfo_SystemID;
				this.systemName = data.SystemInfo_SystemName;
				break;
			case SystemInfoPart.General:
				this.parseData(data, SystemInfoPart.Label);
				this.appTitle = data.SystemInfo_AppTitle;
				this.errorReportRecipient = data.SystemInfo_ErrorReportRecipient;
				this.licenseKey = data.SystemInfo_LicenseKey;
				this.cookieConsentEnabled = data.SystemInfo_CookieConsentEnabled;
				this.cookieConsentButtonText = data.SystemInfo_CookieConsentButText;
				this.cookieDeclineButtonText = data.SystemInfo_CookieDeclineButText;
				this.webProcesses = data.SystemInfo_WebProcesses;
				this.webTitle = data.SystemInfo_WebTitle;
				this.webSubTitle = data.SystemInfo_WebSubTitle;
				this.dialogTitle = data.SystemInfo_DialogTitle;
				this.dialogSubTitle = data.SystemInfo_DialogSubTitle;
				this.searchFieldPlaceholder = data.SystemInfo_SearchFieldText;
				this.showProcessCode = data.SystemInfo_ShowCode;
				this.showMaxRelated = data.SystemInfo_ShowMaxRelated;
				this.showMaxVisited = data.SystemInfo_ShowMaxVisited;
				break;
			case SystemInfoPart.Logo:
				break;
			case SystemInfoPart.CookieConsentText:
				this.cookieConsentText = data.SystemInfo_CookieConsentText;
				break;
			case SystemInfoPart.ObjectFiles:
				this.objectFiles = new Array<ObjectFile>();
				if (data.SystemInfo_ObjectFiles) {
					for (let e of data.SystemInfo_ObjectFiles) {
						this.objectFiles.push(new ObjectFile().parseData(e));
					}
				}
				break;
		}
		return this;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(partName: string): any
	{
		switch (partName) {
			case SystemInfoPart.General:
				return {
					SystemInfo_ObjectDelete: this.objectDelete,
					SystemInfo_SystemName: this.systemName,
					SystemInfo_AppTitle: this.appTitle,
					SystemInfo_ErrorReportRecipient: this.errorReportRecipient,
					SystemInfo_LicenseKey: this.licenseKey,
					SystemInfo_WebSubTitle: this.webSubTitle,
					SystemInfo_WebTitle: this.webTitle,
				}
		}
	}
}

// YnQn6+tLul1nvhMP2oiIUPxruMw=
