<div class="doi-subview">

	<div *ngIf="series && series.length > 0" class="doi-scroll-container">
		<table class="table table-striped table-hover">
			<thead>
				<tr class="doi-clickable">
					<th class="doi-grow-2">Signum/Titel</th>
					<th class="doi-grow-3 doi-hidden-xs-down">Plats</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let s of series" (click)="openSeries(s)" class="doi-clickable">
					<td class="doi-grow-2 doi-wrap"><a [routerLink]="routerLinkSeries(s)">{{s.signum}} - {{s.title}}</a></td>
					<td class="doi-grow-3 doi-wrap doi-hidden-xs-down">{{s.location}}</td>
					<td>&nbsp;</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
