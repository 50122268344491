import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DoiService, DoiObjectPanel, DoiSelectionCriteria} from '../../doi/DoiModule';

import {DocStoreLink, ObjectFile} from '../../klara/KlaraModule';

import {UnitPart, UnitObject, ProcActTypeObject, ProcessObject, RelUnit} from '../../unit/model/UnitObject';
import {UnitService} from '../../unit/model/UnitService';

@Component({
	templateUrl: 'UnitObjectGeneralPanel.html'
})
export class UnitObjectGeneralPanel extends DoiObjectPanel<UnitObject>
{
	name = 'general';
	children: UnitObject[];
	docStoreLinks: DocStoreLink[];

	constructor(doi: DoiService, route: ActivatedRoute, private unitService: UnitService)
	{
		super(doi, route);
	}

	/**
	 * Download an object file.
	 */
	downloadObjectFile(objectFile: ObjectFile): void
	{
		this.unitService.downloadObjectFile(objectFile);
	}

	openProcess(process: ProcessObject)
	{
		this.doi.router.navigate([ '/process', process.processID, { outlets: { tab: ['general'] } } ]);
	}

	openActType(procActType : ProcActTypeObject)
	{
		this.doi.router.navigate([ '/processacttype', procActType.processActTypeID, { outlets: { tab: ['general'] } } ]);
	}

	readChildren(): Observable<UnitObject[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Unit_UnitID', 'Unit_ParentUnitID', 'Unit_UnitTypeID', 'Unit_Code', 'Unit_Title', 'Unit_NavText')
			.where('Unit_ParentUnitID', '='+this.objectID)
			.and('UnitType.NavigatorNode', '=true')
			.orderBy('Unit_Code', 'Unit_ParentUnitID')
			.limit(10);

		return this.service().readObjectSelection(null, criteria);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(UnitPart.General),
			this.refreshObjectPart(UnitPart.Notes),
			this.refreshObjectPart(UnitPart.PropertyValues),
			this.refreshObjectPart(UnitPart.RelatedUnits),
			this.readChildren().pipe(tap((units: UnitObject[]) => {this.children = units})),
			this.refreshObjectPart(UnitPart.ProcActTypes),
			this.refreshObjectPart(UnitPart.Processes),
			this.refreshObjectPart(UnitPart.ObjectFiles),
			this.unitService.readDocumentStoreLinks(this.objectID).pipe(tap((links: DocStoreLink[]) => {this.docStoreLinks = links}))
		);
	}

	/**
	 * Return the icon class for the specified object.
	 */
	unitRelIconClass(relUnit: RelUnit)
	{
		return this.iconClass(UnitObject.iconName);
	}

	routerLinkRelUnit(unit : RelUnit)
	{
		return unit.unitID ? ['/unit', unit.unitID] : null;
	}

	routerLinkArchiveOrig(unit : RelUnit)
	{
		return unit.archiveOrigID ? [ '/archiveorig', unit.archiveOrigID ] : null;
	}

	routerLinkArchiveOrigOrig(unit : UnitObject)
	{
		return unit.archiveOrigID ? [ '/archiveorig', unit.archiveOrigID ] : null;
	}

	openRelUnit(unit : RelUnit)
	{
		this.doi.router.navigate([ '/unit', unit.unitID, { outlets: { tab: ['general'] } } ]);
	}
}

// K8VaWiG2oveAj55HqaFcBZMmvTY=
