import {ActivatedRoute} from '@angular/router';

import {DoiService} from '../service/DoiService';
import {DoiBrokerService} from '../service/DoiBrokerService';
import {DoiObject} from '../service/DoiObject';
import {DoiBinderView} from './DoiBinderView';
import {DoiArraySorter} from '../widget/DoiSortComponent';
import { Directive } from "@angular/core";

/**
 * A selection view is a top level view that shows a selection of objects.
 */
@Directive()
export class DoiSelectionView<T extends DoiObject> extends DoiBinderView<T>
{
	/**
	 * Selected objects.
	 */
	objects: T[];
	
	/**
	 * Object sorter.
	 */
	sorter: DoiArraySorter<T>;

	/**
	 * Construct a new selection view.
	 */
	constructor(doi: DoiService, brokerService: DoiBrokerService<T>, route: ActivatedRoute)
	{
		super(doi, brokerService, route);

		this.sorter = new DoiArraySorter<T>(this, () => this.objects);
	}
}
