import {Component, Input} from '@angular/core';

/**
 * A view footer component that spreads its child components horizontally.
 */
@Component({
	selector: 'doi-view-footer',
	template: `
		<div class="doi-view-footer doi-bar">
			<ng-content></ng-content>
		</div>
	`
})
export class DoiViewFooterComponent
{
}
