import {Component, Input} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import {DoiAction} from '../core/DoiAction';
import {DoiActionTarget} from '../core/DoiActionTarget';
import {DoiActionComponent} from './DoiActionComponent';

/**
 * A tool component for use in an ul element.
 */
@Component({
	selector: '[doi-tool]',
	host: { 'class' : 'doi-tool', '[class]': 'actionNameClass' },
	template: `
		<ng-template #tooltipTpl>
			<span class="doi-tooltip-text">{{tooltip}}</span>
			<span *ngIf="hasKeyMapping()" class="doi-tooltip-keymapping">{{keyMappingText()}}</span>
		</ng-template>
		<a *ngIf="!dropdown"
			[ngbTooltip]="hasTooltip() ? tooltipTpl : null" [attr.href]="link()" (click)="onClick($event)"
				role="button" target="{{anchorTarget}}">
			<span class="doi-tool-icon" [ngClass]="iconClass()" [class.fa-spin]="working" aria-hidden="true"></span>
			<span class="doi-tool-title d-none d-xl-inline" *ngIf="title && !compact">{{title}}</span>
		</a>
		<a *ngIf="dropdown" href="" class="dropdown-toggle" data-offset="1px" [attr.data-bs-toggle]="'dropdown'"
				role="button" [attr.aria-haspopup]="true" aria-expanded="false">
			<span class="doi-tool-icon" [ngClass]="iconClass()" aria-hidden="true"></span><span class="doi-tool-dropdown-icon fas fa-caret-down" aria-hidden="true"></span>
			<span class="doi-tool-title d-none d-xl-inline" *ngIf="title && !compact">{{title}}</span>
		</a>
		<ng-content></ng-content>
  `,
})
export class DoiToolComponent extends DoiActionComponent
{
	/**
	 * Indicates if this tool is compact and suppresses any title.
	 */
	@Input() compact: boolean;

	/**
	 * Indicates if this tool can open a dropdown menu, defined in the content.
	 */
	@Input() dropdown: boolean;

	/**
	 * The anchor target. Initially '_self'.
	 */
	@Input() anchorTarget: string = '_self';

	/**
	 * An optional action argument.
	 */
	@Input() arg: any;

	constructor(private sanitizer: DomSanitizer)
	{
        super();
    }

	get actionNameClass()
	{
		return this.actionName ? 'doi-tool-'+this.actionName.toLowerCase() : null;
	}
	/**
	 * Invoked when the tool is clicked. If an action name and target is set, the action is located and executed.
	 */
	onClick(event: Event)
	{
		let link = this.link();
		if (!link || link.length == 0)
			event.preventDefault();

		super.onClick(event, this.arg);
	}

	/**
	 * Return the key mapping text.
	 */
	keyMappingText(): string
	{
		if (this.target) {
			let action = this.target.action(this.actionName);
			if (action) {
				return action.keyMappingText();
			}
		}
	}

	/**
	 * Return the link, if used.
	 */
	link(): string
	{
		if (!this.enabledUnarmed)
			return null;

		if (this.target) {
			let action = this.target.action(this.actionName);
			if (action) {
				let link = action.link();
				if (link)
					return link;
			}
		}

		return ""; // Makes the component focusable.
	}

	hasKeyMapping()
	{
		if (this.target) {
			let action = this.target.action(this.actionName);
			if (action) {
				return action.hasKeyMapping();
			}
		}

		return false;
	}

	hasTooltip(): boolean
	{
		let text = this.tooltip;
		return text && text.length > 0;
	}
}
