import {Observable, concat, EMPTY} from 'rxjs';
import {map} from "rxjs/operators";

import {DoiIconMapper, DoiObjectRef, DoiSelectionCriteria} from '../doi/DoiModule';
import {DoiNavNode, DoiNavNodeChildSupplier} from '../doi-navigator/DoiNavigatorModule';

import {SeriesService, SeriesObject} from '../series/SeriesModule';
import {SeriesGroupService, SeriesGroupObject} from '../seriesgroup/SeriesGroupModule';

/**
 * A navigator node child supplier for an archive node.
 */
export class ArchiveNodeChildSupplier extends DoiNavNodeChildSupplier
{
	constructor(
		private seriesGroupService: SeriesGroupService,
		private seriesService: SeriesService,
		private seriesGroupNodeChildSupplier: DoiNavNodeChildSupplier,
	)
	{
		super();
	}

	readChildren(node: DoiNavNode): Observable<DoiNavNode[]>
	{
		var criteria: DoiSelectionCriteria;

		if (node.getValue('signumTreeStrict')) {

			let archiveID = node.getValue('archiveID')
			criteria = new DoiSelectionCriteria()
				.select('SeriesGroup_SeriesGroupID', 'SeriesGroup_Code', 'SeriesGroup_Title')
				.where('SeriesGroup_ArchiveID', '='+archiveID)
				.orderBy('SeriesGroup_Code');

			let seriesGroupObservable = this.seriesGroupService.readObjectSelection(null, criteria).pipe(
				map((seriesGroups: SeriesGroupObject[]) =>
				{
					let nodes = new Array<DoiNavNode>();
					for (let seriesGroup of seriesGroups) {
						let objectRef = DoiObjectRef.forObject('SeriesGroup', seriesGroup.objectID);
						nodes.push(
							new DoiNavNode(seriesGroup.objectTitle(), '/seriesgroup/'+seriesGroup.objectID)
								.setObjectRef(objectRef)
								.setIconName(seriesGroup.iconName)
								.setIconMapper(node.getIconMapper())
								.setChildNodeSupplier(this.seriesGroupNodeChildSupplier)
						);
					}
					return nodes;
				})
			);

			return seriesGroupObservable;

		} else {

			criteria = new DoiSelectionCriteria()
				.select('Series_SeriesID', 'Series_Signum', 'Series_Title')
				.where('Series_ArchiveID', '='+node.pathElement.objectID)
				.orderBy('Series_Signum', 'Series_Title', 'Series_SeriesID');

			let seriesObservable = this.seriesService.readObjectSelection(null, criteria).pipe(
				map((serieses: SeriesObject[]) =>
				{
					let nodes = new Array<DoiNavNode>();
					for (let series of serieses) {
						let objectRef = DoiObjectRef.forObject('Series', series.objectID);
						nodes.push(
							new DoiNavNode(series.objectTitle(), '/series/'+series.objectID)
								.setObjectRef(objectRef)
								.setIconName(series.iconName)
								.setIconMapper(node.getIconMapper())
						);
					}
					return nodes;
				})
			);

			return seriesObservable;
		}
	}
}
