<div class="doi-subview">


	<div *ngIf="object?.units && object?.units?.length > 0">
		<h3>Förvaringsenheter</h3>
		<table class="table table-striped">
			<thead>
				<tr>
					<th class="doi-width-15 doi-hidden-xs-down">Typ</th>
					<th class="doi-width-20">Beteckning/Benämning</th>
					<th class="doi-width-15">Tid</th>
					<th class="doi-hidden-sm-down doi-hidden-xs-down">Arkivbildare</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let unit of object.units">
					<td class="doi-hidden-xs-down">{{unit.unitTypeName}}</td>
					<td class="doi-wrap"><a [routerLink]="routerLinkUnit(unit)">{{unit.code}} - {{unit.title}}</a></td>
					<td>{{unit.date}}</td>
					<td class="doi-wrap doi-hidden-sm-down doi-hidden-xs-down"><a *ngIf="routerLinkArchiveOrig(unit)" [routerLink]="routerLinkArchiveOrig(unit)">{{unit.archiveOrigName}}</a></td>
					<td>&nbsp;</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="object?.series && object?.series?.length">
		<h3>Serier</h3>
		<table class="table table-striped">
			<thead>
				<tr>
					<th class="doi-width-15">Signum</th>
					<th class="doi-width-20 doi-hidden-xs-down">Rubrik</th>
					<th class="doi-width-15">Tid</th>
					<th class="doi-hidden-sm-down doi-hidden-xs-down">Arkiv</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let series of object.series">
					<td class="doi-width-15 doi-wrap"><a [routerLink]="routerLinkSeries(series)">{{series.signum}}</a></td>
					<td class="doi-width-20 doi-wrap doi-hidden-xs-down"><a [routerLink]="routerLinkSeries(series)">{{series.title}}</a></td>
					<td class="doi-width-15">{{series.date}}</td>
					<td class="doi-wrap doi-hidden-sm-down doi-hidden-xs-down"><a *ngIf="routerLinkArchive(series)" [routerLink]="routerLinkArchive(series)">{{series.archiveName}}</a></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
