import {Component, Input, Output, EventEmitter, ElementRef, ViewChild} from '@angular/core';

import {DoiFocusFirst} from '../../doi/DoiModule';

/**
 * A filter field.
 */
@Component({
	selector: 'doi-filter',
	host: { 'class' : 'doi-filter' },
	template: `
		<div class="form-group d-print-none" ngbTooltip="{{tooltip}}">
			<input #filterInput class="form-control" size="{{size}}" type="text" placeholder="{{placeholder}}" [(ngModel)]="filter" [attr.aria-label]="placeholder"/>
			<div class="clear">
				<i class="far fa-times" (click)="clear()" aria-label="Rensa"></i>
			</div>
		</div>
  `,
	styles: [ `
		.form-group { display: flex; flex-wrap: nowrap }
		.clear { display: flex; align-items: center; transform: translateX(-1.5em); width: 0; }
		.clear > i { padding: 0.3em; cursor: pointer; color: #666 }
	` ],
})
export class DoiFilterComponent
{
	/**
	 * Size in characters. Initially 10.
	 */
	@Input() size: number = 10;

	/**
	 * Placeholder text.
	 */
	@Input() placeholder: string = 'Filtrera'; // I18N

	/**
	 * Tooltip text.
	 */
	@Input() tooltip: string = "Visa bara rader innehållande angiven text"; // I18N

	/**
	 * Filter change emitter.
	 */
	@Output() filterChange = new EventEmitter<string>();

	/**
	 * The filter input element.
	 */
	@ViewChild('filterInput')
	filterInputRef: ElementRef<HTMLInputElement>;

	_filterText: string;

	/**
	 * Accessor for filter text.
	 */
	get filter(): string
	{
		return this._filterText;
	}

	/**
	 * Mutator for filter text.
	 */
	@Input()
	set filter(text: string)
	{
		this._filterText = text;
		this.filterChange.emit(text);
	}

	/**
	 * Invoked when the eraser is clicked. Clears the filter.
	 */
	clear()
	{
		this.filter = '';
	}

	/**
	 * Focus the first inner component.
	 */
	focusFirst(): boolean
	{
		if (this.filterInputRef) {
			this.filterInputRef.nativeElement.focus();
			return true;
		}

		return false;
	}

	/**
	 * Return the filter input component.
	 */
	filterInput(): HTMLInputElement
	{
		return this.filterInputRef ? this.filterInputRef.nativeElement : null;
	}
}
