<div class="doi-view start-view no-search-tool">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" [tailImplied]="false">
		</ol>
	</doi-view-title>

	<div class="row start-title">
		<div class="col-12">
			<h2>{{title()}}</h2>
		</div>
		<div class="col-12" *ngIf="subtitle()">
			<h3>{{subtitle()}}</h3>
		</div>
	</div>

	<div class="doi-subview doi-scroll">

		<div class="row start-menu">
			<div class="col col-12" [ngClass]="hasFeatureProcess() ? 'col-lg-4' : 'col-lg-6'" >
				<div class="card">
					<div class="card-body">
						<a routerLink="/search" class="doi-card-container">
							<p class="card-title">Sök</p>
						</a>
						<p class="card-text">Sök efter text i hela Klara.</p>
						<doi-search-tool #searchTool (search)="onSearch($event)"></doi-search-tool>
					</div>
				</div>
			</div>
			<div class="col col-12 col-md-6" [ngClass]="hasFeatureProcess() ? 'col-lg-4' : ''" role="navigation" aria-label="Struktur">
				<a routerLink="/archiveorigs" class="doi-card-container">
					<div class="card">
						<div class="card-body">
							<p class="card-title">Struktur</p>
							<p *ngIf="hasFeatureProcess()" class="card-text">
								Navigera bland arkivbildare, klassificeringsstrukturer, processer och handlingstyper.
							</p>
							<p *ngIf="!hasFeatureProcess()" class="card-text">
								Navigera bland arkivbildare, arkiv, serier och volymer.
							</p>
						</div>
					</div>
				</a>
			</div>
			<div *ngIf="hasFeatureProcess()" class="col col-12 col-md-6 col-lg-4" role="navigation" aria-label="Förvaring">
				<a routerLink="/units" class="doi-card-container">
					<div class="card">
						<div class="card-body">
							<p class="card-title">Förvaring</p>
							<p class="card-text">Navigera bland förvaringsenheter.</p>
						</div>
					</div>
				</a>
			</div>
		</div>

		<div *ngIf="anonymous()">
			<p><a routerLink="/login">Logga in</a> för att se dina bokmärken och internt publicerade objekt.</p>
		</div>

		<div class="row mt-2">
			<div *ngIf="bookmarkList" class="col-12 col-sm-6 col-lg-4">
				<div class="doi-titled-list">
					<h3>Bokmärken</h3>
					<ul class="doi-object-list">
						<li *ngFor="let bm of bookmarkList">
							<a [routerLink]="bm.path">
								<i [ngClass]="iconClass(bm.iconName)"></i>
								<span>{{bm.title}}</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div *ngIf="visitedList" class="col-12 col-sm-6 col-lg-4">
				<div class="doi-titled-list">
					<h3>Senast besökta</h3>
					<ul class="doi-object-list">
						<li *ngFor="let bm of visitedList">
							<a [routerLink]="bm.path">
								<i [ngClass]="iconClass(bm.iconName)"></i>
								<span>{{bm.title}}</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div *ngIf="references?.length" class="col-12 col-sm-6 col-lg-4">
				<div class="doi-titled-list">
					<h3>Referenser</h3>
					<object-files [objectFiles]="references" (download)="downloadObjectFile($event)" [iconMapper]="this"></object-files>
				</div>
			</div>
		</div>

	</div>

</div>