import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map, tap} from "rxjs/operators";

import {DoiService, DoiBrokerService} from '../../doi/DoiModule';
import {DoiSearchBrokerService} from '../../doi-search/DoiSearchModule';
import {DoiObject} from '../../doi/service/DoiObject';

import {DocStoreLink} from './DocStoreLink';
import {ObjectFile} from './ObjectFile';

/**
 * Manages KlaraBroker objects.
 */
export abstract class KlaraBrokerService<T extends DoiObject> extends DoiBrokerService<T> implements DoiSearchBrokerService<T>
{
	/**
	 * Construct a new Klara service.
	 */
	constructor(doi: DoiService, name : string)
	{
		super(doi, name)
	}

	/**
	 * Check if an attribute should be included in changed data even if unchanged, provided some other attribute in the same data object
	 * is changed. Normally used to force inclusion of key attributes.
	 *
	 */
	changesForceAttribute(attr : string)
	{
		switch (attr) {
			case 'PropertyType_PropTypeID':
			case 'PropertyValue_PropValueID':
			case 'PropertyValue_Used':
			case 'PropertyValue_Value':
				return true;
			default:
				return false;
		}
	}

	/**
	 * Builds a URL for an object file contents.
	 * @param objectFile The object file.
	 * @returns The URL.
	 */
	objectFileContentsURL(objectFile: ObjectFile): Observable<string>
	{
		if (!objectFile)
			return null;

		return this.doi.http.get(this.doi.auth.urlContext() + '/ObjectFile/url/' + objectFile.objectFileID + '/' + objectFile.filenameLink + '/url.json').pipe(
			map((response: {url: string}) =>
				this.doi.auth.urlContextPub() + '/' + response.url
			),
			catchError((error: HttpErrorResponse) =>
				this.handleError(error)
			)
		);
	}

	/**
	 * Download an object file.
	 * @param objectFile The object file.
	 */
	downloadObjectFile(objectFile: ObjectFile): void
	{
		if (!objectFile)
			return;

		this.objectFileContentsURL(objectFile).subscribe(
			url =>
			{
				window.open(url, '_blank');
			}
		);
	}

	/**
	 * Test if the user is allowed to create new objects. The default implementation returns false. Overridden to
	 * check role memberships.
	 */
	permitNew(): boolean
	{
		return false;
		//	TODO: Check role in overridden service.
	}

	/**
	 * Read document store links for a given unit ID.
	 */
	readDocumentStoreLinks(unitID: number): Observable<DocStoreLink[]>
	{
		let observable = this.doi.http.get(this.url() + '/object/' + (unitID ? unitID : '-') + '/DocStoreLinks/DocStoreLinks.json').pipe(
			tap(response =>
				this.doi.log.fine('readDocumentStoreLinks: ' + unitID, response)
			),
			map((response: any[]) => {
				let links = new Array<DocStoreLink>();
				for (let e of response) {
					links.push(new DocStoreLink().parseData(e));
				}
				return links;
			})
		);
		return observable;
	}

	/**
	 * Read search result entries.
	 */
	readSearchResultEntries(objectIDs?: number[]): Observable<T[]>
	{
		return undefined;
	}

}

// jf0/ab5esbqEx7hJ6VLdzooglq8=
