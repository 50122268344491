import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, Observable, forkJoin, of} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiObjectView, DoiService} from '../../doi/DoiModule';

import {KlaraObjectView, RoleName} from '../../klara/KlaraModule';

import {ArchiveOrigService, ArchiveOrigObject} from '../../archiveorig/ArchiveOrigModule';
import {ProcessStructureService, ProcessStructureObject} from '../../processstructure/ProcessStructureModule';

import {ProcessService} from '../model/ProcessService';
import {ProcessPart, ProcessObject} from '../model/ProcessObject';
import {KlaraDialogTopView} from '../../klara/KlaraModule';

import {environment} from '../../../environments/environment';

/**
 *
 */
@Component({
	templateUrl: 'ProcessObjectView.html'
})
export class ProcessObjectView extends KlaraObjectView<ProcessObject> implements KlaraDialogTopView
{
	name = 'ProcessObjectView';

	/**
	 * Template for a new object.
	 */
	objectTemplate: ProcessObject;

	/**
	 * Construct a new object view.
	 */
	constructor(doi: DoiService, private processService: ProcessService, route: ActivatedRoute)
	{
		super(doi, processService, route);
	}

	/**
	 * Test if creating a new object is possible and allowed.
	 */
	canEditNew()
	{
		//	TODO: Write permission of PS is also required.
		return super.canEditNew();
	}

	/**
	 * Return the corresponding path in Klara Dialog, null if not available or an empty string for the same URL.
	 */
	dialogPath(): string
	{
		if (this.object && this.object.objectID)
			return '/process/'+this.object.objectID+'/general';
		else
			return null;
	}

	routerLinkArchiveOrig()
	{
		return this.object && this.object.processStructure && this.object.processStructure.archiveOrig &&
				['/archiveorig', this.object.processStructure.archiveOrigID];
	}

	routerLinkProcessStructure()
	{
		return ['/processstructure', this.object.procStructID];
	}

	routerLinkParentProcess()
	{
		return ['/process', this.object.parentProcessID, 'general'];
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * Overridden to refresh object parts presented by the object view itself.
	 */
	refreshView(): Observable<any>
	{
		if (this.doi.auth.isAuthenticating())
			return EMPTY;

		return forkJoin(
			this.refreshObjectPart(ProcessPart.Label).pipe(
				switchMap((process: ProcessObject) => {
					this.appView.selectProcessStructure(process.procStructID);
					return this.processService.parentObjectObservable(process);
				})
			),
			this.probeObjectPart(ProcessPart.General),
			this.probeObjectPart(ProcessPart.ActKind),
			this.probeObjectPart(ProcessPart.ActTypes),
			this.probeObjectPart(ProcessPart.Activities),
			this.probeObjectPart(ProcessPart.Units),
			this.probeObjectPart(ProcessPart.Series),
			this.probeObjectPart(ProcessPart.ModelImage),
			super.refreshView()
		);

	}

	/**
	 * Test if any of the object parts used by the specified object panel has been found to have backend values as a result of an object probe. If not
	 * known, undefined is returned.
	 * Overriddene to invoke objectPartHasValues with the part name or part names used by the panel.
	 */
	probedPanel(panelName: string): boolean
	{
		switch (panelName) {
			case 'general': return this.probedPart('General');
			case 'details': return this.probedPart('General');
			case 'acttypes': return this.probedPart('ActTypes');
			case 'actkind': return this.probedPart('ActKind');
			case 'activities': return this.probedPart('Activities');
			case 'units': return (this.probedPart('Units') || this.probedPart('Series'));
			case 'diagram': return this.probedPart('ModelImage');
		}
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Strukturenhet'; // I18N
	}

	/**
	 * Create an observable that when subscribed starts editing the object and activates the optional subview.
	 * Overridden to fetch property values for edit before switching to the super implementation.
	 * @param subviewName The name of the subview to activate.
	 */
	editStartObservable(subviewName?: string): Observable<string>
	{
		if (!this.object.propertyValues)
			return super.editStartObservable();

		return this.processService.readPropertyValuesForEdit(this.objectID, this.object).pipe(
			switchMap((process) => {
				return super.editStartObservable(subviewName);
			})
		);
	}
}

// oIng7sGnb2hZG77nJSYKe/hJNI8=
