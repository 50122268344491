<div class="doi-view doi-selection-view archiveorig-selection-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start"></ol>
		<ul doi-toolbar>
			<doi-viewmode-tools class="doi-hidden-sm-down" [view]="this"></doi-viewmode-tools>
		</ul>
		<doi-title>
			<span>Arkivbildare</span>
			<section><doi-filter name="filter" [(filter)]="filter" #focusFirst></doi-filter></section>
		</doi-title>
	</doi-view-title>

	<div class="doi-subview">

		<div *ngIf="viewModeGrid" class="doi-scroll">
			<div doi-object-grid>
				<div doi-object-grid-item *ngFor="let archiveOrig of archiveOrigs|filter:filter:filterGridText"
					(open)="openObject(archiveOrig)" [iconClass]="archiveOrigIconClass(archiveOrig)" title="{{archiveOrig.name}}" info="{{archiveOrig.code}}">
				</div>
			</div>
		</div>

		<div *ngIf="viewModeTable" class="doi-scroll-container">
			<table class="table table-striped table-hover">
				<thead>
					<tr class="doi-clickable">
						<th class="doi-grow-3">Namn</th>
						<th class="doi-grow-5 doi-hidden-xs-down">Giltighetstid</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let archiveOrig of archiveOrigs|filter:filter:filterTableText" (click)="openObject(archiveOrig)" class="doi-clickable">
						<td class="doi-grow-3 doi-wrap"><a [routerLink]="routerLinkArchiveOrig(archiveOrig)">{{archiveOrig.name}}</a></td>
						<td class="doi-grow-5 doi-wrap doi-hidden-xs-down">{{archiveOrig.existFrom}}--{{archiveOrig.existTo}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		
	</div>

</div>

<!-- 3eM4gBUorx476kgTRD0Xph+SH1w= -->
