import {Injectable} from '@angular/core';
import {HttpParams, HttpErrorResponse} from '@angular/common/http';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiService, DoiBrokerService, DoiObject} from '../../doi/DoiModule';
import {DoiSearchBrokerService} from '../model/DoiSearchBrokerService';
import {DoiSearchCriteria} from '../model/DoiSearchCriteria';
import {DoiSearchResult} from '../model/DoiSearchResult';
import {DoiSearchResultEntry} from '../model/DoiSearchResultEntry';

/**
 * The search service.
 */
@Injectable()
export class DoiSearchService
{
	name = 'DoiSearchService';

	doi: DoiService;

	result: DoiSearchResult = null;
	criteriaPending: DoiSearchCriteria;

	nextSearchID: number = 1;

	constructor(doi: DoiService)
	{
		this.doi = doi;

		this.doi.auth.authenticatedState().subscribe(loggedIn =>
		{
			if (!loggedIn)
				this.result = null;
		});
	}

	url()
	{
		return this.doi.auth.urlContext() + '/search';
	}

	/**
	 * Search with the specified criteria. If there is already a result with the same criteria, it is returned as an observable.
	 */
	search(criteria: DoiSearchCriteria, countLimit?: number, objectTypes?: string[]): Observable<DoiSearchResult>
	{
		if (this.result && this.result.criteria.equals(criteria) && !this.result.isStale()) {
			return of(this.result);
		}

		if (!criteria.text)
			return EMPTY;

		if (this.criteriaPending && this.criteriaPending.equals(criteria))
			return EMPTY;

		if (!countLimit)
			countLimit = 1000;

		let params = new HttpParams()
			.set('text', criteria.text)
			.set('wordMode', String(criteria.wordMode))
			.set('max', countLimit.toString())
			.set('locale', 'sv_SE') // TODO: From DOI service;
		if (criteria.scope)
			params = params.set('scope', criteria.scope);
		if (criteria.client)
			params = params.set('client', criteria.client);

		if (objectTypes) { // TODO: In criteria
			for (let objectType of objectTypes) {
				params = params.append('objectType', objectType);
			}
		}

		let options = {
			params: params
		}

		let searchID = this.nextSearchID++;
		let result = new DoiSearchResult(searchID);

		this.criteriaPending = criteria;

		return this.doi.http.get(this.url() + '/result.json', options).pipe(
			map(response => {
				this.criteriaPending = null;
				result.parseData(response);
				result.computeStaleTime();
				result.criteria = criteria;
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				this.criteriaPending = null;
				return this.doi.handleError(error);
			})
		);
	}

	/**
	 * Fetch all entry objects that hasn't been fetched yet by delegating to object type brokers.
	 */
	fetchResultEntries(entries: DoiSearchResultEntry[], typeServices: Map<string, DoiSearchBrokerService<DoiObject>>, entryProcessor : (entry: DoiSearchResultEntry) => void)
	{
		let fetchEntries = new Map<string, DoiSearchResultEntry[]>();

		for (let entry of entries) {
			if (!entry.object) {
				let entries = fetchEntries.get(entry.objectType);
				if (!entries)
					fetchEntries.set(entry.objectType, entries = new Array<DoiSearchResultEntry>());
				entries.push(entry);
			}
		}

		for (let objectType of Array.from(fetchEntries.keys())) {
			let entries = fetchEntries.get(objectType);
			if (entries && entries.length > 0) {
				let service = typeServices.get(objectType);
				if (service) {
					let ids = new Array<number>();
					let emap = new Map<number, DoiSearchResultEntry>();
					for (let entry of entries) {
						ids.push(entry.objectID);
						emap.set(entry.objectID, entry);
					}
					service.readSearchResultEntries(ids).subscribe(objects => {
						for (let object of objects) {
							let entry = emap.get(object.objectID);
							if (entry)
								entry.object = object;
							entryProcessor(entry);
						}
					})
				}
			}
		}
	}

	/**
	 * Invalidate the result.
	 */
	invalidateResult()
	{
		if (this.result)
			this.result.stale();
	}
}

