import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, Observable, forkJoin} from 'rxjs';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {DoiObjectView, DoiService} from '../../doi/DoiModule';

import {SeriesService} from '../model/SeriesService';
import {SeriesPart, SeriesObject} from '../model/SeriesObject';

/**
 *
 */
@Component({
	templateUrl: 'SeriesObjectView.html'
})
export class SeriesObjectView extends DoiObjectView<SeriesObject>
{
	name = 'SeriesObjectView';

	/**
	 * Template for a new object.
	 */
	objectTemplate: SeriesObject;

	/**
	 * Construct a new object view.
	 */
	constructor(doi: DoiService, private seriesService: SeriesService, route: ActivatedRoute)
	{
		super(doi, seriesService, route);
	}

	/**
	 * Build a link to the archive originator, either from the series archive or through the group if strict.
	 */
	routerLinkArchiveOrig()
	{
		if (this.object.archive)
			return this.object.archive.archiveOrig && ['/archiveorig', this.object.archive.archiveOrigID];
		else if (this.object.seriesGroup)
			return this.object.seriesGroup.archive && this.object.seriesGroup.archive.archiveOrig && ['/archiveorig', this.object.seriesGroup.archive.archiveOrigID];
	}

	/**
	 * Build a title for the link to the archive, either from the series archive or through the group if strict.
	 */
	routerLinkArchiveOrigTitle()
	{
		if (this.object && this.object.archive)
			return this.object.archive.archiveOrig && this.object.archive.archiveOrig.objectTitle()
		else if (this.object && this.object.seriesGroup && this.object.seriesGroup.archive)
			return this.object.seriesGroup.archive.archiveOrig && this.object.seriesGroup.archive.archiveOrig.objectTitle()
	}

	/**
	 * Build a link to the archive, either from the series archive or through the group if strict.
	 */
	routerLinkArchive()
	{
		if (this.object.archive)
			return ['/archive', this.object.archiveID];
		else if (this.object.seriesGroup)
			return this.object.seriesGroup.archive && ['/archive', this.object.seriesGroup.archiveID];
	}

	/**
	 * Build a title for the link to the archive, either from the series archive or through the group if strict.
	 */
	routerLinkArchiveTitle()
	{
		if (this.object && this.object.archive)
			return this.object.archive.objectTitle();
		else if (this.object && this.object.seriesGroup)
			return this.object.seriesGroup.archive && this.object.seriesGroup.archive.objectTitle();
	}

	/**
	 * Build a link to the series group.
	 */
	routerLinkSeriesGroup()
	{
		return this.object.seriesGroup && ['/seriesgroup', this.object.seriesGroupID];
	}

	/**
	 * Build a title for the link to the series group.
	 */
	routerLinkSeriesGroupTitle()
	{
		return this.object && this.object.seriesGroup && this.object.seriesGroup.objectTitle();
	}

	/**
	 * Create and return an observable that when subscribed will refresh the view.
	 * Overridden to refresh object parts presented by the object view itself.
	 */
	refreshView(): Observable<any>
	{
		if (this.doi.auth.isAuthenticating())
			return EMPTY;

		return forkJoin(
			this.refreshObjectPart(SeriesPart.Label).pipe(
				switchMap(series => {
					return this.seriesService.parentObjectObservable(series);
				})
			),
			this.probeObjectPart(SeriesPart.General),
			this.probeObjectPart(SeriesPart.Volumes),
			this.probeObjectPart(SeriesPart.ProcessActTypes),
			this.probeObjectPart(SeriesPart.Processes),
			super.refreshView()
		);

	}

	/**
	 * Test if any of the object parts used by the specified object panel has been found to have backend values as a result of an object probe. If not
	 * known, undefined is returned.
	 * Overriddene to invoke objectPartHasValues with the part name or part names used by the panel.
	 */
	probedPanel(panelName: string): boolean
	{
		switch (panelName) {
			case 'general': return this.probedPart('General');
			case 'volumes': return this.probedPart('Volumes');
			case 'acttypes': return this.probedPart('ProcessActTypes') || this.probedPart('Processes');
		}
	}

	/**
	 * Return a title describing the type of view without data dependencies.
	 */
	typeTitle(): string
	{
		return 'Serie'; // I18N
	}
}

// GuyvzknVeRezYTmoayZDt614AX4=
