import {Component, Input, Output, EventEmitter} from '@angular/core';

import {DoiIconMapper} from '../../doi/DoiModule';

import {ObjectFile} from '../model/ObjectFile';

/*
 * An object files component.
 */
@Component({
	selector: 'object-files',
	host: { 'class' : 'object-files doi-object-list' },
	template: `
		<p *ngFor="let objectFile of objectFiles">
			<object-file [objectFile]="objectFile" [iconMapper]="iconMapper" (download)="download(objectFile)"></object-file>
		</p>
	`,
	styles: [ 'p { margin-bottom: 0; }' ]
})
export class ObjectFilesComponent
{
	/**
	 * The object files.
	 */
	@Input()
	objectFiles: ObjectFile[];

	/**
	 * Download emitter.
	 */
	@Output('download')
	downloadEmitter = new EventEmitter<ObjectFile>();

	/**
	 * Optional icon mapper.
	 */
	@Input()
	iconMapper: DoiIconMapper;

	/**
	 * Invoked when an object file is clicked. Emits the file to the receiver.
	 */
	download(objectFile: ObjectFile)
	{
		this.downloadEmitter.emit(objectFile);
	}
}
