<link *ngFor="let url of themes.cssUrls()" rel="stylesheet" [attr.href]="url">

<div class="doi-app-container" [ngClass]="appContainerClasses()">

	<div class="doi-page-header" role="banner">
		<div class="doi-page-header-title">
			<ul doi-toolbar *ngIf="!navigatorRight()">
				<li doi-tool class="navigator-tool" [target]="this" action="Navigator" [compact]="isAppWebUI()"></li>
			</ul>
			<h1>
				<a routerLink="/">
					<i><img [src]="logoURL()" alt="Klara Logotyp"/></i>
					<div class="app-title">
						<span class="prefix">{{appTitlePrefix()}}</span>
						<span class="suffix doi-hidden-xs-down">{{appTitleSuffix()}}</span>
					</div>
				</a>
			</h1>
		</div>
		<ul doi-toolbar>
			<li doi-tool class="skip-to-content doi-hidden-xs-down" [target]="this" action="SkipToContent" [compact]="true"></li>
			<li class="search-tool" *ngIf="searchToolVisible()"><doi-search-tool class="doi-hidden-sm-down" (search)="onSearch($event)"></doi-search-tool></li>
			<li doi-tool class="doi-hidden-md-up" *ngIf="searchToolVisible()" [target]="this" action="Search"></li>
			<li doi-tool class="doi-hidden-lg-down" anchorTarget="klaradesktop" [target]="this" action="Klara"></li>
			<li *ngIf="hasFeatureDialog()" doi-tool anchorTarget="klaradialog" [target]="this" action="Dialog"></li>
			<li doi-tool *ngIf="!authenticated()" [target]="this" action="Login"></li>
			<li doi-tool *ngIf="authenticated()" [target]="this" dropdown="true" title="{{authenticatedName()}}" icon="far-user">
				<ul doi-dropdown-menu class="dropdown-menu-right" [target]="this" [actions]="menuUserActionNames(false)"></ul>
			</li>
			<li doi-tool [target]="this" action="ViewRefresh" [compact]="true"></li>
			<doi-object-bookmarks-tool [target]="this" [bookmark]="true" [compact]="true"></doi-object-bookmarks-tool>
			<li doi-tool class="doi-hidden-sm-up" [target]="this" dropdown="true" icon="fas-ellipsis-h-alt" tooltip="Verktyg">
				<ul doi-dropdown-menu class="dropdown-menu-right" [target]="this" [actions]="menuAppActionNames(true)"></ul>
			</li>
			<li doi-tool class="doi-hidden-xs-down" [target]="this" dropdown="true" icon="fas-ellipsis-h-alt" tooltip="Verktyg">
				<ul doi-dropdown-menu class="dropdown-menu-right" [target]="this" [actions]="menuAppActionNames(false)"></ul>
			</li>
			<li *ngIf="navigatorRight()" doi-tool class="navigator-tool" [target]="this" action="Navigator" [compact]="isAppWebUI()"></li>
		</ul>
	</div>

	<div class="doi-app-pane-container" [ngClass]="navigatorContainerClasses()">

		<div id="navPane" class="doi-app-pane doi-navigator-pane doi-background-secondary d-print-none"
			 [ngClass]="navigatorPaneClasses()"
			 [doi-hidden]="!navigatorVisible()"
			 mwlResizable [resizeEdges]="navResizeEdges()" (resizing)="navResizing($event)" (resizeEnd)="navResizeEnd($event)" [resizeCursorPrecision]="8"
			 (click)="navigatorPaneClick($event)">
			<div class="doi-navigator-wrapper doi-background-secondary"
				 (click)="navigatorClick($event)">
				<div class="doi-navigator-header doi-bar">
					<ul doi-toolbar>
						<li doi-tool [target]="this" action="NavigatorDocked" [compact]="true"></li>
						<li doi-tool [target]="this" action="NavigatorClose" [compact]="true"></li>
					</ul>
				</div>
				<div id="nav" class="doi-navigator">
					<ul doi-nav-root [root]="navRoot" (select)="navNodeSelected($event)"></ul>
				</div>
				<div class="doi-navigator-footer">
					<div class="small doi-hidden-sm-down" *ngIf="appInfo" ngbTooltip="{{appInfo.version.implementation}}">Version {{appInfo.version.specification}}{{development?" DEV":""}}</div>
				</div>
			</div>
		</div>
		<div class="doi-app-pane container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="doi-alert alert alert-danger theme-alert-webui">
						<span class="doi-alert-message">Detta tema är inte anpassat för Klara Arkivsök. Kontakta systemansvarig.</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<doi-alert></doi-alert>
				</div>
			</div>
			<div class="doi-page-content" role="main">
				<router-outlet (activate)="viewActivate($event)" (deactivate)="viewDeactivate($event)"></router-outlet>
			</div>
		</div>
	</div>

	<div class="doi-page-footer container-fluid" role=”contentinfo”>
		<div class="doi-storage-consent" *ngIf="localStorageChoice() == null && systemInfo?.cookieConsentText">
			<div class="row">
				<div class="col-12">
					<p>{{systemInfo?.cookieConsentText}}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="doi-dialog-buttons">
						<button class="btn btn-primary" (click)="localStorageConsent(true)">{{systemInfo?.cookieConsentButtonText}}</button>
						<button class="btn btn-danger" (click)="localStorageConsent(false)">{{systemInfo?.cookieDeclineButtonText}}</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row doi-debug" *ngIf="settings.debugMode">
			<div class="col-2">
				<span class="doi-hidden-lg-down">XL</span>
				<span class="doi-hidden-not-lg">LG</span>
				<span class="doi-hidden-not-md">MD</span>
				<span class="doi-hidden-not-sm">SM</span>
				<span class="doi-hidden-sm-up">XS</span>
				<span class="doi-hidden-landscape">/P</span>
				<span class="doi-hidden-portrait">/L</span>
			</div>
			<div class="col-2">{{publishLevel}}</div>
			<div class="col-8">{{lastUrl}}</div>
		</div>
	</div>

</div>