import {Injectable} from '@angular/core';
import {HttpParams, HttpErrorResponse} from '@angular/common/http';

import {environment} from '../environments/environment';

import {DoiBookmarkList, DoiBookmark, DoiObjectBookmark, DoiService, DoiObject} from './doi/DoiModule';

@Injectable({
	providedIn: 'root',
})
export class AppService extends DoiService
{
	/**
	 * Indicates if process codes are included in object titles. Initialied to true.
	 */
	showProcessCodes = true;

	/**
	 * The environment.
	 */
	get environment(): typeof environment { return environment }

	/**
	 * Test if the environment specifies that Klara Dialog is running.
	 */
	isAppDialog(): boolean
	{
		return this.environment.dialog;
	}

	/**
	 * Test if the environment specifies that Klara WebUI is running.
	 */
	isAppWebUI(): boolean
	{
		return !this.environment.dialog;
	}

	/**
	 * Add a bookmark.
	 * @param bookmark The bookmark.
	 */
	bookmarkAdd(bookmark: DoiBookmark): void
	{
		if (!this.auth.isAuthenticated()) {
			super.bookmarkAdd(bookmark);
			return;
		}


		this.bookmarkList().add(bookmark);

		if (!(bookmark instanceof DoiObjectBookmark))
			return;

		let data = {
			General: {
				Bookmark_BinderName: bookmark.objectType,
				Bookmark_ObjectID: bookmark.objectID,
			}
		};

		this.http.post(this.auth.urlContext()+'/Bookmark/object/-/bookmark.json', data).subscribe(
			(response: any) => {
				bookmark.bookmarkID = response.ID;
			},
			(error: HttpErrorResponse) => this.handleError(error)
		);
	}

	/**
	 * Remove a bookmark.
	 * @param bookmark The bookmark.
	 */
	bookmarkRemove(bookmark: DoiBookmark): void
	{
		if (!this.auth.isAuthenticated()) {
			super.bookmarkRemove(bookmark);
			return;
		}

		this.bookmarkList().remove(bookmark);

		if (!(bookmark instanceof DoiObjectBookmark))
			return;

		this.http.delete(this.auth.urlContext()+'/Bookmark/object/'+bookmark.bookmarkID+'/bookmark.json').subscribe(
			(response: any) => {},
			(error: HttpErrorResponse) => this.handleError(error)
		);
	}

	/**
	 * Load the bookmark list.
	 */
	bookmarksLoad(bookmarks: DoiBookmarkList)
	{
		if (!this.auth.isAuthenticated()) {
			super.bookmarksLoad(bookmarks);
			return;
		}

		if (!this.auth.isAuthenticated())
			return;

		let params = new HttpParams();

		params = params.set('type', '0');
		if (bookmarks.maxSize)
			params = params.set('count', bookmarks.maxSize.toString());
		for (let name of this.brokerServiceNames()) {
			if (this.isAppDialog() && name != 'Process' && name != 'ProcessActType')
				continue;
			params = params.append('objectType', name);
		}

		let stripRE = null;
		if (!this.showProcessCodes)
			 stripRE = new RegExp('^[\\d\\.]+\\s*-?\\s*');

		this.http.get(this.auth.urlContext()+'/Bookmark/selection/bookmarks.json', { params: params }).subscribe(
			(response: any) => {
				for (let r of response) {
					let bookmarkID = r.Bookmark_BookmarkID;
					let objectType = r.Bookmark_BinderName;
					let objectID = r.Bookmark_ObjectID;
					let objectTitle: string = r.Bookmark_Title;
					if (stripRE)
						objectTitle = objectTitle.replace(stripRE, '');
					let brokerService = this.brokerService(objectType);
					if (brokerService) {
						let bookmark = new DoiObjectBookmark(bookmarkID, objectType, objectID, objectTitle, brokerService.iconName);
						bookmarks.list.push(bookmark);
					}
				}
			});
	}

}