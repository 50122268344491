import {DoiObject, DoiObjectRef, DoiObjectPermission} from '../../doi/DoiModule';
import {DocStoreLink, PropertyValue, ObjectFile} from '../../klara/KlaraModule';

/**
 *	Object part names for the ArchiveOrig entity object.
 */
export const ArchiveOrigPart =
{
	Label : 'Label',
	General : 'General',
	History : 'History',
	LabelText : 'LabelText',
	Logo : 'Logo',
	Notes : 'Notes',
	Children : 'Children',
	PropertyValues : "PropertyValues",
	ObjectFiles : "ObjectFiles",
	ProcessStructures : "ProcessStructures",
	Archives : 'Archives',
	AltNames : 'AltNames',
	BusinessPlaces : 'BusinessPlaces',
	BusinessTypes : 'BusinessTypes',
}

/**
 *	The ArchiveOrig entity object.
 */
export class ArchiveOrigObject extends DoiObject
{
	static readonly iconName = 'warehouse';

	/**
	 *	Attribute values.
	 */
	name: string;
	archOrigCategoryID: number;
	parentArchiveOrigID: number;
	code: string;
	existFrom: string;
	existTo: string;
	history: string;
	inProgress: boolean;
	forGrouping: boolean;
	labelText: string;
	notes: string;
	objectUUID: any;
	orgCode: string;
	sequence: number;
	topographicName: string;
	categoryName: string;

	children: Array<Child>;
	propertyValues: Array<PropertyValue>;
	objectFiles: Array<ObjectFile>;
	processStructures: Array<ArchOrigProcStructObject>;
	archives: Array<Archive>;
	businessPlaces: Array<BusinessPlace>;
	businessTypes: Array<BusinessType>;
	altNames: Array<AltName>;
	docStoreLinks: DocStoreLink[];

	/**
	 *	Parent.
	 */
	parent: ArchiveOrigObject;

	/**
	 * Construct a new ArchiveOrig object.
	 */
	constructor(objectID: number)
	{
		super(objectID);
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		if (!this.forGrouping)
			return ArchiveOrigObject.iconName;
		else
			return 'folder';
	}

	/**
	 * Return a text suitable for additional text in search results etc. Overridden to return notes.
	 */
	objectText(): string
	{
		return this.notes;
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectTitle(): string
	{
		return this.name;
	}

	/**
	 * The symbolic object type name, i e 'ArchiveOrig'.
	 */
	get objectType(): string
	{
		return 'ArchiveOrig';
	}

	/**
	 * Return an object reference path from the root, corresponding to the navigation structure, or null.
	 */
	objectRefPath(): DoiObjectRef[]
	{
		let path: DoiObjectRef[] = null;

		if (this.parent) {
			path = this.parent.objectRefPath();
			path.push(this.objectRef());
			return path;
		}

		path = new Array<DoiObjectRef>();

		path.push(this.objectRef());

		return path;
	}

	/**
	 * Populate an object from a data object.
	 */
	parseData(data: any, partName: string): ArchiveOrigObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, ArchiveOrigPart.Label);
				//	Additional search result entry information.
				if (data.ArchiveOrig_Notes)
					this.notes = data.ArchiveOrig_Notes;
				break;
			case ArchiveOrigPart.Label:
				this.objectID = data.ArchiveOrig_ArchiveOrigID;
				this.name = data.ArchiveOrig_Name;
				this.code = data.ArchiveOrig_Code;
				this.forGrouping = data.ArchiveOrig_ForGrouping;
				this.parentArchiveOrigID = data.ArchiveOrig_ParentArchiveOrigID;
				this.sequence = data.ArchiveOrig_Sequence;
				this.objectUUID = data.ArchiveOrig_ObjectUUID;
				if (data.ArchiveOrig_ExistFrom)
					this.existFrom = data.ArchiveOrig_ExistFrom;
				if (data.ArchiveOrig_ExistTo)
					this.existTo = data.ArchiveOrig_ExistTo;
				break;
			case ArchiveOrigPart.General:
				this.parseData(data, ArchiveOrigPart.Label);
				this.inProgress = data.ArchiveOrig_InProgress;
				this.forGrouping = data.ArchiveOrig_ForGrouping;
				this.orgCode = data.ArchiveOrig_OrgCode;
				this.sequence = data.ArchiveOrig_Sequence;
				this.topographicName = data.ArchiveOrig_TopographicName;
				this.categoryName = data.ArchOrigCategory_Name;
				break;
			case ArchiveOrigPart.History:
				this.history = data.ArchiveOrig_History;
				break;
			case ArchiveOrigPart.LabelText:
				this.labelText = data.ArchiveOrig_LabelText;
				break;
			case ArchiveOrigPart.Logo:
				break;
			case ArchiveOrigPart.Notes:
				this.notes = data.ArchiveOrig_Notes;
				break;
			case ArchiveOrigPart.PropertyValues:
				this.propertyValues = new Array<PropertyValue>();
				if (data.ArchiveOrig_PropertyValues) {
					for (let e of data.ArchiveOrig_PropertyValues) {
						this.propertyValues.push(new PropertyValue().parseData(e));
					}
				}
				break;
			case ArchiveOrigPart.ObjectFiles:
				this.objectFiles = new Array<ObjectFile>();
				if (data.ArchiveOrig_ObjectFiles) {
					for (let e of data.ArchiveOrig_ObjectFiles) {
						let objectFile = new ObjectFile();
						objectFile.parseData(e);
						this.objectFiles.push(objectFile);
					}
				}
				break;
			case ArchiveOrigPart.Children:
				this.children = new Array<Child>();
				if (data.ArchiveOrig_Children) {
					for (let e of data.ArchiveOrig_Children) {
						let child = new Child();
						child.parseData(e);
						this.children.push(child);
					}
				}
				break;
			case ArchiveOrigPart.ProcessStructures:
				this.processStructures = new Array<ArchOrigProcStructObject>();
				if (data.ArchiveOrig_ProcessStructures) {
					for (let e of data.ArchiveOrig_ProcessStructures) {
						let procStruct = new ArchOrigProcStructObject();
						procStruct.parseData(e);
						this.processStructures.push(procStruct);
					}
				}
				break;
			case ArchiveOrigPart.Archives:
				this.archives = new Array<Archive>();
				if (data.ArchiveOrig_Archives) {
					for (let e of data.ArchiveOrig_Archives) {
						let archive = new Archive();
						archive.parseData(e);
						this.archives.push(archive);
					}
				}

				break;
			case ArchiveOrigPart.BusinessPlaces:
				this.businessPlaces = new Array<BusinessPlace>();
				if (data.ArchiveOrig_BusinessPlaces) {
					for (let e of data.ArchiveOrig_BusinessPlaces) {
						let businessPlace = new BusinessPlace();
						businessPlace.parseData(e);
						this.businessPlaces.push(businessPlace);
					}
				}
				break;
			case ArchiveOrigPart.BusinessTypes:
				this.businessTypes = new Array<BusinessType>();
				if (data.ArchiveOrig_BusinessTypes) {
					for (let e of data.ArchiveOrig_BusinessTypes) {
						let businessType = new BusinessType();
						businessType.parseData(e);
						this.businessTypes.push(businessType);
					}
				}
				break;
			case ArchiveOrigPart.AltNames:
				this.altNames = new Array<AltName>();
				if (data.ArchiveOrig_AltNames) {
					for (let e of data.ArchiveOrig_AltNames) {
						let altName = new AltName();
						altName.parseData(e);
						this.altNames.push(altName);
					}
				}
				break;
		}
		return this;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(partName: string): any
	{
		switch (partName) {
			case ArchiveOrigPart.General:
				return {
					ArchiveOrig_ObjectDelete: this.objectDelete,
					ArchiveOrig_Name: this.name,
					ArchiveOrig_Code: this.code,
					ArchiveOrig_ExistFrom: this.existFrom,
					ArchiveOrig_ExistTo: this.existTo,
					ArchiveOrig_InProgress: this.inProgress,
					ArchiveOrig_ForGrouping: this.forGrouping,
					ArchiveOrig_ObjectUUID: this.objectUUID,
					ArchiveOrig_OrgCode: this.orgCode,
					ArchiveOrig_Sequence: this.sequence,
					ArchiveOrig_TopographicName: this.topographicName,
				}
		}
	}

	/**
	 * Add permissions on a new object, based on the parent object.
	 */
	newPermissions()
	{
		if (this.parent && this.parent.permitWrite())
			this.objectPermissions |= DoiObjectPermission.create|DoiObjectPermission.write;
	}
}

/**
 * A Child, corresponding to a detail object from Project part children.
 */
export class Child
{
	static readonly iconName = 'circle';

	archiveOrigID: number;
	name: string;
	parentArchiveOrigID: number;
	code: string;
	existFrom: string;
	existTo: string;
	categoryName: string;
	sequence: number;
	forGrouping: boolean;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): Child
	{
		this.archiveOrigID = data.ArchiveOrig_ArchiveOrigID;
		this.code = data.ArchiveOrig_Code;
		this.name = data.ArchiveOrig_Name;
		this.parentArchiveOrigID = data.ArchiveOrig_parentArchiveOrigID;
		this.existFrom = data.ArchiveOrig_ExistFrom;
		this.existTo = data.ArchiveOrig_ExistTo;
		this.categoryName = data.ArchOrigCategory_Name;
		this.sequence = data.ArchiveOrig_Sequence;
		this.forGrouping = data.getArchiveOrig_ForGrouping;

		return this;
	}
}

export class ArchOrigProcStructObject
{
	static readonly iconName = 'sitemap';

	processStructureID: number;
	name: string;
	description: string;
	endDate: Date;
	startDate: Date;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): ArchOrigProcStructObject
	{
		this.processStructureID = data.ProcessStructure_ProcStructID;
		this.name = data.ProcessStructure_Name;
		this.description = data.ProcessStructure_Description;
		this.startDate = data.ProcessStructure_StartDate;
		this.endDate = data.ProcessStructure_EndDate;
		return this;
	}
}

/**
 * A Child, coresponding to a detail object from archive.
 */
export class Archive
{
	static readonly iconName = 'warehouse-alt';

	archiveID: number;
	name: string;
	parentArchiveOrigID: number;
	instCode: string;
	refCode: string;
	countryCode: string;
	archiveInstCode: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): Archive
	{
		this.archiveID = data.Archive_ArchiveID;
		this.name = data.Archive_Name;
		this.instCode = data.ArchiveInst_InstCode;
		this.refCode = data.Archive_RefCode;
		this.countryCode = data.Archive_CountryCode;
		this.archiveInstCode = data.ArchiveInst_InstCode;

		return this;
	}
}

/**
 * A Child, coresponding to a detail object from businessPlace.
 */
export class BusinessPlace
{

	businessPlaceID: number;
	code: string;
	name: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): BusinessPlace
	{
		this.businessPlaceID = data.BusinessPlace_BusinessPlaceID;
		this.name = data.BusinessPlace_Name;
		this.code = data.BusinessPlace_Code;
		return this;
	}
}

/**
 * A Child, coresponding to a detail object from businessType.
 */
export class BusinessType
{

	businessTypeID: number;
	name: string;
	code: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): BusinessType
	{
		this.businessTypeID = data.BusinessType_BusinessTypeID;
		this.name = data.BusinessType_Name;
		this.code = data.BusinessType_Code;
		return this;
	}
}
/**
 * A Child, coresponding to a detail object from businessType.
 */
export class AltName
{

	altNameID: number;
	name: string;
	usedFrom: string;
	usedTo: string;
	concatName: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): AltName
	{
		this.altNameID = data.ArchOrigAltName_ArchOrigAltNameID;
		this.name = data.ArchOrigAltName_Name;
		this.usedFrom = data.ArchOrigAltName_UsedFrom;
		this.usedTo = data.ArchOrigAltName_UsedTo;
		this.concatName = data.ArchOrigAltName_Name + data.ArchOrigAltName_UsedFrom ? data.ArchOrigAltName_UsedFrom  :"" + data.ArchOrigAltName_UsedTo ? data.ArchOrigAltName_UsedTo  :"" ;

		return this;
	}
}

// 5MBM6+F2AUFDPO2Z645Ycfzr0tY=
