import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {DoiModule, DoiObjectEditGuard, DoiRedirectGuard} from '../doi/DoiModule';

import {KlaraModule} from '../klara/KlaraModule';
import {ArchiveService} from './model/ArchiveService';
import {ArchiveObjectView} from './view/ArchiveObjectView';
import {ArchiveObjectGeneralPanel} from './view/ArchiveObjectGeneralPanel';
import {ArchiveObjectHistoryPanel} from './view/ArchiveObjectHistoryPanel';
import {ArchiveObjectSeriesPanel} from './view/ArchiveObjectSeriesPanel';
import {ArchiveObjectDetailPanel} from './view/ArchiveObjectDetailPanel';

export * from './model/ArchiveObject';
export * from './model/ArchiveService';

export const archiveRoutes: Routes = [
	{path: 'archive/:id', component: ArchiveObjectView, canActivate: [DoiRedirectGuard], canDeactivate: [DoiObjectEditGuard], data: { defaultChild: 'general' }, children: [
		{path: 'general', component: ArchiveObjectGeneralPanel, data: {title: 'Allmänt'}},
		{path: 'history', component: ArchiveObjectHistoryPanel, data: {title: 'Historik'}},
		{path: 'series', component: ArchiveObjectSeriesPanel, data: {title: 'Serier'}},
		{path: 'detail', component: ArchiveObjectDetailPanel, data: {title: 'Detaljer'}},
	]},
];

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(archiveRoutes),
		DoiModule, KlaraModule,
	],
	declarations: [
		ArchiveObjectView, ArchiveObjectGeneralPanel, ArchiveObjectHistoryPanel, ArchiveObjectSeriesPanel, ArchiveObjectDetailPanel,
	],
	exports: [
	],
	providers: [
		ArchiveService,
	],

})
export class ArchiveModule
{
}

// 8lqtZXFZy11dpJtx54Ko/8pII6E=
