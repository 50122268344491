<div class="doi-subview doi-scroll">

	<form role="form">
		<div class="row">
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="TidFran" label="Tid från" size="60" [(ngModel)]="object.existFrom"  [edit]="editing()"></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="TidTill" label="Tid till" size="60" [(ngModel)]="object.existTo" [edit]="editing()"></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="Beteckning" label="Beteckning" size="60" [(ngModel)]="object.code" [edit]="editing()"></doi-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="Huvudkategori" label="Huvudkategori" size="60" [(ngModel)]="object.categoryName"  [edit]="editing()"></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="Organisationskod" label="Organisationskod" size="60" [(ngModel)]="object.orgCode" [edit]="editing()"></doi-field>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<doi-field name="Topografiskt namn" label="Topografiskt namn" size="60" [(ngModel)]="object.topographicName"  [edit]="editing()"></doi-field>
			</div>
		</div>
		<div class="row">
			<div *ngIf="object.businessTypes && object.businessTypes.length" class="col-12 col-md-6">
				<h3>Verksamhetstyper</h3>
				<table class="table table-striped table-hover">
					<thead>
						<tr >
							<th class="doi-width-10" doi-wrap>Namn</th>
							<th class="doi-width-10" >Kod</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let businessType of object.businessTypes" >
							<td class="doi-width-10 doi-wrap">{{businessType.name}}</td>
							<td class="doi-width-10">{{businessType.code}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row">
			<div *ngIf="object.businessPlaces && object.businessPlaces.length" class="col-12 col-md-6">
				<h3>Verksamhetsorter</h3>
				<table class="table table-striped table-hover">
					<thead>
						<tr>
							<th class="doi-width-10 doi-wrap">Namn</th>
							<th class="doi-width-10">Kod</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let businessPlace of object.businessPlaces">
							<td class="doi-width-10 doi-wrap">{{businessPlace.name}}</td>
							<td class="doi-width-10">{{businessPlace.code}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row">
			<div *ngIf="object.altNames && object.altNames.length" class="col-12 col-md-6">
				<h3>Alternativa namn</h3>
				<table class="table table-striped table-hover">
					<thead>
						<tr >
							<th class="doi-width-10 doi-wrap">Namn</th>
							<th class="doi-width-5">Användes från</th>
							<th class="doi-width-5">Användes till</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let altName of object.altNames" >
							<td class="doi-width-10 doi-wrap">{{altName.name}}</td>
							<td class="doi-width-5">{{altName.usedFrom}}</td>
							<td class="doi-width-5">{{altName.usedTo}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</form>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
