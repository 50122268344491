import {Component, Input, Output, EventEmitter, forwardRef, HostBinding} from '@angular/core';
import {DatePipe} from '@angular/common';
import {FormControl} from '@angular/forms';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable, of} from 'rxjs';
import {map, tap} from "rxjs/operators";

import {DoiLabeledValue} from '../core/DoiLabeledValue';
import {DoiValueComponent} from './DoiValueComponent';

/**
 * A lookup component that allows the user to type in some filter text and then look up matching values.
 */
@Component({
	selector: 'doi-lookup',
	host: { 'class' : 'doi-lookup' },
	template: `
		<div class="form-group doi-field" [ngClass]="{'doi-editing':edit, 'doi-not-editing':!edit}">
			<label *ngIf="label" class="doi-label">{{label}}</label><br/>
			<a class="dropdown-toggle" data-bs-toggle="dropdown">
				<div class="input-group" *ngIf="edit && !readonly">
					<input class="form-control" name="{{name}}" size="{{size}}" type="text" [(ngModel)]="text" [disabled]="!enabled" [readonly]="!filter" [required]="required" (blur)="focusLost($event)"/>
					<div class="input-group-append">
						<button type="button" class="btn" [disabled]="!enabled"><span class="fas fa-caret-down"></span></button>
					</div>
				</div>
				<ul class="dropdown-menu dropdown-menu-right" role="menu">
				  <li class="dropdown-item"
				  *ngFor="let v of valueList()|async"><a href="" [ngClass]="v.value == value ? 'selected' : null" (click)="select(v)">{{v.label}}&nbsp;</a></li>
				</ul>
			</a>
			<div class="input-group" *ngIf="edit && readonly">
				<input class="form-control" name="{{name}}" size="{{size}}" type="text" [(ngModel)]="text" [disabled]="true"/>
			</div>
			<span *ngIf="!edit" style="{{color ? 'color:'+color+';':''}}"><a class="doi-nolink" href="" title="{{tooltip}}" [innerHtml]="valueText()"></a></span>
		</div>
	`,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DoiLookupComponent),
		multi: true
	}]
})
export class DoiLookupComponent extends DoiValueComponent implements ControlValueAccessor
{
	/**
	 * Indicates if enabled.
	 */
	@Input()
	enabled = true;

	/**
	 * Allow the user to filter the value list.
	 */
	@Input() filter: boolean = true;

	/**
	 * The value list function.
	 */
	@Input('values')
	valueListFunction = (filter?: string) => { return of(<DoiLabeledValue<any>[]>[]); };

	/**
	 * The display text for the current value.
	 */
	private _text: string;

	/**
	 * The value list observable.
	 */
	private valueListObs: Observable<DoiLabeledValue<any>[]>;

	/**
	 * The filter value corresponding to the above observable.
	 */
	private valueListFilter: string;

	/**
	 * The values corresponding to the above observable.
	 */
	private valueListResult: DoiLabeledValue<any>[];

	/**
	 * Construct a new component.
	 */
	constructor()
	{
		super();
	}

	/**
	 * The text for the current value.
	 */
	@Input()
	get text(): any
	{
		return this._text;
	}
	set text(text: any)
	{
		this._text = text;
	}

	/**
	 * Select the specified value, which may be null.
	 * Invoked by the dropdown items populated from the result list.
	 */
	select(value: DoiLabeledValue<any>)
	{
		if (this.readonly)
			return;

		this.value = value ? value.value : null;
		this.text = value ? value.label : null;
	}

	/**
	 * Return the value list.
	 */
	valueList(): Observable<DoiLabeledValue<any>[]>
	{
		//	Discard any old result if there is no value list observable or if the filter text has been edited.

		if (!this.valueListObs || this.valueListFilter != this._text) {
			this.valueListFilter = this._text;
			this.valueListResult = null;
			this.valueListObs = this.valueListFunction(this.filter ? this.valueListFilter : null);
		}

		//	Use the current value list if available.

		if (this.valueListResult)
			return of(this.valueListResult);

		//	Invoke the observable to fetch a new value list.

		return this.valueListObs.pipe(
			map(values => {
				//	Save the received result.
				let result = values;
				//	If selection is optional, create a new result prepended with an empty value.
				if (!this.required) {
					result = new Array<DoiLabeledValue<any>>();
					result.push(DoiLabeledValue.of(null, ''));
					if (values) {
						for (let value of values) {
							if (value && value.value != null)
								result.push(value);
						}
					}
				}
				//	Find the current value in the result and pick up the text.
				let v = this.value;
				if (result && v != null && !this.filter) {
					for (let value of result) {
						if (value && value.value == v) {
							this.text = value.label;
							break;
						}
					}
				}
				//	Use the new result list.
				this.valueListResult = result;
				return result;
			})
		);
	}

	/**
	 * Return the displayed value text.
	 */
	valueText(): any
	{
		return this.text;
	}
}
