<div class="doi-subview doi-scroll">

	<form role="form">
		<div class="col-12 col-xl-11" *ngIf="object?.history">
			<span name="History" [innerHtml]="object.history"></span>
		</div>
	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
