/**
 * A path associated with a title and optional icon. Used for bookmarks or entries in a recently visited list.
 */
export class DoiBookmark
{
	path: string;
	title: string;
	iconName: string;

	/**
	 * Construct a new bookmark.
	 */
	constructor(path: string, title: string, iconName?: string)
	{
		this.path = path;
		this.title = title;
		this.iconName = iconName;
	}
}
