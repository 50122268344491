import {PropertyValue} from './PropertyValue';

export class Unit
{
	unitID: number;
	unitTypeName: string;
	code: string;
	title: string;
	date: string;
	navText: string;

	description: string;

	propertyValues: PropertyValue[];
}