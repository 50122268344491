import {ActivatedRoute, ParamMap} from '@angular/router';
import {map, switchMap, tap} from "rxjs/operators";

import {DoiService} from '../service/DoiService';
import {DoiView} from './DoiView';
import {DoiTopView} from './DoiTopView';
import { Directive } from "@angular/core";

@Directive()
export class DoiSubView extends DoiView
{
	name = 'DoiSubView';

	route: ActivatedRoute;

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi);

		this.route = route;
	}

	/**
	 * Invoked when the view has been initialized. Subscribes to path and query parameters.
	 */
	ngOnInit()
	{
		super.ngOnInit();

		if (this.route) {
			this.route.paramMap.pipe(switchMap((pm: ParamMap) => [ pm ])).subscribe((pm) => this.paramsRecieved(pm));
			this.route.queryParamMap.pipe(switchMap((pm: ParamMap) => [ pm ])).subscribe((pm) => this.queryParamsRecieved(pm));
		}
	}

	/**
	 * Invoked when path parameters are received. Invokes processParams.
	 */
	paramsRecieved(pm: ParamMap): void
	{
		this.processParams(pm);
	}

	/**
	 * Invoked when query parameters are received. Invokes processParams.
	 */
	queryParamsRecieved(pm: ParamMap): void
	{
		this.processParams(pm);
	}

	/**
	 * Invoked by when path are received.  Invokes processParams on the parent view.
	 */
	processParams(pm: ParamMap): void
	{
		let tv = this.topView();
		if (tv)
			tv.processParams(pm);
	}

	error(message: string)
	{
		if (this.parentView)
			this.parentView.error(message);
	}

	/**
	 * Return the period separated full view name. Overridden to prepend the parent name.
	 */
	fullName(): string
	{
		if (this.parentView)
			return this.parentView.fullName()+'.'+this.name;
		else
			return this.name;
	}

	/**
	 * Return the top view, or null.
	 */
	topView(): DoiTopView
	{
		if (this.parentView instanceof DoiTopView)
			return this.parentView;

		if (this.parentView instanceof DoiSubView)
			return this.parentView.topView();

		return null;
	}
}
