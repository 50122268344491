<div class="doi-view doi-selection-view unit-selection-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start"></ol>
		<ul doi-toolbar>
			<doi-viewmode-tools class="doi-hidden-sm-down" [view]="this"></doi-viewmode-tools>
		</ul>
		<doi-title>
			<span>Förvaringsenheter</span>
			<section><doi-filter name="filter" [(filter)]="filter" #focusFirst></doi-filter></section>
		</doi-title>
	</doi-view-title>

	<div class="doi-subview">

		<div *ngIf="viewModeGrid" class="doi-scroll">
			<div doi-object-grid>
				<div doi-object-grid-item *ngFor="let unit of units|filter:filter:filterGridText"
					(open)="openObject(unit)" [iconClass]="unitIconClass(unit)" title="{{unit.navText}}">
				</div>
			</div>
		</div>

		<div *ngIf="viewModeTable" class="doi-scroll-container">
			<table class="table table-striped table-hover">
				<thead>
					<tr class="doi-clickable">
						<th class="doi-grow-1">Typ</th>
						<th class="doi-grow-2">Beteckning/Benämning</th>
						<th class="doi-grow-1 doi-hidden-xs-down">Tid</th>
						<th class="doi-grow-5 doi-hidden-xs-down">Arkivbildare</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let unit of units|filter:filter:filterTableText" (click)="openObject(unit)" class="doi-clickable">
						<td class="doi-grow-1 doi-wrap">{{unit.unitTypeName}}</td>
						<td class="doi-grow-2 doi-wrap"><a [routerLink]="routerLinkUnit(unit)">{{unit.navText}}</a></td>
						<td class="doi-grow-1 doi-wrap doi-hidden-xs-down">{{unit.date}}</td>
						<td class="doi-grow-5 doi-wrap doi-hidden-xs-down"><a *ngIf="routerLinkArchiveOrig(unit)" [routerLink]="routerLinkArchiveOrig(unit)">{{unit.archiveOrigName}}</a></td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>

</div>

<!-- 9rOT4wQw7S8UIq1IvzfJA7CN5hY= -->
