import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {catchError, map, startWith, switchMap, tap} from "rxjs/operators";
import {Observable, throwError, of} from 'rxjs';

import {DoiService, DoiBrokerService} from '../../doi/DoiModule';

import {DocStoreLink, KlaraBrokerService} from '../../klara/KlaraModule';

import {SeriesService, SeriesObject, SeriesPart} from '../../series/SeriesModule';
import {VolumePart, VolumeObject} from './VolumeObject';

/**
 * Manages Volume objects.
 */
@Injectable()
export class VolumeService extends KlaraBrokerService<VolumeObject>
{
	/**
	 * Construct a new Volume service.
	 */
	constructor(doi: DoiService, private seriesService: SeriesService)
	{
		super(doi, 'Volume')
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return VolumeObject.iconName;
	}

	/**
	 * Create a new Volume object.
	 */
	newObject(objectID: number): VolumeObject
	{
		return new VolumeObject(objectID);
	}

	/**
	 * Create and return an observable that fetches the parent structure.
	 * This implementation returns an observable that fetches the Series, connects it to the Volume
	 * and then switches to a SeriesService.parentObjectObservable.
	 */
	parentObjectObservable(volume: VolumeObject): Observable<any>
	{
		return this.seriesService.readObjectPart(volume.seriesID, SeriesPart.Label, volume.series).pipe(
			switchMap((series: SeriesObject) => {
				volume.series = series;
				return this.seriesService.parentObjectObservable(series);
			})
		);
	}

	/**
	 * Read an object part.
	 */
	readObjectPart(id: number, partName: string, object?: VolumeObject): Observable<VolumeObject>
	{
		let options = undefined;
		switch (partName) {
			case VolumePart.General:
				options = {
					params: new HttpParams().append('related', 'Series')
				};
				break;
		}

		return super.readObjectPart(id, partName, object, options);
	}

	/**
	 * Read search result entries.
	 */
	readSearchResultEntries(objectIDs?: number[]): Observable<VolumeObject[]>
	{
		let params = new HttpParams();

		params = params
			.append('col', 'Volume_VolumeID')
			.append('col', 'Volume_VolumeCode')
			.append('col', 'Volume_Title')
			.append('col', 'Series_Signum');

		for (let objectID of objectIDs) {
			params = params.append('id', objectID.toString());
		}

		return this.readObjectSelection(params, null);
	}
}

// XLOXVuhZQ4knYsDUBIuK6/tJn2c=
