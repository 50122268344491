import {Component, Input, Output, EventEmitter} from '@angular/core';

/**
 * A refresh indicator component.
 */
@Component({
	selector: 'doi-refresh-ind',
	template: `
		<span class="d-print-none">
			<a *ngIf="period" title="Förnyas med period {{period/1000}} sekunder" href=""><span class="far fa-clock"></span></a>
			<a *ngIf="!(period || noFlash)" title="Förnyas automatiskt" href=""><span class="fa fa-bolt"></span></a>
		</span>	
  `,
})
export class DoiRefreshIndComponent
{
	/**
	 * The period in ms.
	 */
	@Input() period: number = 0;
	
	/**
	 * Suppress flash.
	 */
	@Input('no-flash') noFlash: boolean;
	
	/**
	 * Construct a new refresh indicator component.
	 */
	constructor()
	{
	}
}
