<div class="doi-view doi-object-view archive-object-view">

	<doi-view-title>
		<ol doi-breadcrumbs [view]="this" homeText="Start">
			<li doi-breadcrumb text="Struktur" link="/archiveorigs"></li>
			<li doi-breadcrumb *ngIf="routerLinkArchiveOrig()" [text]="object?.archiveOrig?.objectTitle()" [link]="routerLinkArchiveOrig()"></li>
		</ol>
		<ul doi-toolbar>
			<doi-object-edit-tools [objectView]="this"></doi-object-edit-tools>
		</ul>
		<doi-title>Arkiv {{object.isNew() && !object.objectID ? '(ny)' : object.objectTitle()}}</doi-title> <!-- I18N -->
	</doi-view-title>

	<ul doi-view-tabs #doiViewTabs [iconMapper]="this">
		<li doi-view-tab name="general" [iconName]="probedIconName('general')" #focusFirst>Allmänt</li>
		<li doi-view-tab name="detail" [iconName]="probedIconName('detail')">Detaljer</li>
		<li doi-view-tab name="history" [iconName]="probedIconName('history')">Historik</li>
		<li doi-view-tab name="series" [iconName]="probedIconName('series')">Serier</li>
	</ul>

	<router-outlet (activate)="tabActivate($event)" (deactivate)="tabDeactivate($event)"></router-outlet>

</div>

<!-- egB1PtPw8Y2ZMZNs93HODFkOY3E= -->
