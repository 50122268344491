import {Pipe, PipeTransform} from '@angular/core';

/**
 *
 */
@Pipe({name: 'filter'})
export class DoiFilterPipe<T> implements PipeTransform
{
	transform(items: T[], filterText?: string, filterTextFunction?: (item: T) => string): T[]
	{
		if (!items || !filterText) {
			return items;
		}

		let f = filterText.toLocaleLowerCase();

		if (filterTextFunction) {
			return items.filter(item => (filterTextFunction(item)||'').toLocaleLowerCase().indexOf(f) >= 0);
		}

		return items.filter(item => {
			for (var p in item) {
				if (item.hasOwnProperty(p)) {
					let t = typeof item[p];
					if (t == 'string' || t == 'number') {
						let s = String(item[p]);
						s = s.toLocaleLowerCase();
						if (s.indexOf(f) >= 0)
							return true;
					}
				}
			}
			return false;
		});
	}
}
