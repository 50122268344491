import {Component, Input} from '@angular/core';

/**
 * A view title component containing breadcrumbs, a toolbar and a title.
 */
@Component({
	selector: 'doi-view-title',
	template: `
		<div class="doi-view-title">
			<div *ngIf="tools" class="row doi-background-secondary">
				<div class="col-12 doi-bar">
					<ng-content select="[doi-breadcrumbs]"></ng-content>
					<ng-content select="[doi-toolbar]"></ng-content>
				</div>
			</div>
			<div class="row doi-header">
				<div class="col-12">
					<ng-content select="doi-title"></ng-content>
				</div>
			</div>
		</div>
	`
})
export class DoiViewTitleComponent
{
	@Input()
	tools = true;
}
