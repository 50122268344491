import {DoiBookmark} from './DoiBookmark';
import {DoiObject} from '../service/DoiObject';

/**
 * An object bookmark.
 */
export class DoiObjectBookmark extends DoiBookmark
{
	bookmarkID: number;
	objectType: string;
	objectID: number;

	/**
	 * Construct a new object bookmark.
	 */
	constructor(bookmarkID: number, objectType: string, objectID: number, objectTitle?: string, iconName?: string)
	{
		super('/'+objectType.toLowerCase()+'/'+objectID, objectTitle, iconName);

		this.bookmarkID = bookmarkID;
		this.objectType = objectType;
		this.objectID = objectID;
	}

	/**
	 * Create an object bookmark for the specified object.
	 */
	static forObject(object: DoiObject): DoiObjectBookmark
	{
		return new DoiObjectBookmark(null, object.objectType, object.objectID, object.objectTitle(), object.iconName);
	}
}
