/**
 * Implemented by classes that owns some storage and can get, set and remove storage items.
 */
export interface DoiStorageOwner
{
	/**
	 * Get the specified item from the preferred storage, either local or session.
	 * The actual item name is constructed from the view name and the item.
	 */
	storageGetItem(item: string, def?: any): any;

	/**
	 * Set the specified item in the preferred storage, either local or session.
	 * The actual item name is constructed from the view name and the item.
	 */
	storageSetItem(item: string, value: any): any;

	/**
	 * Remove the specified item from preferred storage, either local or session.
	 * The actual item name is constructed from the view name and the item.
	 */
	storageRemoveItem(item: string): any
}
