<div class="doi-subview">

	<div *ngIf="object.processesTop?.length" class="doi-scroll-container">
		<table class="table table-striped table-hover">
			<thead>
				<tr class="doi-clickable">
					<th class="doi-grow-3">Strukturenhet</th>
					<th class="doi-grow-1 doi-hidden-xs-down">Tid från</th>
					<th class="doi-grow-5 doi-hidden-xs-down">Tid till</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let process of object.processesTop" (click)="openProcess(process)" class="doi-clickable">
					<td class="doi-grow-3 doi-wrap"><a [routerLink]="routerLinkProcess(process)">{{process.code}} - {{process.nameList}}</a></td>
					<td class="doi-grow-1 doi-wrap doi-hidden-xs-down">{{process.dateBegin|date:'shortDate':'':'sv'}}</td>
					<td class="doi-grow-5 doi-wrap doi-hidden-xs-down">{{process.dateEnd|date:'shortDate':'':'sv'}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>