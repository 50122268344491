
/**
 * A DocStoreLink, corresponding to a document store link.
 */
export class DocStoreLink
{
	ID: number;
	code: string;
	name: string;
	URL: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): DocStoreLink
	{
		this.ID = data.Integration_IntegrationID;
		this.code = data.Integration_Code;
		this.name = data.Integration_Name;
		this.URL = data.Integration_URL;
		return this;
	}
}
