import { PropertyValue } from "./PropertyValue";

/**
 * A property value group.
 */
export class PropertyValueGroup
{
	title: string;
	sequence: number;
	values: PropertyValue[];

	/**
	 * Construct a new property value group from
	 */
	constructor(title: string, sequence: number)
	{
		this.title = title;
		this.sequence = sequence;
		this.values = new Array<PropertyValue>();
	}

	/**
	 * Pad the value array with nulls to ensure that grid cells are created for each row.
	 */
	padded(): PropertyValueGroup
	{
		if (!this.values.length)
			return this;
		while (this.values.length % 4) {
			this.values.push(null);
		}
		return this;
	}
}