import {Component, Input} from '@angular/core';

import {DoiBookmarkList} from '../bookmark/DoiBookmarkList'
import {DoiBookmark} from '../bookmark/DoiBookmark'
import {DoiTopView} from '../view/DoiTopView'

/**
 * Tool for selecting a bookmark from a dropdown menu and optionally bookmarking an object.
 */
@Component({
	selector: 'doi-object-bookmarks-tool',
	template: `
		<li doi-tool [dropdown]="dropdownAvailable()" title="Bokmärken" [icon]="dropdownIcon()" [target]="target" [compact]="compact">
			<ul doi-dropdown-menu class="dropdown-menu-right">
				<li doi-dropdown-item *ngIf="hasBookmarkAction" [separatorAfter]="bookmarksAvailable()" [target]="target" action="Bookmark"></li>
				<li doi-dropdown-item *ngFor="let bm of bookmarkList()" [target]="target" [icon]="bm.iconName" [title]="bm.title" (click)="bookmarkOpen(bm)"></li>
			</ul>
		</li>
  `,
})
export class DoiObjectBookmarksToolComponent
{
	/**
	 * The target view.
	 */
	@Input() target: DoiTopView;

	/**
	 * Indicates if the Bookmark action should have a menu entry. Use false if a separate bookmark tool is used.
	 */
	@Input('bookmark')
	hasBookmarkAction: boolean = true;

	/**
	 * Indicates if the tool is compact and suppresses any title.
	 */
	@Input() compact: boolean = true;

	/**
	 * Construct a new component.
	 */
	constructor()
	{
	}

	/**
	 * Test if a dropdown menu should be available.
	 * If a Bookmark action is available, a dropdown list is available if the action is enabled. TODO: Why?
	 * If a Bookmark action is not available, a dropdown list is available if a bookmark list is available. TODO: Why?
	 */
	dropdownAvailable(): boolean
	{
		let action = this.target.action('Bookmark');
		if (action)
			return action.enabled;
		return this.bookmarksAvailable();
	}

	/**
	 * Return the icon of for the dropdown bookmark menu.
	 * If a Bookmark action is available, the icon is fetched from the action, filled or unfilled depending on the action state.
	 * If a Bookmark action is not available, the icon is always unfilled.
	 */
	dropdownIcon()
	{
		if (!this.hasBookmarkAction)
			return 'far-bookmark';

		let action = this.target.action('Bookmark');
		if (action)
			return action.icon;
		else
			return 'far-bookmark';
	}

	bookmarksAvailable(): boolean
	{
		let bookmarks = this.target.doi.bookmarkList().list;
		return bookmarks.length > 0;
	}

	bookmarkList(): DoiBookmark[]
	{
		let bookmarks = this.target.doi.bookmarkList().list;
		return bookmarks;
	}

	bookmarkOpen(bookmark: DoiBookmark)
	{
		this.target.doi.router.navigate([ bookmark.path, 'general' ]);
	}
}
