import {DoiSearchCriteria} from './DoiSearchCriteria';
import {DoiSearchResultCat, DoiSearchResultCatGroup} from './DoiSearchResultCatGroup';
import {DoiSearchResultEntry} from './DoiSearchResultEntry';

/**
 * A search result containing a criteria, category groups and result entries. Also keeps track of page count and current page index.
 */
export class DoiSearchResult
{
	/**
	 * The search ID, or 0 for a result that has not been received from the server.
	 */
	readonly searchID: number;

	/**
	 * The category groups.
	 */
	readonly catGroups = new Array<DoiSearchResultCatGroup>();

	/**
	 * The search result entries.
	 */
	readonly entries = new Array<DoiSearchResultEntry>();

	/**
	 * Index of category IDs to categories.
	 */
	readonly catIndex = new Map<number, DoiSearchResultCat>();

	/**
	 * Count limited object types.
	 */
	readonly countLimited = new Set<String>();

	/**
	 * The search criteria.
	 */
	criteria = new DoiSearchCriteria();

	/**
	 * The # of enties included after category filtering.
	 */
	countIncluded: number;

	/**
	 * The page size. Initially 20.
	 */
	pageSize: number = 20;

	/**
	 * The # of pages required for the filtered result.
	 */
	pageCount: number;

	/**
	 * The current 0 based page index.
	 */
	pageIndex: number = 0;

	/**
	 * The entries for the current page.
	 */
	pageEntries: DoiSearchResultEntry[];

	/**
	 * The system time when this result was created.
	 */
	createTime: number;

	/**
	 * The system time when this result is stale.
	 */
	staleTime: number;

	/**
	 * Construct a new search result.
	 */
	constructor(searchID?: number)
	{
		this.searchID = searchID ? searchID : 0;

		this.createTime = Date.now();
		this.staleTime = this.createTime + 1000;
	}

	/**
	 * The total # of enties.
	 */
	get countTotal(): number
	{
		return this.entries.length;
	}

	/**
	 * Check the current category selection and set anySelected on all category group with any category selected. Return true
	 * if any category in any group is selected.
	 */
	checkCatSelection(): boolean
	{
		let anyCat = false;

		for (let catGroup of this.catGroups) {
			catGroup.anySelected = false;
			for (let cat of catGroup.cats) {
				if (cat.selected) {
					catGroup.anySelected = true;
					anyCat = true;
				}
				cat.implied = false;
			}
			if (!catGroup.anySelected) {
				for (let cat of catGroup.cats) {
					cat.implied = true;
				}
			}
		}

		return anyCat;
	}

	/**
	 * Filter the current result to only include entries in selected categories. Sets the included property on each included entry, the counts on each category
	 * and the global included count.
	 */
	filterResultEntries()
	{
		//	Check category selection and clear category counts.

		this.checkCatSelection();

		let objectTypeCatIDs = new Set<number>();
		let selectedObjectTypeCatIDs = new Set<number>();
		let explicitGroups: Set<DoiSearchResultCatGroup> = new Set();

		for (let catGroup of this.catGroups) {
			let objectTypeGroup = catGroup.name == 'ObjectType';
			if (catGroup.anySelected)
				explicitGroups.add(catGroup);
			for (let cat of catGroup.cats) {
				cat.countIncluded = cat.countAvailable = 0;
				cat.countLimited = false;
				if (objectTypeGroup) {
					objectTypeCatIDs.add(cat.catID);
					if (cat.selected || !catGroup.anySelected)
						selectedObjectTypeCatIDs.add(cat.catID);
				}
			}
		}

		//	Process all result entries.

		this.countIncluded = 0;

		for (let entry of this.entries) {

			//	Determine if the entry is included and in which category groups.

			let includedInGroups: Set<DoiSearchResultCatGroup> = new Set();
			entry.included = true;

			for (let catID of entry.catIDs) {
				let cat = this.catIndex.get(catID);
				if (cat.group.anySelected && !cat.selected) {
					//	The entry belongs to an unselected category. Exclude.
					entry.included = false;
				} else {
					//	The entry is in an explicitly or implicitly selected category.
					includedInGroups.add(cat.group)
				}
			}

			//	Exclude if not included in all groups with explicit selection.

			for (let catGroup of Array.from(explicitGroups)) {
				if (!includedInGroups.has(catGroup))
					entry.included = false;
			}

			//	Update counts.

			let countLimited = this.isCountLimited(entry.objectType);

			for (let catID of entry.catIDs) {
				let cat = this.catIndex.get(catID);
				if (entry.included) {
					//	If the entry is included it must be included in a category it belongs to.
					++cat.countIncluded;
					//	If the entry object type is count limited then this category is.
					if (countLimited)
						cat.countLimited = true;
				} else if (includedInGroups.size == entry.catIDs.length-1) {
					//	If not included but belongs to all other groups, it might be is available.
					let otherSelected = false;
					for (let catGroup of this.catGroups) {
						if (catGroup.anySelected && catGroup != cat.group) {
							otherSelected = true;
							break;
						}
					}
					//	If no other group has a selected category, it is available.
					if (!otherSelected)
						++cat.countAvailable;
				}
				//	If the entry object type is count limited, the object type category is. Also all other categories
				//	if the object type category is selected.
				if (countLimited) {
					if (objectTypeCatIDs.has(catID) || selectedObjectTypeCatIDs.has(catID))
						cat.countLimited = true;
				}
			}

			if (entry.included)
				++this.countIncluded;
		}

		for (let catGroup of this.catGroups) {
			for (let cat of catGroup.cats) {
				if (!cat.group.anySelected || cat.selected)
					cat.countDisplayed = cat.countIncluded;
				else
					cat.countDisplayed = cat.countAvailable;
			}
		}
	}

	/**
	 * Build a page with entries in selected categories.
	 */
	filterResultPage()
	{
		this.filterResultEntries();

		this.pageCount = Math.ceil(this.countIncluded / this.pageSize);
		this.pageIndex = Math.min(Math.max(this.pageIndex, 0), this.pageCount-1);

		this.pageEntries = new Array<DoiSearchResultEntry>();

		let skipCount = this.pageSize * this.pageIndex;

		for (let entry of this.entries) {

			if (entry.included) {
				if (skipCount)
					--skipCount;
				else
					this.pageEntries.push(entry);
				if (this.pageEntries.length >= this.pageSize)
					break;
			}
		}
	}

	/**
	 * Populate an object from a data object received from the server. The data object may be empty.
	 */
	parseData(data: any): DoiSearchResult
	{
		//	Parse category groups.

		if (!data.CategoryGroups)
			return this;

		for (let de of data.CategoryGroups) {
			let catGroup = new DoiSearchResultCatGroup();
			catGroup.parseData(de);
			this.catGroups.push(catGroup);
		}

		//	Index the categories.

		for (let catGroup of this.catGroups) {
			for (let cat of catGroup.cats) {
				this.catIndex.set(cat.catID, cat);
				cat.countTotal = cat.countIncluded = 0;
			}
		}

		//	Parse count limited object types.

		if (data.CountLimitedTypes) {
			for (let objectType of data.CountLimitedTypes) {
				this.countLimited.add(objectType);
			}
		}

		//	Parse entries.

		if (data.Entries) {
			for (let de of data.Entries) {
				let entry = new DoiSearchResultEntry();
				entry.parseData(de);
				this.entries.push(entry);
			}
		}

		//	Propagate count limits.

		for (let entry of this.entries) {
			let countLimited = this.isCountLimited(entry.objectType);
			for (let catID of entry.catIDs) {
				let cat = this.catIndex.get(catID);
				if (countLimited)
					cat.countLimited = true;
				++cat.countTotal;
			}
		}

		return this;
	}

	/**
	 * Test if an object type has missing entries due to count limitation.
	 */
	isCountLimited(objectType: string): boolean
	{
		return this.countLimited.has(objectType);
	}

	/**
	 * Compute the stale time. Currently the initial stale time is increased with the elapsed time since creation.
	 * That way, the longer it takes to produce a result, the longer it is considered fresh enough.
	 */
	computeStaleTime()
	{
		if (!this.entries)
			return;

		this.staleTime += Date.now() - this.createTime;
	}

	/**
	 * Test if the result is stale.
	 */
	isStale(): boolean
	{
		return Date.now() > this.staleTime;
	}

	/**
	 * Make the result stale.
	 */
	stale()
	{
		this.staleTime = Date.now() - 1000;
	}
}
