/*
 * Object file
 */

export class ObjectFile
{
	objectFileID: number;
	sequence: number;
	title: string;
	mimeType: string;
	type: number;
	filenameLink: string;
	description: string;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): ObjectFile
	{
		this.objectFileID = data.ObjectFile_ObjectFileID;
		this.sequence = data.ObjectFile_sequence;
		this.title = data.ObjectFile_Title;
		this.mimeType = data.ObjectFile_MimeType;
		this.type = data.ObjectFile_Type;
		this.filenameLink = data.ObjectFile_FilenameLink;
		this.description = data.ObjectFile_Description;

		return this;
	}

	isFile()
	{
		return this.type == 1;
	}

	isLink()
	{
		return this.type == 0;
	}
}
