import {Component, HostBinding, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors} from '@angular/forms';

import {DoiFocusFirst, minLengthOrEmptyValidator} from '../../doi/DoiModule';

/**
 * A search tool with text field.
 */
@Component({
	selector: 'doi-search-tool',
	host: { 'class' : 'doi-search-tool' },
	template: `
		<form name="search-tool-form" [formGroup]="formGroup" (ngSubmit)="onSearch()">
			<label for="search-tool-input" class="doi-accessible">Sök</label>
			<input class="doi-search-field form-control" #searchToolInput id="search-tool-input" formControlName="text" size="{{size}}" type="text" placeholder="{{placeholder}}" aria-label="Sök"/>
			<div class="doi-search-button"><i class="far fa-search" (click)="onSearch()" aria-hidden="true"></i></div>
		</form>
	`
})
export class DoiSearchToolComponent implements DoiFocusFirst
{
	/**
	 * Size in characters. Initially 8.
	 */
	@Input() size: number = 8;

	/**
	 * Placeholder text.
	 */
	@Input() placeholder: string = 'Sök'; // I18N

	/**
	 * Clear field on search.
	 */
	@Input() clearOnSearch: boolean = true;

	/**
	 * Indicates if the search button should be enabled.
	 */
	@Input()
	@HostBinding('class.doi-search-enabled')
	searchEnabled: boolean = true;

	/**
	 * Event emitter for the search event.
	 */
	@Output() search = new EventEmitter<string>();

	/**
	 * The search tool input element.
	 */
	@ViewChild('searchToolInput')
	searchToolInputRef: ElementRef<HTMLInputElement>;

	/**
	 * The form.
	 */
	formGroup: FormGroup;

	/**
	 * Construct a new search tool component.
	 */
	constructor()
	{
		this.formGroup = new FormGroup({});

		this.formGroup.addControl('text', new FormControl('', [minLengthOrEmptyValidator(2)]));
	}

	/**
	 * Focus the first inner component.
	 */
	focusFirst(): boolean
	{
		if (this.searchToolInputRef) {
			this.searchToolInputRef.nativeElement.focus();
			return true;
		}

		return false;
	}

	/**
	 * Emit the search event with the search text.
	 */
	onSearch()
	{
		let text = this.formGroup.value.text;

		if (text && !this.formGroup.valid)
			return;

		if (this.clearOnSearch)
			this.formGroup.get('text').setValue(null);

		this.search.emit(text);
	}

	/**
	 * Test if search is disabled. The opposite of the searchEnabled property.
	 */
	@HostBinding('class.doi-search-disabled')
	get searchDisabled(): boolean
	{
		//console.log('enabled: '+this.searchEnabled);
		return !this.searchEnabled;
	}

	/**
	 * The current search text.
	 */
	get text(): string
	{
		return this.formGroup.value.text;
	}
	set text(text: string)
	{
		this.formGroup.get('text').setValue(text);
	}

	/**
	 * The current search text, if valid.
	 */
	get validText(): string
	{
		return this.formGroup.valid ? this.formGroup.value.text : null;
	}
}
