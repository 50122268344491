import {Component, Input, Output, EventEmitter} from '@angular/core';

import {DoiIconMapper} from '../../doi/DoiModule';

import {ObjectFile} from '../model/ObjectFile';

/*
 * An object file component.
	styles: [
		'i { min-width: 1em; text-align: center; margin-right: 0.4em; }',
		'a { white-space: nowrap; }'
	]
 */
@Component({
	selector: 'object-file',
	host: { 'class' : 'object-file' },
	template: `
		<a *ngIf="objectFile.isLink()" [attr.href]="objectFile.filenameLink" target="_blank" title="{{objectFile.description}}"><i [class]="iconClass()"></i><span>{{objectFile.title}}</span></a>
		<a *ngIf="objectFile.isFile()" href="" (click)="download()" title="{{objectFile.description}}"><i [class]="iconClass()"></i><span>{{objectFile.title}}</span></a>
	`,
})
export class ObjectFileComponent
{
	/**
	 * The object file.
	 */
	@Input()
	objectFile: ObjectFile;

	/**
	 * Download emitter.
	 */
	@Output('download')
	downloadEmitter = new EventEmitter<ObjectFile>();

	/**
	 * Optional icon mapper.
	 */
	@Input()
	iconMapper: DoiIconMapper;

	/**
	 * The icon name. Determined on demand.
	 */
	private _iconName: string;

	/**
	 * Invoked when an object file is clicked. Emits the file to the receiver.
	 */
	download()
	{
		this.downloadEmitter.emit(this.objectFile);
	}

	/**
	 * Return the icon class for the file.
	 */
	iconClass(): string
	{
		if (this.iconMapper)
			return this.iconMapper.iconClass(this.iconName());
		else
			return "fad fa-"+this.iconName();
	}

	/**
	 * Map the file mime type to an icon name.
	 */
	iconName(): string
	{
		if (this._iconName)
			return this._iconName;

		if (this.objectFile.isLink()) {
			this._iconName = 'external-link';
		} else {
			this._iconName = 'file';
			if (this.objectFile.mimeType) {
				let parts = this.objectFile.mimeType.split('/');
				if (parts.length >= 2) {
					switch (parts[0]) {
						case 'application':
							switch (parts[1]) {
								case 'pdf':
									this._iconName = 'file-pdf';
									break;
								case 'msword':
								case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
								case 'vnd.oasis.opendocument.text':
									this._iconName = 'file-download';
									break;
								case 'vnd.ms-excel':
								case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
								case 'vnd.oasis.opendocument.spreadsheet':
									this._iconName = 'file-spreadsheet';
									break;
								case 'vnd.ms-powerpoint':
								case 'vnd.openxmlformats-officedocument.presentationml.presentation':
								case 'vnd.oasis.opendocument.presentation':
									this._iconName = 'file-chart-pie';
									break;
								case 'zip':
									this._iconName = 'file-archive';
									break;
								default:
									if (parts[1].includes('archive'))
										this._iconName = 'file-archive';
							}
							break;
						case 'text':
							this._iconName = 'file-download'
							switch (parts[1]) {
								case 'html':
									this._iconName = 'file-code';
									break;
							}
							break;
						case 'image':
							this._iconName = 'file-image'
							break;
					}
				}
			}
		}

		return this._iconName;
	}
}
