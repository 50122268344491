import {Component, Input, HostBinding} from '@angular/core';

import {compareObjects, compareValues, nullsLast} from '../core/DoiComparators';
import {DoiStorageOwner} from '../core/DoiStorageOwner';

/**
 * Interface implemented by sortable components.
 */
export interface DoiSortTarget
{
	/**
	 * Sort the target by the specified attribute.
	 */
	sortBy(attrName: string, direction: number): void;

	/**
	 * Return the sort direction in the target for the specified attribute as -1 for descending, 1 for ascending, 0 for not sorted or undefined.
	 */
	sortedBy(attrName: string): number;
}

/**
 * A sort target that can sort an array of objects and keep track of selected sort order.
 */
export class DoiArraySorter<T extends any> implements DoiSortTarget
{
	/**
	 * The current sort attribute.
	 */
	sortAttrName: string;

	/**
	 * The current sort direction.
	 */
	sortDirection: number;

	/**
	 * The current sort comparator.
	 */
	sortComparator: (o1: T, o2: T) => number;

	/**
	 * The local storage owner. May be null.
	 */
	storageOwner: DoiStorageOwner;

	/**
	 * An array accessor. May be null if sort is overridden.
	 */
	arrayAccessor: () => T[];

	/**
	 * An attribute value accessor.
	 */
	valueAccessor: (o: T, an: string) => any;

	/**
	 * Construct a new array sorter.
	 */
	constructor(storageOwner: DoiStorageOwner = null, arrayAccessor: () => T[] = null)
	{
		this.storageOwner = storageOwner;
		this.arrayAccessor = arrayAccessor;

		if (storageOwner) {
			this.sortBy(
				storageOwner.storageGetItem('sortAttr'),
				parseInt(storageOwner.storageGetItem('sortDir', '1'))
			);
		}
	}

	/**
	 * Sort the target by the specified attribute.
	 */
	sortBy(attrName: string, direction: number): void
	{
		this.sortAttrName = attrName;
		this.sortDirection = direction;

		if (this.valueAccessor)
			this.sortComparator = attrName ? (p1: T, p2: T) => nullsLast(this.valueAccessor(p1, attrName), this.valueAccessor(p2, attrName), (v1, v2) => compareValues(v1, v2)*this.sortDirection) : null;
		else
			this.sortComparator = attrName ? (p1: T, p2: T) => compareObjects(p1, p2, direction, attrName) : null;

		if (this.storageOwner) {
			if (attrName) {
				this.storageOwner.storageSetItem('sortAttr', attrName);
				this.storageOwner.storageSetItem('sortDir', direction != null ? String(direction) : '1');
			} else {
				this.storageOwner.storageRemoveItem('sortAttr');
			}
		}

		this.sort();
	}

	/**
	 * Return the sort direction in the target for the specified attribute as -1 for descending, 1 for ascending, 0 for not sorted or undefined.
	 */
	sortedBy(attrName: string): number
	{
		return attrName == this.sortAttrName ? this.sortDirection : 0;
	}

	/**
	 * Perform the actual sort. The default implementation uses the array accessor and sorts the array if it exists.
	 */
	sort(): void
	{
		if (this.arrayAccessor && this.sortComparator) {
			let a = this.arrayAccessor();
			if (a)
				a.sort(this.sortComparator);
		}
	}
}

/**
 * A sub component, usually a column, in a sortable component.
 */
@Component({
	selector: '[sort]',
	host: { 'class' : 'doi-sortable' },
	styles: [ `
		span i { min-width: 1rem; margin-left: 0.4rem; }
	` ],
	template: `
		<span (click)="onClick($event)"><ng-content></ng-content><i [ngClass]="sortIconClass()"></i></span>
	`
})
export class DoiSortComponent
{
	/**
	 * The sort attribute.
	 */
	@Input('sort')
	sortAttr: string;

	/**
	 * The sort target.
	 */
	@Input()
	target: DoiSortTarget;

	/**
	 * The selected state of the target action.
	 */
	@HostBinding('class.doi-sorted')
	get sorted()
	{
		return this.target && this.target.sortedBy(this.sortAttr);
	}

	/**
	 * Determine the class to apply on the icon placeholder, depending on the sort direction.
	 */
	sortIconClass()
	{
		if (this.target) {
			switch (this.target.sortedBy(this.sortAttr)) {
				case -1: return "fas fa-caret-down text-primary";
				case  0: return "fas fa-sort text-muted";
				case  1: return "fas fa-caret-up text-primary";
			}
		}
	}

	/**
	 * Invoked when the column header is clicked. An event to "click" is emitted.
	 */
	onClick(event: Event)
	{
		if (this.target) {
			let direction = this.target.sortedBy(this.sortAttr);
			this.target.sortBy(this.sortAttr, direction ? -direction : 1);
		} else {
			console.error('No target specified for sort attribute "'+this.sortAttr+'".')
		}
	}
}
