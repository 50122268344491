import {DoiBookmark} from './DoiBookmark';
import {DoiObjectBookmark} from './DoiObjectBookmark';

/**
 * A list of bookmarks, used for both explicit and automatic (visited).
 */
export class DoiBookmarkList
{
	name: string;

	list = new Array<DoiBookmark>();
	maxSize: number;

	/**
	 * Construct a new bookmark list.
	 */
	constructor(name: string, maxSize?: number)
	{
		this.name = name;
		this.maxSize = maxSize;
	}

	/**
	 * Parse entries from a JSON string.
	 */
	load(json: string)
	{
		if (json) {
			let list = JSON.parse(json);
			for (let f of list) {
				if (!this.maxSize || this.list.length < this.maxSize) {
					if (f.objectID)
						this.list.push(new DoiObjectBookmark(f.bookmarkID, f.objectType, f.objectID, f.title, f.iconName));
					else
						this.list.push(new DoiBookmark(f.path, f.title, f.iconName));
				}
			}
		}
	}

	/**
	 * Return the JSON representation of the entries.
	 */
	toJson(): string
	{
		return JSON.stringify(this.list);
	}

	indexOf(path: string): number
	{
		return this.list.findIndex((f) => f.path == path );
	}

	has(path: string)
	{
		return this.indexOf(path) >= 0;
	}

	get(path: string): DoiBookmark
	{
		let ix = this.indexOf(path);
		if (ix >= 0)
			return this.list[ix];
		else
			return null;
	}

	add(bookmark: DoiBookmark): void
	{
		let ix = this.indexOf(bookmark.path);
		if (ix >= 0)
			this.list.splice(ix, 1);

		this.list.splice(0, 0, bookmark);
		if (this.maxSize && this.list.length > this.maxSize)
			this.list.splice(this.maxSize, this.list.length-this.maxSize);
	}

	remove(bookmark: DoiBookmark): void
	{
		if (bookmark) {
			let ix = this.indexOf(bookmark.path);
			if (ix >= 0)
				this.list.splice(ix, 1);
		}
	}
}
