import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators/';
import {ObjectFile} from '../../klara/KlaraModule';
import {DoiService, DoiBrokerService, DoiObject, DoiObjectPanel, DoiSelectionCriteria} from '../../doi/DoiModule';

import {ProcessStructurePart, ProcessStructureObject, ProcessStructureProcessObject} from '../model/ProcessStructureObject';
import {ProcessStructureService} from '../../processstructure/model/ProcessStructureService';

@Component({
	templateUrl: 'ProcessStructureObjectGeneralPanel.html'
})
export class ProcessStructureObjectGeneralPanel extends DoiObjectPanel<ProcessStructureObject>
{
	name = 'general';

	/**
	 * Top level processes.
	 */
	processes: DoiObject[];

	constructor(doi: DoiService, route: ActivatedRoute, private processStructureService: ProcessStructureService)
	{
		super(doi, route);
	}

	/**
	 * Download an object file.
	 */
	downloadObjectFile(objectFile: ObjectFile): void
	{
		this.processStructureService.downloadObjectFile(objectFile);
	}

	routerLinkProcess(process : ProcessStructureProcessObject)
	{
		return process.processID ? ['/process', process.processID] : null;
	}

	/**
	 * Return the icon class for the specified object.
	 */
	processIconClass()
	{
		return this.iconClass(ProcessStructureProcessObject.iconName);
	}

	openProcessStructure(processStructureID : number)
	{
		this.appView.openObject('ProcessStructure', processStructureID);
	}

	/**
	 * Read top level processes.
	 */
	readTopLevelProcesses(): Observable<DoiObject[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Process_ProcessID', 'Process_ProcStructID', 'Process_ProcessTypeID', 'Process_Code', 'Process_NameList')
			.where('Process_ProcStructID', '='+this.objectID)
			.and('Process_ParentProcessID', '=')
			.orderBy('Process_Code', 'Process_ProcStructID')
			.limit(20);

		return this.doi.brokerService('Process').readObjectSelection(null, criteria);
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(ProcessStructurePart.General),
			this.refreshObjectPart(ProcessStructurePart.Description),
			this.refreshObjectPart(ProcessStructurePart.PropertyValues),
			this.refreshObjectPart(ProcessStructurePart.ObjectFiles),
			this.readTopLevelProcesses().pipe(tap((processes: DoiObject[]) => {this.processes = processes})),
		);
	}
}

// 00UYoZBwbgLwVnN/NBkSwqWLAUs=
