import {DoiObject, DoiObjectRef} from '../../doi/DoiModule';

import {ProcessStructureObject} from '../../processstructure/ProcessStructureModule';
import {ProcessService} from '././ProcessService';
import {PropertyValue, ObjectFile, Unit} from '../../klara/KlaraModule';

/**
 *	Object part names for the Process entity object.
 */
export const ProcessPart =
{
	Label: 'Label',
	General: 'General',
	Description: 'Description',
	ProcAltNames: 'ProcAltNames',
	ActTypes: 'ActTypes',
	ActKind: 'ActKind',
	ActKindAltNames: 'ActKindAltNames',
	PropertyValues: 'PropertyValues',
	Units: 'Units',
	UnitsNav: 'UnitsNav',
	Series: 'Series',
	Children: 'Children',
	Activities: 'Activities',
	ObjectFiles : "ObjectFiles",
	ModelImage : 'ModelImage',
	ModelImageEgon : 'ModelImageEgon',
	ModelImagePNG : 'ModelImagePNG',
	ModelImageSVG : 'ModelImageSVG',

}

/**
 *	The Process entity object.
 */
export class ProcessObject extends DoiObject
{
	static readonly iconName = 'rectangle-landscape';

	processService: ProcessService;

	/**
	 *	Attribute values.
	 */
	code: string;
	name: string;
	nameML: string;
	nameShort: string;
	nameList: string;
	altNameList: string;
	cullPeriodAmount: number;
	cullPeriodTimeUnit: CullPeriodTimeUnit;
	descriptionShort: string;
	description: string;
	dateBegin: Date;
	dateEnd: Date;
	prevCode: String;
	procStructID: number;
	parentProcessID: number;
	processTypeID: number;
	processTypeName: string;
	actKindNameList: string;
	actKindName: string;
	actKindNameML: string;
	actKindNameShort: string;
	actKindDescription: string;
	actKindAltNameList: string;
	modelImage: string;
	modelImageMap: ProcessModelArea[];

	actTypes: Array<ProcessActType>;
	propertyValues: Array<PropertyValue>;
	units: Array<ProcessUnit>;
	series: Array<ProcessSeriesObject>;
	children: Array<ProcessChild>;
	activities: Array<ProcessActivity>;
	objectFiles: Array<ObjectFile>;

	/**
	 *	Parents.
	 */
	parent: ProcessObject;
	processStructure: ProcessStructureObject;

	/**
	 * Construct a new object.
	 */
	constructor(objectID: number, processService: ProcessService)
	{
		super(objectID);

		this.processService = processService;
	}

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return ProcessObject.iconName;
	}

	/**
	 * Return a text suitable for additional text in search results etc. Overridden to return the short or long description.
	 */
	objectText(): string
	{
		return this.descriptionShort || this.description;
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectTitle(): string
	{
		let name = this.nameShort || this.nameList;
		if (this.processService.showProcessCodes && this.code && name)
			return this.code + ' - ' + name;
		else
			return name;
	}

	/**
	 * Return a text suitable for bookmarks, navigator nodes, etc.
	 */
	objectName(): string
	{
		return (this.nameShort || this.nameList);
	}

	/**
	 * The symbolic object type name, i e 'Process'.
	 */
	get objectType(): string
	{
		return 'Process';
	}

	/**
	 * Return an object reference path from the root, corresponding to the navigation structure, or null.
	 */
	objectRefPath(): DoiObjectRef[]
	{
		let path: DoiObjectRef[] = null;

		if (this.parent) {
			path = this.parent.objectRefPath();
			path.push(this.objectRef());
			return path;
		}

		if (this.processStructure) {
			path = this.processStructure.objectRefPath();
			path.push(this.objectRef());
			return path;
		}
		
		return null;
	}

	/**
	 * Populate an object  from a data object received from the server.
	 */
	parseData(data: any, partName: string): ProcessObject
	{
		super.parseData(data, partName);

		switch (partName) {
			case null:
				this.parseData(data, ProcessPart.Label);
				//	Additional search result entry information.
				if (data.Process_DescriptionShort)
					this.descriptionShort = data.Process_DescriptionShort;
				break;
			case ProcessPart.Label:
				this.objectID = data.Process_ProcessID;
				this.procStructID = data.Process_ProcStructID;
				this.parentProcessID = data.Process_ParentProcessID;
				this.code = data.Process_Code;
				this.name = data.Process_Name;
				this.nameShort = data.Process_NameShort;
				this.nameList = data.Process_NameList;
				this.processTypeName = data.ProcessType_Name;
				this.processTypeID = data.Process_ProcessTypeID;
				break;
			case ProcessPart.General:
				this.parseData(data, ProcessPart.Label);
				this.descriptionShort = data.Process_DescriptionShort;
				this.cullPeriodAmount = data.Process_CullPeriodAmount;
				this.cullPeriodTimeUnit = data.Process_CullPeriodTimeUnit;
				this.dateBegin = data.Process_DateBegin;
				this.dateEnd = data.Process_DateEnd;
				this.prevCode = data.Process_PrevCode;
				this.nameML = data.Process_NameML;
				break;
			case ProcessPart.Description:
				this.description = data.Process_Description;
				break;
			case ProcessPart.ProcAltNames:
				this.altNameList = data.Process_AltNameList;
				break;
			case ProcessPart.ActKind:
				this.actKindNameList = data.Process_NameListActKind;
				this.actKindNameShort = data.Process_NameShortActKind;
				this.actKindName = data.Process_ActKindName;
				this.actKindDescription = data.Process_ActKindDescription;
				this.actKindNameML = data.Process_ActKindNameML;
				break;
			case ProcessPart.ActKindAltNames:
				this.actKindAltNameList = data.Process_AltNameListActKind
				break;
			case ProcessPart.ActTypes:
				this.actTypes = new Array<ProcessActType>();
				if (data.Process_ActTypes) {
					for (let e of data.Process_ActTypes) {
						this.actTypes.push(new ProcessActType().parseData(e));
					}
				}
				break;
			case ProcessPart.PropertyValues:
				this.propertyValues = new Array<PropertyValue>();
				if (data.Process_PropertyValues) {
					for (let e of data.Process_PropertyValues) {
						this.propertyValues.push(new PropertyValue().parseData(e));
					}
				}
				break;
			case ProcessPart.Units:
			case ProcessPart.UnitsNav:
				this.units = new Array<ProcessUnit>();
				if (data.Process_Units) {
					for (let e of data.Process_Units) {
						this.units.push(new ProcessUnit().parseData(e));
					}
				}
				break;
			case ProcessPart.Series:
				this.series = new Array<ProcessSeriesObject>();
				if (data.Process_Series) {
					for (let e of data.Process_Series) {
						this.series.push(new ProcessSeriesObject().parseData(e));
					}
				}
				break;
			case ProcessPart.Activities:
				this.activities = new Array<ProcessActivity>();
				if (data.Process_Activities) {
					for (let e of data.Process_Activities) {
						this.activities.push(new ProcessActivity().parseData(e));
					}
				}
				break;
			case ProcessPart.Children:
				this.children = new Array<ProcessChild>();
				if (data.Process_Children) {
					for (let e of data.Process_Children) {
						this.children.push(new ProcessChild().parseData(e));
					}
				}
				break;
			case ProcessPart.ObjectFiles:
				this.objectFiles = new Array<ObjectFile>();
				if (data.Process_ObjectFiles) {
					for (let e of data.Process_ObjectFiles) {
						this.objectFiles.push(new ObjectFile().parseData(e));
					}
				}
				break;

			case ProcessPart.ModelImage:
				this.modelImage = data.Process_ModelImagePNG;
				this.modelImageMap = new Array<ProcessModelArea>();
				if (data.Process_ModelImageMap) {
					for (let e of data.Process_ModelImageMap) {
						let area = new ProcessModelArea().parseData(e);
						if (area)
							this.modelImageMap.push(area);
					}
				}
				break;

		}

		return this;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(partName: string): any
	{
		switch (partName) {
			case ProcessPart.General:
				return {
					Process_Code: this.code,
					Process_Name: this.name,
					Process_NameShort: this.nameShort,
					Process_NameML: this.nameML,
					Process_DescriptionShort: this.descriptionShort,
				}
			case ProcessPart.Description:
				return {
					Process_Description: this.description
				}
			case ProcessPart.ActKind:
				return  {
					Process_NameShortActKind: this.actKindNameShort,
					Process_ActKindName: this.actKindName,
					Process_ActKindDescription: this.actKindDescription,
					Process_ActKindNameML: this.actKindNameML,
				}
			case ProcessPart.PropertyValues:
				if (this.propertyValues) {
					let propertyValues = new Array<any>();
					for (let propertyValue of this.propertyValues) {
						propertyValues.push(propertyValue.buildDataPart());
					}
					return {
						Process_PropertyValues: propertyValues
					}
				}
				break;
			case ProcessPart.Activities:
				if (this.activities) {
					let activities = new Array<any>();
					for (let processActivity of this.activities) {
						activities.push(processActivity.buildDataPart());
					}
					return {
						Process_Activities: activities
					}
				}
				break;

		}
	}
}

/**
 * Enumeration se.knowit.klara.process.model.CullPeriodTimeUnit.
 */
export enum CullPeriodTimeUnit
{
}

/**
 * A ProcessModelArea, representing an area in the process model image map.
 */
export class ProcessModelArea
{
	static rectPattern = new RegExp('\\[x=(\\d+)\\s*,y=(\\d+)\\s*,width=(\\d+)\\s*,height=(\\d+)\\s*\\]');

	objectType: string;
	objectID: number;
	name: string;
	area: { x: number, y: number, width: number, height: number };
	coords: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): ProcessModelArea
	{
		this.objectType = data.EntityType;
		this.objectID = data.EntityID;
		this.name = data.Name;

		if (this.objectType == 'ActKind')
			this.objectType = 'Process';

		let r = ProcessModelArea.rectPattern.exec(data.Rectangle);
		if (r) {
			this.area = { x: parseInt(r[1]), y: parseInt(r[2]), width: parseInt(r[3]), height: parseInt(r[4]) };
			if (this.area.x >= 0 && this.area.y >= 0 && this.area.width > 0 && this.area.height > 0) {
				this.coords = this.area.x+','+this.area.y+','+(this.area.x+this.area.width-1)+','+(this.area.y+this.area.height-1);
				return this;
			} else {
				console.error('Invalid: '+this.area);
			}
		}

		return null;
	}
}

/**
 * A ProcessUnit, corresponding to a detail object from Project part Units.
 */
export class ProcessUnit
{
	static readonly iconName = 'circle';

	unitID: number;
	unitTypeName: string;
	code: string;
	title: string;
	date: string;
	navText: string;
	location: string;
	archiveOrigID: number;
	archiveOrigName: string;


	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): ProcessUnit
	{
		this.unitID = data.ProcessUnit_UnitID;
		this.title = data.Unit_Title;
		this.code = data.Unit_Code;
		this.date = data.Unit_Date;
		this.navText = data.Unit_NavText;
		this.location = data.Unit_Location;
		this.unitTypeName = data.UnitType_Name;
		this.archiveOrigID = data.Unit_ArchiveOrigID;
		this.archiveOrigName = data.ArchiveOrig_Name;

		return this;
	}

	toUnit(): Unit
	{
		let unit = new Unit();
		unit.unitID = this.unitID;
		unit.unitTypeName = this.unitTypeName;
		unit.code = this.code;
		unit.title = this.title;
		unit.date = this.date;
		unit.navText = this.navText;

		return unit;
	}

}

export class ProcessSeriesObject
{
	static readonly iconName = 'inventory';

	seriesID: number;
	signum: string;
	title: string;
	date: string;
	location: string;
	archiveID: number;
	archiveName: string;
	archiveOrigID: number;
	archiveOrigName: string;

	/**
	 * Populate an object from a data object received from the server.
	 */
	parseData(data: any): ProcessSeriesObject
	{
		this.seriesID = data.Series_SeriesID;
		this.signum = data.Series_Signum;
		this.title = data.Series_Title;
		this.date = data.Series_Date;
		this.location = data.Series_Location;
		this.archiveID = data.Series_ArchiveID;
		this.archiveName = data.Archive_Name;

		return this;
	}
}

/**
 * A ProcessActType, corresponding to a detail object from Project part ActTypes.
 */
export class ProcessActType
{
	static readonly iconName = 'file-alt';

	processActTypeID: number;
	name: string;
	nameShort: string;
	nameList: string;
	validPeriod: string;
	descriptionShort: string;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): ProcessActType
	{
		this.processActTypeID = data.ProcessActType_ProcessActTypeID;
		this.name = data.ProcessActType_Name;
		this.nameShort = data.ProcessActType_NameShort;
		this.nameList = data.ProcessActType_NameList;
		this.validPeriod = data.ProcessActType_ValidPeriod;

		return this;
	}
}

/**
 * A ProcessActivity, corresponding to a detail object from Project part Activites.
 */
export class ProcessActivity
{
	static readonly iconName = 'circle';

	objectDelete: boolean;

	activityID: number;
	name: string;
	nameML: string;
	notes: string;
	sequence: number;
	linkedProcessID: number;
	linkedProcessName: string;
	linkedProcessCode: string;
	actTypes: Array<ProcessActType>;

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): ProcessActivity
	{
		this.activityID = data.Activity_ActivityID;
		this.name = data.Activity_Name;
		this.nameML = data.Activity_NameML;
		this.notes = data.Activity_Notes;
		this.sequence = data.Activity_Sequence;
		this.linkedProcessID = data.Activity_LinkedProcessID;
		this.linkedProcessName = data.Process_Name;
		this.linkedProcessCode = data.Process_Code;
		this.actTypes = new Array<ProcessActType>();
		if (data.Activity_ProcessActTypes) {
			for (let e of data.Activity_ProcessActTypes) {
				this.actTypes.push(new ProcessActType().parseData(e));
			}
		}

		return this;
	}

	/**
	 * Build a data object part for writing.
	 */
	buildDataPart(): any
	{
		let actTypes = null;
		if (this.actTypes) {
			actTypes = new Set<number>();
			for (let actType of this.actTypes) {
				actTypes.add(actType.processActTypeID);
			}
		}

		let data = {
			Activity_ActivityID: this.activityID,
			Activity_Name: this.name,
			Activity_NameML: this.nameML,
			Activity_Notes: this.notes,
			Activity_Sequence: this.sequence,
			Activity_LinkedProcessID: this.linkedProcessID,
			Activity_ActTypes: actTypes,
			ObjectDelete: this.objectDelete
		}

		return data;
	}
}

/**
 * A Child, corresponding to a detail object from Project part children.
 */
export class ProcessChild
{
	processID: number;
	code: string;
	name: string;
	nameShort: string;
	nameList: string;
	parentProcessID: number;
	processTypeName: string;
	dateBegin: Date;
	dateEnd: Date;
	descriptionShort: string;

	/**
	 * The icon name.
	 */
	get iconName(): string
	{
		return ProcessObject.iconName;
	}

	/**
	 * Populate from a data object received from the server.
	 */
	parseData(data: any): ProcessChild
	{
		this.processID = data.Process_ProcessID;
		this.code = data.Process_Code;
		this.name = data.Process_Name;
		this.nameShort = data.Process_NameShort;
		this.processTypeName = data.Process_ProcessTypeName;
		this.dateBegin = data.Process_DateBegin;
		this.dateEnd = data.Process_DateEnd;
		this.descriptionShort = data.Process_DescriptionShort;

		this.nameList = this.nameShort || this.name;

		return this;
	}
}

// j4vJmnB0qXwx5eEKZZVEUEBS5Jk=

