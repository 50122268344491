import {Observable} from 'rxjs';

import {DoiObjectRef, DoiTreeNode, DoiTreeNodeChildSupplier} from '../../doi/DoiModule';
import {DoiNavNode} from './DoiNavNode';

/**
 * A child node supplier.
 */
export abstract class DoiNavNodeChildSupplier extends DoiTreeNodeChildSupplier<DoiNavNode, DoiObjectRef>
{
	/**
	 * The default subview name. Can be used for navigation when a specific should be opened for all children.
	 */
	private _subviewName: string;

	/**
	 * Return the default subview name. Can be used for navigation when a specific should be opened for all children.
	 */
	getSubviewName(): string
	{
		return this._subviewName;
	}

	/**
	 * Set a default subview name. Can be used for navigation when a specific should be opened for all children.
	 */
	setSubviewName(subviewName: string): DoiNavNodeChildSupplier
	{
		this._subviewName = subviewName;

		return this;
	}
}
