<div class="doi-subview">

	<div *ngIf="object.children?.length" class="doi-scroll-container">
		<table class="table table-striped table-hover">
			<thead>
				<tr class="doi-clickable">
					<th class="doi-grow-3">Namn</th>
					<th class="doi-grow-5 doi-hidden-xs-down">Giltighetstid</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let archiveOrig of object.children" (click)="openArchiveOrig(archiveOrig)" class="doi-clickable">
					<td class="doi-grow-3 doi-wrap"><a [routerLink]="routerLinkArchiveOrig(archiveOrig)">{{archiveOrig.name}}</a></td>
					<td class="doi-grow-5 doi-wrap doi-hidden-xs-down">{{archiveOrig.existFrom}}--{{archiveOrig.existTo}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
