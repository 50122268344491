/**
 * A selectable process structure, used to select which PS to show.
 */
export class ProcessStructureSelectable
{
	procStructID: number;
	name: string;
	startDate: Date;
	endDate: Date;
	modifiedWhen: Date;
	archiveOrigID: number;
	archiveOrigName: string;

	/**
	 * Populate from a data object received from the server.
	 */
	constructor(data: any)
	{
		this.procStructID = data.ProcessStructure_ProcStructID;
		this.name = data.ProcessStructure_Name;
		this.startDate = data.ProcessStructure_StartDate ? new Date(data.ProcessStructure_StartDate) : null;
		this.endDate = data.ProcessStructure_EndDate ? new Date(data.ProcessStructure_EndDate) : null;
		this.modifiedWhen = data.ProcessStructure_ModifiedWhen ? new Date(data.ProcessStructure_ModifiedWhen) : null;
		this.archiveOrigID = data.ProcessStructure_ArchiveOrigID;
		this.archiveOrigName = data.ArchiveOrig_Name;
		return this;
	}

	/**
	 * Compare this PS with another, to order a PS list by preference.
	 */
	compareTo(that: ProcessStructureSelectable): number
	{
		let c = 0;

		c = (this.valid() ? 0 : 1) - (that.valid() ? 0 : 1);
		if (c != 0)
			return c;

		c = (that.endDate == null ? Number.POSITIVE_INFINITY : that.endDate.getTime()) - (this.endDate == null ? Number.POSITIVE_INFINITY : this.endDate.getTime());
		if (c != 0)
			return c;

		c = (that.modifiedWhen == null ? 0 : that.modifiedWhen.getTime()) - (this.modifiedWhen == null ? 0 : this.modifiedWhen.getTime());
		if (c != 0)
			return c;

		c = this.name.localeCompare(that.name);

		return 0;
	}

	/**
	 * Test if this PS is valid.
	 */
	valid(): boolean
	{
		let now = Date.now();

		return (this.startDate != null && this.startDate.getTime() <= now) && (this.endDate == null || this.endDate.getTime() >= now);
	}
}
