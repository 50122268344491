import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, forkJoin} from 'rxjs';

import {DoiService, DoiObjectPanel} from '../../doi/DoiModule';
import {SeriesPart, SeriesObject, ProcessActTypeObject, ProcessObject} from '../../series/model/SeriesObject';

@Component({
	templateUrl: 'SeriesObjectActTypesPanel.html'
})
export class SeriesObjectActTypesPanel extends DoiObjectPanel<SeriesObject>
{
	name = 'acttypes';

	constructor(doi: DoiService, route: ActivatedRoute)
	{
		super(doi, route);
	}

	openProcessFromActType(processActType: ProcessActTypeObject)
	{
		this.doi.router.navigate([ '/process', processActType.processID, { outlets: { tab: ['general'] } } ]);
	}

	openProcess(process: ProcessObject)
	{
		this.doi.router.navigate([ '/process', process.processID, { outlets: { tab: ['general'] } } ]);
	}


	openActType(processActType: ProcessActTypeObject)
	{
		this.doi.router.navigate([ '/processacttype', processActType.processActTypeID, { outlets: { tab: ['general'] } } ]);
	}

	routerLinkProcessStructure(process: ProcessObject)
	{
		return ['/processstructure', process.procStructID];
	}

	routerLinkProcess(process: ProcessObject)
	{
		return ['/process', process.processID];
	}

	routerLinkProcessStructActType(processActType: ProcessActTypeObject)
	{
		return ['/processstructure', processActType.procStructID];
	}

	routerLinkProcessActTypeProcess(processActType: ProcessActTypeObject)
	{
		return ['/process', processActType.processID];
	}

	routerLinkProcessActType(processActType: ProcessActTypeObject)
	{
		return ['/processacttype', processActType.processActTypeID];
	}

	refreshView(): Observable<any>
	{
		return forkJoin(
			this.refreshObjectPart(SeriesPart.ProcessActTypes),
			this.refreshObjectPart(SeriesPart.Processes));
	}

	/**
	 * Return the icon class for the specified ProcessActType object.
	 */
	getProcessActTypeIconClass()
	{
		return this.iconClass(ProcessActTypeObject.iconName);
	}

	/**
	 * Return the icon class for the specified Process object.
	 */
	getProcessIconClass()
	{
		return this.iconClass(ProcessObject.iconName);
	}
}

// K8VaWiG2oveAj55HqaFcBZMmvTY=
