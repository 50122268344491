<div class="doi-subview doi-scroll">

	<form #form="ngForm" role="form">
		<div class="row">
			<div class="col-12 col-xl-11">
				<doi-field name="actKindNameShort" label="Kortnamn" maxLength="50" labelEdit="Kortnamn" [(ngModel)]="object.actKindNameShort" [edit]="editingAsStructAdm()"></doi-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-xl-11">
				<doi-field name="actKindName" label="Namn" maxLength="100" labeEdit="Namn" [(ngModel)]="object.actKindName" [edit]="editingAsStructAdm()"></doi-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-xl-11">
				<doi-field name="actKindAltNames" label="Publika namn" [(ngModel)]="object.actKindAltNameList"></doi-field>
			</div>
		</div>
		<div class="row" >
			<div class="col-12 col-xl-11">
				<doi-textarea name="actKindDescription" label="Beskrivning av handlingsslaget" [(ngModel)]="object.actKindDescription"
					[edit]="editingAsStructAdm()"></doi-textarea>
			</div>
		</div>
		<property-values [view]="this" [propertyValues]="propertyValues()" [edit]="editingAsStructAdm()"></property-values>
	</form>

</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
