<div class="doi-subview">

	<div class="doi-scroll-container">
		<div *ngIf="object?.processes?.length">
			<div class="doi-titled-list">
				<h3>Handlingsslag</h3>
				<table class="table table-striped">
					<thead>
						<tr>
							<th class="doi-width-20 doi-wrap doi-hidden-xs-down">Klassificeringsstruktur</th>
							<th class="doi-width-20 doi-wrap ">Handlingsslag</th>
							<th class="doi-width-15">&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let process of object?.processes">
							<td class="doi-width-20 doi-wrap doi-hidden-xs-down"><a [routerLink]="routerLinkProcessStructure(process)">{{process.processStructureName}}</a></td>
							<td class="doi-width-20 doi-wrap"><a [routerLink]="routerLinkProcess(process)">{{process.code}} - {{process.nameListActKind}}</a></td>
							<td class="doi-width-15">&nbsp;</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div *ngIf="object?.processActTypes?.length">
			<div class="doi-titled-list">
				<h3>Handlingstyper</h3>
				<table class="table table-striped">
					<thead>
						<tr>
							<th class="doi-width-20 doi-wrap doi-hidden-xs-down">Klassificeringsstruktur</th>
							<th class="doi-width-20 doi-wrap">Handlingsslag</th>
							<th class="doi-width-15 doi-wrap">Handlingstyp</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let processActType of object?.processActTypes">
							<td class="doi-width-20 doi-wrap doi-hidden-xs-down"><a [routerLink]="routerLinkProcessStructActType(processActType)">{{processActType.processStructureName}}</a></td>
							<td class="doi-width-20 doi-wrap"><a [routerLink]="routerLinkProcessActTypeProcess(processActType)">{{processActType.processCode}} - {{processActType.nameListActKind}}</a></td>
							<td class="doi-width-15 doi-wrap "><a [routerLink]="routerLinkProcessActType(processActType)">{{processActType.nameList}}</a></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<!-- y35ikbwX303Q+2GdzYSH3wP+sVM= -->
