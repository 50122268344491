import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {DoiModule, DoiObjectEditGuard, DoiRedirectGuard} from '../doi/DoiModule';
import {KlaraModule} from '../klara/KlaraModule';
import {VolumeService} from './model/VolumeService';
import {VolumeObjectView} from './view/VolumeObjectView';
import {VolumeObjectGeneralPanel} from './view/VolumeObjectGeneralPanel';
import {VolumeObjectNotesPanel} from './view/VolumeObjectNotesPanel';

export * from './model/VolumeObject';
export * from './model/VolumeService';

export const volumeRoutes: Routes = [
	{path: 'volume/:id', component: VolumeObjectView, canActivate: [DoiRedirectGuard], canDeactivate: [DoiObjectEditGuard], data: { defaultChild: 'general' }, children: [
		{path: 'general', component: VolumeObjectGeneralPanel, data: {title: 'Allmänt'}},{path: 'notes', component: VolumeObjectNotesPanel, data: {title: 'Beskrivning'}},
	]},
];

@NgModule({
	imports: [
		BrowserModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(volumeRoutes),
		DoiModule,KlaraModule,
	],
	declarations: [
		VolumeObjectView, VolumeObjectGeneralPanel,VolumeObjectNotesPanel,
	],
	exports: [
	],
	providers: [
		VolumeService,
	],

})
export class VolumeModule
{
}

// 6+lXOEaz0vjnxZ0JgmGEQAZ4xt0=
